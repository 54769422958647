import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import Select from "../forms/Select_User"

const SelectUser = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Select a user to add</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select id={props.id} getGroupDetails={props.getGroupDetails}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SelectUser;

import React, { useContext } from "react";
import Sidebar from "components/chatSidebar/Sidebar";
import InnerChat from "components/chat/Chat";
import { DashboardContext } from "context/DashboardContext";

const Chat = ({ conversations}) => {
  const { chatMessages, innerChat } = useContext(DashboardContext);


  return (
    <div className="chat__card ">
      <div className="chat__card__body">
        <Sidebar conversations={conversations} />
        {chatMessages.length === 0 && !innerChat ? (
          <div className="default__image">
            Click on a conversation to view messages
              </div>
        ) : (
          innerChat &&
          <InnerChat  />
        )}
      </div>
    </div>
  );
};

export default Chat;

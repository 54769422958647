import React, { useContext, useRef, useEffect } from "react";
import Navbar from "components/dashboard-nav";
import Sidebar from "components/dashboard-sidebar";
import { ModalContext } from "context/ModalContext";
import { DashboardContext } from "context/DashboardContext";
import AddQuotation from "components/widgets/modals/AddQuotation";
import { Avatar, Skeleton, Descriptions, Divider } from "antd";
import Moment from "react-moment";
import { FileAddOutlined } from '@ant-design/icons';
import * as Icon from 'react-feather'

export default function View_Request(props) {
  // Access Context
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const {
    view_quotation_request,
    listQuotations,
    quotations,
    setQuotations,
  } = useContext(DashboardContext);

  const { quotation_request } = listQuotations;
  const client_request_id = props.match.params.id;

  useEffect(() => {
    view_quotation_request(client_request_id);
    setQuotations({ ...quotations, client_request_id: client_request_id });
  }, []);

  return (
    <>
      {quotation_request === "" ? (
        <>
          <Skeleton active />
          <Skeleton avatar active paragraph={{ rows: 7 }} />
        </>
      ) : (
        <div id="main-wrapper">
          <div className="pageheader pd-t-25 ">
            <div className="pd-t-5">
              <h1 className="pd-0 mg-0 tx-20">{`Quotation Request ${quotation_request.client_request_details.client_id}`}</h1>
            </div>
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Claims
              </a>
              <a className="breadcrumb-item active" href>
                View
              </a>
            </div>
          </div>
          <AddQuotation show={modalShow} onHide={modalClose} />
          <div className="text-right mg-y-20 pd-20  m-auto">
            <button
              type="submit"
              className="btn btn-blue text-white rounded-5  mr-2 "
              onClick={() => setModalShow(true)}
            >
              <Icon.FileText size={14} /> Create Quotation
            </button>
            <button
              type="button"
              className="btn btn-danger rounded-5"
              onclick="javascript:window.print();"
            >
              <Icon.XCircle size={14} /> Reject Request
            </button>
          </div>
          <div className=" pd-20  m-auto">
            <div className="card rounded-10 card-body bg-lighter">
              <div className="  pd-y-20">
  
                <Avatar size={65} src="https://source.unsplash.com/random" />
              </div>
              <Descriptions
                layout="vertical"
              >
                <Descriptions.Item label="Client Name">
                  <b> {quotation_request.client_request_details.names}</b>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <b>
                    {quotation_request.client_request_details.request_status}
                  </b>
                </Descriptions.Item>
                <Descriptions.Item label="Request Date">
                  {" "}
                  <b>
                    <Moment
                      format="DD MMM YYYY"
                      date={
                        quotation_request.client_request_details.created_date
                      }
                    />
                  </b>
                </Descriptions.Item>
                <Descriptions.Item label="Remarks">
                  <b>{quotation_request.client_request_details.remarks}</b>
                </Descriptions.Item>
              </Descriptions>
              <Divider/>
              <Descriptions title="Request Details">
              {quotation_request.client_request_details.request_data.map((detail)=>(
                  <Descriptions.Item label={detail.name}>{detail.value}</Descriptions.Item>
              ))}
  </Descriptions>
           

              <div className="mg-t-20">
                <a
                  type="button"
                  href
                  onClick={() => console.log("send a message clicked")}
                >
                  <p className="text-primary">
                    <i className="fa fa-comments mr-1" /> Send a message{" "}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState } from "react";
import Footer from "components/dashboard-footer";
import Navbar from "components/dashboard-nav";
import Sidebar from "components/dashboard-sidebar";
import { Button, Select } from "antd";
import * as Icon from "react-feather";
import { Pagination } from "antd";
import { Link } from "react-router-dom";

export default function Renewals() {
  const [current, setCurrent] = useState(1);

  const { Option } = Select;
  const onChange = (page) => {
    setCurrent(page);
  };

  const rows = [
    {
      image: "",
      name: "Stanley Owens",
      underwriter: "Nexus Insurance",
    policy: "NK786TY",
    product: "Hospital",
      status: "completed",
    },
    {
      image: "",
      name: "John Doe",
      underwriter: "AIG Insurance",
    policy: "YUW234",
    product: "House",
      status: "declined",
    },
    {
      image: "",
      name: "Adam Easter",
      underwriter: "Britam Insurance",
    policy: "PHE3409",
    product: "Health",
      status: "pending",
    },
    {
      image: "",
      name: "Robert Owens",
      underwriter: "Dhl",
    policy: "GB4E78",
      product: "Motor Vehicle",
      status: "completed",
    },
  ];

  return (
    <>
   
              <div className="pageheader pd-t-25 pd-b-35">
                {/* <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20">
                    <Icon.RefreshCcw size={34} className="pr-2 text-primary" />
                    Renewals
                  </h1>
                </div> */}
                <div className="breadcrumb pd-0 mg-0">
                  <a className="breadcrumb-item" href="index.html">
                    <i className="icon ion-ios-home-outline" /> Home
                  </a>
                  <a className="breadcrumb-item active" href>
                    Dashboard
                  </a>
                </div>
              </div>
              <div className="col-lg-12 col-xl-12">
                <div className="card mg-b-20">
                  <div className="card-header">
                  <div className="pd-t-5 pd-b-5 pd-l-5">
                  <h1 className=" tx-20">
                    <Icon.RefreshCcw size={34} className="pr-2 text-primary" />
                    Renewals
                  </h1>
                </div>
                    <div className="form-group mt-3 ml-auto">
                      <label className="font-weight-bold pr-2">
                        Renewals due in:
                      </label>
                      <Select defaultValue="" style={{ width: 120 }}>
                        <Option value="">Choose..</Option>
                        <Option value="completed">One week</Option>
                        <Option value="pending">One month</Option>
                        <Option value="declined">Six months</Option>
                        <Option value="declined">One year</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="card mg-b-20">
                    <div
                      className="card-body pd-0 collapse show"
                      id="customerDetails"
                    >
                      <div className="table-responsive">
                        <table className="table table-hover mg-0">
                          <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                            <tr className="bg-light">
                              <th className="pl-5">Client</th>
                              <th>Policy Number</th>
                              <th>Underwriter</th>
                              <th>Product</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row) => {
                              return (
                                <tr>
                                  <td className="d-flex align-items-center">
                                    <div className="avatar mr-2">
                                      <img
                                        src="https://via.placeholder.com/100x100"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="d-block">
                                      <a href className="my-0 mt-1 ">
                                        {row.name}
                                      </a>
                                      {/* <p className=" mg-0 tx-gray-500">
                                        {row.description}
                                      </p> */}
                                    </div>
                                  </td>
                                  <td>
                                    <p className=" mb-0 ">{row.policy}</p>
                                  </td>
                                  <td>
                                    <p className=" mb-0 ">{row.underwriter}</p>
                                  </td>
                                  <td>
                                    <p className=" mb-0 ">{row.product}</p>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge ${
                                        row.status === "pending"
                                          ? "badge-warning"
                                          : row.status === "completed"
                                          ? "badge-success"
                                          : "badge-danger"
                                      }`}
                                    >
                                      {row.status}
                                    </span>
                                  </td>

                                  <td>
                                    <div className="dropdown">
                                    <a href data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-sm btn-label-primary"><Icon.MoreHorizontal/></a>
                                      <ul className="dropdown-menu dropdown-menu-left">
                                        <li className="dropdown-item">
                                          <Link
                                            className="dropdown-link tx-13 tx-gray-500"
                                            to="/renewals/view"
                                          >
                                            <Icon.Eye
                                              size={14}
                                              className="mr-2"
                                            />
                                            View
                                          </Link>
                                        </li>

                                        <li className="dropdown-item">
                                          <a
                                            className="dropdown-link tx-13 tx-gray-500"
                                            href=""
                                          >
                                            <Icon.Mail
                                              size={14}
                                              className="mr-2"
                                            />
                                            Send Email
                                          </a>
                                        </li>
                                        <li className="dropdown-item">
                                          <a
                                            className="dropdown-link tx-13 tx-gray-500"
                                            href=""
                                          >
                                            <Icon.DownloadCloud
                                              size={14}
                                              className="mr-2"
                                            />
                                            Export as PDF
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    total={85}
                    responsive={true}
                    showTotal={(total) => `Total ${total} items`}
                    pageSize={20}
                    defaultCurrent={1}
                  />
                </div>
              </div>
      
    </>
  );
}

import React from 'react';

export default function View_Renewal() {
	return (
		<div id="main-wrapper">
			<div className="pageheader pd-t-25 ">
				<div className="pd-t-5">
					<h1 className="pd-0 mg-0 tx-20">View Renewal</h1>
				</div>
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Claims
					</a>
					<a className="breadcrumb-item active" href>
						View
					</a>
				</div>
			</div>

			<div className="text-right mg-y-20 pd-20  m-auto">
				{/* <button type="submit" className="btn btn-primary mg-t-5 mr-2" onClick={() => setModalShow(true)}><i className="far fa-sticky-note" /> Add a Note</button> */}
				<button type="submit" className="btn btn-primary mg-t-5 mr-2">
					<i className="fa fa-send" /> Send to Mail
				</button>
				<button type="button" className="btn btn-primary mg-t-5">
					<i className="fa fa-print" /> Print
				</button>
			</div>
			<div className="card pd-20  m-auto">
				<h5 className="card-title bd-b pd-y-20">
					Renewal Id:
					<a href className="tx-dark">
						#2577861
					</a>
				</h5>

				<div className="card-body pd-0 printing-area">
					<div className="row">
						<div className="col-lg-12  d-lg-block">
							<div className="card card-body bg-lighter">
								<div className="fs-12">
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Client</strong>: Robert Njoroge
									</div>
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Policy</strong>: #45234MKL{' '}
									</div>
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Premium Amount</strong>: KES. 30000
									</div>
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Purchase Date</strong>: 10/05/2020
									</div>
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Status</strong>:{' '}
										<span className="text-warning">Pending</span>
									</div>
								</div>
								<div className="">
									<strong className="d-inline-block wd-100">Remarks</strong>
								</div>
								<p className="fs-12">
									Objectively optimize multidisciplinary solutions with standards compliant materials.
									Uniquely incentivize cross-media internal or "organic" sources through virtual data.
									Energistically benchmark interdependent networks vis sticky partnerships.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

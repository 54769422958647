import React from 'react';
import { Link } from 'react-router-dom';
import { Descriptions, Dropdown, Menu } from 'antd';

const ChannelDetails = () => {
	function handleMenuClick(e) {
		console.log('click', e);
	}

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="1">Verify</Menu.Item>
			<Menu.Item key="2">Update</Menu.Item>
			<Menu.Item key="3">Delete</Menu.Item>
		</Menu>
	);
	return (
		<div>
			<div className="pageheader pd-t-25 ">
				<div className="pd-t-5">
					<h1 className="pd-0 mg-0 tx-20">Channel Details</h1>
				</div>
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Claims
					</a>
					<a className="breadcrumb-item active" href>
						View
					</a>
				</div>
			</div>
			<div className="card mg-t-45">
				<div className="card-body">
					<Descriptions
						title="Channel Details"
						size="default"
						extra={
							<Dropdown.Button overlay={menu} type="priamry">
								Actions
							</Dropdown.Button>
						}
					>
						<Descriptions.Item label="Name">CHANNEL NAME</Descriptions.Item>
						<Descriptions.Item label="System Reference">REF12</Descriptions.Item>
						<Descriptions.Item label="Status">Active</Descriptions.Item>
						<Descriptions.Item label="Channel">
							<Link style={{ color: '#40a9ff', textDecoration: 'underline' }} to="#">
								Bank
							</Link>
						</Descriptions.Item>
						<Descriptions.Item label="Creator">
							<ul>
								<li>DATE: 10/05/2021</li>
								<li>BY:RJNR</li>
							</ul>
						</Descriptions.Item>
						<Descriptions.Item label="Description" span={2}>
							dfrggr
						</Descriptions.Item>
						<Descriptions.Item label="Details" span={2}>
							<ul>
								<li>Key: Value</li>
								<li>Key: Value</li>
							</ul>
						</Descriptions.Item>
					</Descriptions>
				</div>
			</div>
		</div>
	);
};

export default ChannelDetails;

import React, { useContext } from "react";
import { Form, Input, Button } from "antd";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";
import { add_sales_rep } from "services/apiSrv";
import { useMutation } from "react-query";

export default function AddSalesRepForm() {
  const { modalClose } = useContext(ModalContext);
  const [form] = Form.useForm();

  const _addSalesRep = useMutation(add_sales_rep, {
    onSuccess: (data) => {
      console.log("THE DATA FROM USE MUTATION", data);
      modalClose();
      show_toast("Sales Rep added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });
  const onFinish = (values) => {
    console.log("ZE ZE VALUES", values);
    _addSalesRep.mutate(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="add_sales_rep_form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Sales Rep Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input a name!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Sales Rep Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "The input is not valid E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Sales Rep Phone Number"
        name="phone"
        rules={[
          {
            required: true,
            message: "Please input a phone number!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          block
          loading={_addSalesRep.isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

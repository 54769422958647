import React, { useContext } from "react";

import { Modal } from "react-bootstrap";
import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import { show_toast } from "utils/helpers";
import { ModalContext } from "context/ModalContext";
import { reject_quotation_request } from "services/apiSrv";
import routes from "routes";

const RejectQuotationRemarks = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { modalClose } = useContext(ModalContext);
  const history = useHistory();

  const reject = useMutation(reject_quotation_request, {
    onSuccess: (data) => {
      modalClose();
      show_toast("Request has been rejected successfully", "success");
      history.push(routes.quotationRequests);
    },
    onError: async (error) => {
      modalClose();
    },
  });

  const onFinish = (values) => {
    let data = {
      system_quotation_request_ref: props.systemRef,
      rejection_remarks: values.remarks,
    };
    reject.mutate(data);
  };
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Reject Quotation Request</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <p>
              If you are sure you wish to proceed, input the reason for
              rejecting the request{" "}
            </p>
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: true, message: "Remarks are required" }]}
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={reject.isLoading}
                className="text-white"
              >
                {reject.isLoading ? (
                  <>
                    <i
                      className="fa fa-circle-notch fa-spin text-white"
                      style={{ marginRight: "5px" }}
                    />
                    Loading..
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RejectQuotationRemarks;

import React from "react";
import Sales from "./Sales";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const SalesRepContainer = () => {
  async function fetch_sales_rep() {
    let resp = await (await api_srv).get_sales_rep();
    console.log("THE SALES REP HERE", resp);
    return resp.sales_reps;
  }
  const { data, isLoading, isSuccess } = useQuery("sales rep", fetch_sales_rep);

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Sales data={data} />}
    </div>
  );
};

export default SalesRepContainer;

import React from 'react';
import { Divider, Avatar, Empty } from 'antd';
import { useParams } from 'react-router-dom';
import Loader from 'components/widgets/loader';
import * as Icon from 'react-feather';
import Moment from 'react-moment';
import { useQuery } from 'react-query';
import { rm_details } from 'services/apiSrv';
import { useHistory } from 'react-router-dom';

const RMDetails = () => {
	const { id } = useParams();
	console.log('THE ID RM', id);
	const history = useHistory();

	const { isLoading, isSuccess, data } = useQuery(['rm details', id], async () => {
		const data = await rm_details(id);
		console.log('THE DATA RM DETAILS', data);
		return data;
	});

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && (
				<>
					<div className="pageheader pd-t-25 ">
						<div className="pd-t-5">
							<h1 className="pd-0 mg-0 tx-20">RM</h1>
						</div>
						<div className="breadcrumb pd-0 mg-0">
							<a className="breadcrumb-item" href="index.html">
								<i className="icon ion-ios-home-outline" /> Home
							</a>
							<a className="breadcrumb-item active" href>
								RM
							</a>
							<a className="breadcrumb-item active" href>
								View
							</a>
						</div>
					</div>
					<div className="card mg-t-45">
						<div className="card-body">
							<div className="row">
								<div className="col-md-3 ">
									<p className="text-center pd-t-5">
										<i className="text-dark">
											<b>RM Information</b>
										</i>
									</p>
									<div className="d-flex flex-column justify-content-center align-items-center pd-t-10 pd-x-15 flex-wrap">
										<Avatar size={94} className="mb-3" />
									</div>
								</div>

								<div className="col-md-9">
									<i className="fa fa-bell-o text-primary mb-3 float-right" />
									<Divider orientation="left" className="bg-light pd-y-5 font-italic">
										RM details
									</Divider>
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<strong className="d-inline-block wd-150">Name</strong>:{' '}
												{/* {data2.cover_start_date} */}
											</div>
											<div className="mb-3">
												<strong className="d-inline-block wd-150">Email</strong>:
											</div>
											<div className="mb-3">
												<strong className="d-inline-block wd-150">Contact</strong>:
												{/* {data2.premium_amount} */}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<strong className="d-inline-block wd-150">Created Date</strong>:{' '}
												{/* <Moment format="DD MMM YYYY" date={data2.purchase_date} /> */}
											</div>

											<div className="mb-3">
												<strong className="d-inline-block wd-150">RM Status</strong>:{''}
												{data.rm_details.status}
											</div>
											<div className="mb-3 d-flex">
												<strong className="d-inline-block wd-150">Reference</strong>:{' '}
												{data.rm_details.system_rm_ref}
											</div>
										</div>
									</div>
									<Divider orientation="left" className="bg-light pd-y-5 font-italic">
										Business Details
									</Divider>

									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<strong className="d-inline-block">Number of businesses</strong>:{' '}
												{data.rm_summary.total}
											</div>
											<div className="mb-3">
												<strong className="d-inline-block">Open Businesses</strong>:{' '}
												{data.rm_summary.open}
											</div>
											<div className="mb-3">
												<strong className="d-inline-block">Closed Businesses</strong>:{' '}
												{data.rm_summary.closed}
											</div>
										</div>
									</div>
									<Divider orientation="left" className="bg-light pd-y-5 font-italic">
										My Leads
									</Divider>
									{data.rm_leads.length === 0 ? (
										<Empty />
									) : (
										<div className="table-responsive">
											<table className="table table-hover mg-0">
												<thead className="tx-dark tx-uppercase tx-12 tx-bold">
													<tr className="bg-light">
														<th className="pl-5">Name</th>
														<th>Email</th>
														<th>Actions</th>
													</tr>
												</thead>
												<tbody>
													{data.rm_leads.map((lead) => (
														<tr>
															<td className="d-flex align-items-center">
																<div className="avatar mr-2">
																	<img
																		src="https://via.placeholder.com/100x100"
																		className="img-fluid"
																		alt=""
																	/>
																</div>
																<div className="d-block">
																	<a href className="my-0 mt-1 ">
																		{lead.lead_details.names}
																	</a>
																	{/* <p className=" mg-0 tx-gray-500">
                                        {row.description}
                                      </p> */}
																</div>
															</td>
															<td>{lead.lead_details.email}</td>
															<td>
																<a
																	href
																	onClick={() =>
																		history.push(
																			`/dashboard/leads/${lead.lead_ref}`
																		)
																	}
																>
																	view
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default RMDetails;

import React from "react";

import { toast } from "react-toastify";
import * as Icon from "react-feather";
import Moment from "react-moment";

// add comma separator to numbers
function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return x;
}

// show feedback toast
const show_toast = (
  msg,
  type = "error",
  position = "top-right",
  duration = 5000
) => {
  toast(`${msg}`, {
    position: position,
    type: type,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

// object iterator
function iterateObject(obj) {
  // console.log(`${key}: ${value}`);
  return (
    <ul>
      {Object.entries(obj).map(([key, value]) => (
        <li>
          <Icon.Check color="green" size={12} />
          <i className="pl-2">
            {key}~ <span>{value}</span>
          </i>{" "}
          :
        </li>
      ))}
    </ul>
  );
}

// Check file extension
const fileExtension = (file_url) => {
  let extension = file_url.split(".").pop();
  return extension;
};

// get initials
const getInitials = (string) => {
  var names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// format date
const formatDate = (date) => {
  return <Moment format="DD MMM YYYY HH:mm A" date={date} />;
};

// Check if function
function isFunction(obj) {
  return obj !== undefined && obj !== null && obj.constructor === Function;
}

// currency formatter
function convertCurrency(amount, currency) {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency || "KES",
  });
}

// greeting
function greeting() {
  var myDate = new Date();
  var hrs = myDate.getHours();

  if (hrs < 12) {
    return <span>Good Morning</span>;
  } else if (hrs >= 12 && hrs <= 17) {
    return <span>Good Afternoon</span>;
  } else if (hrs >= 17 && hrs <= 24) {
    return <span>Good Evening</span>;
  }
}

// append options
function appendOptions(data, label, value) {
  let options = [];
  for (let i = 0; i < data.length; i++) {
    const _data = data[i];
    options.push({
      value: _data[value],
      label: _data[label],
    });
  }

  return options;
}


/**
 * Generates a list of steps for display by the steps component.
 * This function returns a function that accepts a step object and returns
 * the object with extra properties.
 *
 * @param {Object} route
 * @returns {Function}
 */
export function generateStepsList({ route }) {
    const routeParams = route.params
    const routeParamKeys = Object.keys(routeParams)

    // Swap out any dynamic routes with their param values so "/portfolio/:portfolioId" becomes "/portfolio/1"
    const replaceParams = (path, param) => path.replace(`:${param}`, routeParams[param]);

    const createStepEntry = ({ path, label }) => {
        let routePath = routeParamKeys.length ? routeParamKeys.reduce(replaceParams, path) : path
        let active = route.pathname === path

        return {
            path: routePath,
            active,
            label
        };
    }

    return createStepEntry;
  }


export {
  numberWithCommas,
  show_toast,
  iterateObject,
  fileExtension,
  getInitials,
  formatDate,
  isFunction,
  convertCurrency,
  greeting,
  appendOptions,
};

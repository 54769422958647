import React, { useContext, useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import { Select } from "antd";
import { useQuery, useMutation } from "react-query";

import "../../../assets/css/dashboard.css";
import { ModalContext } from "context/ModalContext";
import { DashboardContext } from "context/DashboardContext";
import {
  add_product_benefits,
  list_product_benefits,
  recommendation_details,
} from "services/apiSrv";
import { show_toast } from "utils/helpers";
import Loader from "components/widgets/loader/ComponentLoader";

const AddProductBenefit = (props) => {
  const { Option } = Select;
  // Access Context

  console.log("THE PROPS FOR BENEFITS", props);
  const { modalClose } = useContext(ModalContext);
  const { prodId } = useContext(DashboardContext);
  const [selectedBenefits, setSelectedBenefits] = useState([]);

  // await add_product_benefits(quotationRequestRef, selectedBenefits);
  function handleBenefitChange(e, value) {
    console.log("selected benefits", e);
    setSelectedBenefits(e);
  }
  async function fetch_benefits() {
    let resp = await list_product_benefits(prodId);
    return resp.product_benefits;
  }

  const { data, isLoading, isSuccess } = useQuery("benefits", fetch_benefits);

  const postBenefits = useMutation(add_product_benefits, {
    onSuccess: (data) => {
      modalClose();
      recommendation_details(props.requestref);
      show_toast("Benefits added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
      let err = await error;
      throw err;
    },
  });

  let postData = {
    ref: props.requestref,
    benefits: selectedBenefits,
  };
  console.log("PRODUCT ID IS PASSED", props);

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Add Product Benefit</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isLoading && <Loader />}
          {isSuccess && (
            <>
              <p>Select the product benefits to attach to the recommendation</p>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder=""
                defaultValue={[]}
                onChange={(_, e) => handleBenefitChange(e)}
              >
                {data.map((benefit, i) => (
                  <Option
                    key={i}
                    value={benefit.product_benefit_id}
                    name={benefit.free_limit}
                  >
                    {benefit.name}
                  </Option>
                ))}
              </Select>

              <div className="d-flex justify-content-center">
                <button
                  disabled={postBenefits.isLoading}
                  className=" btn btn-success px-5 my-3 text-white"
                  onClick={() => postBenefits.mutate(postData)}
                >
                  {postBenefits.isLoading && (
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {postBenefits.isLoading && (
                    <span className="text-capitalize">Please wait...</span>
                  )}
                  {!postBenefits.isLoading && (
                    <span className="text-capitalize">Add</span>
                  )}
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProductBenefit;

export const loginForm = {
  form_title: "Sign In",
  form_subtitle: "Welcome back",
  button_label: "Log In",
  button_styles: "block btn-blue",
  fields: [
    {
      key: "username",
      label: "Username",
      name: "username",
    },
    {
      key: "password",
      label: "Password",
      name: "password",
      type: "password",
    },
    {
      key: "signed_in",
      label: "",
      name: "signed_in",
      type: "checkbox",
      options: [
        {
          key: "true",
          label: "Keep me signed in",
          value: "true",
        },
      ],
    },
  ],
};

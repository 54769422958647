import React, { useContext } from "react";
import { Avatar, Pagination, Button } from "antd";
import * as Icon from "react-feather";
import { ModalContext } from "context/ModalContext";
import NegotiateRate from "components/widgets/modals/NegotiateRate";
import ViewRateModal from "components/widgets/modals/ViewUnderwriterRate";
import { useQuery } from "react-query";
import { fetch_rates, base_url } from "services/apiSrv";
import { useParams } from "react-router-dom";
import CustomTable from "components/widgets/table";
import Loader from "components/widgets/loader";

const UnderwriterRates = (props) => {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const { groupRef } = useParams();
  async function get_rates() {
    let resp = await fetch_rates(groupRef);
    console.log("TTHE RATES", resp);
    return resp.group_products;
  }
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    "rates",
    get_rates
  );
  const tableData = {
    icon: "",
    title: "My Rates",
    table_fields: [
      { name: "Name", align: "c" },
      { name: "Class", align: "c" },
      { name: "Rate Type", align: "c" },
      { name: "Rate Name", align: "c" },
      { name: "Value", align: "c" },
      { name: "Actions", align: "c" },
    ],
    // search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "scheme_group_logo", class: "bold center" },
      { type: "text", field: "scheme_group_name", class: "small center" },
      {
        type: "text",
        field: "scheme_group_description",
        class: "small center",
      },
      {
        type: "text",
        field: "policy_management_type",
        class: "small center",
      },
      { type: "text", field: "scheme_group_status", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "system_scheme_group_ref",
            method: "",
          },
          {
            type: "menu",
            title: "Delete",
            event: "table:delete",
            icon: "fa-trash-alt",
          },
        ],
      },
    ],
  };

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <div>
          <NegotiateRate
            show={modalShow === "negotiate" ? true : false}
            onHide={modalClose}
            system_scheme_group_ref={groupRef}
            refetch={refetch}
          />
          <ViewRateModal
            show={modalShow === "viewRate" ? true : false}
            onHide={modalClose}
          />
          <div className="pageheader pd-t-25 ">
            <div className="pd-t-5">
              <h1 className="pd-0 mg-0 tx-20">View Underwriter Rate</h1>
            </div>
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Underwriter Rate
              </a>
              <a className="breadcrumb-item active" href>
                View
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mg-t-50">
              <div className="mg-b-20">
                <h5 className="text-center">Negotiate Rate</h5>
                <div
                  className="card-body pd-0 collapse show"
                  id="customerDetails"
                >
                  <div className="clearfix">
                    <Button
                      className="btn-primary btn-blue rounded mb-3 float-right text-white "
                      onClick={() => setModalShow("negotiate")}
                    >
                      Negotiate
                    </Button>
                  </div>
                  {/* <CustomTable
                    table_fields={tableData.table_fields}
                    row_data={tableData.row_data}
                    table_data={data}
                    title={tableData.title}
                  /> */}
                  <div className="table-responsive">
                    <table className="table table-hover mg-0">
                      <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                        <tr className="bg-light">
                          <th className="pl-5">Product Name</th>

                          <th>Insurance Class</th>
                          <th>Rate Name</th>
                          <th>Rate Description</th>
                          <th>Underwriter</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((prod, i) => (
                          <tr key={i}>
                            <td className="d-flex align-items-center">
                              <div className="avatar mr-2">
                                <img
                                  src={base_url + prod.product_details.logo}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="d-block">
                                <a href className="my-0 mt-1 ">
                                  {prod.product_details.product_name}
                                </a>
                              </div>
                            </td>
                            <td>{prod.insurance_details.name}</td>
                            <td>{prod.scheme_rate_name}</td>
                            <td>{prod.scheme_rate_description}</td>
                            <td>{prod.underwriter_details.name}</td>

                            <td>
                              <div className="dropdown">
                                <a
                                  href
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className="btn btn-sm btn-label-primary"
                                >
                                  <Icon.MoreHorizontal />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-left">
                                  <li className="dropdown-item">
                                    <a
                                      href
                                      // onClick={() => setModalShow("viewRate")}
                                      className="dropdown-link tx-13"
                                    >
                                      <Icon.Eye size={14} className="mr-2" />
                                      View
                                    </a>
                                  </li>

                                  <li className="dropdown-item">
                                    <a href className="dropdown-link tx-13">
                                      <Icon.Trash2 size={14} className="mr-2" />
                                      Remove
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                responsive={true}
                total={data.length}
                showTotal={(total) => `Total ${total} items`}
                pageSize={10}
                defaultCurrent={1}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UnderwriterRates;

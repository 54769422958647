import React, { useState, useContext, useEffect } from 'react';
import './chat.css';
import { Avatar } from 'antd';
import * as Icon from 'react-feather';
import io from 'socket.io-client';
import VideoChat from 'components/widgets/modals/VideoChat';
import { ModalContext } from 'context/ModalContext';
import { DashboardContext } from 'context/DashboardContext';
import { Scrollbars } from 'react-custom-scrollbars';

const Endpoint = 'https://chat.demo.nexus.ke';

let socket;

const Chat = () => {
	const [newMessage, setNewMessage] = useState('');
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const { chatMessages, setChatMessages, incomingMessage, conversationId, toChatId } = useContext(DashboardContext);

	useEffect(() => {
		// check for incoming message & if its from current conversation

		if (Object.keys(incomingMessage).length !== 0) {
			setChatMessages([...chatMessages, incomingMessage]);
		}
	}, [incomingMessage]);

	//   send a chat
	const sendMessage = async () => {
		socket = io(Endpoint);
		try {
			let message = {};
			message.conversation_id = conversationId;
			message.conversation = {
				conversation_type: 'text',
				conversation_text: newMessage,
				conversation_path: '',
				from_chat_user_id: parseInt(sessionStorage.getItem('chat_id')),
				to_chat_user_id: parseInt(toChatId),
			};

			await socket.emit('chat:message', message);
			setChatMessages([...chatMessages, message]);
			console.log('message was sent successfully', chatMessages);
			setNewMessage('');
		} catch (err) {
			console.log('message was not sent successfully', err);
			throw err;
		}
	};

	const messageHandler = (e) => {
		const { value } = e.target;
		setNewMessage(value);
	};
	return (
		<>
			<VideoChat show={modalShow === 'video' ? true : false} onHide={modalClose} />

			<div className="chat">
				<div className="chat__header">
					<Avatar src="https://avatars.dicebear.com/api/human/a30.svg" />
					<div className="chat__headerInfo">
						<h3>Robert Njoroge</h3>
						<p>Last seen at.....</p>
					</div>
					<div className="chat__headerRight">
						<a href>
							<Icon.Search size={18} />
						</a>
						<a href onClick={() => setModalShow('video')}>
							<Icon.Video size={18} />
						</a>
						<a href>
							<Icon.MoreVertical size={18} />
						</a>
					</div>
				</div>
				<Scrollbars
					autoHide
					autoHideTimeout={1000}
					autoHideDuration={200}
					// style={{ height: '100vh' }}
				>
					<div className="chat__body">
						{/* {setRecepient(chatMessages[0].conversation.to_chat_user_id)} */}
						{chatMessages.map((message) => {
							let condition =
								Number(message.conversation.from_chat_user_id) ===
								Number(sessionStorage.getItem('chat_id'));
							return (
								<p className={`chat__messages  ${condition && 'chat__reciever'}`}>
									{/* <span className="chat__name">Kelvin Kamau</span> */}

									{message.conversation.conversation_text}
									<span className="chat__timestamp">{message.conversation.conversation_date}</span>
								</p>
							);
						})}
					</div>
				</Scrollbars>
				<div className="chat__footer">
					<a href>
						<Icon.Smile size={18} />
					</a>
					<a href>
						<Icon.Paperclip size={18} />
					</a>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							sendMessage();
						}}
					>
						<input value={newMessage} onChange={messageHandler} placeholder="Type a message" type="text" />
						<button type="submit">
							<Icon.Send color="#000" size={18} />
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default Chat;

import React from "react";
import Underwriters from "./Underwriters";
import { fetch_my_underwriters } from "services/apiSrv";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const UnderwritersContainer = () => {
  async function get_my_underwriters() {
    let resp = await fetch_my_underwriters();
    return resp.underwritters;
  }
  const { data, isLoading, isSuccess, refetch } = useQuery(
    "underwriters",
    get_my_underwriters
  );

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Underwriters data={data} refetch={refetch} />}
    </div>
  );
};

export default UnderwritersContainer;

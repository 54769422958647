import React from 'react';
import Departments from './Departments';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const DepartmentsContainer = (props) => {
	const fetch_departments = async () => {
		let resp = await (await api_srv).list_departments();
		return resp.departments;
	};

	const view_department = (id) => {
		props.history.push(`/dashboard/usmg/departments/view/${id}`);
	};

	const { data, isLoading, isSuccess, refetch } = useQuery('departments', fetch_departments);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Departments state={data} view_department={view_department} refetch={refetch} />}
		</div>
	);
};

export default DepartmentsContainer;

import React from "react";
import styles from "./Footer.module.css";

export default function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  return (
    <footer className={styles.footer}>
      <div></div>
      <div className="pd-t-8 pd-b-0 pd-x-20">
        <div className="tx-10 tx-uppercase">
          <p className="pd-y-10 mb-0 text-dark">
            Copyright© {year} | All rights reserved. | Nexus
          </p>
        </div>
      </div>
      <ul>
        <li>
          <a href="#">
            <span />
            <span />

            <span className="fa fa-twitter" />
          </a>
        </li>
        <li>
          <a href="#">
            <span />
            <span />
            <span className="fa fa-facebook" />
          </a>
        </li>
        <li>
          <a href="#">
            <span />
            <span />
            <span className="fa fa-instagram" />
          </a>
        </li>
        <li>
          <a href="#">
            <span />
            <span />
            <span className="fa fa-linkedin" />
          </a>
        </li>
        <li>
          <a href="#">
            <span />
            <span />
            <span className="fa fa-whatsapp" />
          </a>
        </li>
      </ul>
    </footer>
  );
}

import React, { useContext } from "react";
import RoleForm from "./AddRoleForm";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

const AddRoleFormContainer = ({ fetch_roles_list }) => {
  const { usmg, setUsmg } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  const handleChange = (evt) => {
    const { value, name } = evt.target;

    setUsmg((prevState) => ({
      ...prevState,
      role_added: {
        ...prevState.role_added,
        [name]: value,
      },
    }));
  };

  const addRole = async () => {
    setUsmg((prevState) => ({
      ...prevState,
      role_added: {
        ...prevState.role_added,
        loading: true,
      },
    }));
    try {
      let resp = await (await api_srv).add_usmg_roles(usmg.role_added);
      console.log("Role", resp);
      setUsmg((prevState) => ({
        ...prevState,
        role_added: {
          ...prevState.role_added,
          loading: false,
          name: "",
          description: "",
          role_code: "",
        },
      }));
      fetch_roles_list();
      modalClose();
      show_toast("Role added successfully", "success");
    } catch (err) {
      let error = await err;
      console.log("error adding role", error);
      setUsmg((prevState) => ({
        ...prevState,
        role_added: {
          ...prevState.role_added,
          loading: false,
        },
      }));
      modalClose();
    }
  };
  return (
    <div>
      <RoleForm handleChange={handleChange} addRole={addRole} />
    </div>
  );
};

export default AddRoleFormContainer;

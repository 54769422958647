import React, { useContext } from "react";

import { Link, withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import { MenuOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import Moment from "react-moment";

import { DashboardContext } from "context/DashboardContext";
import routes from "routes";
import { api_srv } from "services";

function Nav_Content() {
  const { isToggled, setToggled } = useContext(DashboardContext);

  async function fetch_intermediary_details() {
    let resp = await (await api_srv).get_intermediary_type();
    return resp.intermediary_details.intermediary_details;
  }

  async function get_notifications() {
    let resp = await (await api_srv).fetch_notifications();
    return resp.result;
  }

  const {
    data: details,
    isLoading: detailsLoading,
    isError: detailsError,
    isSuccess: detailsLoaded,
  } = useQuery("profile details", fetch_intermediary_details);
  const {
    data: notifications,
    isLoading: notifLoading,
    isError: notifError,
    isSuccess: notifLoaded,
  } = useQuery("notifications", get_notifications);
  const toggleTrueFalse = () => setToggled(!isToggled);

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <ul className="list-inline d-flex justify-content-between list-unstyled mg-r-20">
          <li className="list-inline-item align-text-top">
            <a
              className="hidden-xs hidden-sm tx-20"
              href
              id="collapsed-sidebar-toggle-button"
            >
              {React.createElement(MenuOutlined, {
                onClick: toggleTrueFalse,
              })}
            </a>
          </li>
        </ul>

        {/*/ Brand and Logo End */}
        {/*================================*/}
        {/* Header Right Start */}
        {/*================================*/}
        {detailsLoading && null}
        {detailsLoaded && (
          <div className="header-right pull-right ml-auto">
            <ul className="list-inline justify-content-end">
              {/*================================*/}
              {/* Notifications Dropdown Start */}
              {/*================================*/}
              <li className="list-inline-item dropdown hidden-xs">
                <a
                  className="notification-icon"
                  href
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Icon.Bell size={18} />
                  <span className="notification-count wave in" />
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow-2">
                  {/* Top Notifications Area */}
                  <div className="top-notifications-area">
                    {/* Heading */}
                    <div className="notifications-heading">
                      <div className="heading-title">
                        <h6>Notifications</h6>
                      </div>
                      <span>5+ New Notifications</span>
                    </div>
                    <div className="notifications-box" id="notificationsBox">
                      {/* {notifications.map((obj, i) => (
                        <a
                          key={i}
                          className="dropdown-item list-group-item"
                          href
                        >
                          <div className="d-flex justify-content-between">
                            <div className="wd-45 ht-38 mg-r-15 d-flex align-items-center justify-content-center rounded-circle card-icon-primary">
                              <i className="fa fa-info tx-info tx-16" />
                            </div>
                            <div>
                              <span>{obj.subject}</span>
                              <span className="small tx-gray-600 ft-right">
                                <Moment fromNow>{obj.notification_date}</Moment>
                              </span>
                              <div className="tx-gray-600 tx-11">
                                {obj.notification}
                              </div>
                            </div>
                          </div>
                        </a>
                      ))} */}
                    </div>
                    <div className="notifications-footer">
                      <a href>View all Notifications</a>
                    </div>
                  </div>
                </div>
              </li>
              {/*/ Notifications Dropdown End */}
              {/*================================*/}
              {/* Messages Dropdown Start */}
              {/*================================*/}
              <li className="list-inline-item dropdown hidden-xs">
                <a
                  className="message-icon"
                  href
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Icon.Mail size={18} />
                  <span className="messages-count wave in" />
                </a>
              </li>
              {/*/ Messages Dropdown End */}
              {/*================================*/}
              {/* Profile Dropdown Start */}
              {/*================================*/}
              <li className="list-inline-item dropdown">
                <a
                  href
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="select-profile">
                    Hi,{details.intermediary_names}
                  </span>
                  <img
                    src={
                      `${api_srv.base_url}${details.image_path}` ||
                      `https://via.placeholder.com/100x100`
                    }
                    className="img-fluid wd-35 ht-35 rounded-circle"
                    alt=""
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
                  <div className="user-profile-area">
                    <div className="user-profile-heading">
                      <div className="profile-text">
                        <h6>{details.intermediary_names}</h6>
                        {details.emails.map((email) => (
                          <span>{email}</span>
                        ))}
                      </div>
                    </div>
                    <Link to={routes.profile} className="dropdown-item">
                      <Icon.User size={14} className="mr-2" /> My profile
                    </Link>
                    <Link to={routes.reviews} className="dropdown-item">
                      <Icon.ThumbsUp size={14} className="mr-2" />
                      Reviews{" "}
                      <span className="badge badge-warning ft-right mg-t-3">
                        5+
                      </span>
                    </Link>
                    <Link to={routes.home} className="dropdown-item">
                      <Icon.Power size={14} className="mr-2" /> Sign-out
                    </Link>
                  </div>
                </div>
              </li>
              {/* Profile Dropdown End */}
              {/*================================*/}
            </ul>
          </div>
        )}
        {/*/ Header Right End */}
      </nav>
    </>
  );
}
export default withRouter(Nav_Content);

import React from 'react';
import { Input, Form, Button } from 'antd';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

const ChannelDetails = ({ showConfirmDrawer, onDetailsDrawerClose }) => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		console.log(values);
		showConfirmDrawer();
	};
	return (
		<div>
			<Form
				form={form}
				layout="vertical"
				name="channel_details"
				onFinish={onFinish}
				validateMessages={validateMessages}
			>
				<Form.Item name="channel_name" label="Channel Name" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item name="channel_description" label="Description">
					<Input.TextArea />
				</Form.Item>
				<Form.Item name="channel_reference" label="Your Reference" rules={[{ required: true }]}>
					<Input />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" className="float-right">
						Next
					</Button>
					<Button type="primary" className="float-right mr-2" onClick={() => onDetailsDrawerClose()}>
						Back
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default ChannelDetails;

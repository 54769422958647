import React, { useState } from 'react';
import { Form, Input, Button, Radio, Select } from 'antd';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

const types = ['fixed', 'percentage', 'slab'];

const AddIncentive = () => {
	const [form] = Form.useForm();
	const onFinish = (values, key) => {
		console.log('we are finished', values);
	};
	const onFinishFailed = ({ errorFields }) => {
		form.scrollToField(errorFields[0].name);
	};

	const { TextArea } = Input;
	const { Option } = Select;
	const [type, setType] = useState('');

	const handleFormValuesChange = (changedValues) => {
		const fieldName = Object.keys(changedValues)[0];

		if (fieldName === 'types') {
			const value = changedValues[fieldName];
			setType(value);
		}
	};

	return (
		<div>
			<Form
				name="incentives"
				layout="vertical"
				onFinish={onFinish}
				scrollToFirstError
				validateMessages={validateMessages}
				onValuesChange={handleFormValuesChange}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item label="Incentive Name" name="names" labelAlign="left" rules={[{ required: true }]}>
					<Input />
				</Form.Item>
				<Form.Item label="Incentive Reference" name="reference" labelAlign="left" rules={[{ required: true }]}>
					<Input />
				</Form.Item>

				<Form.Item label="Incentive Type" name="types" labelAlign="left" rules={[{ required: true }]}>
					<Radio.Group>
						{types.map((option) => (
							<Radio key={option} value={option}>
								{option}
							</Radio>
						))}
					</Radio.Group>
				</Form.Item>
				{console.log('THE TYPE IS', type)}
				{type === 'fixed' && (
					<Form.Item label="Incentive Value" name="value" labelAlign="left" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				)}
				{type === 'percentage' && (
					<Form.Item label="Incentive Percentage" name="value" labelAlign="left" rules={[{ required: true }]}>
						<Select defaultValue="">
							{[...Array(100)].map((el, index) => (
								<Option key={index} value={el}>
									{Number(el + 1)}
								</Option>
							))}
						</Select>
					</Form.Item>
				)}
				{type === 'slab' && (
					<Form.Item label="Incentive Range" name="value" labelAlign="left" rules={[{ required: true }]}>
						<Radio.Group>
							<Radio value={12}>0-10,000 </Radio>
							<Radio value={14}>10,001- 20,000</Radio>
							<Radio value={16}>20,001-30,000</Radio>
							<Radio value={19}>Above 30,000</Radio>
						</Radio.Group>
					</Form.Item>
				)}
				<Form.Item
					label="Incentive Description"
					name="description"
					labelAlign="left"
					rules={[{ required: true }]}
				>
					<TextArea rows={4} />
				</Form.Item>

				<Form.Item>
					<Button
						className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
						htmlType="submit"
						block
					>
						Add
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddIncentive;

import React from 'react';
import { Select } from 'antd';

export default function AssignRmForm({ rm, selectedManager, _assign, handleChange, lead_ref }) {
	const { Option } = Select;
	let data = {
		lead_ref: lead_ref,
		system_rm_ref: selectedManager.value,
	};
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				_assign.mutate(data);
			}}
		>
			<div className="form-row">
				<div className="col-md-12">
					<div className="form-group mt-0 mb-3">
						<label className="">Select Relationship Manager</label>
						<Select
							// mode="multiple"
							allowClear
							style={{ width: '100%' }}
							placeholder="Please select"
							// defaultValue={[]}
							onChange={handleChange}
						>
							{rm.map((option, i) => (
								<Option key={option.system_rm_ref} value={option.system_rm_ref}>
									{option.employee_id}~{option.system_rm_ref}
								</Option>
							))}
						</Select>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center mb-4">
				<button className="mx-auto btn btn-success" type="submit" style={{ width: '220px' }}>
					{_assign.isLoading && <i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} />}
					{_assign.isLoading && <span className="text-capitalize">Please wait...</span>}
					{!_assign.isLoading && <span className="text-capitalize">Submit</span>}
				</button>
			</div>
		</form>
	);
}

import React, { useContext, useEffect } from "react";
import VerifyToken from ".";
import { AuthContext } from "../../context/AuthContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { api_srv } from "../../services";

const VerifyResetTokenContainer = (props) => {
  const {
    confirmimgToken,
    setConfirmingToken,
  } = useContext(AuthContext);

  const { token } = queryString.parse(props.location.search);
  let history = useHistory();

  const verify_token = async (token) => {
    setConfirmingToken(true);
    try {
      let resp = await (await api_srv).validate_reset_token(token);
      localStorage.setItem(
        "password_change_request",
        resp.password_change_request_id
      );
      setConfirmingToken(false);
      history.push("/reset2");

   
    } catch (err) {
      let error = await err;
      setConfirmingToken(false);
      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });
    }
  };

  useEffect(() => {
    verify_token(token);
  }, []);

  return (
    <VerifyToken
      
      confirmimgToken={confirmimgToken}
    />
  );
};

export default VerifyResetTokenContainer;

import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import { Select } from "antd";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

const AddProfession = (props) => {
  const { Option } = Select;
  // Access Context
  const { professionList, setProfessionList } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  async function fetch_professions_list() {
    try {
      let resp = await (await api_srv).get_professions_list();
      console.log(resp);
      setProfessionList({
        ...professionList,
        professions: resp.insurance_sub_classes,
      });
    } catch (err) {
      let error = await err;
      console.log(error);
    }
  }
  useEffect(() => {
    fetch_professions_list();
  }, [professionList.selected]);

  const handleProfessionListChange = (value) => {
    setProfessionList({ ...professionList, selected: value });
  };

  async function update_professions() {
    if (professionList.selected.length === 0) {
      modalClose();
      show_toast("Kindly select a profession", "error");
    } else {
      try {
        setProfessionList({ ...professionList, loading: true });
        let resp = await (
          await api_srv
        ).update_professions(professionList.selected);
        setProfessionList({ ...professionList, loading: false, selected: [] });
        modalClose();
        show_toast("Profession added successfully", "success");
      } catch (err) {
        setProfessionList({ ...professionList, loading: false, selected: [] });
        modalClose();
      }
    }
  }

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Add a Profession</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Select the professions for which you have been registered to
            undertake
          </p>
          <div className="form-group ">
            <label htmlFor="select" className="font-weight-bold">
              Select your professions
            </label>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select a profession(s)"
              defaultValue={[]}
              onChange={handleProfessionListChange}
            >
              {professionList.professions.map((profession, i) => (
                <Option key={i} value={profession.code}>
                  {profession.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="submit"
              disabled={professionList.loading}
              className=" btn btn-success px-5 my-3 text-white"
              onClick={() => {
                update_professions();
              }}
            >
              {professionList.loading && (
                <i
                  className="fa fa-circle-notch fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
              {professionList.loading && (
                <span className="text-capitalize">Please wait...</span>
              )}
              {!professionList.loading && (
                <span className="text-capitalize">Add</span>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProfession;

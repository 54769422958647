import React, { useState, useContext } from "react";
// import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "antd";
import { api_srv } from "services";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";
const AddClaimRemarks = (props) => {
  const [remarks, setRemarks] = useState("");
  const { modalClose } = useContext(ModalContext);

  const handleChange = (evt) => {
    const value = evt.target.value;
    setRemarks(value);
  };

  const sendRemarks = async () => {
    try {
      let resp = await (await api_srv).approve_pending_claims(remarks);
      modalClose();
      show_toast(`${resp.claim_approval_status}`, "success");
    } catch (err) {}
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="text-grey">Remarks</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="remarks">Enter your remarks</label>
          <textarea
            name="remarks"
            rows={3}
            value={remarks}
            className="form-control"
            onChange={handleChange}
            placeholder=""
          ></textarea>
        </div>
        <button
          className="btn btn-success btn-block  mt-5 mb-3  px-4 py-1 rounded text-white"
          onClick={() => sendRemarks()}
        >
          Send
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AddClaimRemarks;

import React, { useState } from 'react';
import { Select, Radio, Input, Slider, Form, Button, InputNumber, Row, Col } from 'antd';
import { GrChapterAdd } from 'react-icons/gr';
import { AiOutlineDelete } from 'react-icons/ai';

export default function TaxForm({
	_addtaxes,
	management,
	taxCategories,
	taxType,
	accounts,
	handleFormValuesChange,
	onInputChange,
	slab,
	handleAddClick,
	handleRemoveClick,
}) {
	const { Option } = Select;
	const [form] = Form.useForm();
	const { TextArea } = Input;

	const [systemTaxRef, setSystemTaxRef] = useState('');

	function handleChange(value, obj) {
		setSystemTaxRef(obj.id);
	}

	const onFinish = async (values) => {
		let modValues = { ...values, slab, systemTaxRef };
		_addtaxes.mutate(modValues);
	};

	return (
		<Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={handleFormValuesChange}>
			<Form.Item label="Tax Name" name="tax_name" rules={[{ required: true, message: 'Tax Name is required' }]}>
				<Input placeholder="input the tax name" />
			</Form.Item>
			<Form.Item
				label="Tax Reference"
				name="entity_tax_ref"
				rules={[{ required: true, message: 'Tax Reference is required' }]}
			>
				<Input placeholder="input the tax reference" />
			</Form.Item>

			<Form.Item
				label="Description"
				name="tax_description"
				rules={[{ required: true, message: 'Tax Description is required' }]}
			>
				<TextArea placeholder="input the tax description" />
			</Form.Item>
			<Form.Item
				label="Tax Category"
				name="tax_category"
				rules={[{ required: true, message: 'Select a category' }]}
			>
				<Select
					showSearch
					placeholder="Search or choose your tax category.."
					optionFilterProp="children"
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					onChange={handleChange}
				>
					{taxCategories.map((option) => (
						<Option
							value={option.tax_category_name}
							key={option.system_tax_category_ref}
							id={option.system_tax_category_ref}
						>
							{option.tax_category_name}
						</Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item
				label="Tax Type"
				name="tax_type"
				rules={[{ required: true, message: 'Select tax type' }]}
				initialValue={taxType}
			>
				<Radio.Group buttonStyle="solid">
					<Radio.Button value="flat">Flat</Radio.Button>
					<Radio.Button value="percentage">Percentage</Radio.Button>
					<Radio.Button value="slab">Slab</Radio.Button>
				</Radio.Group>
			</Form.Item>
			{taxType === 'flat' && (
				<Form.Item label="" name="tax_value">
					<InputNumber placeholder="input value" style={{ width: '100%' }} />
				</Form.Item>
			)}
			{taxType === 'percentage' && (
				<Form.Item label="" name="tax_value">
					<InputNumber placeholder="input value" style={{ width: '100%' }} />
				</Form.Item>
			)}
			{taxType === 'slab' &&
				slab.map((el, i) => (
					<div className="row" key={i}>
						<div className="col-md-3">
							<Form.Item label="Min Value">
								<input
									name="min"
									type="number"
									required
									style={{ width: '100%' }}
									value={el.min}
									oninput="(validity.valid)||(value='');"
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-3">
							<Form.Item label="Max Value">
								<input
									name="max"
									type="number"
									style={{ width: '100%' }}
									value={el.max}
									oninput="(validity.valid)||(value='');"
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-4">
							<Form.Item label="Enter Value">
								<input
									name="value"
									type="number"
									required
									min={el.min}
									max={el.max}
									oninput="(validity.valid)||(value='');"
									style={{ width: '100%' }}
									value={el.value}
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-2">
							{slab.length - 1 === i && <GrChapterAdd color="#296918" onClick={handleAddClick} />}
							{slab.length !== 1 && (
								<AiOutlineDelete className="ml-2" color="red" onClick={() => handleRemoveClick(i)} />
							)}
						</div>
					</div>
				))}

			<Form.Item
				label="Management"
				name="management"
				rules={[{ required: true, message: 'Select management' }]}
				initialValue={management}
			>
				<Radio.Group>
					<Radio value="SYSTEM">Nexus</Radio>
					<Radio value="ENTITY">On Intermediary</Radio>
				</Radio.Group>
			</Form.Item>
			{management === 'SYSTEM' && (
				<Form.Item label="Select Account" name="entity_tax_account">
					<Select defaultValue="">
						{accounts.map((account) => (
							<Option value={account.account_number} key={account.account_number}>
								{account.account_name}
							</Option>
						))}
					</Select>
				</Form.Item>
			)}
			<Form.Item label="Remarks" name="remarks" rules={[{ required: true, message: 'Remarks are required' }]}>
				<TextArea placeholder="input remarks" />
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit" block>
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
}

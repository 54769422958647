import React from 'react';
import { Modal } from 'react-bootstrap';
import 'assets/css/dashboard.css';
import NewConfig from 'components/widgets/forms/settlementConfig/SettlementConfigContainer';

const AddUser = (props) => {
	return (
		<div>
			<Modal
				show={props.show}
				onHide={props.onHide}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey text-center">Add Settlement Configuration</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<NewConfig refetch={props.refetch} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddUser;

import { toast } from "react-toastify";

async function handle_api_error(error) {
  const notify = (error) => {
    toast.error(`${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  try {
    if (error.response) {
      // Request made and server responded
      console.log(
        "=======Request made and server responded====",
        error.response.data.err_msg
      );
      return notify(error.response.data.err_msg);

      // return <ToastContainer />;
    } else if (error.request) {
      console.log(
        "========The request was made but no response =========== ",
        error.request
      );

      return notify(
        "An error occured while making the request. Kindly check your internet connection"
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error, error.err_msg);

      return error.err_msg;
    }
  } catch (err) {
    return err;
  }
}

export { handle_api_error };

import React, { useState } from "react";

import { Descriptions } from "antd";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";

import { getTaxDetails, calculateTax } from "services/apiSrv";
import Loader from "components/widgets/loader";
import { formatDate } from "utils/helpers";
import { calculateTaxForm } from "components/widgets/forms/Calculate_Tax_Form";
import CustomForm from "components/widgets/forms/CustomForm";
import { CalculateTaxSchema } from "utils/validationSchemas";
import routes from "routes";

const TaxDetails = () => {
  const { id } = useParams();
  const [calculations, setCalculations] = useState(null);

  async function fetchTaxDetails() {
    let resp = await getTaxDetails(id);
    console.log("THE RESP", resp);
    return resp.tax_details;
  }

  const { data, isSuccess, isLoading } = useQuery(
    "Tax details",
    fetchTaxDetails
  );

  const onSubmit = async (model) => {
    let system_tax_ref = data.system_tax_ref;
    let modValues = { ...model, system_tax_ref };
    try {
      let resp = await calculateTax(modValues);
      setCalculations(resp.tax_calculation);
    } catch (err) {}
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <Link className="breadcrumb-item active" to={routes.dashboard}>
                <i className="icon ion-ios-home-outline " /> Home
              </Link>
              <Link className="breadcrumb-item active" to="#">
                <i className="icon ion-ios-home-outline " /> Account
              </Link>
              <Link className="breadcrumb-item active" to={routes.tax}>
                <i className="icon ion-ios-home-outline " /> Taxes
              </Link>
              <Link className="breadcrumb-item ">details</Link>
            </div>
            <h5 className="text-capitalize">{data.tax_name} Details</h5>
          </div>

          <div className="card mg-b-20">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  {" "}
                  <Descriptions title="Tax Details" size="default">
                    <Descriptions.Item label="Tax Name">
                      {data.tax_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Type">
                      {data.tax_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Value">
                      {data.tax_value}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created Date">
                      {formatDate(data.created_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Account">
                      {data.entity_tax_account}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Reference">
                      {data.entity_tax_ref}
                    </Descriptions.Item>
                    <Descriptions.Item label="Tax Management">
                      {data.tax_management}
                    </Descriptions.Item>
                    <Descriptions.Item label=" Status">
                      {data.status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Currency">
                      {data.entity_tax_currency}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {data.tax_description}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="col-md-3">
                  <h6 className="text-center">Calculate Tax</h6>
                  <CustomForm
                    className="form mt-md-0 mt-4"
                    validationSchema={CalculateTaxSchema}
                    model={calculateTaxForm}
                    onSubmit={(model) => {
                      onSubmit(model);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {calculations !== null && (
            <div className="card mg-b-20">
              <div className="card-body">
                <Descriptions title="Calculation Results" size="default">
                  <Descriptions.Item label="Tax Type">
                    {calculations.tax_type}
                  </Descriptions.Item>
                  <Descriptions.Item label="Gross Amount">
                    {calculations.gross_amount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tax Value">
                    {calculations.tax_value}
                  </Descriptions.Item>
                  <Descriptions.Item label="Net Amount">
                    {calculations.net_amount}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tax Amount">
                    {calculations.tax_amount}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TaxDetails;

import React, { useContext, useRef, useEffect, useState } from 'react';
import { DashboardContext } from '../../../context/DashboardContext';
import { ModalContext } from '../../../context/ModalContext';
import routes from 'routes';
import { Row, Form, Col, Input, Select, Radio, Checkbox, Button, InputNumber, DatePicker, TimePicker } from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Location from 'components/widgets/modals/AssetLocation';
import * as Icon from 'react-feather';
import { UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import UploadComponent from 'components/uploads';
// import { useSnackbar } from 'notistack';

const ClaimForm = ({ product_id, claim_code, closeIncidentDrawer, onClose }) => {
	// const { enqueueSnackbar } = useSnackbar();

	const history = useHistory();
	const {
		claimFormFields,
		address,
		claimDetails,
		setClaimFormFields,
		setClaimDetails,
		setAddress,
		coordinates,
		setCoordinates,
		handleClaimDetailsChange,
		claimId,
		handleOpenSnackbar,
	} = useContext(DashboardContext);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	const [checkedValues, setCheckedValues] = useState([]);

	const fetch_claim_form = async () => {
		let resp = await (await api_srv).fetch_form(product_id, claim_code);
		setClaimFormFields(resp.form_fields);
		console.log('THE REPORT FORM HERE', resp.form_fields);
		return resp.form_fields;
	};
	const { isLoading, isError, isSuccess, data } = useQuery('report form', fetch_claim_form);
	const { Option } = Select;

	const handleChange = (address) => {
		setAddress(address);
	};

	const handleCheckbox = (e) => {
		const {
			target: { name, value },
		} = e;
		setCheckedValues([...checkedValues, value]);
		setClaimDetails({ ...claimDetails, [name]: checkedValues });
	};

	console.log('the claim form fields are', claimFormFields);
	const radioStyle = {
		display: 'block',
		height: '30px',
		lineHeight: '30px',
	};
	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select style={{ width: 85 }} defaultValue="254">
				<Option value="254">+254</Option>
				<Option value="255">+255</Option>
				<Option value="256">+256</Option>
			</Select>
		</Form.Item>
	);

	const handleSelect = async (value) => {
		try {
			const results = await geocodeByAddress(value);
			// get the lat and lng of the  result user has selected
			const { lat, lng } = await getLatLng(results[0]);
			setCoordinates({ ...coordinates, lat: lat, lng: lng });
			setAddress(value);
		} catch (error) {}
	};

	const report_a_claim = async (values) => {
		try {
			let resp = await (await api_srv).report_claim(claimId.id, values);

			closeIncidentDrawer();
			onClose();
			//   enqueueSnackbar('Claim reported successfully!', {variant:'success',anchorOrigin: {
			//     vertical: 'bottom',
			//     horizontal: 'right',
			// },});
			history.push(routes.claims);
		} catch (err) {
			let error = await err;
			console.log('report a claim error', error.message);
		}
	};

	const onFinish = async (values) => {
		console.log('======the value to be sent==========');
		console.log(values);
		console.log('======the value to be sent==========');
		report_a_claim(values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	return (
		<Form layout="vertical" id="report-claim-form" onFinish={onFinish} onFinishFailed={onFinishFailed}>
			<Location show={modalShow === 'reportClaimMap' ? true : false} onHide={modalClose} />
			{isLoading && 'Loading'}
			{isSuccess && (
				<Row gutter={16}>
					{data.map((field) => {
						switch (field.type_info.type) {
							case 'text':
								if (field.type_info.sub_type === 'textarea') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												onChange={handleClaimDetailsChange}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Input.TextArea rows={4} placeholder={field.hint} />
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'calender') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												onChange={handleClaimDetailsChange}
											>
												<DatePicker />
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'email') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												rules={[
													{
														type: 'email',
														message: 'The input is not valid E-mail!',
													},
													{
														required: `${field.validation.is_required}`,
														message: 'Please input your E-mail!',
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'number') {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<InputNumber min={1} max={1000000000000} />
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'contact') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Input addonBefore={prefixSelector} style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									);
								} else {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												onChange={handleClaimDetailsChange}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Input name={field.field_label} placeholder={field.hint} />
											</Form.Item>
										</Col>
									);
								}

							case 'option':
								if (field.type_info.sub_type === 'select') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Select placeholder={field.hint} name={field.field_label}>
													{field.values.map((option) => (
														<Option value={option}>{option}</Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									);
								} else if (
									field.type_info.sub_type === 'radio' ||
									field.type_info.sub_type === 'multiple_select_other'
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												name={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Radio.Group
													onChange={handleClaimDetailsChange}
													name={field.field_label}
												>
													{field.values.map((option) => (
														<Radio style={radioStyle} value={option}>
															{option}
														</Radio>
													))}
												</Radio.Group>
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'date_time') {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												name={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<TimePicker format="HH:mm" />
											</Form.Item>
										</Col>
									);
								} else if (
									field.type_info.sub_type === 'yes_qstn' ||
									field.type_info.sub_type === 'yes_no'
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												name={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<Radio.Group
													onChange={handleClaimDetailsChange}
													name={field.field_label}
												>
													{field.values.map((option) => (
														<Radio style={radioStyle} value={option}>
															{option}
														</Radio>
													))}
												</Radio.Group>
											</Form.Item>
										</Col>
									);
								} else if (field.type_info.sub_type === 'checkbox') {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												name={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												{field.values.map((checkbox) => (
													<Checkbox
														id={field.field_label}
														name={field.field_label}
														value={checkbox}
														onChange={handleCheckbox}
													>
														{checkbox}
													</Checkbox>
												))}
											</Form.Item>
										</Col>
									);
								}
								break;
							case 'attachement':
								if (field.type_info.sub_type === 'document') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												onChange={handleClaimDetailsChange}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<UploadComponent />
											</Form.Item>
										</Col>
									);
								} else {
									return (
										<Col span={12}>
											<Form.Item
												label={field.field_label}
												name={field.field_label}
												rules={[
													{
														required: `${field.validation.is_required}`,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<UploadComponent />
											</Form.Item>
										</Col>
									);
								}
							case 'search':
								if (field.type_info.sub_type === 'location') {
									return (
										<Col span={12}>
											<Form.Item
												name={field.field_label}
												label={field.field_label}
												rules={[
													{
														required: false,
														message: `${field.field_label} is required`,
													},
												]}
											>
												<PlacesAutocomplete
													value={address}
													onChange={handleChange}
													onSelect={handleSelect}
												>
													{({
														getInputProps,
														suggestions,
														getSuggestionItemProps,
														loading,
													}) => (
														<div>
															<input
																{...getInputProps({
																	placeholder: 'Search Location ...',
																	className:
																		'location-search-input form-control border ',
																})}
															/>
															<div className="autocomplete-dropdown-container">
																{loading && <div>Loading...</div>}
																{suggestions.map((suggestion, i) => {
																	const style = suggestion.active
																		? {
																				backgroundColor: '#f5f5f5',
																				cursor: 'pointer',
																		  }
																		: {
																				backgroundColor: '#ffffff',
																				cursor: 'pointer',
																		  };
																	return (
																		<div
																			className="input-suggestion"
																			key={i}
																			{...getSuggestionItemProps(suggestion, {
																				style,
																			})}
																		>
																			<i className="material-icons">
																				location_on{' '}
																			</i>{' '}
																			<span>{suggestion.description}</span>
																		</div>
																	);
																})}
															</div>
														</div>
													)}
												</PlacesAutocomplete>

												<a
													href
													onClick={() => setModalShow('reportClaimMap')}
													className="d-block text-primary"
												>
													<small className="text-primary">
														or choose from Map <Icon.MapPin size={14} />
													</small>
												</a>
											</Form.Item>
										</Col>
									);
								}
								break;
							default:
								console.log('option here');
						}
					})}
				</Row>
			)}
		</Form>
	);
};

export default ClaimForm;

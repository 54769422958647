import React, { useContext, useRef } from 'react';
import { Button, Pagination } from 'antd';
import * as Icon from 'react-feather';
import { ModalContext } from 'context/ModalContext';
import Sales from 'components/widgets/modals/SalesRep';
import NoData from 'components/no-data/NoData';
import { useHistory } from 'react-router-dom';

export default function SalesComponent({ data }) {
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const history = useHistory();

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>

			<Sales show={modalShow} onHide={modalClose} />
			<div className="row row-xs clearfix">
				<Button
					className="btn-primary btn-blue rounded mb-3 ml-auto text-white "
					onClick={() => setModalShow(true)}
				>
					Add Representative
				</Button>

				<div className="col-lg-12  rounded-10 col-xl-12">
					{data.length === 0 ? (
						<NoData message="You do not have any sales representatives" />
					) : (
						<div className=" card mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5">
									<h1 className="pd-0 mg-0 tx-20">
										<Icon.Briefcase size={36} className="pr-2 text-primary" />
										Sales Representative
									</h1>
								</div>
							</div>

							<>
								<div className="mg-b-20">
									<div className="card-body pd-0 collapse show" id="customerDetails">
										<div className="table-responsive">
											<table className="table table-hover mg-0">
												<thead className="tx-dark tx-uppercase tx-12 tx-bold">
													<tr className="bg-light">
														<th className="pl-5">Name</th>
														<th>Email</th>
														<th>Sales Rep Ref</th>
														<th>Status</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{data.map((row, i) => (
														<tr>
															<td className="pl-5">{row.sales_rep_names}</td>
															<td>{row.sales_rep_email}</td>
															<td>{row.sales_rep_ref}</td>
															<td>{row.status}</td>

															<td>
																{' '}
																<a
																	href
																	className="btn btn-sm btn-label-primary"
																	onClick={() =>
																		history.push(
																			`/dashboard/salesrep/${row.sales_rep_ref}`
																		)
																	}
																>
																	View
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<Pagination
									responsive={true}
									total={data.length}
									showTotal={(total) => `Total ${total} items`}
									pageSize={10}
									defaultCurrent={1}
								/>
							</>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

import React, {useState, useEffect,useContext} from "react";
import { Button, Input } from "antd";
import {DashboardContext} from "context/DashboardContext"
import * as Icon from 'react-feather';
import { Pagination } from 'antd';
import {Link} from 'react-router-dom'


export default function Commissions() {

  const context =useContext(DashboardContext)
  const {paginate, numEachPage,handlePaginationChange,commissionAccounts,fetch_commission}= context

  const [current, setCurrent]= useState(1)
  const { Search } = Input;

  const [search, setSearch]= useState('')
  const onChange = page => {
  
    setCurrent(page);
  };

useEffect(()=>{
  fetch_commission()
},[])

console.log("accounts here",commissionAccounts)
  var data = [
    {
      "id": 0,
      "name": "Mayer Leonard",
      "date": "12/02/2020",
      "status": "Completed",
 
    },
    {
      "id": 1,
      "name": "Someoner Leonard",
      "date": "10/03/2020",
      "status": "Pending",
   
    },
    {
      "id": 2,
      "name": "Mayer Leonard",
      "date": "23/08/2020",
      "status": "Pending",
     
    },
    {
      "id": 3,
      "name": "New Leonard",
      "date": "09/04/2020",
      "status": "Rejected",
    },
    {
      "id": 4,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 5,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 6,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 7,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 8,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 9,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },
    {
      "id": 10,
      "name": "John Leonard",
      "date": "3/06/2020",
      "status": "Completed",
    },

  ];


function searchMe(evt){
  evt.preventDefault();

  if (evt.target.value){
    let filteredItems = rows.filter((item)=>{
     
  return(
    // item.id.toLowerCase().includes(search.toLowerCase()) ||
    item.name.toLowerCase().includes(search.toLowerCase())|| 
    item.date.toLowerCase().includes(search.toLowerCase()) ||
    item.status.toLowerCase().includes(search.toLowerCase()) 
    // item.id &&    item.name &&
    // Item.date  &&    item.status
    // .match(search)
     
  )
    })
  setSearch(evt.target.value)
  setRows(filteredItems)

  }else{
    setSearch("")
    setRows(data)
  }
}

const[rows, setRows]= useState(data)


  return (
    <>
  
              <div className="pageheader pd-t-25 pd-b-35">
                {/* <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20"><Icon.Shield  size={36} className="pr-2 text-primary" />Claims</h1>
                </div> */}
                <div className="breadcrumb pd-0 mg-0">
                  <a className="breadcrumb-item" href="index.html">
                    <i className="icon ion-ios-home-outline" /> Home
                  </a>
                  <a className="breadcrumb-item active" href>
                    Dashboard
                  </a>
                </div>
              </div>
              <div className="col-lg-12 card rounded-10 col-xl-12">
             
        <div className="mg-b-20">
          <div className="card-header">
          <div className="pd-t-5 pd-b-5 pd-l-5">
                  <h1 className="tx-20"><Icon.Shield  size={36} className="pr-2 text-primary" />My Commission Accounts</h1>
                </div>
            <Search
              placeholder="input search text"
              type="search"
              value={search}
              onChange={searchMe}
              className="ml-auto mg-r-10"
      style={{ width: 200 }}
    />
          </div>
          <div className="card-body collapse show" id="studentDetails">
            <div className="">
           
        
              <table className="table table-hover mb-0">
                <thead className="tx-13 tx-uppercase">
                  <tr className="bg-light">
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Currency Code</th>
                    <th>Available Balance</th>
                    <th className="tx-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                 { commissionAccounts.length===0?null : commissionAccounts.map((row)=>{
                    return(
                      <tr>
                      <td>#{row.account_name}</td>
                      <td>{row.account_number}</td>
                      <td>{row.currency_code}</td>
                      <td>
                        {row.available_balance}
                      </td>
                      <td className="tx-right">
                        <div className="dropdown">
                        <a href data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="btn btn-sm btn-label-primary"><Icon.MoreHorizontal/></a>
                         
                          <ul className="dropdown-menu dropdown-menu-right">
                            <li className="dropdown-item"><Link className="dropdown-link tx-13 tx-gray-500" to="/claims/view"><Icon.Eye size={12} className=" mr-2" />View</Link></li>
  
                            <li className="dropdown-item"><Link className="dropdown-link tx-13 tx-gray-500" href><Icon.Mail size={12} className=" mr-2"/>Send Email</Link></li>
                            <li className="dropdown-item"><Link className="dropdown-link tx-13 tx-gray-500" href><Icon.FileText size={12} className=" mr-2"/>Export as PDF</Link></li>
   
                          </ul>
                        </div>
                      </td>
                    </tr>
                    )
                  })}
              
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
      className="ml-auto"
      responsive={true}
      dataSource={commissionAccounts}
      total={commissionAccounts.length}
      showTotal={total => `Total ${total} items`}
      defaultPageSize={numEachPage}
      defaultCurrent={1}
      onChange={handlePaginationChange}
    />
      </div>
    

    </>
  );
}

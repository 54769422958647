import React from "react";

import { useHistory } from "react-router-dom";

import MyPolicies from "./MyPolicies";
import { get_policies } from "services/apiSrv";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const PoliciesContainer = () => {
  const history = useHistory();

  async function fetch_my_policies() {
    let policies_resp = await get_policies();
    return policies_resp.portfolio;
  }

  const { isLoading, isSuccess, data } = useQuery(
    "policies",
    fetch_my_policies
  );

  const handle_view_portfolio = (id) => {
    history.push(`/dashboard/policies/view/${id}`);
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <MyPolicies data={data} handle_view_portfolio={handle_view_portfolio} />
      )}
    </div>
  );
};

export default PoliciesContainer;

import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { Select } from "antd";
import { show_toast } from "utils/helpers";

//  Validation
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function AddUser({ fetchUsers }) {
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [security, setSecurity] = useState([]);

  // Access Context

  const { usersmgt, setUsersmgt } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  // Destructure

  const { Option } = Select;

  function handleUsersMgtChange(evt) {
    const { value, name } = evt.target;
    setUsersmgt({
      ...usersmgt,
      [name]: value,
    });
  }

  const addUser = async () => {
    setUsersmgt({ ...usersmgt, loading: true });
    try {
      let resp = await (await api_srv).create_user(usersmgt);
      setUsersmgt({ ...usersmgt, loading: false });
      fetchUsers();
      modalClose();
      show_toast(
        `An invitation email has been sent to ${usersmgt.email}`,
        "success"
      );
    } catch (err) {
      setUsersmgt({ ...usersmgt, loading: false });
      modalClose();
    }
  };

  const initialize = async () => {
    try {
      let resp = await (await api_srv).list_usmg_roles();
      setRoles(resp.roles);
      let department_resp = await (await api_srv).list_departments();
      setDepartments(department_resp.departments);
      let security_resp = await (await api_srv).list_security_context();
      console.log("security res", security_resp);
      setSecurity(security_resp.security_contexts);
    } catch (err) {
      let error = await err;
    }
  };
  useEffect(() => {
    initialize();
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={usersmgt}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Group Name is required"),
          email: Yup.string()
            .email("Please enter a valid email")
            .required("Email is required"),
          contact: Yup.string().required("Contact is required"),
          username: Yup.string().required("Username is required"),
          ref: Yup.string().required("Staff Reference is required"),
          role: Yup.string().required("Role is required"),
          department: Yup.string().required("Please select a department"),
          security: Yup.string().required("Please select security context"),
        })}
        onSubmit={() => addUser()}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <div className="form-row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="name" className="font-weight-bold">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    value={usersmgt.name}
                    onChange={handleUsersMgtChange}
                    className={
                      "form-control border" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="email" className="font-weight-bold">
                    Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    value={usersmgt.email}
                    onChange={handleUsersMgtChange}
                    className={
                      "form-control border" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="contact" className="font-weight-bold">
                    Contact
                  </label>
                  <input
                    name="contact"
                    type="contact"
                    value={usersmgt.contact}
                    onChange={handleUsersMgtChange}
                    className={
                      "form-control border" +
                      (errors.contact && touched.contact ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="contact"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="username" className="font-weight-bold">
                    Username
                  </label>
                  <input
                    name="username"
                    type="text"
                    value={usersmgt.username}
                    onChange={handleUsersMgtChange}
                    className={
                      "form-control border" +
                      (errors.username && touched.username ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="username"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="ref" className="font-weight-bold">
                    Staff Reference
                  </label>
                  <input
                    name="ref"
                    type="text"
                    value={usersmgt.ref}
                    onChange={handleUsersMgtChange}
                    className={
                      "form-control border" +
                      (errors.ref && touched.ref ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="ref"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="role" className="font-weight-bold">
                    Role
                  </label>
                  <select
                    defaultValue=""
                    name="role"
                    style={{ display: "block" }}
                    onChange={handleUsersMgtChange}
                    value={usersmgt.role}
                    className={`form-control ${
                      errors.role && touched.role ? " is-invalid" : ""
                    } `}
                  >
                    <option value="">select Role</option>
                    {roles.map((role) => (
                      <option value={role.role_code}>{role.name}</option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="department" className="font-weight-bold">
                    Department
                  </label>
                  <select
                    className={`form-control ${
                      errors.department && touched.department
                        ? " is-invalid"
                        : ""
                    } `}
                    id="department"
                    name="department"
                    onChange={handleUsersMgtChange}
                    value={usersmgt.department}
                  >
                    <option value="">select A department</option>
                    {departments.map((department) => (
                      <option value={department.department_code}>
                        {department.department_name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="department"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="security" className="font-weight-bold">
                    Security Context
                  </label>
                  <select
                    defaultValue=""
                    name="security"
                    style={{ display: "block" }}
                    onChange={handleUsersMgtChange}
                    value={usersmgt.security}
                    className={`form-control ${
                      errors.security && touched.security ? " is-invalid" : ""
                    } `}
                  >
                    <option value="">select Security Context</option>
                    {security.map((security) => (
                      <option value={security.security_context_code}>
                        {security.name}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="security"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                color="primary"
                variant="contained"
                type="submit"
                disabled={usersmgt.loading}
                className="btn btn-success px-5 my-4 "
              >
                {usersmgt.loading && (
                  <i
                    className="fa fa-circle-notch fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {usersmgt.loading && (
                  <span className="text-capitalize">Please wait...</span>
                )}
                {!usersmgt.loading && (
                  <span className="text-capitalize"> Add User</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

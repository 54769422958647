import React, { useContext, useEffect } from 'react';
import ViewSecurityContext from './ViewSecurityContext';
import { api_srv } from 'services';
import { DashboardContext } from 'context/DashboardContext';

const ViewSecurityContextContainer = (props) => {
	let security_code = props.match.params.id;

	const { securityDetails, setSecurityDetails } = useContext(DashboardContext);
	const context_details = async () => {
		setSecurityDetails({ ...securityDetails, loading: true });
		try {
			let resp = await (await api_srv).get_context(security_code);
			console.log('response', resp.security_context_details);
			setSecurityDetails({ ...securityDetails, data: resp.security_context_details, loading: false });
		} catch (err) {
			let error = await err;
			console.log('error getting details', error);
			setSecurityDetails({ ...securityDetails, loading: false });
		}
	};
	useEffect(() => {
		context_details();
	}, []);

	return (
		<div>
			<ViewSecurityContext state={securityDetails.data} />
		</div>
	);
};

export default ViewSecurityContextContainer;

import React, { useContext } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { Button, Input, Pagination, Avatar } from "antd";
import NoData from "components/no-data/NoData";
import AddUser from "components/widgets/modals/AddUser";

export default function Users({
  data,
  view_user,
  fetchUsers,
  getInitials,
  search,
  searchSpace,
}) {
  // access context

  const { paginate, numEachPage, handlePaginationChange } = useContext(
    DashboardContext
  );
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  const { Search } = Input;


  const items = data
          .filter((data) => {
        
            if (search == null) return data;
            else if (
              data.names.toLowerCase().includes(search.toLowerCase()) ||
              data.username.toLowerCase().includes(search.toLowerCase()) ||
              data.department_code
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              data.role_code.toLowerCase().includes(search.toLowerCase()) ||
              data.status_code.toLowerCase().includes(search.toLowerCase())
            ) {
              return data;
            }
          })
          .slice(paginate.minValue, paginate.maxValue)
          .map((row) => {
            let initials = getInitials(row.names);

            return (
              <tr key={row.user_id}>
                <td className="d-flex align-items-center">
                  <div className=" mr-4">
                    <Avatar
                      size={40}
                      style={{
                        color: "#001f5f",
                        backgroundColor: "rgba(5,88,183,0.10)",
                      }}
                    >
                      <small>{initials}</small>
                    </Avatar>
                  </div>
                  <div className="d-block">
                    <a href className="my-0 mt-1 ">
                      {row.names}
                    </a>
                  </div>
                </td>
                <td>{row.username}</td>

                <td>{row.department_code}</td>
                <td>{row.role_code}</td>
                <td>{row.status_code}</td>

                <td>
                  <div className="dropdown">
                    <a
                      href
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="btn btn-sm btn-label-primary"
                    >
                      <Icon.MoreHorizontal />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-left">
                      <li className="dropdown-item">
                        <Link
                          onClick={() => view_user(row.user_id)}
                          className="dropdown-link tx-13"
                        >
                          <Icon.Eye size={14} className="mr-2" />
                          View
                        </Link>
                      </li>

                      <li className="dropdown-item">
                        <a className="dropdown-link tx-13 tx-gray-500" href>
                          <Icon.Trash2 size={14} className="mr-2" />
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            );
          });

  return (
    <>
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="breadcrumb pd-0 mg-0">
            <a className="breadcrumb-item" href="index.html">
              <i className="icon ion-ios-home-outline" /> Home
            </a>
            <a className="breadcrumb-item active" href>
              Dashboard
            </a>
          </div>
        </div>
        <AddUser show={modalShow} onHide={modalClose} fetchUsers={fetchUsers} />
        <Button
          className="btn-blue text-white rounded float-right mb-3"
          onClick={() => setModalShow(true)}
        >
          New User
        </Button>

        {data.length === 0 ?(
          <NoData message="You currently have no users" />
        ) : (
          <>
            <div className="col-lg-12 card rounded-10 col-xl-12">
              <div className="mg-b-20">
                <div className="card-header">
                  <div className="pd-t-5 pd-b-5 pd-l-5">
                    <h1 className=" tx-18">
                      <Icon.Users size={30} className="pr-2 text-primary" />
                      Users
                    </h1>
                  </div>
                  <Search
                    placeholder="Search User"
                    type="search"
                    onChange={(e) => searchSpace(e)}
                    className="ml-md-auto mg-r-10"
                    style={{ width: 200 }}
                  />
                </div>
                <div className="mg-b-20">
                  <div
                    className="card-body pd-0 collapse show"
                    id="customerDetails"
                  >
                    <div className="table-responsive">
                      <table className="table table-hover mg-0">
                        <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                          <tr className="bg-light">
                            <th className="">Name</th>
                            <th>Username</th>
                            <th>Department Code</th>
                            <th>Role Code</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{items}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                  className="ml-auto"
                  responsive={true}
                  dataSource={data}
                  total={data.length}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={numEachPage}
                  defaultCurrent={1}
                  onChange={handlePaginationChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

import React, { useState } from "react";
import ChatGroups from "./ChatGroups";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const ChatGroupContainer = (props) => {
  const [search, setSearch] = useState(null);

  
  const createChatGroup =async ()=>{
      try{
        let resp = await (await api_srv).create_chat_groups()
        console.log("THE RESPONSE FROM CREATING CHAT GROUP", resp)
      }catch(err){
        throw err
      }
  }

  const fetch_chat_groups = async () => {
    let resp = await (await api_srv).get_chat_groups();
    console.log("fetched chat groups", resp);
    if(resp.users.length===0){
      createChatGroup()
    }
    return resp.users;
  };

 

  const { data, isLoading, isSuccess } = useQuery("chatGroups", fetch_chat_groups);

  // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <ChatGroups
          data={data}
          search={search}
          searchSpace={searchSpace}
          fetch_chat_groups={fetch_chat_groups}
        />

       )} 

    </div>
  );
};

export default ChatGroupContainer;

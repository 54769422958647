import React from 'react';
import { Link } from 'react-router-dom';
import { Descriptions, Dropdown, Menu } from 'antd';

const SettlementDetails = () => {
	return (
		<div>
			<div className="pageheader pd-t-25 ">
				<div className="pd-t-5">
					<h1 className="pd-0 mg-0 tx-20">Settlement Details</h1>
				</div>
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Claims
					</a>
					<a className="breadcrumb-item active" href>
						View
					</a>
				</div>
			</div>
			<div className="card mg-t-45">
				<div className="card-body">
					<Descriptions title="Settlement Details" size="default">
						<Descriptions.Item label="Account">1234</Descriptions.Item>
						<Descriptions.Item label="Reference">ref2344</Descriptions.Item>
						<Descriptions.Item label="Amount">10000</Descriptions.Item>
						<Descriptions.Item label="Status">Active</Descriptions.Item>
						<Descriptions.Item label="Channel">Active</Descriptions.Item>
						<Descriptions.Item label="Transaction ref">
							<Link style={{ color: '#40a9ff', textDecoration: 'underline' }} to="#">
								reteye3435
							</Link>
						</Descriptions.Item>
						<Descriptions.Item label="Created By">
							<ul>
								<li>date: 10/05/2021</li>
								<li>name: RB JNR</li>
							</ul>
						</Descriptions.Item>
					</Descriptions>
				</div>
			</div>
		</div>
	);
};

export default SettlementDetails;

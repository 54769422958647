import React from "react";
import { Redirect } from "react-router-dom";
import Spinner from "components/widgets/loader";
import "assets/css/custom.css";

export default function VerifyEmail({ confirmimgToken }) {
  const styles = {
    height: "70px",
    width: "70px",
  };

  return (
    <div className="centerdiv">
      {confirmimgToken ? (
        <>
          <p>Please wait...</p>
          <Spinner style={styles} />{" "}
        </>
      ) :  <Redirect to="/reset2" />
     
      
      }
    </div>
  );
}

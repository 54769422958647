import React, { useContext, useState } from "react";
import SecurityContextView from "./Security_Context";
import { DashboardContext } from "context/DashboardContext";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const SecurityContextContainer = (props) => {
  const [visible, setVisible] = useState(false);

  const fetch_security_context = async () => {
    let resp = await (await api_srv).list_security_context();
    return resp.security_contexts;
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const view_context = (id) => {
    props.history.push(`/dashboard/usmg/security/view/${id}`);
  };
  const { data, isLoading, isSuccess } = useQuery(
    "securityContext",
    fetch_security_context
  );
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <SecurityContextView
          data={data}
          showDrawer={showDrawer}
          onClose={onClose}
          visible={visible}
          setVisible={setVisible}
          view_context={view_context}
          fetch_security_context={fetch_security_context}
        />
      )}
    </div>
  );
};

export default SecurityContextContainer;

import React,{useEffect, useContext, useState} from 'react'
import ViewUsers from './View_Users'
import {DashboardContext} from 'context/DashboardContext'
import {api_srv} from 'services'

const ViewUsersContainer = (props) => {

    const [search, setSearch] = useState(null);


    const {quotationRequests,setQuotationRequests}= useContext(DashboardContext)

    async function fetch_quotation_requests() {
        setQuotationRequests({ ...quotationRequests, loading: true });
        try {
          let resp = await (await api_srv).get_quotation_requests();
          setQuotationRequests({
            ...quotationRequests,
            data: resp.client_requests,
            loading: false,
          });
        } catch (err) {
          let error = await err;
          setQuotationRequests({ ...quotationRequests, loading: false });
        }
      }

    useEffect(() => {
        try {
          fetch_quotation_requests();
        } catch (error) {
          console.log(error.response);
        } 
      
    },[]);


      // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  const view_quotation_request = (id) => {
    props.history.push(`/dashboard/quotation-requests/view/${id}`);
  };
    return (
        <div>
           <ViewUsers
           
           searchSpace={searchSpace}
           search={search}
           /> 
        </div>
    )
}

export default ViewUsersContainer

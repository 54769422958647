import React, { useContext, useState } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import MultiSelect from "react-multi-select-component";
import {Upload} from 'antd';
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons";
//  Validation
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function AddUserChat() {

  // Access Context

  const dashboardContext = useContext(DashboardContext);

  // Destructure

  const{reportGroup, handleReportGroupChange,loading,add_report_group} = dashboardContext

 const users=[]
 const uploadButton = (
  <div>
    {loading ? (
      <LoadingOutlined />
    ) : (
      <CameraOutlined style={{ fontSize: "25px" }} />
    )}
    <div className="ant-upload-text">Upload</div>
  </div>
);

// const { imageUrl } = logo;
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={reportGroup}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Group Name is required"),
          code: Yup.string().required("Report Group Code is required"),
          description: Yup.string().required("A description is required"),

        })}
        onSubmit={()=>add_report_group()}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <div className="form-row">
              <div className="col-md-12">
              <div className="form-group "> 
          <label htmlFor="select"  className="font-weight-bold">Select a User to add</label>
          <MultiSelect
            options={users}
            // value={users.selected}
            // onChange={handleProfessionListChange}
            labelledBy={"Select"}
          />
        </div>
              </div>
              <div className="col-md-12">
              <div className="form-group d-flex ">
              <div className="">
                <label className="font-weight-bold">Upload Image</label>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader logo-image"
                  showUploadList={false}
                  // customRequest={uploadDirectorImage}
                  // beforeUpload={beforeUpload}
                  // onChange={handle_director_image_change}
                >
                  {/* {imageUrl ? ( */}
                    <img
                      src=""
                      alt="avatar"
                      className="img-fluid wd-100 ht-100 "
                    />
                  {/* ) : (
                    uploadButton */}
                  {/* )} */}
                </Upload>
              </div>
            </div>
              </div>
      
              <div className="col-md-12 m-0">
          <div className="form-group  mt-0 ">
            <label className="font-weight-bold">Chat Description</label>
            <textarea
              rows="4"
              type="text"
              name="description"
              className={
                "form-control border" +
                (errors.description && touched.description
                  ? " is-invalid"
                  : "")
              }
              value={reportGroup.description}
              onChange={handleReportGroupChange}
            />
                <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
          </div>
        </div>
         
            </div>
            <div className="d-flex justify-content-center">
              <button
                color="primary"
                variant="contained"
                type="submit"
                className="btn btn-outline-success px-5 my-4 "
              >
                Add User
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import { Button, Form, Input } from "antd";
import { useMutation } from "react-query";
import { show_toast } from "utils/helpers";
import { ModalContext } from "context/ModalContext";
import { create_lead_quotation } from "services/apiSrv";
import routes from "routes";
import { useHistory } from "react-router-dom";

const CloseRemarks = (props) => {
  const [form] = Form.useForm();
  const { modalClose } = useContext(ModalContext);
  const history = useHistory();

  const _createQuotation = useMutation(create_lead_quotation, {
    onSuccess: (data) => {
      modalClose();
      show_toast("Success", "success");
      history.push(routes.quotations1);
    },
    onError: async (error) => {
      modalClose();
    },
  });

  const onFinish = (values) => {
    let data = {
      lead_ref: props.lead_ref,
      lead_quotation_ref: values.lead_quotation_ref,
    };
    _createQuotation.mutate(data);
  };
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Create Quotation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Enter Quotation Reference"
              name="lead_quotation_ref"
              rules={[
                { required: true, message: "Quotation Reference is required" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={_createQuotation.isLoading}
              >
                {_createQuotation.isLoading ? (
                  <div className="text-white">
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    Loading..
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CloseRemarks;

import React, { useContext } from "react";
import bg from "assets/images/auth/credentials3.jpg";
import { Link } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Alert from "components/alerts/WarningAlert";
import { AlertsContext } from "context/AlertsContext";
import { AuthContext } from "context/AuthContext";

const PasswordReset = ({
  state,
  submitHandler,
  handleChange,
  togglePassword,
  toggleConfirmPassword,
}) => {
  const { showAlert } = useContext(AlertsContext);
  const{alert} = useContext(AuthContext)
  const { message, variant } = alert;
  console.log("the state", state)

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      validationSchema={Yup.object().shape({
        newPass: Yup.string()
          .min(6, "Password must be atleast 6 characters")
          .required("Password is required"),
        newPass2: Yup.string()
          .oneOf([Yup.ref("newPass")], "Passwords do not match")
          .required("Confirm password is required"),
      })}
      onSubmit={() => submitHandler()}
    >
      {({ errors, touched }) => (
        <div className="d-flex">
          <div
            className="col-md-6 d-flex "
            style={{
              backgroundImage: `url(${bg})`,
              backgroundSize: "cover",
              height: "100vh",
            }}
          >
            <Link to="/" className="">
              <img
                style={{
                  height: "100px",
                  width: "100px",
                }}
                alt="Logo"
              />
            </Link>

            <div
              className=""
              style={{
                position: "absolute",
                margin: "auto",
                bottom: "0",
              }}
            >
              <Link to="#" className="">
                Terms and conditions |
              </Link>
              <Link to="#" className=" ">
                {" "}
                &copy; {new Date().getFullYear()} Nexus
              </Link>
            </div>
          </div>

          <div className="col-md-6">
            <div className="container">
              <div
                className="col-md-7 "
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "25%",
                }}
              >
                <h4 className="text-center mb-5">Enter your New Password</h4>
                {showAlert && <Alert message={message} variant={variant} />}
                <Form className="login-form">
                  
                      <div className="form-group mt-0">
                        <label htmlFor="newPass">New Password</label>
                        <input
                          name="newPass"
                          type={state.showPassword ? "text" : "password"}
                          value={state.newPass}
                          onChange={handleChange}
                          className={
                            "form-control border password-input" +
                            (errors.newPass && touched.newPass
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <i
                          className={`fa ${
                            state.showPassword ? "fa-eye" : "fa-eye-slash"
                          } password-icon`}
                          onClick={togglePassword}
                        />
                        <ErrorMessage
                          name="newPass"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                 
                      <div className="form-group mt-0">
                        <label htmlFor="newPass2">Confirm Password</label>
                        <input
                          name="newPass2"
                          type={state.showConfirmPassword ? "text" : "password"}
                          value={state.newPass2}
                          onChange={handleChange}
                          className={
                            "form-control border" +
                            (errors.newPass2 && touched.newPass2
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <i
                          className={`fa ${
                            state.showConfirmPassword
                              ? "fa-eye"
                              : "fa-eye-slash"
                          } password-icon`}
                          onClick={toggleConfirmPassword}
                        />
                        <ErrorMessage
                          name="newPass2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                   
                 

                  <button
                    type="submit"
                    disabled={state.loading}
                    className="btn btn-login btn-block my-5"
                  >
                    {state.loading && (
                      <i
                        className="fa fa-circle-notch fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {state.loading && (
                      <span className="text-capitalize">Please wait...</span>
                    )}
                    {!state.loading && (
                      <span className="text-capitalize"> Submit</span>
                    )}
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
export default PasswordReset ;

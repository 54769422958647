import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './loader.css';

const Loader = () => {
	return (
		<div id="overlay">
			<main className="c-loader">
				<span className="c-loader__item"></span>
				<span className="c-loader__item"></span>
				<span className="c-loader__item"></span>
				<span className="c-loader__item"></span>
				<span className="c-loader__item"></span>
			</main>
			<p className="text-center">Loading...</p>
		</div>
	);
};

export default Loader;

import React from "react";
import { useMutation } from "react-query";

import Signup from ".";
import { register } from "services/apiSrv";
import { show_toast } from "utils/helpers";

const SignupContainer = () => {
  const signup = useMutation(register, {
    onSuccess: (data) => {
      show_toast(
        ` Email verification link sent to ${localStorage.getItem("REG_EMAIL")}`,
        "success"
      );
    },
    onError: async (error) => {},
  });

  return <Signup signup={signup} />;
};

export default SignupContainer;

import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import 'assets/css/dashboard.css';
import QuotationForm from 'components/stepper/quotation-stepper/index';
import { KycContext } from 'context/KycContext';
import { ModalContext } from 'context/ModalContext';
import { DashboardContext } from 'context/DashboardContext';

const AddQuotation = (props) => {
	// access context

	const { setCurrent } = useContext(KycContext);
	const { setModalShow } = useContext(ModalContext);
	const { setQuotations, quotations } = useContext(DashboardContext);

	// on modal close clear state

	const close = () => {
		setModalShow(false);
		setCurrent(0);
		setQuotations({ ...quotations, insuranceClass: '', product_id: '' });
	};

	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
				onHide={() => close()}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Prepare Quotation</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="bg-light">
					<QuotationForm />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddQuotation;

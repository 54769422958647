import React from 'react';
import { Button } from 'antd';

const ConfirmChannel = ({ onConfirmDrawerClose }) => {
	return (
		<div>
			Confirm details
			<Button type="primary" htmlType="submit" className="float-right">
				Save
			</Button>
			<Button type="primary" className="float-right mr-2" onClick={() => onConfirmDrawerClose()}>
				Back
			</Button>
		</div>
	);
};

export default ConfirmChannel;

import React, { useEffect, useContext } from "react";
import Roles from "./Roles";
import { api_srv } from "services";
import { DashboardContext } from "context/DashboardContext";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const RolesContainer = (props) => {

  const fetch_roles_list = async () => {
    let resp = await (await api_srv).list_usmg_roles();
    return resp.roles;
  };

  const { data, isLoading, isSuccess } = useQuery("roles", fetch_roles_list);

  const view_role = (id) => {
    props.history.push(`/dashboard/usmg/roles/view/${id}`);
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <Roles
          data={data}
          view_role={view_role}
          fetch_roles_list={fetch_roles_list}
        />
      )}
    </div>
  );
};

export default RolesContainer;

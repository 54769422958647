import React, { useContext, useState } from "react";

import {
  Dropdown,
  Avatar,
  Drawer,
  Button,
  Empty,
  Descriptions,
  Menu,
} from "antd";
import * as Icon from "react-feather";
import { useQuery } from "react-query";

import ClaimForm from "components/widgets/forms/ReportClaimForm";
import { DashboardContext } from "context/DashboardContext";
import MakeClaimForm from "components/widgets/forms/MakeClaimForm";
import Loader from "components/widgets/loader";
import { api_srv } from "services";
import { formatDate, convertCurrency } from "utils/helpers";
import Wizard from "components/widgets/wizard";
import { Test } from "components/widgets/forms/Test_Step";

const PolicyDetails = (props) => {
  const id = props.match.params.id;

  const { claimId, setClaimId } = useContext(DashboardContext);

  const [visible, setVisible] = useState(false);
  const [incidentDrawer, setIncidentDrawer] = useState(false);
  const [claimDrawer, setClaimDrawer] = useState(false);
  const [claimDrawerForm, setClaimDrawerForm] = useState(false);

  const [category, setCategory] = useState({
    id: "",
    category: "",
  });

  async function fetch_claim_types() {
    let resp = await (await api_srv).fetch_claim_types(id);
    return resp.portfolio_claim_types;
  }
  async function fetch_poliy_claims() {
    let resp = await (await api_srv).fetch_poliy_claims(id);
    return resp.claims;
  }

  async function fetch_policy_details() {
    let resp = await (await api_srv).view_policy(id);
    return resp.portfolio_details;
  }

  const {
    isLoading,
    isSuccess: isSuccess1,
    data: claimTypes,
  } = useQuery("claim_types", fetch_claim_types);
  const {
    isLoading: isLoading2,
    isSuccess: isSuccess2,
    data: data2,
  } = useQuery("policy_details", fetch_policy_details);
  const {
    isLoading: isLoading3,
    isSuccess: isSuccess3,
    data: data3,
  } = useQuery("policy_claims", fetch_poliy_claims);

  async function get_claim_ref() {
    setClaimId({ ...claimId, loading: true });
    try {
      let resp = await (
        await api_srv
      ).generate_claim_ref(
        data2.client_product_id,
        data2.client_id,
        category.category
      );
      setClaimId({ ...claimId, id: resp.claim_id, loading: false });
      setIncidentDrawer(true);
    } catch (err) {
      let error = await err;
      setClaimId({ ...claimId, loading: false });
    }
  }

  function handleCategoryChange(evt) {
    const { value, id } = evt.target;
    setCategory({ ...category, category: value, id: id });
  }

  // report claim
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showIncidentDrawer = async () => {
    get_claim_ref();
  };

  const closeIncidentDrawer = () => {
    setIncidentDrawer(false);
  };

  // make claim
  const showClaimDrawer = () => {
    setClaimDrawer(true);
  };

  const onCloseClaimDrawer = () => {
    setClaimDrawer(false);
  };
  const showClaimDrawerForm = () => {
    setClaimDrawerForm(true);
  };
  const onCloseClaimDrawerForm = () => {
    setClaimDrawerForm(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showDrawer}>
        Report Incident/Accident
      </Menu.Item>
      <Menu.Item key="2" onClick={showClaimDrawer}>
        File a Claim
      </Menu.Item>
      <Menu.Item key="3">Renew this policy</Menu.Item>
    </Menu>
  );

  return (
    <div>
      {isLoading && isLoading2 && isLoading3 && <Loader />}
      {isSuccess1 && isSuccess2 && isSuccess3 && (
        <>
          <Drawer
            title="Choose a category"
            width={500}
            closable={true}
            onClose={onClose}
            visible={visible}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={onClose}
                  style={{ marginRight: 8 }}
                  type="danger"
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  form="claimType"
                  type="primary"
                  // onClick={showIncidentDrawer}
                  // disabled={claimId.loading}
                >
                  {claimId.loading && (
                    <i
                      className="fa fa-circle-notch fa-spin text-white"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {claimId.loading && (
                    <span className="text-capitalize text-white">
                      Please wait...
                    </span>
                  )}
                  {!claimId.loading && (
                    <span className="text-capitalize"> Next</span>
                  )}
                </Button>
              </div>
            }
          >
            <div>
              {/* <Wizard form={Test} /> */}
              <form
                className=" py-3 "
                id="claimType"
                onSubmit={(e) => {
                  e.preventDefault();
                  showIncidentDrawer();
                }}
              >
                {claimTypes.length === 0 ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No categories present"
                  />
                ) : (
                  claimTypes.map((type, i) => {
                    return (
                      <div className="mb-3" key={i}>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id={type.claim_type_code}
                            onChange={handleCategoryChange}
                            name="claim_type"
                            value={type.claim_type_code}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label font-weight-bold"
                            htmlFor={type.claim_type_code}
                          >
                            {type.claim_type_name}
                          </label>
                        </div>

                        <i className="pl-4">~ {type.claim_type_description}</i>
                      </div>
                    );
                  })
                )}
              </form>
            </div>
            <Drawer
              title="Fill up the Claim form"
              width={600}
              closable={false}
              onClose={closeIncidentDrawer}
              visible={incidentDrawer}
              bodyStyle={{ paddingBottom: 80 }}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    onClick={closeIncidentDrawer}
                    style={{ marginRight: 8 }}
                    type="danger"
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType="submit"
                    form="report-claim-form"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              }
            >
              <ClaimForm
                product_id={data2.client_product_id}
                claim_code={category.category}
                closeIncidentDrawer={closeIncidentDrawer}
                onClose={onClose}
              />
            </Drawer>
          </Drawer>
          <Drawer
            title="Choose report that you wish to convert to Claim"
            width={500}
            closable={false}
            onClose={onCloseClaimDrawer}
            visible={claimDrawer}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button
                  onClick={onCloseClaimDrawer}
                  style={{ marginRight: 8 }}
                  type="danger"
                >
                  Previous
                </Button>
                <Button
                  htmlType="submit"
                  form="claimType"
                  type="primary"
                  onClick={showClaimDrawerForm}
                  // disabled={claimId.loading}
                >
                  {claimId.loading && (
                    <i
                      className="fa fa-circle-notch fa-spin text-white"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {claimId.loading && (
                    <span className="text-capitalize text-white">
                      Please wait...
                    </span>
                  )}
                  {!claimId.loading && (
                    <span className="text-capitalize"> Next</span>
                  )}
                </Button>
              </div>
            }
          >
            <div>
              <form
                className=" py-3 "
                id="claimType"
                onSubmit={(e) => {
                  e.preventDefault();
                  showIncidentDrawer();
                }}
              >
                {console.log("THE CLAIMS TO BE CONVERTED", data3)}
                {data3.length === 0 ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No claims are currently reported"
                  />
                ) : (
                  data3.map((type, i) => {
                    return (
                      <div className="mb-3" key={i}>
                        <div className="custom-control custom-radio">
                          <input
                            type="radio"
                            id={type.claim_id}
                            onChange={handleCategoryChange}
                            name="claim"
                            value={type.claim_type}
                            className="custom-control-input"
                          />
                          <label
                            className="custom-control-label font-weight-bold"
                            htmlFor={type.claim_id}
                          >
                            {type.claim_id}
                          </label>
                        </div>

                        <i className="pl-4">
                          ~ Claim reported on {type.report_details.report_date}
                        </i>
                      </div>
                    );
                  })
                )}
              </form>
            </div>

            <Drawer
              title="Fill up the Claim form"
              width={600}
              closable={false}
              onClose={onCloseClaimDrawerForm}
              visible={claimDrawerForm}
              bodyStyle={{ paddingBottom: 80 }}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    onClick={onCloseClaimDrawerForm}
                    style={{ marginRight: 8 }}
                    type="danger"
                  >
                    Previous
                  </Button>
                  <Button
                    htmlType="submit"
                    form="make-claim-form"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              }
            >
              <MakeClaimForm
                product_id={data2.client_product_id}
                claim_code={category.category}
                claim_id={category.id}
                client_id={data2.client_id}
                onCloseClaimDrawer={onCloseClaimDrawer}
                onCloseClaimDrawerForm={onCloseClaimDrawerForm}
              />
            </Drawer>
          </Drawer>

          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <a className="breadcrumb-item active" href="index.html">
                Home
              </a>
              <a className="breadcrumb-item active" href>
                Policies
              </a>
              <a className="breadcrumb-item " href>
                Details
              </a>
            </div>
            <h5>Policy Details</h5>
          </div>
          <div className="card mg-b-20">
            <div className="card-body">
              {" "}
              <Descriptions
                title="Policy Details"
                size="default"
                extra={
                  <Dropdown.Button type="primary" overlay={menu}>
                    Actions
                  </Dropdown.Button>
                }
              >
                <Descriptions.Item label="Start Date">
                  {formatDate(data2.cover_start_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Policy Number"></Descriptions.Item>
                <Descriptions.Item label="Premium">
                  {convertCurrency(data2.premium_amount)}
                </Descriptions.Item>
                <Descriptions.Item label="Purchase Date">
                  {formatDate(data2.purchase_date)}
                </Descriptions.Item>
                <Descriptions.Item label="End Date">
                  {formatDate(data2.cover_end_date)}
                </Descriptions.Item>
                <Descriptions.Item label="IPF Status">
                  {" "}
                  {data2.under_ipf ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-danger">In-Active</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Product Status">
                  {formatDate(data2.cover_end_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Workflow">
                  <ul>
                    {data2.workflow.map((workflow, i) => (
                      <li key={i}>
                        <Icon.Check color="green" size={12} />
                        <i className="pl-2">
                          {workflow.name}~ <span>{workflow.status}</span>
                        </i>{" "}
                      </li>
                    ))}
                  </ul>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <div className="card ">
            <div className="card-body">
              <Descriptions title="Product Details" size="default">
                <Descriptions.Item label="Product Name">
                  {data2.product_details.product_name}
                </Descriptions.Item>
                <Descriptions.Item label="Product Class">
                  {data2.product_details.class_code}
                </Descriptions.Item>
                <Descriptions.Item label="Underwriter">
                  {data2.underwritter_details.name}
                </Descriptions.Item>
                <Descriptions.Item label="Cover Type">
                  {data2.product_details.covertype_code}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PolicyDetails;

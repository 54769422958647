import React, { useContext } from "react";
import { Steps } from "antd";
import "../../../assets/css/dashboard.css";
import AddToGroup from "./AddToGroup";
import Class from "./Select_Class";
import Confirm from "./Confirm";
import { KycContext } from "context/KycContext";
import { DashboardContext } from "context/DashboardContext";

const { Step } = Steps;

export default function Index(props) {
  // access context

  const context = useContext(KycContext);
  const { current } = context;
  const { quotations } = useContext(DashboardContext);

  const steps = [
    {
      title: "Select Insurance Class",
      content: <Class />,
    },
    {
      title: "Select Undewriter",
      content: (
        <AddToGroup
          class_id={quotations.insuranceClass}
          system_scheme_group_ref={props.system_scheme_group_ref}
          refetch={props.refetch}
        />
      ),
    },
    {
      title: "Confirm Details",
      content: <Confirm />,
    },
  ];

  return (
    <div>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
}

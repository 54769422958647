import React,{useContext} from 'react'
import ResetPassword from '.'
import { AlertsContext } from "../../context/AlertsContext";
import { AuthContext } from "../../context/AuthContext";
import { api_srv } from "../../services";

const ResetPasswordContainer = () => {

      // context
  const { forgotUsername, setForgotUsername, alert,setAlert } = useContext(AuthContext);

  const { setShowAlert} = useContext(AlertsContext);

  const resetPassword = async () => {
    try {
      setForgotUsername({ loading: true });
      let reset_resp = await (await api_srv).forgot_password(forgotUsername);
     
      setAlert({ ...alert, message: reset_resp.status, variant: "success" });
      setShowAlert(true);
      setForgotUsername({ loading: false, data: ""});
   
    } catch (err) {
      setForgotUsername({ loading: false, data:"" });
      let error = await err;

      setAlert({ ...alert, message: error.message, variant: "danger" });
      setShowAlert(true);
    }
  };

    const handleResetPasswordChange = (evt) => {
        const {value,name} = evt.target;
        setForgotUsername({
          ...forgotUsername,
          [name]: value,
        });
      };

    return (
        <ResetPassword
        state={forgotUsername}
        handleChange={handleResetPasswordChange}
        alert={alert}
        resetPassword={resetPassword}
        />
    )
}

export default ResetPasswordContainer

import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import Appointment from '../forms/appointment/AppointmentFormContainer';
// import { Modal } from 'antd';

const AddAppointment = (props) => {
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Add Appointment Details</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Appointment />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddAppointment;

import React from 'react';
import RecentRequests from './RecentRequests';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const RecentRequestsContainer = () => {
	const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

	async function fetch_quotation_requests() {
		let resp = await (await api_srv).get_quotation_requests();
		return resp.quotation_requests;
	}

	const { isLoading, isSuccess, data } = useQuery('recent requests', fetch_quotation_requests);
	return (
		<>
			{isLoading && <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} indicator={antIcon} />}
			{isSuccess && <RecentRequests data={data} />}
		</>
	);
};

export default RecentRequestsContainer;

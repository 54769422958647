import React from 'react';
import Leads from './OpenLeads';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const OpenLeadsContainer = () => {
	async function _listOpenLeads() {
		let resp = await (await api_srv).list_open_leads();
		console.log('THE OPEN LEADS', resp);
		return resp.leads;
	}
	const { data, isLoading, isSuccess } = useQuery('open leads', _listOpenLeads);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Leads data={data} />}
		</div>
	);
};

export default OpenLeadsContainer;

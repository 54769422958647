import React, { useState, useContext } from "react";
import * as Icon from "react-feather";
import { DashboardContext } from "context/DashboardContext";
import { Button, Input, Pagination, Drawer } from "antd";
import NoData from "components/no-data/NoData";
import SecurityContextForm from "components/widgets/forms/Add_Security_Context";

export default function SecurityContext({
  data,
  onClose,
  showDrawer,
  visible,
  setVisible,
  view_context,
  fetch_security_context,
}) {
  // access context

  const { handlePaginationChange, numEachPage, paginate } = useContext(
    DashboardContext
  );

  const { Search } = Input;
  const [search, setSearch] = useState(null);

  // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  const items = data
    .filter((data) => {
      if (search == null) return data;
      else if (
        data.names.toLowerCase().includes(search.toLowerCase()) ||
        data.created_date.toLowerCase().includes(search.toLowerCase()) ||
        data.request_status.toLowerCase().includes(search.toLowerCase()) ||
        data.remarks.toLowerCase().includes(search.toLowerCase())
      ) {
        return data;
      }
    })
    .slice(paginate.minValue, paginate.maxValue)
    .map((row) => {
      return (
        <tr key={row.security_context_id}>
          <td>{row.name}</td>
          <td>{row.security_context_status}</td>

          <td>{row.description}</td>

          <td>
            <div>
              <a
                href
                onClick={() => view_context(row.security_context_id)}
                className="btn btn-sm btn-label-primary"
              >
                view
              </a>
            </div>
          </td>
        </tr>
      );
    });

  return (
    <>
      <div className="pageheader pd-t-25 pd-b-35">
        <div className="breadcrumb pd-0 mg-0">
          <a className="breadcrumb-item" href="index.html">
            <i className="icon ion-ios-home-outline" /> Home
          </a>
          <a className="breadcrumb-item active" href>
            Dashboard
          </a>
        </div>
      </div>
      <Drawer
        title="Security Context Settings"
        placement="right"
        width={500}
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        <SecurityContextForm
          setVisible={setVisible}
          fetch_security_context={fetch_security_context}
        />
      </Drawer>
      <Button
        className="btn-blue text-white rounded float-right mb-3"
        onClick={showDrawer}
      >
        Add Security Context
      </Button>

      {data.length === 0 ? (
        <NoData message="You currently have no security contexts" />
      ) : (
        <>
          <div className="col-lg-12 card rounded-10 col-xl-12">
            <div className="mg-b-20">
              <div className="card-header">
                <div className="pd-t-5 pd-b-5 pd-l-5">
                  <h1 className=" tx-18">
                    <Icon.Lock size={30} className="pr-2 text-primary" />
                    Security Context
                  </h1>
                </div>
                <Search
                  placeholder="Search Security Context"
                  type="search"
                  onChange={(e) => searchSpace(e)}
                  className="ml-md-auto mg-r-10"
                  style={{ width: 200 }}
                />
              </div>
              <div className="mg-b-20">
                <div
                  className="card-body pd-0 collapse show"
                  id="customerDetails"
                >
                  <div className="table-responsive">
                    <table className="table table-hover mg-0">
                      <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                        <tr className="bg-light">
                          <th className="">Name</th>
                          <th className="">Status</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{items}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                className="ml-auto"
                responsive={true}
                dataSource={data}
                total={data.length}
                showTotal={(total) => `Total ${total} items`}
                defaultPageSize={numEachPage}
                defaultCurrent={1}
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

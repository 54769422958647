import React,{useContext} from 'react'
import * as Icon from 'react-feather';
import {DashboardContext} from '../../context/DashboardContext'
import Moment from 'react-moment'
import {Empty} from 'antd'
export default function Index() {

  const {accountTrans}= useContext(DashboardContext)

    return (
        <div className="card rounded-10 col-md-12">
        <div className=" mg-b-20">
          <div className="card-header p-4">
            <h4 className="card-header-title tx-16-force">
             <i className="fa fa-exchange mr-2" /> Recent Transactions
            </h4>
          </div>
          <div className="card-body pd-0 collapse show" id="customerDetails">
          {accountTrans.length===0 ?  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description ="You dont have any recent transactions"/>:
          <div className="table-responsive">
              <table className="table table-hover mg-0">
                <thead className="tx-dark tx-uppercase tx-10 tx-bold">
                  <tr>
                    <th>Date</th>
                    <th>Transaction Reference</th>
                    <th>Transaction Type</th>
                    <th>Status</th>
                    <th>Amount</th>
                    {/* <th className="tx-right">Action</th> */}
                  </tr>
                </thead>
                <tbody>
               
                    {accountTrans.map(row=>
                        <tr>
                        <td>
                        <Moment
                              format="DD MMM YYYY"
                              date={row.tran_date}
                            />
                          
                      </td>
                      <td>
                        {row.remarks}
                      </td>
                      <td>
                        {row.account_type}
                      </td>
                      <td>
                        <span className={`badge ${row.tran_status==='pending'?'badge-warning': row.tran_status==='COMPLETED'?'badge-success':'badge-danger'}`}>{row.tran_status}</span>
                      </td>
                      <td>
                        {row.currency_code} {""}{row.tran_amount}
                      </td>
                      {/* <td className="tx-right">
                        <a href className="btn btn-sm btn-label-primary"><Icon.MoreHorizontal/></a>
                      </td> */}
                      </tr>
                      
                    )}
                  
                
          
              
                </tbody></table>
            </div>
}
          </div>
        </div>
      </div>
    )
}

import React from "react";

import { Link } from "react-router-dom";

import logo from "assets/images/logos/Logo_1.png";
import TnC from "assets/docs/Nexus General Terms and Conditions 27.7.2020.pdf";
import "./signup.css";
import { signupForm } from "pages/signup/form/Signup_Form";
import CustomForm from "components/widgets/forms/CustomForm";
import SignupSchema from "pages/signup/validations/SignupSchema";

const Signup = ({ signup }) => {
  const onSubmit = (model) => {
    signup.mutate(model);
  };

  return (
    <div className="d-flex">
      <div className="col-md-12 col-sm-12  " id="login-background">
        <div
          style={{
            minHeight: "100%",
            paddingBottom: "40px",
            marginBottom: "-40px",
          }}
        >
          <div className="container">
            <Link to="/" className="">
              <img
                src={logo}
                style={{
                  height: "30px",
                  width: "auto",
                  position: "absolute",
                  top: "20px",
                }}
                alt="Logo"
              />
            </Link>

            <div className="signup_content col-md-6">
              <h3 className="text-white pb-3">Register for Free</h3>
              <p className="text-white pb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet
                itaque debitis doloribus?Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Amet itaque debitis doloribus?met itaque
                debitis doloribus? Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Amet itaque debitis doloribus?Lorem ipsum
                dolor sit amet consectetur
              </p>
              <h4 className="text-white mt-3">Benefits of being part of us</h4>
              <ul>
                <div className="d-flex justify-content-between">
                  <div>
                    <li className="p-2 text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                    <li className="p-2 text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                    <li className="p-2 text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                  </div>
                  <div>
                    <li className="p-2 text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                    <li className="p-2  text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                    <li className="p-2 text-white">
                      <i className="fa fa-check pr-2" /> Lorem ipsum lot
                      erluipsum
                    </li>
                  </div>

                  <div></div>
                </div>
              </ul>
            </div>

            <div
              className=""
              style={{
                position: "fixed",
                right: "40px",
                bottom: "0",
              }}
            >
              <a
                href={TnC}
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                Terms and conditions |
              </a>

              <Link to="#" className=" text-white">
                {" "}
                &copy; {new Date().getFullYear()} Nexus
              </Link>
            </div>

            <div className="card signup__card__position">
              <div className="card-body p-md-5">
                <img src={logo} alt="Logo" className="d-md-none mobile__logo" />
                <CustomForm
                  className="form mt-md-0 mt-4"
                  validationSchema={SignupSchema}
                  model={signupForm}
                  onSubmit={(model) => {
                    onSubmit(model);
                  }}
                />
                <p className="text-right">
                  Already have an account?{" "}
                  <Link to="/login" className="custom-text-primary">
                    <small>Log In</small>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;

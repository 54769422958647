import React, { useContext, useRef } from 'react';
import Navbar from 'components/dashboard-nav';
import Sidebar from 'components/dashboard-sidebar';
import { ModalContext } from 'context/ModalContext';
import AddClaimRemarks from 'components/widgets/modals/AddClaimRemarks';
import { Drawer, Button, Tooltip, Descriptions, Empty } from 'antd';
import Badge from 'react-bootstrap/Badge';
import ClaimForm from 'components/widgets/forms/MakeClaimForm';
import Moment from 'react-moment';
import { useQuery } from 'react-query';
import { api_srv } from 'services';

export default function View_Claim({
	data,
	id,
	claimForm,
	visible,
	onClose,
	send_my_claim,
	individualClaimDetails,
	showDrawer,
	claimSummary,
}) {
	// MODAL CONTEXT

	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	console.log('THE DATA IS ', data);

	return (
		<>
			<Drawer
				title="Fill up the Claim form"
				width={600}
				closable={false}
				onClose={onClose}
				visible={visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={
					<div
						style={{
							textAlign: 'right',
						}}
					>
						<Button onClick={onClose} style={{ marginRight: 8 }} type="danger">
							Previous
						</Button>
						<Button htmlType="submit" form="report-claim-form" type="primary">
							Submit
						</Button>
					</div>
				}
			>
				<ClaimForm
					// product_id={data2.client_product_id}
					claim_code={data.claim_type}
					onClose={onClose}
				/>
			</Drawer>

			<div className="pageheader pd-t-25 pd-b-35">
				<div className="pd-t-5 pd-b-5">
					<h1 className="pd-0 mg-0 tx-20">Claim Details</h1>
				</div>
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						ClaimsPendingApproval
					</a>
					<a className="breadcrumb-item active" href>
						View
					</a>
				</div>
			</div>
			<AddClaimRemarks show={modalShow} onHide={modalClose} />

			<div className="text-right pd-5">
				{data.claim_category_stage !== 'REPORT' && (
					<button type="submit" className="btn btn-sm btn-blue mg-y-15 mr-2 wd-md-110" onClick={showDrawer}>
						<i className="fa fa-file mr-1" /> File Claim
					</button>
				)}

				<button
					onClick={() => setModalShow(true)}
					type="submit"
					className="btn btn-sm btn-blue mg-y-15 mr-2 wd-md-110"
					// onClick={showDrawer}
				>
					<i className="fas fa-share mr-1" /> Forward to Underwriter
				</button>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="card">
						<div className="card-body">
							<Descriptions
								column={2}
								title={`Claim Details Information`}
								layout="vertical"
								colon={false}
							>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Status</b>}>
									<Badge variant="success"> {data.claim_status}</Badge>
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Claim Category Stage</b>}>
									{data.claim_category_stage}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Claim Decision Category</b>}>
									{data.claim_decision_category}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Incident date</b>}>
									<Moment format="DD-MM-YYYY HH:mm A">{data.report_details.report_date}</Moment>
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Claim Decision</b>}>
									{data.claim_decision}
								</Descriptions.Item>
							</Descriptions>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="card">
						<div className="card-body">
							<Descriptions column={2} title={`Policy Details`} layout="vertical" colon={false}>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Policy Holder Name</b>}>
									{claimSummary.client_details.names}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Policy Holder Email</b>}>
									{claimSummary.client_details.email}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Gender</b>}>
									{claimSummary.client_details.gender}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Policy Purchase Date</b>}>
									<Moment format="DD-MM-YYYY HH:mm A">
										{claimSummary.policy_details.purchase_date}
									</Moment>
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Policy End Date</b>}>
									{claimSummary.policy_details.cover_end_date}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>Product Name</b>}>
									{claimSummary.product_details.product_name}
								</Descriptions.Item>
								<Descriptions.Item label={<b style={{ color: '#000' }}>IPF Status</b>}>
									<span
										style={{
											color: `${
												claimSummary.policy_details.under_ipf === true ? '#FF0000' : '#28a745;'
											}`,
										}}
									>
										{claimSummary.policy_details.under_ipf === true
											? 'This policy is under IPF'
											: 'This policy is not under IPF'}
									</span>
								</Descriptions.Item>

								<Descriptions.Item label={<b style={{ color: '#000' }}>Claimant Photo</b>}>
									<img
										className="img-fluid mb-5"
										src="https://via.placeholder.com/250x250"
										alt=""
										style={{ height: '150px', width: '150px' }}
									/>
								</Descriptions.Item>
							</Descriptions>
						</div>
					</div>
				</div>
			</div>
			<div>
				<p className="mt-5" style={{ color: '#000' }}>
					<strong className="d-inline-block wd-100">Photos</strong>
				</p>
				{data.claim_images.length === 0 ? (
					<div className="card">
						<div className="card-body">
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No photos attached " />
						</div>
					</div>
				) : (
					<div className="row row-xs no-gutters">
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5 m" src="https://via.placeholder.com/250x250" alt="" />
						</a>
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5" src="https://via.placeholder.com/250x250" alt="" />
						</a>
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5" src="https://via.placeholder.com/250x250" alt="" />
						</a>
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5" src="https://via.placeholder.com/250x250" alt="" />
						</a>
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5" src="https://via.placeholder.com/250x250" alt="" />
						</a>
						<a className="col-lg-3" href>
							<img className="img-fluid mb-5" src="https://via.placeholder.com/250x250" alt="" />
						</a>
					</div>
				)}
			</div>
		</>
	);
}

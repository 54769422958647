import React, { useState } from "react";

import { Formik, Field, ErrorMessage } from "formik";
import MaskedInput from "react-input-mask";

export default function DynamicForm(props) {
  const [inputList, setInputList] = useState([{ name: "", val: "" }]);

  const handleAddClick = () => {
    setInputList([...inputList, { name: "", val: "" }]);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    if (props.onSubmit) {
      setSubmitting(true);
      try {
        await props.onSubmit(values, setSubmitting);
        setSubmitting(false);
      } catch (err) {
        console.log("THE ERR FORM", err);
        setSubmitting(false);
        resetForm({});
      }
    }
  };

  const renderForm = (errors, touched, handleChange, values, handleBlur) => {
    let model = props.model.fields;

    let formUI = model.map((m) => {
      let key = m.key;
      let type = m.type || "text";
      let props = m.props || {};
      let name = m.name;
      let value = m.value;
      let style = m.class || "col-12";

      // let target = key;
      // value = state[target] || "";

      // **************TEXT FIELD*******************
      let input = (
        <>
          <input
            {...props}
            className={`
              form-control ${
                errors[name] && touched[name] ? " is-invalid" : ""
              } 
            `}
            type={type}
            key={key}
            name={name}
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={touched[name] && errors[name]}
          />
          {errors[name] && (
            <small className="feedback text-danger">{errors[name]}</small>
          )}
        </>
      );

      if (type === "radio") {
        return (
          <div role="group" aria-labelledby="radio-group">
            {
              (input = m.options.map((o) => (
                <React.Fragment key={"fr" + o.key}>
                  <label>
                    <Field type="radio" name={name} value={o.value} />
                    {o.label}
                  </label>
                </React.Fragment>
              )))
            }
            {errors[name] && (
              <small className="feedback text-danger">{errors[name]}</small>
            )}
            {(input = <div className="">{input}</div>)}
          </div>
        );
      }

      // ****************TEXT AREA****************
      if (type === "textarea") {
        input = (
          <React.Fragment key={"fr" + key}>
            <Field
              {...props}
              component={type}
              key={key}
              rows={4}
              name={name}
              value={values[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched[name] && errors[name]}
              className={`
              form-control ${
                errors[name] && touched[name] ? " is-invalid" : ""
              } 
            `}
            />

            {errors[name] && (
              <small className="feedback text-danger">{errors[name]}</small>
            )}
          </React.Fragment>
        );

        input = <div className="">{input}</div>;
      }
      // *******************SELECT FIELD*************************
      if (type === "select") {
        input = m.options.map((o) => {
          return (
            <>
              <option {...props} className="" key={o.key} value={o.value}>
                {o.label}
              </option>
            </>
          );
        });

        input = (
          <React.Fragment>
            <Field
              as="select"
              name={m.name}
              className={`
              form-control ${
                errors[m.name] && touched[m.name] ? " is-invalid" : ""
              }
            `}
              value={values[m.name]}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched[m.name] && errors[m.name]}
            >
              {input}
            </Field>
            {errors[name] && (
              <small className="feedback text-danger">{errors[m.name]}</small>
            )}
          </React.Fragment>
        );
      }

      // **********************MASKED INPUT*****************
      if (type === "MaskedInput") {
        console.log("Masked Input: ", value, name);
        input = (
          <React.Fragment>
            <MaskedInput
              mask={[
                "I",
                "R",
                "A",
                "/",
                "0",
                "5",
                "/",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "/",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              {...props}
              className={
                "form-control" +
                (errors[name] && touched[name] ? " is-invalid" : "")
              }
              placeholder="IRA/05/****/****"
              guide={true}
              key={key}
              name={name}
              value={values[name]}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched[name] && errors[name]}
            />
            <ErrorMessage
              name={`${name}`}
              component="div"
              className="invalid-feedback"
            />
          </React.Fragment>
        );
      }
      // if (type === "DynamicInput") {
      //   console.log("Dynamic Input: ", value, name);
      //   input = (
      //     <React.Fragment>
      //       {inputList.map((x, i) => {
      //         return (
      //           <>
      //             <div className="row">
      //               <div className="col-md-6">
      //                 <input
      //                   key={key}
      //                   name={name}
      //                   value={x.name}
      //                   className={
      //                     "form-control" +
      //                     (errors[x.name] && touched[x.name]
      //                       ? " is-invalid"
      //                       : "")
      //                   }
      //                   onChange={(e) => {
      //                     onChange(e, m.key, "multiple");
      //                   }}
      //                 />
      //                 {/* <ErrorMessage
      //                   name={`${x.name}`}
      //                   component="div"
      //                   className="invalid-feedback"
      //                 /> */}
      //               </div>
      //               <div className="col-md-6">
      //                 <input
      //                   className={
      //                     "form-control ml-3" +
      //                     (errors[x.val] && touched[x.val] ? " is-invalid" : "")
      //                   }
      //                   key={key}
      //                   name={name}
      //                   value={x.val}
      //                   onChange={(e) => {
      //                     onChange(e, m.key, "multiple");
      //                   }}
      //                 />
      //                 {/* <ErrorMessage
      //                   name={`${x.val}`}
      //                   component="div"
      //                   className="invalid-feedback"
      //                 /> */}
      //               </div>
      //             </div>

      //             <div className="btn-box">
      //               {inputList.length !== 1 && (
      //                 <button
      //                   className="mr10"
      //                   onClick={() => handleRemoveClick(i)}
      //                 >
      //                   Remove
      //                 </button>
      //               )}
      //               {inputList.length - 1 === i && (
      //                 <button onClick={handleAddClick}>Add</button>
      //               )}
      //             </div>
      //           </>
      //         );
      //       })}
      //     </React.Fragment>
      //   );
      // }

      // let _inputs = state[m.key];
      // const handleAddClick = (key) => {
      //   const list = [..._inputs];
      //   console.log("UPDATED LIST", state[key]);
      //   let others = state[key] ? [...state[key]] : [];
      //   state[key].push(others[0]);

      //   // _inputs = list;
      //   console.log("UPDATED LIST 2", state[key]);
      // };
      // const handleRemoveClick = (index) => {
      //   const list = [..._inputs];
      //   list.splice(index, 1);
      //   _inputs = list;
      // };

      //   input = _inputs.map((o, i) => {
      //     return (
      //       <React.Fragment key={"dy" + o.key}>
      //         <Field
      //           {...props}
      //           type={type}
      //           key={o.key}
      //           name={o.name}
      //           className={
      //             `form-control col-md-${12 / _inputs.length}` +
      //             (errors[o.name] && touched[o.name] ? " is-invalid" : "")
      //           }
      //           value={o.value}
      //           onChange={(e) => {
      //             onChange(e, m.key, "multiple");
      //           }}
      //         />
      //         <label className="" key={"TT" + o.key}>
      //           {o.label}
      //         </label>
      //         <ErrorMessage
      //           name={`${o.name}`}
      //           component="div"
      //           className="invalid-feedback"
      //         />
      //         <div className="btn-box">
      //           {_inputs.length !== 1 && (
      //             <button className="mr10" onClick={() => handleRemoveClick(i)}>
      //               Remove
      //             </button>
      //           )}
      //           {_inputs.length - 1 === i && (
      //             <button onClick={() => handleAddClick(m.key)}>Add</button>
      //           )}
      //         </div>
      //         {console.log("INPUTS  MAPPED", _inputs)}
      //       </React.Fragment>
      //     );
      //   });
      //   input = <div className="">{input}</div>;
      // }

      if (type === "checkbox") {
        input = m.options.map((o) => {
          //let checked = o.value == value;
          let checked = false;
          if (values[o.name] && values[o.name].length > 0) {
            checked = values[o.name].indexOf(o.value) > -1 ? true : false;
          }
          //console.log("Checkbox: ", checked);
          return (
            <React.Fragment key={"cfr" + o.key}>
              <Field
                {...props}
                type={type}
                key={o.key}
                name={o.name}
                // value={values[o.name]}
                //  checked={checked}
                // value={o.value}
                // onChange={(e) => {
                //   onChange(e, m.key, "multiple");
                // }}
              />
              <label className="ml-2" key={"ll" + o.key}>
                {o.label}
              </label>
              <ErrorMessage
                name={`${o.name}`}
                component="div"
                className="invalid-feedback"
              />
            </React.Fragment>
          );
        });

        input = <div className="form-group-checkbox">{input}</div>;
      }

      return (
        <div key={"g" + key} className={`form-group mb-3 ${style}`}>
          <label className="form-label" key={"l" + key} htmlFor={key}>
            {m.label}
          </label>
          {input}
        </div>
      );
    });
    return <div className="row">{formUI}</div>;
  };

  let title = props.model.form_title || "";
  let subtitle = props.model.form_subtitle || "";

  return (
    <div className={props.className}>
      <Formik
        enableReinitialize
        validationSchema={props.validationSchema}
        initialValues={{}}
        onSubmit={(values, actions) => {
          onSubmitHandler(values, actions);
        }}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form className="dynamic-form" onSubmit={handleSubmit}>
            <h4 className="form-title">{title}</h4>
            <p>{subtitle}</p>
            {renderForm(
              errors,
              touched,
              handleChange,
              values,
              handleBlur,
              isSubmitting
            )}
            <button
              type="submit"
              className={`btn ${props.model.button_styles}`}
              disabled={isSubmitting}
            >
            
              {props.model.button_label || "button label"}{" "}
              {isSubmitting && (
                <i
                  className="fa fa-circle-notch fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
            </button>
            {props.children}
          </form>
        )}
      </Formik>
    </div>
  );
}

import React, { useContext } from "react";

import { Button } from "antd";
import { useHistory, Link } from "react-router-dom";

import { ModalContext } from "context/ModalContext";
import AddFees from "components/widgets/modals/Fees";
import CustomTable from "components/widgets/table";
import routes from "routes";

export default function Fees({ data, refetch }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  const history = useHistory();

  const view_fee_details = (id) => {
    history.push(`/dashboard/account-management/fees/details/${id}`);
  };

  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "Fee Name", align: "c" },
      { name: "Ref", align: "c" },
      { name: "Type", align: "c" },
      { name: "Value", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "text", field: "fee_name", class: "center" },
      { type: "text", field: "entity_fee_ref", class: "center" },
      { type: "text", field: "fee_type", class: "center" },
      { type: "currency", field: "fee_value", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "entity_fee_ref",
            method: view_fee_details,
          },
        ],
      },
    ],
  };
  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item active" to="#">
            <i className="icon ion-ios-home-outline " /> Account
          </Link>
          <Link className="breadcrumb-item">Fees</Link>
        </div>
        <h5>My Fees</h5>
      </div>

      <div className=" clearfix">
        <Button
          className="btn-primary btn-blue rounded mb-3 float-right text-white "
          onClick={() => setModalShow(true)}
        >
          Add A Fee
        </Button>
      </div>

      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
      {/* modal */}
      <AddFees show={modalShow} onHide={modalClose} refetch={refetch} />
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import Peer from 'simple-peer';
import styled from 'styled-components';

const Container = styled.div`
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const Video = styled.video`
	border: 1px solid blue;
	width: 50%;
	height: 50%;
`;

function VideoChat() {
	const [stream, setStream] = useState();
	const [receivingCall, setReceivingCall] = useState(false);
	const [caller, setCaller] = useState('');
	const [callerSignal, setCallerSignal] = useState();
	const [callAccepted, setCallAccepted] = useState(false);

	const userVideo = useRef();
	const partnerVideo = useRef();
	const Endpoint = 'http://chat.demo.nexus.ke/';

	let socket;

	useEffect(() => {
		navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
			setStream(stream);
			if (userVideo.current) {
				userVideo.current.srcObject = stream;
			}
		});
	}, []);

	// Listen for any messages incoming and push to conversations

	useEffect(() => {
		let socket = io(Endpoint);
		console.log('START LISTENING FOR ANY VIDEO CALLS');
		socket.on('video:call', (message) => {
			console.log('LISTENING FOR ANY INCOMING VIDEOS', message);
			setReceivingCall(true);
			// set also caller id of person calling you
			setCaller();
			// set signal of the call
			setCallerSignal();
		});
	}, []);

	function callPeer() {
		// initialize a new Peer
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: stream,
		});

		// signalling the other peer
		peer.on('signal', async () => {
			console.log('=======TRYING TO CALL ANOTHER PEER===========');
			// socket = io(Endpoint);
			try {
				let message = {};
				//  message.conversation_id = conversationId;
				message.conversation = {
					conversation_type: 'video',
					from_chat_user_id: parseInt(sessionStorage.getItem('chat_id')),
					//  to_chat_user_id: parseInt(toChatId),
				};

				await socket.emit('video:call', message);
			} catch (err) {
				throw err;
			}
		});

		// get partner's data(signal returned) and show own video and partner's side by side
		peer.on('stream', (stream) => {
			if (partnerVideo.current) {
				partnerVideo.current.srcObject = stream;
			}
		});

		// accept the signal sent by partner also
		socket.current.on('callAccepted', (signal) => {
			setCallAccepted(true);
			peer.signal(signal);
		});
	}

	function acceptCall() {
		setCallAccepted(true);
		// initialize a new peer with initiator as false(receiver of the call)
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: stream,
		});
		peer.on('signal', (data) => {});

		// partner's video
		peer.on('stream', (stream) => {
			if (partnerVideo.current) {
				partnerVideo.current.srcObject = stream;
			}
		});
		peer.signal(callerSignal);
	}

	let UserVideo;
	if (stream) {
		UserVideo = <Video playsInline muted ref={userVideo} autoPlay />;
	}

	let PartnerVideo;
	if (callAccepted) {
		PartnerVideo = <Video playsInline ref={partnerVideo} autoPlay />;
	}

	let incomingCall;
	if (receivingCall) {
		incomingCall = (
			<div>
				<h1>{caller} is calling you</h1>
				<button onClick={acceptCall}>Accept</button>
			</div>
		);
	}

	return (
		<Container>
			<Row>
				{UserVideo}
				{PartnerVideo}
			</Row>
			<Row>
				{/* call peer will accept a parameter of user id of person you wish to call */}
				<button onClick={() => callPeer()}>Call someone's chat_id</button>
			</Row>
			<Row>{incomingCall}</Row>
		</Container>
	);
}

export default VideoChat;

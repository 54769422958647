import React from 'react';
import { Input } from 'antd';
import { ImHourGlass } from 'react-icons/im';
import { Pagination } from 'antd';

import NoData from '../../components/no-data/NoData';

export default function ClaimsPendingApproval({
	state,
	view_claim_details,
	search,
	paginate,
	numEachPage,
	handlePaginationChange,
}) {
	const { Search } = Input;

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			{state.length === 0 ? (
				<NoData message="You appear not to have any claims pending approval at the moment" />
			) : (
				<div className="col-lg-12 card rounded-10 col-xl-12">
					<div className="mg-b-20">
						<div className="card-header">
							<div className="pd-t-5 pd-b-5 pd-l-5">
								<h1 className="tx-20">
									<ImHourGlass size={36} className="pr-2 text-primary" />
									Claims Pending Approval
								</h1>
							</div>
							<Search
								placeholder="search your claim"
								type="search"
								value={search}
								// onChange={searchMe}
								className="ml-auto mg-r-10"
								style={{ width: 200 }}
							/>
						</div>
						<div className="card-body collapse show" id="studentDetails">
							<div className="">
								<table className="table table-hover mb-0">
									<thead className="tx-13 tx-uppercase">
										<tr className="bg-light">
											<th>Claim Date</th>
											<th>Claim Ref</th>
											<th>Policy Number</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{state.length > 0 &&
											state.slice(paginate.minValue, paginate.maxValue).map((row) => {
												return (
													<tr>
														<td>{row.names}</td>
														<td>{row.system_claim_ref}</td>
														<td>{row.policy_no}</td>
														<td>
															<a
																href
																className="btn btn-sm btn-label-primary"
																onClick={() =>
																	view_claim_details(
																		row.claim_id,
																		row.system_claim_ref
																	)
																}
															>
																View
															</a>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<Pagination
						className="ml-auto"
						responsive={true}
						dataSource={state}
						total={state.length}
						showTotal={(total) => `Total ${total} items`}
						defaultPageSize={numEachPage}
						defaultCurrent={1}
						onChange={handlePaginationChange}
					/>
				</div>
			)}
		</>
	);
}

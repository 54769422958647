import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AddIncentive = (props) => {
	const [note] = useState('');

	const saveNote = () => {
		console.log('note saved');
	};
	return (
		<Formik
			enableReinitialize
			initialValues={note}
			validationSchema={Yup.object().shape({
				note: Yup.string().required('Add a note'),
			})}
			onSubmit={() => saveNote()}
		>
			{() => (
				<div>
					<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
						<Modal.Header closeButton>
							<Modal.Title>
								<h5 className="text-grey">Missing Information</h5>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group">
								<label htmlFor="missingInfo">Select the missing Info</label>
								<select className="form-control" id="missingInfo">
									<option>Identification Card</option>
									<option>Driving License</option>
									<option>Assessor's report</option>
									<option>Police Abstract</option>
								</select>

								<ErrorMessage name="note" component="div" className="invalid-feedback" />
							</div>
							<button
								className="btn btn-success btn-block  mt-5 mb-3  px-4 py-1 rounded text-white"
								htmlType="submit"
							>
								Send
							</button>
						</Modal.Body>
					</Modal>
				</div>
			)}
		</Formik>
	);
};

export default AddIncentive;

import React, { useContext, useState } from "react";
import {
  Pagination,
  Descriptions,
  Menu,
  Dropdown,
  Tag,
  Empty,
  Button,
  Checkbox,
  Collapse,
  Popconfirm,
} from "antd";
import { DownOutlined, LoadingOutlined, MailOutlined } from "@ant-design/icons";
import * as Icon from "react-feather";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import Loading from "components/widgets/loader";
import SelectUser from "components/widgets/modals/SelectUser";

const ViewRole = ({
  state,
  onPermissionChange,
  add_resource,
  add_permissions,
  handleMenuClick,
  onChange,
  resources,
  revoke,
  roleDetails,
  roleGrants,
  remove_permission,
}) => {
  const { usmg, numEachPage, handlePaginationChange } = useContext(
    DashboardContext
  );
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { SubMenu } = Menu;

 
  const permissions = [
    { label: "CREATE", value: "CREATE" },
    { label: "VIEW", value: "VIEW" },
    { label: "UPDATE", value: "UPDATE" },
    { label: "DELETE", value: "DELETE" },
    { label: "VERIFY", value: "VERIFY" },
  ];



  return (
    <div id="main-wrapper">
      {roleDetails.length === 0 ? (
        <Loading />
      ) : (
        <>
          <SelectUser show={modalShow} onHide={modalClose} />
          <div className="pageheader pd-t-25 ">
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Claims
              </a>
              <a className="breadcrumb-item active" href>
                View
              </a>
            </div>
          </div>

          <div className="card mg-t-25">
            <div className="card-header">
              <h5>{roleDetails.name}</h5>
            </div>
            <div className="card-body">
              <Descriptions title="" layout="vertical">
                <Descriptions.Item
                  label={<span className="font-weight-bold">Name</span>}
                >
                  {roleDetails.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-bold">Code</span>}
                >
                  {roleDetails.role_code}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-bold">Status</span>}
                >
                  {roleDetails.record_status_code === 0 ? "Active" : "Pending"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-bold">Created Date</span>}
                >
                  <b>03/11/2020</b>
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span className="font-weight-bold">Description</span>}
                  span={2}
                >
                  {roleDetails.description}
                </Descriptions.Item>
              </Descriptions>
              <div className="card-body">
                <a href="#" className="card-link">
                  Approve
                </a>
                <a href="#" className="card-link">
                  Edit
                </a>
                <a href="#" className="card-link">
                  Activate
                </a>
                <a href="#" className="card-link">
                  Audit Trail
                </a>
                <a href="#" className="card-link">
                  Delete
                </a>
                
              </div>
            </div>
          </div>

          <div className="card mg-t-25">
            <div className="card-header">
              <h5>Resource Permissions</h5>
              
              <Dropdown
                // trigger={[""]}
                className="ml-auto rounded btn-blue text-white"
                overlay={
                  <Menu
                    mode="inline"
                   
                    style={{ width: 256 }}
                  >
                    {resources.map((resource, index) => (
                      <SubMenu
                        key={index}
                        title={
                          <Checkbox
                            onChange={onChange}
                            value={resource.resource_code}
                          >
                            {resource.name}
                          </Checkbox>
                        }
                      >
                        
                      </SubMenu>
                    ))}

                    <p className="text-center">
                      <button
                        className="btn-success btn btn-sm px-5 text-white"

                        onClick={() => add_resource()}
                      >
                        Add
                      </button>
                    </p>
                  </Menu>
                }
              >
                <Button>
                  Grant Resource <DownOutlined />
                </Button>
              </Dropdown>
            </div>
            <div className="card-body">
              {roleGrants.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description ="You dont have any resource permissions"/>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover mg-0">
                      <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                        <tr className="bg-light">
                          <th className="pl-5">#</th>
                          <th>Name</th>
                          <th>Permissions</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roleGrants.map((permission, index) => (
                          <tr key={index}>
                            <td>{index}</td>
                            <td>{permission.resource_code}</td>
                            <td>
                              {permission.permissions.map((tag, i) => (
                                <Tag
                                key={i}
                                  color="green"
                                  closable
                                  onClose={() =>
                                    remove_permission(permission.grant_id, tag)
                                  }
                                >
                                  {tag}
                                </Tag>
                              ))}
                            </td>
                            <td>
                              <Dropdown
                                overlay={
                                  <Menu
                                  mode="inline"
                                  style={{ width: 256 }}
                                >
                                  {permissions.map((resource, index) => (
                                    <SubMenu
                                      key={index}
                                      icon={<MailOutlined />}
                                      title={
                                        <Checkbox
                                          onChange={onPermissionChange}
                                          value={resource.value}
                                        >
                                          {resource.label}
                                        </Checkbox>
                                      }
                                    >
                                      
                                    </SubMenu>
                                  ))}
              
                                  <p className="text-center">
                                    <button
                                      className="btn-success btn btn-sm px-5 text-white"
                                      onClick={() => add_permissions(permission.grant_id)}
                                    >
                                      Add
                                    </button>
                                  </p>
                                </Menu>
                                }
                              >
                             
                                  <Icon.PlusCircle
                                   size={16}
                                     className="text-info" 
                                     style={{cursor: "pointer"}}
                                    />
                                  
                              
                                 
                           
                              </Dropdown>
                              <Popconfirm
                                title="Are you sure you want to revoke this resource?"
                                onConfirm={() =>
                                  revoke(permission.resource_code)
                                }
                                onCancel
                                okText="Yes"
                                cancelText="No"
                              >
                               
                                  <Icon.Trash2 
                                  size={16} 
                                  style={{cursor: "pointer"}}
                                  className="mx-4 text-danger" />
                                
                              </Popconfirm>
                              ,
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    className="ml-auto"
                    responsive={true}
                    dataSource={roleGrants}
                    total={roleGrants.length}
                    showTotal={(total) => `Total ${total} items`}
                    defaultPageSize={numEachPage}
                    defaultCurrent={1}
                    onChange={handlePaginationChange}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewRole;

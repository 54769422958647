import React, { useState, useContext } from "react";
import { Steps, Button } from "antd";
import "antd/dist/antd.css";
import "../../../assets/css/dashboard.css";

import AssetDetails from "./Asset_Detail";
import Benefits from "./Benefits";
import Confirm from "./Confirm";
import { KycContext } from "../../../context/KycContext";

const { Step } = Steps;

export default function Index(props) {
  const steps = [
    {
      title: "Risk Details",
      content: <AssetDetails id={props.id} />,
    },
    {
      title: "Optional Benefits",
      content: <Benefits id={props.id} />,
    },
    {
      title: "Confirm",
      content: <Confirm id={props.id} />,
    },
  ];

  // access context

  const { current } = useContext(KycContext);

  return (
    <div className="row">
      <div className="col-md-3">
        <Steps current={current} direction="vertical">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div className="col-md-9">
        <div className="steps-content">{steps[current].content}</div>
      </div>
    </div>
  );
}

import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default function (ComposedComponent) {
	class NetworkDetector extends Component {
		state = {
			isDisconnected: false,
		};

		componentDidMount() {
			this.handleConnectionChange();
			window.addEventListener('online', this.handleConnectionChange);
			window.addEventListener('offline', this.handleConnectionChange);
		}

		componentWillUnmount() {
			window.removeEventListener('online', this.handleConnectionChange);
			window.removeEventListener('offline', this.handleConnectionChange);
		}

		handleConnectionChange = () => {
			const condition = navigator.onLine ? 'online' : 'offline';
			if (condition === 'online') {
				const webPing = setInterval(() => {
					fetch('//google.com', {
						mode: 'no-cors',
					})
						.then(() => {
							this.setState({ isDisconnected: false }, () => {
								return clearInterval(webPing);
							});
						})
						.catch(() => {
							this.setState({ isDisconnected: true });
						});
				}, 2000);
				return;
			}

			return this.setState({ isDisconnected: true });
		};
		notify = () => {
			toast.error("It appears you're offline. Kindly check your internet connection!", {
				position: 'bottom-center',
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		};

		render() {
			const { isDisconnected } = this.state;
			return (
				<div>
					{isDisconnected && <ToastContainer />}
					<ComposedComponent {...this.props} />
				</div>
			);
		}
	}

	return NetworkDetector;
}

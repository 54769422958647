import React, { useContext } from "react";
import ConfigForm from "./Add_Settlemet_Config";
import { createConfig, fetch_wallet_info } from "services/apiSrv";
import { useMutation, useQuery } from "react-query";
import Loader from "components/widgets/loader/ComponentLoader";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";

const SettlementConfigContainer = ({ refetch }) => {
  const { modalClose } = useContext(ModalContext);
  const listAcc = async () => {
    let resp = await fetch_wallet_info();
    console.log("ACCOUNTS", resp);
    return resp.entity_accounts;
  };

  const create = useMutation(createConfig, {
    onSuccess: () => {
      refetch();
      modalClose();
      show_toast("Configuration added successfully", "success");
    },
    onError: async (err) => {
      modalClose();
    },
  });

  const { data, isLoading, isSuccess } = useQuery("list accounts", listAcc);
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <ConfigForm accounts={data} create={create} />}
    </div>
  );
};

export default SettlementConfigContainer;

import React from 'react';
import AddIncentive from './AddIncentive';

const AddIncentiveContainer = () => {
	return (
		<div>
			<AddIncentive />
		</div>
	);
};

export default AddIncentiveContainer;

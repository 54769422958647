import React from "react";

import { Descriptions } from "antd";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";

import Loader from "components/widgets/loader";
import { getFeeDetails, calculateFee } from "services/apiSrv";
import routes from "routes";
import { calculateFeeForm } from "components/widgets/forms/Calculate_Fee_Form";
import CustomForm from "components/widgets/forms/CustomForm";
import { CalculateFeeSchema } from "utils/validationSchemas";
import { formatDate } from "utils/helpers";

const FeesDetails = () => {
  const { id } = useParams();

  async function fetchFeeDetails() {
    let resp = await getFeeDetails(id);
    return resp.fee_details;
  }

  const { data, isSuccess, isLoading } = useQuery(
    "Fee details",
    fetchFeeDetails
  );

  const onSubmit = async (model) => {
    let system_fee_ref = data.system_fee_ref;
    let modValues = { ...model, system_fee_ref };
    try {
      calculateFee(modValues);
    } catch (err) {}
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <Link className="breadcrumb-item active" to={routes.dashboard}>
                <i className="icon ion-ios-home-outline " /> Home
              </Link>
              <Link className="breadcrumb-item active" to="#">
                <i className="icon ion-ios-home-outline " /> Account
              </Link>
              <Link className="breadcrumb-item active" to={routes.tax}>
                <i className="icon ion-ios-home-outline " /> Fees
              </Link>
              <Link className="breadcrumb-item ">details</Link>
            </div>
            <h5 className="text-capitalize">{data.fee_name} Details</h5>
          </div>

          <div className="card mg-b-20">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  {" "}
                  <Descriptions title="Fee details" size="default">
                    <Descriptions.Item label="Fee Name">
                      {data.fee_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Type">
                      {data.fee_type}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Value">
                      {data.fee_value}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created Date">
                      {formatDate(data.created_date)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Account">
                      {data.entity_fee_account}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Reference">
                      {data.entity_fee_ref}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fee Management">
                      {data.fee_management}
                    </Descriptions.Item>
                    <Descriptions.Item label=" Status">
                      {data.status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Currency">
                      {data.entity_fee_currency}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {data.fee_description}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="col-md-3">
                  <h6 className="text-center">Calculate Fee</h6>
                  <CustomForm
                    className="form mt-md-0 mt-4"
                    validationSchema={CalculateFeeSchema}
                    model={calculateFeeForm}
                    onSubmit={(model) => {
                      onSubmit(model);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FeesDetails;

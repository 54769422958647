import React, { useContext } from 'react';

import { Modal } from 'react-bootstrap';
import { Button } from 'antd';

import { KycContext } from 'context/KycContext';
import { ModalContext } from 'context/ModalContext';
import SelectClass from 'components/stepper/request-stepper/Select_Class';
import ProductRequirement from 'components/stepper/request-stepper/ProductRequirement';

const AddRisk = (props) => {
	// access context

	const { setCurrent, current, next, prev } = useContext(KycContext);
	const { setModalShow } = useContext(ModalContext);

	// on modal close clear state

	const close = () => {
		setModalShow(false);
		setCurrent(0);
	};

	const steps = [
		{
			title: 'Select an Insurance Class',
			content: <SelectClass />,
		},
		{
			title: 'Fill Product Requirements',
			content: <ProductRequirement systemRef={props.systemRef} refetchRisks={props.refetchRisks} />,
		},
		{
			title: '3',
			content: '3',
		},
	];

	return (
		<div>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				backdrop="static"
				keyboard={false}
				onHide={() => close()}
			>
				<Modal.Header className="bg-light" closeButton>
					<span className="">{steps[current].title}</span>
				</Modal.Header>
				<Modal.Body className="">
					<div className="steps-content ">{steps[current].content}</div>
				</Modal.Body>
				<Modal.Footer>
					{current > 0 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded "
							onClick={() => prev()}
						>
							Back
						</Button>
					)}
					{current === 1 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded "
							form="RISK_FORM"
							key="submit"
							htmlType="submit"
						>
							Add Risk
						</Button>
					)}
					{current !== steps.length - 2 && (
						<Button
							className="btn-blue float-right mt-5 mb-3 text-white  px-4 py-1 rounded"
							onClick={() => next()}
						>
							Next
						</Button>
					)}
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AddRisk;

import React, { useContext, useState } from "react";
import { Form, Input, Button, Radio } from "antd";
import { KycContext } from "../../../context/KycContext";
import * as Icon from 'react-feather'


export default function SearchClient() {

  const { next } = useContext(KycContext);
const [parameter, setParameter]= useState("phone")




  const [form] = Form.useForm();
  const onChange = ({ parameter }) => {
    setParameter(parameter);
  };

  return (
    <div >
     
        <>
          <p className="upload-title text-center mt-2">
           Search for a client in NEXUS
          </p>

            <Form
      form={form}
      layout="vertical"
      initialValues={{ parameter }}
      onValuesChange={onChange}
    >
      <Form.Item label="Search by:" name="parameter">
        <Radio.Group>
          <Radio.Button value="phone">Phone Number</Radio.Button>
          <Radio.Button value="email">Email Address</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {parameter==="phone" &&
        <Form.Item label="" >
        <Input placeholder="Enter phone number" />
      </Form.Item>
      }
    {parameter==="email" &&      
     <Form.Item
        label=""
      >
        <Input placeholder="Enter email address" />
      </Form.Item>}

      <Form.Item>
        <Button type="primary"><span className="fa fa-search mr-2" /> Search</Button>
      </Form.Item>
    </Form>
        </>
      
      <footer>
        <Button
          // disabled={quotations.loading}
          className="btn-blue float-right mt-5 mb-3 text-white px-4 py-1 rounded "
          type="submit"
           onClick={() => next()}
        >
          Next
        </Button>
      </footer>
    </div>
  )
}

import React from "react";

import Input from "components/formFields/input/Input";
import Select from "components/formFields/select";
import MultipleSelect from "components/formFields/select/multiple";
import Checkbox from "components/formFields/checkbox";
import TextArea from "components/formFields/textArea";
import Radio from "components/formFields/radio";
import {
  requiredRule,
  minLengthRule,
  maxLengthRule,
  passwordMatchRule,
} from "./inputValidationRules";

export function createFormFieldConfig(label, name, type, defaultValue = "") {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Input
          key={key}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

export function createMultipleSelectFormFieldConfig(
  label,
  name,
  options,
  defaultValue = ""
) {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <MultipleSelect
          key={key}
          name={name}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
          options={options}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}
export function createSelectFormFieldConfig(
  label,
  name,
  options,
  defaultValue = ""
) {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Select
          key={key}
          name={name}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
          options={options}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

export function createCheckboxFieldConfig(
  label,
  name,
  type,
  defaultValue = ""
) {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Checkbox
          key={key}
          name={name}
          type={type}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

export function createTextAreaFieldConfig(label, name, defaultValue = "") {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <TextArea
          key={key}
          name={name}
          label={label}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

export function createRadioFieldConfig(
  groupLabel,
  radioData,
  radioClass,
  defaultValue = ""
) {
  return {
    renderInput: (handleChange, value, isValid, error, key) => {
      return (
        <Radio
          groupLabel={groupLabel}
          key={key}
          radioData={radioData}
          isValid={isValid}
          value={value}
          handleChange={handleChange}
          errorMessage={error}
          radioClass={radioClass}
        />
      );
    },
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}
// object representation of signup form
export function testing(options) {
  let signupForm = {
    email: {
      ...createFormFieldConfig("Email", "email", "email"),
      validationRules: [
        requiredRule("email"),
        minLengthRule("email", 10),
        maxLengthRule("email", 25),
      ],
    },
    pin: {
      ...createFormFieldConfig("KRA Pin", "pin", "text"),
      validationRules: [
        requiredRule("pin"),
        minLengthRule("pin", 3),
        maxLengthRule("pin", 25),
      ],
    },
    category: {
      ...createSelectFormFieldConfig("Category", "category", options),
      validationRules: [requiredRule("category")],
    },
  };
  return signupForm;
}

// object representation of login form
export const loginForm = {
  username: {
    ...createFormFieldConfig("Username", "username", "text"),
    validationRules: [requiredRule("username")],
  },

  password: {
    ...createFormFieldConfig("Password", "password", "password"),
    validationRules: [
      requiredRule("password"),
      minLengthRule("password", 7),
      maxLengthRule("password", 20),
    ],
  },
};
// object representation of credentials form
export const credentialsForm = {
  names: {
    ...createFormFieldConfig("Full Name", "names", "text"),
    validationRules: [requiredRule("names")],
  },
  contact: {
    ...createFormFieldConfig("Phone Number", "contact", "text"),
    validationRules: [requiredRule("contact")],
  },
  username: {
    ...createFormFieldConfig("Username", "username", "text"),
    validationRules: [requiredRule("username")],
  },
  // ...(true && {username: {
  // 	...createFormFieldConfig('Username', 'username', 'text'),
  // 	validationRules: [requiredRule('username')],
  // }}),

  password: {
    ...createFormFieldConfig("Password", "password", "password"),
    validationRules: [
      requiredRule("password"),
      minLengthRule("password", 7),
      maxLengthRule("password", 20),
    ],
  },
  confirmPassword: {
    ...createFormFieldConfig("Confirm Password", "confirmPassword", "password"),
    validationRules: [passwordMatchRule()],
  },
};

export const addTaxForm = {
  tax_name: {
    ...createFormFieldConfig("Tax Name", "tax_name", "text"),
    validationRules: [requiredRule("tax_name")],
  },
  tax_reference: {
    ...createFormFieldConfig("Tax Reference", "tax_reference", "text"),
    validationRules: [requiredRule("tax_reference")],
  },
  tax_description: {
    ...createTextAreaFieldConfig("Tax Description", "tax_description"),
    validationRules: [requiredRule("fee_description")],
  },
};
export const addDepartment = {
  name: {
    ...createFormFieldConfig("Department Name", "name", "text"),
    validationRules: [requiredRule("name")],
  },
  code: {
    ...createFormFieldConfig("Department Code", "code", "text"),
    validationRules: [requiredRule("code")],
  },

  description: {
    ...createTextAreaFieldConfig("Department Description", "description"),
    validationRules: [requiredRule("description")],
  },
};

export function _addUsers(departments, roles, security) {
  let addUsersForm = {
    name: {
      ...createFormFieldConfig("Enter Name", "name", "text"),
      validationRules: [requiredRule("name")],
    },
    email: {
      ...createFormFieldConfig("Email", "email", "email"),
      validationRules: [
        requiredRule("email"),
        minLengthRule("email", 10),
        maxLengthRule("email", 25),
      ],
    },
    username: {
      ...createFormFieldConfig("Username", "username", "text"),
      validationRules: [requiredRule("username")],
    },
    contact: {
      ...createFormFieldConfig("Contact", "contact", "text"),
      validationRules: [requiredRule("contact")],
    },
    ref: {
      ...createFormFieldConfig("Staff Reference", "ref", "text"),
      validationRules: [requiredRule("ref")],
    },
    department: {
      ...createSelectFormFieldConfig(
        "Select a Department",
        "department",
        departments
      ),
      validationRules: [requiredRule("department")],
    },
    role: {
      ...createSelectFormFieldConfig("Select a Role", "role", roles),
      validationRules: [requiredRule("role")],
    },
    security: {
      ...createSelectFormFieldConfig(
        "Select Security Context",
        "security",
        security
      ),
      validationRules: [requiredRule("security")],
    },
  };
  return addUsersForm;
}

import React, { useContext, useRef, useEffect } from 'react';
import { Radio, Button, Avatar, Divider } from 'antd';
import { KycContext } from '../../../context/KycContext';
import { DashboardContext } from '../../../context/DashboardContext';
import { ModalContext } from '../../../context/ModalContext';
import { AlertsContext } from '../../../context/AlertsContext';
import * as Icon from 'react-feather';
import CustomMsg from 'components/widgets/modals/SuccessMsg';
import InfoAlert from '../../alerts/InfoAlert';

export default function Confirm(props) {
	// Access Context
	const { prev } = useContext(KycContext);
	const { view_quotation, asset, checked, loading, send_to_client, assetLimits, listQuotations } =
		useContext(DashboardContext);
	const { showAlert, setShowAlert } = useContext(AlertsContext);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	let risk = [];

	console.log('THE ASSET HERE', asset);
	Object.keys(asset).map((key) => {
		risk.push({
			name: key,
			value: asset[key],
		});
	});

	return (
		<>
			<CustomMsg show={modalShow === 'customMsg' ? true : false} onHide={modalClose} />
			<h3 className="upload-title text-center">Confirm your Details</h3>

			<div className="row mt-md-4 px-md-4">
				<div className="col-md-6">
					<p className="text-dark font-weight-bold">Asset Details</p>
					{risk.map((asset) => (
						<p className="font-weight-bold">
							{asset.name}: <span className="font-weight-normal pl-2">{asset.value}</span>{' '}
						</p>
					))}
				</div>
				<div className="col-md-6">
					<p className="text-dark font-weight-bold">Optional Benefits</p>
					<ol>
						{assetLimits.map((limit) => (
							<li>
								{limit.benefit_name} with a limit of upto KES.{' '}
								<span className="font-weight-bold">{limit.limit} </span>
							</li>
						))}
					</ol>
				</div>
			</div>
			<footer>
				<Button
					className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
					type="submit"
					onClick={() => send_to_client(risk, assetLimits, localStorage.getItem('client_quotation_id'))}
				>
					{loading && <i className="fa fa-circle-notch fa-spin text-white" style={{ marginRight: '5px' }} />}
					{loading && <span className="text-capitalize text-white">Please wait...</span>}
					{!loading && <span className="text-capitalize">Send Quotation</span>}
				</Button>
				<Button
					className="btn-secondary float-right mb-5 mr-3  px-4 py-1 rounded "
					type="submit"
					onClick={prev}
				>
					Back
				</Button>
			</footer>
		</>
	);
}

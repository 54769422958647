import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import Rm from '../forms/Rm';

const RequestUnderwriter = (props) => {
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Add a manager</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Rm users={props.users} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default RequestUnderwriter;

import React from 'react'
import { api_srv } from 'services'
import ViewUserGroup from './View_User_Group'
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const ViewUserGroupContainer = (props) => {

    const {id} = props.match.params

    const getGroupDetails=async()=>{
           let resp = await (await api_srv).get_user_details(id)
           return resp.entity_group_details
       
    }

    const { isLoading, isSuccess, data } = useQuery(
        "viewUserGroup",
        getGroupDetails
      );
    return (
        <div>
            {isLoading && <Loader/>}
            {isSuccess &&     <ViewUserGroup
            data={data}
            id={id}
            getGroupDetails={getGroupDetails}
            />}
        
        </div>
    )
}

export default ViewUserGroupContainer

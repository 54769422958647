import React, {useContext, useState } from "react";
import * as Icon from "react-feather";
import { DashboardContext } from "../../context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { Button, Input, Pagination, Avatar, Image} from "antd";
import NoData from "../../components/no-data/NoData";
import AddChatUsers from "components/widgets/modals/AddChatUsers";


export default function UserGroups({
  data,
  view_user_group,
  search,
  searchSpace,
  fetch_user_groups,
}) {
  // access context

  const { paginate, numEachPage, handlePaginationChange } = useContext(
    DashboardContext
  );
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);



  // destructure

  const { Search } = Input;

  const items = data
    .filter((data) => {
      if (search == null) return data;
      else if (
        data.email.toLowerCase().includes(search.toLowerCase()) ||
        data.names.toLowerCase().includes(search.toLowerCase()) ||
        data.username.toLowerCase().includes(search.toLowerCase())
      ) {
        return data;
      }
    })
    .slice(paginate.minValue, paginate.maxValue)
    .map((row) => {
      return (
        <tr key={row.user_id}>
          <td>   <Avatar
      src={<Image src={`https://api-uat.nexus.ke` + row.profile_photo_path} />}
    /></td>

          <td>{row.names}</td>
          <td>{row.username}</td>

          <td>{row.email}</td>

          <td>
           
             <Icon.Trash2 onClick={() => view_user_group(row.group_id)} size={16} className="mr-2" color="red"/> 
   
          </td>
        </tr>
      );
    });

  return (
    <>
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 pd-b-35">
          <div className="breadcrumb pd-0 mg-0">
            <a className="breadcrumb-item" href="index.html">
              <i className="icon ion-ios-home-outline" /> Home
            </a>
            <a className="breadcrumb-item active" href>
              Dashboard
            </a>
          </div>
        </div>
        <AddChatUsers
          show={modalShow}
          onHide={modalClose}
          fetch_user_groups={fetch_user_groups}
        />
        <Button
          className="btn-blue text-white rounded float-right mb-3"
          onClick={() => setModalShow(true)}
        >
        Add Users
         
        </Button>

        {/* {data.length === 0 ? (
          <NoData message="No chat groups currently" />
        ) : ( */}
          <>
            <div className="col-lg-12 card rounded-10 col-xl-12">
              <div className="mg-b-20">
                <div className="card-header">
                  <div className="pd-t-5 pd-b-5 pd-l-5">
                    <h1 className=" tx-18">
                      <Icon.MessageSquare size={30} className="pr-2 text-primary" />
                      Chat Groups
                    </h1>
                  </div>
                  <Search
                    placeholder="Search UserGroup"
                    type="search"
                    onChange={(e) => searchSpace(e)}
                    className="ml-md-auto mg-r-10"
                    style={{ width: 200 }}
                  />
                </div>
                <div className="mg-b-20">
                  <div
                    className="card-body pd-0 collapse show"
                    id="customerDetails"
                  >
                    <div className="table-responsive">
                      <table className="table table-hover mg-0">
                        <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                          <tr className="bg-light">
                            <th className=""></th>
                            <th className="">Name</th>
                            <th>Email</th>
                            <th>Username</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{items}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                  className="ml-auto"
                  responsive={true}
                  // dataSource={data}
                  // total={data.length}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={numEachPage}
                  defaultCurrent={1}
                  onChange={handlePaginationChange}
                />
              </div>
            </div>
          </>
         {/* )}   */}
      </div>
    </>
  );
}

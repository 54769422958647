import React, { useContext } from "react";

import { Avatar, Empty } from "antd";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";

import CustomTable from "components/widgets/table";
import { DashboardContext } from "context/DashboardContext";

export default function RecentRequests({ data }) {
  const context = useContext(DashboardContext);
  const { quotationRequests, setQuotationRequests } = context;
  const history = useHistory();

  var getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  // let rows = data
  let rows = data.slice(Math.max(data.length - 5, 0));

  const view_quotation_request = (id) => {
    history.push(`/dashboard/quotation-requests/view/${id}`);
  };
  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "", align: "c" },
      { name: "Client", align: "c" },
      { name: "Request Date", align: "c" },
      { name: "Status", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "names", class: "center" },
      { type: "text", field: "names", class: "center" },
      { type: "date", field: "created_date", class: "center" },
      { type: "status", field: "status", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "system_quotation_request_ref",
            method: view_quotation_request,
          },
        ],
      },
    ],
  };

  return (
    <div className="col-md-12 col-xl-8">
      <div className="card mg-b-20">
        <div className="card-header">
          <h4 className="card-header-title">Recent Quotation Requests</h4>
        </div>
        <div className="card-body pd-0 collapse show" id="customerDetails">
          <CustomTable
            table_fields={tableData.table_fields}
            row_data={tableData.row_data}
            table_data={rows}
            title={tableData.title}
          />
        </div>
      </div>
    </div>
  );
}

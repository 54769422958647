import React from "react";

import { useQuery } from "react-query";

import { get_quotation_requests } from "services/apiSrv";
import Loader from "components/widgets/loader";
import QuotationRequest from "./Quotation_Requests";

const QuotationRequestContainer = (props) => {
  async function fetch_quotation_requests() {
    let resp = await get_quotation_requests();
    return resp.quotation_requests;
  }

  const { isLoading, isSuccess, data } = useQuery(
    "quotationRequests",
    fetch_quotation_requests
  );

  const view_quotation_request = (id) => {
    props.history.push(`/dashboard/quotation-requests/view/${id}`);
  };
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <QuotationRequest
          data={data}
          view_quotation_request={view_quotation_request}
        />
      )}
    </div>
  );
};

export default QuotationRequestContainer;

import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import Map from '../../map';

const Location = (props) => {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" top transparent={true}>
			<Modal.Body>
				<Map />
			</Modal.Body>
		</Modal>
	);
};

export default Location;

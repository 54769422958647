import React, { useContext, useEffect } from "react";
import ViewQuotation from "./View_Quotation";
import { api_srv } from "services";
import { DashboardContext } from "context/DashboardContext";

const ViewQuotationContainer = (props) => {
  const { listQuotations, setListQuotations } = useContext(DashboardContext);

  const client_quotation_id = props.match.params.id;

  async function view_quotation(id) {
    setListQuotations({ ...listQuotations, loading: true });
    try {
      let resp = await (await api_srv).view_a_quotation(id);
      console.log("the quotation detail resp", resp);
      setListQuotations({
        ...listQuotations,
        quotation_details: resp.quotation_details,
        loading: false,
      });
    } catch (err) {
      let error = await err;
      console.log("error viewing quotations", error);
      setListQuotations({ ...listQuotations, loading: false });
    }
  }

  useEffect(() => {
    view_quotation(client_quotation_id);
  }, []);

  const individual_quotation = (id) => {
    let tempDetails = [...listQuotations.all_quotations];
    const quote_detail = tempDetails.find(
      (quote_detail) => quote_detail.client_quotation_id === id
    );
    setListQuotations({ ...listQuotations, quote: quote_detail });
  };

  return (
    <div>
      <ViewQuotation data={listQuotations.quotation_details} />
    </div>
  );
};

export default ViewQuotationContainer;

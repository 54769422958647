import React from 'react';
import './salesstyles.css';
import { Avatar, Divider, Progress, Empty } from 'antd';
import * as Icon from 'react-feather';
import { Line } from 'react-chartjs-2';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

const graphData = {
	labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
	datasets: [
		{
			label: 'Sales Summary',
			data: [33, 53, 85, 41, 44, 65],
			fill: true,
			backgroundColor: 'rgba(75,192,192,0.2)',
			borderColor: 'rgba(75,192,192,1)',
		},
	],
};

const SalesDetails = (props) => {
	const _getDetails = async () => {
		let id = props.match.params.id;
		let resp = await (await api_srv).get_rep_details(id);
		console.log('THE DETAILS ZA REP', resp);
		return resp;
	};

	const { data, isLoading, isSuccess } = useQuery('sales rep details', _getDetails);
	const history = useHistory();
	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<div>
					{console.log('SALES DTA', data)}
					<div className="pageheader pd-t-25 pd-b-35">
						<div className="breadcrumb pd-0 mg-0">
							<a className="breadcrumb-item" href="index.html">
								<i className="icon ion-ios-home-outline" /> Home
							</a>
							<a className="breadcrumb-item active" href>
								Dashboard
							</a>
						</div>
					</div>
					<h1 className="tx-20">Sales Representative Info</h1>
					<div className="container">
						<div className="row">
							<div className="card col-md-4 mr-md-3">
								<div className="card-body">
									<div className="sales__rep__avatar mb-2">
										<Avatar
											size={{
												xs: 24,
												sm: 32,
												md: 40,
												lg: 64,
												xl: 80,
												xxl: 100,
											}}
											src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
										/>
										<b className="pt-2">{data.sales_rep.sales_rep_names}</b>
									</div>
									<Divider orientation="left">Personal Info</Divider>
									<ul>
										<li>
											<Icon.Phone size={14} className="mr-2 text-primary" />
											Contact:
											<span className="float-right">{data.sales_rep.contact}</span>
										</li>
										<li>
											<Icon.Mail size={14} className="mr-2 text-primary" />
											Email:
											<span className="float-right">{data.sales_rep.sales_rep_email}</span>
										</li>
										<li>
											<Icon.Clock size={14} className="mr-2 text-primary" />
											Created Date:
											<span className="float-right">
												<Moment format="DD-MM-YYYY">{data.sales_rep.created_date}</Moment>
											</span>
										</li>
										<li>
											<Icon.File size={14} className="mr-2 text-primary" />
											Reference:
											<span className="float-right">{data.sales_rep.sales_rep_ref}</span>
										</li>
									</ul>
									<Divider orientation="left">Sales Summary</Divider>
									<Progress type="circle" percent={75} />
								</div>
							</div>
							<div className="col-md-7">
								<div className="card mb-3">
									<div className="card-body">
										<h6>My Leads</h6>
										{data.leads.length === 0 ? (
											<Empty />
										) : (
											<div className="table-responsive">
												<table className="table table-hover mg-0">
													<thead className="tx-dark tx-uppercase tx-12 tx-bold">
														<tr className="bg-light">
															<th className="pl-5">Name</th>
															<th>Email</th>
															<th>Actions</th>
														</tr>
													</thead>
													<tbody>
														{data.leads.map((lead) => (
															<tr>
																<td className="d-flex align-items-center">
																	<div className="avatar mr-2">
																		<img
																			src="https://via.placeholder.com/100x100"
																			className="img-fluid"
																			alt=""
																		/>
																	</div>
																	<div className="d-block">
																		<a href className="my-0 mt-1 ">
																			{lead.lead_details.names}
																		</a>
																		{/* <p className=" mg-0 tx-gray-500">
                                        {row.description}
                                      </p> */}
																	</div>
																</td>
																<td>{lead.lead_details.email}</td>
																<td>
																	<a
																		href
																		onClick={() =>
																			history.push(
																				`/dashboard/leads/${lead.lead_ref}`
																			)
																		}
																	>
																		view
																	</a>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										)}
									</div>
								</div>
								<div className="card">
									<div className="card-body">
										<h6>Graph</h6>
										<Line data={graphData} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SalesDetails;

import React, { useContext, useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import { Select } from "antd";
import { useQuery, useMutation } from "react-query";

import "../../../assets/css/dashboard.css";
import { ModalContext } from "context/ModalContext";
import { add_risk_to_recommendation, list_risks } from "services/apiSrv";
import { show_toast } from "utils/helpers";
import Loader from "components/widgets/loader/ComponentLoader";

const AddProductBenefit = (props) => {
  const { Option } = Select;
  // Access Context

  console.log("THE PROPS FOR RISK RECOM", props);
  const { modalClose } = useContext(ModalContext);
  const [risk, setRisk] = useState("");

  function handleRiskChange(value) {
    setRisk(value);
  }
  async function fetch_risks() {
    let resp = await list_risks(props.id);
    console.log("risk det", resp);
    return resp.risks;
  }

  const { isLoading, isSuccess, data, refetch } = useQuery(
    "risks",
    fetch_risks
  );

  const postRisk = useMutation(add_risk_to_recommendation, {
    onSuccess: (data) => {
      modalClose();
      props.refetchrecommendations();
      show_toast("Risk added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  let postData = {
    ref: props.requestref,
    risk_ref: risk,
  };
  console.log("PRODUCT ID IS PASSED", props);

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Attach Risk to Recommendation</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select a risk to attach to the recommendation</p>
          {isLoading && <Loader />}
          {isSuccess && (
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder=""
              defaultValue={[]}
              onChange={handleRiskChange}
            >
              {data.map((risk, i) => (
                <Option key={i} value={risk.risk_system_ref} name="risk">
                  {risk.risk_reference}-{risk.sum_assured}
                </Option>
              ))}
            </Select>
          )}
          <div className="d-flex justify-content-center">
            <button
              disabled={postRisk.isLoading}
              className=" btn btn-success px-5 my-3 text-white"
              onClick={() => postRisk.mutate(postData)}
            >
              {postRisk.isLoading && (
                <i
                  className="fa fa-circle-notch fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
              {postRisk.isLoading && (
                <span className="text-capitalize">Please wait...</span>
              )}
              {!postRisk.isLoading && (
                <span className="text-capitalize">Add</span>
              )}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProductBenefit;

export const addSchemeForm = {
  form_title: "",
  form_subtitle: "",
  button_label: "Save",
  button_styles: "block btn-blue",
  fields: [
    {
      key: "name",
      label: "Scheme Name",
      name: "name",
    },
    {
      key: "reference",
      label: "Scheme Reference",
      name: "reference",
    },
    {
      key: "admin_email",
      label: "Admin Email",
      name: "admin_email",
      type: "email",
    },
    {
      key: "kra_pin",
      label: "KRA PIN",
      name: "kra_pin",
      type: "email",
    },
    {
      key: "contact_person",
      label: "Contact",
      name: "contact_person", //add how many inputs should be looped, if 3 3 inputs are displayed
      type: "DynamicInput",
      inputs: [
        {
          key: "contact_name",
          label: "Name",
          name: "contact_name",
        },
        {
          key: "contact_phone",
          label: "Phone Number",
          name: "contact_phone",
        },
        {
          key: "contact_email",
          label: "Email",
          type: "email",
          name: "contact_email",
        },
      ],
    },
    {
      key: "signed_in",
      label: "",
      name: "signed_in",
      type: "checkbox",
      options: [
        {
          key: "true",
          label: "Keep me signed in",
          value: "true",
        },
      ],
    },
  ],
};

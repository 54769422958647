import React, { useState } from "react";
import UserGroups from "./UserGroups";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const UserGroupContainer = (props) => {
  const [search, setSearch] = useState(null);

  const fetch_user_groups = async () => {
    let resp = await (await api_srv).list_user_groups();
    console.log("fetched user groups", resp);
    return resp.user_groups;
  };

  const view_user_group = (id) => {
    props.history.push(`/dashboard/usmg/usergroup/view/${id}`);
  };

  const { data, isLoading, isSuccess } = useQuery("userGroups", fetch_user_groups);

  // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <UserGroups
          data={data}
          view_user_group={view_user_group}
          search={search}
          searchSpace={searchSpace}
          fetch_user_groups={fetch_user_groups}
        />
      )}
    </div>
  );
};

export default UserGroupContainer;

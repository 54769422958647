import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import AddGroup from "../forms/Add_User_Chat"

const AddReportGroup = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Add a Report Group</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddGroup/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddReportGroup;

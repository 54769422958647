import React, { useContext, useState } from 'react';
import { Select, Radio, Input, Slider, Form, Button, InputNumber, Row, Col } from 'antd';
import { GrChapterAdd } from 'react-icons/gr';
import { AiOutlineDelete } from 'react-icons/ai';

export default function FeeForm({
	feeType,
	_addfees,
	management,
	taxed,
	handleFormValuesChange,
	taxes,
	accounts,
	onInputChange,
	slab,
	handleAddClick,
	handleRemoveClick,
}) {
	const { TextArea } = Input;

	const { Option } = Select;
	const [form] = Form.useForm();

	const marks = {
		0: '0°C',
		20: {
			style: {
				color: '#f50',
			},
			label: <strong>100°C</strong>,
		},
	};

	const onFinish = async (values) => {
		let modValues = { ...values, slab };
		_addfees.mutate(modValues);
	};

	return (
		<Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={handleFormValuesChange}>
			<Form.Item label="Fee Name" name="fee_name" rules={[{ required: true, message: 'Fee Name is required' }]}>
				<Input placeholder="input the fee name" />
			</Form.Item>
			<Form.Item
				label="Fee Reference"
				name="fee_reference"
				rules={[{ required: true, message: 'Fee Reference is required' }]}
			>
				<Input placeholder="input the fee reference" />
			</Form.Item>
			<Form.Item
				label="Description"
				name="fee_description"
				rules={[{ required: true, message: 'Fee Description is required' }]}
			>
				<TextArea placeholder="input the fee description" />
			</Form.Item>

			<Form.Item
				label="Is Taxed"
				name="taxed"
				rules={[{ required: true, message: 'Select if the fee is taxed or not' }]}
			>
				<Radio.Group>
					<Radio value={true}>Yes </Radio>
					<Radio value={false}>No</Radio>
				</Radio.Group>
			</Form.Item>
			{taxed && (
				<Form.Item label="Select Tax" name="entity_tax_ref">
					<Select
						showSearch
						optionFilterProp="children"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{taxes.map((tax) => (
							<Option value={tax.system_tax_ref} key={tax.entity_tax_account}>
								{tax.tax_name}
							</Option>
						))}
					</Select>
				</Form.Item>
			)}

			<Form.Item
				label="Fee Type"
				name="fee_type"
				rules={[{ required: true, message: 'Select fee type' }]}
				initialValue={feeType}
			>
				<Radio.Group buttonStyle="solid">
					<Radio.Button value="flat">Flat</Radio.Button>
					<Radio.Button value="percentage">Percentage</Radio.Button>
					<Radio.Button value="slab">Slab</Radio.Button>
				</Radio.Group>
			</Form.Item>
			{feeType === 'flat' && (
				<Form.Item label="" name="fee_value">
					<InputNumber placeholder="input value" style={{ width: '100%' }} />
				</Form.Item>
			)}
			{feeType === 'percentage' && (
				<>
					<Form.Item label="" name="fee_value">
						<InputNumber placeholder="input value" style={{ width: '100%' }} />
					</Form.Item>
				</>
			)}
			{feeType === 'slab' &&
				slab.map((el, i) => (
					<div className="row" key={i}>
						<div className="col-md-3">
							<Form.Item label="Min Value">
								<input
									name="min"
									type="number"
									required
									style={{ width: '100%' }}
									value={el.min}
									oninput="(validity.valid)||(value='');"
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-3">
							<Form.Item label="Max Value">
								<input
									name="max"
									type="number"
									style={{ width: '100%' }}
									value={el.max}
									oninput="(validity.valid)||(value='');"
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-4">
							<Form.Item label="Enter Value">
								<input
									name="value"
									type="number"
									required
									min={el.min}
									max={el.max}
									oninput="(validity.valid)||(value='');"
									style={{ width: '100%' }}
									value={el.value}
									onChange={(e) => onInputChange(e, i)}
								/>
							</Form.Item>
						</div>
						<div className="col-md-2">
							{slab.length - 1 === i && <GrChapterAdd color="#296918" onClick={handleAddClick} />}
							{slab.length !== 1 && (
								<AiOutlineDelete className="ml-2" color="red" onClick={() => handleRemoveClick(i)} />
							)}
						</div>
					</div>
				))}
			<Form.Item
				label="Management"
				name="management"
				rules={[{ required: true, message: 'Select management' }]}
				initialValue={management}
			>
				<Radio.Group>
					<Radio value="SYSTEM">Nexus</Radio>
					<Radio value="ENTITY">On the Intermediary</Radio>
				</Radio.Group>
			</Form.Item>
			{management === 'SYSTEM' && (
				<Form.Item label="Select Account" name="entity_fee_account">
					<Select defaultValue="">
						{accounts.map((account) => (
							<Option value={account.account_number} key={account.account_number}>
								{account.account_name}
							</Option>
						))}
					</Select>
				</Form.Item>
			)}
			<Form.Item label="Remarks" name="remarks" rules={[{ required: true, message: 'Remarks are required' }]}>
				<TextArea placeholder="input remarks" />
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit" block disabled={_addfees.isLoading}>
					{_addfees.isLoading ? (
						<span className="text-white">
							<i className="fa fa-circle-notch fa-spin" style={{ marginRight: '5px' }} /> Please wait...
						</span>
					) : (
						'Submit'
					)}
				</Button>
			</Form.Item>
		</Form>
	);
}

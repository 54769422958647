import React, { useContext } from "react";
import { Avatar, Badge } from "antd";
import { api_srv } from "services";
import "./sidebarchat.css";
import { DashboardContext } from "context/DashboardContext";

const SidebarChat = ({ recepient, id, name, addNewChat, image, chat_status }) => {
  const { setChatMessages,setConversationId,setInnerChat, setToChatId } = useContext(DashboardContext);
  setToChatId(recepient)
  setConversationId(id)

  // load all the conversations with a specific user
  const load_conversation = async () => {
    try{
      let resp = await (await api_srv).chat_message_conversations(id);
      console.log("THE CONVERSATION+++++++++++", resp.chats)
        setChatMessages(resp.chats);
        setInnerChat(true)

    }catch (err){
      throw err
    }
    
  };
  return !addNewChat ? (
    
    <div className="sidebarChat" onClick={()=>load_conversation()}>
      <Badge  className="chat__badge" style={{ backgroundColor: '#d40c7a' }} count={5}/>
        <Avatar src="https://avatars.dicebear.com/api/human/a23.svg" />
       
        <div className="sidebarChat__info">
        
          <h2>
            {name}{" "}
          </h2>
         
          <p>Last message will appear here...</p>
        </div>
        
      
    </div>
  ) : (
    <div className="sidebarChat">
      <h2>Add new Chat</h2>
    </div>
  );
};

export default SidebarChat;

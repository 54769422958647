import React from "react";
import { Select } from "antd";

const MultipleSelectField = ({
  label,
  name,
  handleChange,
  errorMessage,
  isValid,
  value,
  placeholder,
  options,
}) => {
  const { Option } = Select;
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        value={value}
        onChange={handleChange}
     
        className="form-control custom-select d-block w-100 "
      >
        <option value>Choose...</option>
        {options.length === 0
          ? null
          : options.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
      </select>
      {errorMessage && !isValid && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
    // <div className="form-group">
    //   <label htmlFor={name}>{label}</label>
    //   <Select
    //     mode="multiple"
    //     allowClear
    //     style={{ width: "100%" }}
    //     placeholder={placeholder}
    //     defaultValue={[]}
    //     onChange={handleChange}
    //   >
    //     {options.map((option) => (
    //       <Option value={option.value}>{option.label}</Option>
    //     ))}
    //   </Select>
    //   {errorMessage && !isValid && (
    //     <span className="error">{errorMessage}</span>
    //   )}
    // </div>
  );
};

export default React.memo(MultipleSelectField);

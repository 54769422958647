import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import CustomSuccessMsg from '../../results/CustomSuccessMsg'
import routes from "../../../routes"

const CustomMsg = (props) => {


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      top
      transparent={true}
      backdrop="static"
      keyboard={false}
    >
     
      <Modal.Body>
      <CustomSuccessMsg title="Success" subTitle="Quotation sent successfully to client" />
      </Modal.Body>
    
    </Modal>
  );
};

export default CustomMsg ;

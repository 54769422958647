import React from "react";
import { Link } from "react-router-dom";

export default function CountCard({ summary }) {
  return (
    <div className="row row-xs clearfix">
      {summary.map((obj) => (
        <div className="col-6 col-md-3 col-xl-3">
          <Link to={obj.link}>
            <div className="card mg-b-20">
              <div className="card-body pd-y-0">
                <div className="custom-fieldset mb-4">
                  <div className="clearfix">
                    <label className>{obj.label}</label>
                  </div>
                  <div className="d-flex align-items-center text-dark">
                    <div
                      className={`wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded ${obj.style}`}
                    >
                      {obj.icon}
                    </div>
                    <div>
                      <h2 className="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                        <span className="counter">{obj.summary}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "context/ModalContext";
import { Checkbox, List, Avatar, message } from "antd";
import { api_srv } from "services";

export default function SelectUser({ id,getGroupDetails }) {
  const [users, setUsers] = useState([]);

  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  // Access Context

  const { modalClose } = useContext(ModalContext);

  // Destructure

  useEffect(() => {
    async function init() {
      let resp = await (await api_srv).list_users();

      setUsers(resp.users);
    }
    init();
  }, []);

  const add_user = async () => {
    if (checked.length === 0) {
      message.error("No user added");
    } else {
      try {
        setLoading(true);
        let resp = await (await api_srv).add_user_to_group(id, checked[0]);
        console.log("response adding user", resp);
        getGroupDetails()
        setLoading(false);
        modalClose();
      } catch (err) {
        let error = await err;
        console.log("error adding user", error);
        setLoading(false);
        modalClose();
      }
    }
  };

  function updateStateList(e) {
    console.log(e.target.value);
    if (e.target.checked) {
      //append to array
      setChecked(checked.concat([e.target.value]));
    } else {
      //remove from array
      setChecked(
        checked.filter(function (val) {
          return val !== e.target.value;
        })
      );
    }
  }
  return (
    <div>
      <Checkbox.Group style={{ width: "100%" }}>
        <List
          bordered
          dataSource={users}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                name={item.names}
                value={item.user_id}
                onChange={updateStateList}
              >
                <Avatar
                  size={40}
                  className="mr-2"
                  style={{
                    color: "#001f5f",
                    backgroundColor: "rgba(5,88,183,0.10)",
                  }}
                ></Avatar>
                <span className="font-weight-bold">
                  {item.names} <i className="pl-4">~{item.email}</i>
                </span>
              </Checkbox>
            </List.Item>
          )}
        />
      </Checkbox.Group>
      <div className="d-flex justify-content-center">
        <button
          color="primary"
          variant="contained"
          disabled={loading}
          className="btn btn-success px-5 my-4 "
          onClick={() => add_user()}
        >
          {loading && (
            <i
              className="fa fa-circle-notch fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <span className="text-capitalize">Adding User</span>}
          {!loading && <span className="text-capitalize"> Add</span>}
        </button>
      </div>
    </div>
  );
}

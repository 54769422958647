import React from "react";
import Moment from "react-moment";

export default function View_Department({ data }) {
  return (
    <>
      <div id="main-wrapper">
        <div className="pageheader pd-t-25 ">
          <div className="breadcrumb pd-0 mg-0">
            <a className="breadcrumb-item" href="index.html">
              <i className="icon ion-ios-home-outline" /> Home
            </a>
            <a className="breadcrumb-item active" href>
              Claims
            </a>
            <a className="breadcrumb-item active" href>
              View
            </a>
          </div>
        </div>

        <div className=" pd-20  m-auto">
          <h5 className="card-title bd-b pd-y-20">{data.department_name}</h5>

          <div className=" pd-0 printing-area">
            <div className="card card-body bg-lighter">
              <div className="row">
                <div className="col-lg-4  d-lg-block">
                  <div className="fs-12">
                    <div className="mb-3">
                      <strong className="d-inline-block ">Name</strong>:{" "}
                      {data.department_name}
                    </div>
                    <div className="mb-3">
                      <strong className="d-inline-block ">Code</strong>:{" "}
                      {data.department_code}{" "}
                    </div>
                    <div className="mb-3">
                      <strong className="d-inline-block ">Created Date</strong>:{" "}
                      <Moment format="DD/MM/YYYY">{data.created_date}</Moment>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  d-lg-block">
                  <div className="mb-3">
                    <strong className="d-inline-block ">Status</strong>:{" "}
                    <span
                      className={`${
                        data.rec_status_code === 0
                          ? "text-success"
                          : "text-warning"
                      }`}
                    >
                      {data.rec_status_code === 0 ? "Active" : "Pending"}
                    </span>
                  </div>
                  <div className="">
                    <strong className="d-inline-block ">Description</strong>
                  </div>
                  <p className="fs-12">{data.department_description}</p>
                </div>
              </div>
              <div className="mg-y-20 pd-20">
                <button type="button" className="btn btn-success mg-t-5 mr-2">
                  <i className="fa fa-check" /> Approve
                </button>
                <button type="button" className="btn btn-info mg-t-5 mr-2">
                  <i className="fa fa-edit" /> Edit
                </button>
                <button type="button" className="btn btn-secondary mg-t-5 mr-2">
                  <i className="fa fa-users" /> View Users
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

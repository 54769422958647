import React, { useContext } from 'react';
import { KycContext } from 'context/KycContext';
import 'assets/css/dashboard.css';
import { Button, Select, Form, Input } from 'antd';

const Confirm = ({ onChange, fields }) => {
	// destructure

	const { Option } = Select;
	const { prev } = useContext(KycContext);

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: '${label} is required!',
		types: {
			email: '${label} is not a valid email!',
			number: '${label} is not a valid number!',
		},
		number: {
			range: '${label} must be between ${min} and ${max}',
		},
	};

	const onFinish = (values) => {
		console.log(values);
	};

	return (
		<Form
			name="scheme"
			onFinish={onFinish}
			className="mt-5"
			scrollToFirstError
			validateMessages={validateMessages}
			layout="vertical"
		>
			Confirm Details
			<Form.Item>
				<Button className="btn-blue text-white float-right mb-5  px-4 py-1 rounded " htmlType="submit">
					Save
				</Button>
			</Form.Item>
		</Form>
	);
};
export default Confirm;

import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import { Avatar } from 'antd';

const ViewUnderwriterRate = (props) => {
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" backdrop="static">
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Rate Details</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-4">
							<div className="d-flex flex-column justify-content-center align-items-center  pd-x-15 flex-wrap">
								<Avatar size={94} className="mb-3" />
							</div>
						</div>
						<div className="col-md-8">
							<div className="mb-3 pd-t-10">Sanlam Motors</div>
							<div className="mb-3">
								<i>Motor </i>
							</div>
						</div>
						<div className="col-md-12 pd-x-35">
							<div className="mb-3">
								<strong className="d-inline-block ">Type:</strong> percentage
							</div>
							<div className="mb-3">
								<strong className="d-inline-block ">Value:</strong> 10
							</div>
							<div className="mb-3">
								<strong className="d-inline-block ">Name:</strong>Test
							</div>
							<div className="mb-3">
								<strong className="d-inline-block ">Description:</strong> Test
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ViewUnderwriterRate;

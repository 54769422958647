import React from 'react';
import { Divider, Button } from 'antd';
import Moment from 'react-moment';

const IncentiveDetails = () => {
	return (
		<div className="card mg-t-45">
			<div className="card-body">
				<Divider orientation="left" className="bg-light pd-y-5 font-italic">
					Incentive details
				</Divider>
				<div className="row">
					<div className="col-md-6">
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Name</strong>: Test Incentive
						</div>
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Reference Number</strong>: 23456ET
						</div>
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Type</strong>: Percentage
						</div>
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Description</strong>:{' '}
							eyfgwefgweuifgweuifhuiwehfuiwehfuiweghweuigfweuifgui
						</div>
					</div>
					<div className="col-md-6">
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Value</strong>: 2
						</div>
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Created Date</strong>:
							<Moment format="DD MMM YYYY" date={new Date()} />
						</div>
						<div className="mb-3">
							<strong className="d-inline-block wd-150">Status</strong>: Active
						</div>
					</div>
				</div>
			</div>
			<div className="card-footer bg-white">
				<Button className=" btn-teal mr-3 ml-auto rounded text-white">Verify</Button>
				<Button className="btn-blue btn-blue rounded mr-3 ">Edit</Button>
				<Button type="danger" className=" rounded mr-3 ">
					Delete
				</Button>
			</div>
		</div>
	);
};

export default IncentiveDetails;

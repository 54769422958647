import React, { useState, useContext } from 'react';
import ClaimsPendingApproval from './ClaimsPendingApproval';
import { DashboardContext } from 'context/DashboardContext';
import { api_srv } from '../../services';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const ClaimsContainer = () => {
	const history = useHistory();

	const { paginate, setPaginate, numEachPage } = useContext(DashboardContext);

	async function get_pending_claims() {
		let resp = await (await api_srv).list_pending_claims();
		console.log('PENDING CLAIMS HEREEEEE', resp);
		return resp.intermediary_claims;
	}

	const { isLoading, isSuccess, data } = useQuery('pending', get_pending_claims);

	const [search] = useState('');

	const view_claim_details = async (id, ref) => {
		localStorage.setItem('system_ref', ref);
		history.push(`/dashboard/claims-pending-approval/view/${id}`);
	};

	const handlePaginationChange = (value) => {
		setPaginate({
			minValue: (value - 1) * numEachPage,
			maxValue: value * numEachPage,
		});
	};

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<ClaimsPendingApproval
					state={data}
					search={search}
					paginate={paginate}
					numEachPage={numEachPage}
					handlePaginationChange={handlePaginationChange}
					view_claim_details={view_claim_details}
				/>
			)}
		</>
	);
};

export default ClaimsContainer;

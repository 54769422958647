import React from 'react'
import Success from '../../results/SuccessMsg'

export default function Finish() {
    return (
        <div>
            <Success/>
        </div>
    )
}

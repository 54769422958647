import React, { useContext } from "react";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

//  Validation
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function AddUserGroup({ fetch_user_groups }) {
  const { addUserGroup, setAddUserGroup } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  function handleUserGroupChange(evt) {
    const { name, value } = evt.target;
    setAddUserGroup({
      ...addUserGroup,
      [name]: value,
    });
  }

  const add_group = async () => {
    setAddUserGroup({ ...addUserGroup, loading: true });
    try {
      let resp = await (await api_srv).create_user_group(addUserGroup);
      setAddUserGroup({ ...addUserGroup, loading: false });
      fetch_user_groups();
      modalClose();
      show_toast("User Group added successfully", "success");
    } catch (err) {
      setAddUserGroup({ ...addUserGroup, loading: false });
      modalClose();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={addUserGroup}
      validationSchema={Yup.object().shape({
        code: Yup.string().required("Group Code is required"),
        name: Yup.string().required("Group name is required"),
        description: Yup.string().required("A description is required"),
      })}
      onSubmit={() => add_group()}
    >
      {({ errors, touched }) => (
        <Form className="login-form">
          <div className="form-group ">
            <label htmlFor="name">Group Name</label>
            <input
              name="name"
              type="text"
              value={addUserGroup.name}
              onChange={handleUserGroupChange}
              className={
                "form-control border" +
                (errors.name && touched.name ? " is-invalid" : "")
              }
            />

            <ErrorMessage
              name="name"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group ">
            <label htmlFor="code">Group Code</label>
            <input
              name="code"
              type="text"
              value={addUserGroup.code}
              onChange={handleUserGroupChange}
              className={
                "form-control border" +
                (errors.code && touched.code ? " is-invalid" : "")
              }
            />

            <ErrorMessage
              name="code"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="form-group ">
            <label htmlFor="name">Description</label>
            <textarea
              className={
                "form-control border" +
                (errors.code && touched.code ? " is-invalid" : "")
              }
              name="description"
              value={addUserGroup.description}
              onChange={handleUserGroupChange}
              rows="3"
            ></textarea>
            <ErrorMessage
              name="code"
              component="div"
              className="invalid-feedback"
            />
          </div>

          <div className="d-flex justify-content-center">
            <button
              disabled={addUserGroup.loading}
              variant="contained"
              type="submit"
              className="btn btn-success px-5 my-4 "
            >
              {addUserGroup.loading && (
                <i
                  className="fa fa-circle-notch fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
              {addUserGroup.loading && (
                <span className="text-capitalize">Adding Group</span>
              )}
              {!addUserGroup.loading && (
                <span className="text-capitalize"> Add</span>
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

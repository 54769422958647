import React, { useContext } from 'react';
import { Descriptions } from 'antd';
import Loader from 'components/widgets/loader';
import NoData from 'components/no-data/NoData';
import { DashboardContext } from 'context/DashboardContext';

const ViewSecurityContext = ({ state }) => {
	const { securityDetails } = useContext(DashboardContext);

	console.log('the state here', state);

	return (
		<div id="main-wrapper">
			{!state && securityDetails.loading ? (
				<Loader />
			) : !state && !securityDetails.loading ? (
				<NoData message="Unable to fetch context details" />
			) : (
				<>
					<div className="pageheader pd-t-25 ">
						<div className="breadcrumb pd-0 mg-0">
							<a className="breadcrumb-item" href="index.html">
								<i className="icon ion-ios-home-outline" /> Home
							</a>
							<a className="breadcrumb-item active" href>
								Claims
							</a>
							<a className="breadcrumb-item active" href>
								View
							</a>
						</div>
					</div>
					<div className="card mg-t-25">
						<div className="card-body">
							<Descriptions title={state.name}>
								<Descriptions.Item label={<span className="font-weight-bold">Name</span>}>
									{state.name}
								</Descriptions.Item>
								<Descriptions.Item
									label={<span className="font-weight-bold">Security Context Code</span>}
								>
									{state.security_context_code}
								</Descriptions.Item>
								<Descriptions.Item
									label={<span className="font-weight-bold">Security Context Status</span>}
								>
									{state.security_context_status}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Token Time To Live</span>}>
									{state.token_ttl} hour(s)
								</Descriptions.Item>
								<Descriptions.Item
									label={<span className="font-weight-bold">Two factor Authentication</span>}
								>
									{`${state.two_factor_authentication}`}
								</Descriptions.Item>
								<Descriptions.Item
									label={<span className="font-weight-bold">Password Change Policy</span>}
								>
									{state.password_change_policy}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Login Context</span>}>
									{state.login_context === undefined ? null : (
										<>
											high:{state.login_context.high}
											<br />
											low:{state.login_context.low}
										</>
									)}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Idle Max Timeout</span>}>
									{state.idle_max_timeout}minutes
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Holiday Login</span>}>
									{`${state.holiday_login}`}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Device Version</span>}>
									{state.device_versions === undefined
										? null
										: state.device_versions.map((version, i) => {
												return (
													<div key={i}>
														<ul>
															<li>device Type: {version.device_type}</li>
															<li>device Version: {version.device_version}</li>
														</ul>
													</div>
												);
										  })}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Allowed Devices</span>}>
									{state.allowed_devices === undefined
										? null
										: state.allowed_devices.map((device, i) => <p key={i}>{device}</p>)}
								</Descriptions.Item>
								<Descriptions.Item label={<span className="font-weight-bold">Allowed Channels</span>}>
									{state.allowed_channels === undefined
										? null
										: state.allowed_channels.map((channel, i) => <p key={i}>{channel}</p>)}
								</Descriptions.Item>
								<Descriptions.Item
									label={<span className="font-weight-bold">Description</span>}
									span={2}
								>
									{state.description}
								</Descriptions.Item>
							</Descriptions>
							<div className="card-body">
								<a href className="card-link">
									Approve
								</a>
								<a href className="card-link">
									Edit
								</a>
								<a href className="card-link">
									Activate
								</a>
								<a href className="card-link">
									Audit Trail
								</a>
								<a href className="card-link">
									Delete
								</a>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ViewSecurityContext;

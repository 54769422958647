import React, { useContext, useEffect } from "react";
import { DashboardContext } from "context/DashboardContext";
import Moment from "react-moment";
import { Skeleton } from "antd";
import _ from "lodash";

export default function View_Quotation({ data }) {
  const { listQuotations } = useContext(DashboardContext);

  return (
    <div id="main-wrapper">
      <div className="pageheader pd-t-25 pd-b-35">
        <div className="pd-t-5 pd-b-5">
          <h1 className="pd-0 mg-0 tx-20">View Quotation</h1>
        </div>
        <div className="breadcrumb pd-0 mg-0">
          <a className="breadcrumb-item" href="index.html">
            <i className="icon ion-ios-home-outline" /> Home
          </a>
          <a className="breadcrumb-item active" href>
            Dashboard
          </a>
        </div>
      </div>

      <div className="card pd-20 wd-80p m-auto">
        {_.isEmpty(data) && listQuotations.loading ? (
          <Skeleton avatar paragraph={{ rows: 14 }} />
        ) : (
          <>
            <h5 className="card-title bd-b pd-y-20">
              Quotation Reference
              <span className="text-primary">
                : #{data.quotation_reference}
              </span>
            </h5>
            <div className="card-body pd-0 printing-area">
              <div className="row">
                <div className="col-md-3">
                  <address>
                    <img
                      src={
                        data.intermediary_details === undefined
                          ? null
                          : `https://intermediary-uat.nexus.ke/api${data.intermediary_details.image_path}`
                      }
                      className="img-fluid ht-55 wd-55"
                      alt="logo"
                    />
                    <br />
                    <br />
                    <strong>
                      {data.intermediary_details === undefined
                        ? null
                        : data.intermediary_details.intermediary_names}
                    </strong>
                    <br />
                    P.O. Box:{" "}
                    {/* {
                            info.intermediary_details.intermediary_details
                              .address
                          } */}
                    <br />
                    <abbr title="Email">Email: </abbr>
                    {/* {info.intermediary_details.intermediary_details.emails.map(
                            (email) => email
                          )} */}
                    <br />
                    <abbr title="Phone">Phone: </abbr>
                    {/* {info.intermediary_details.intermediary_details.contacts.map(
                            (contact) => contact
                          )} */}
                    <br />
                  </address>
                </div>
                <div className="col-md-3 ml-md-auto text-md-right">
                  <h4 className="text-dark text-left">To:</h4>
                  <address>
                    <strong>
                      {data.client_details === undefined
                        ? null
                        : data.client_details.names}
                    </strong>
                    <br />
                    Client Address
                    <br />
                    <abbr title="Email">Email:</abbr> info@customer-llc.com
                    <br />
                    <abbr title="Phone">Phone:</abbr> (123) 456-7890
                  </address>
                  <br />
                  <br />
                  <p>
                    <strong>Created Date: </strong>
                    <Moment format="DD MMM YYYY" date={data.created_date} />
                  </p>
                  <br />

                  <br />
                  <br />
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr className="bg-light">
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Description</th>
                      <th>Policy Term</th>
                      <th className="text-right">Minimum Premium</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td className="d-flex flex-grow-1 align-items-center">
                        <div className="mr-2">
                          <img
                            src={
                              data.product_details === undefined
                                ? null
                                : `https://api.nexus.ke${data.product_details.logo}`
                            }
                            className="img-fluid wd-35 ht-35 rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="d-block">
                          {data.product_details === undefined
                            ? null
                            : data.product_details.product_name}
                        </div>
                      </td>
                      <td className="text-wrap">
                        {data.product_details === undefined
                          ? null
                          : data.product_details.description}
                      </td>
                      <td>
                        {data.product_details === undefined
                          ? null
                          : data.product_details.policy_term}
                      </td>
                      <td className="text-right">
                        {data.product_details === undefined
                          ? null
                          : data.product_details.minimum_premium}
                      </td>

                      {/* <td className="text-right">$312</td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    <u>Client Request Data</u>
                  </p>
                  {data.client_request === undefined ? null : data
                      .client_request.request_data.length === 0 ? (
                    <p>Client request data unavailable</p>
                  ) : data.client_request === undefined ? null : (
                    data.client_request.request_data.map((note, index) => {
                      return (
                        <ul key={index}>
                          <li>
                            <b>{note.name}</b>
                            <i>{note.value}</i>
                          </li>
                        </ul>
                      );
                    })
                  )}
                </div>
                <div className="col-md-4 ml-md-auto text-right">
                  <br />
                  <span className="bt-1 d-inline-block pt-10 pl-40">
                    Sub - Total amount: $2,230
                    <br />
                    VAT (10%): $22.3
                  </span>
                  <br />
                  <br />
                  <p className="tx-bold">
                    Grand Total:{" "}
                    <span className="tx-20 tx-gray-900">
                      {data.quoation_amount}
                    </span>
                  </p>
                  <br />
                </div>
              </div>
              <hr />
              <div className="text-right mg-y-20">
                <button
                  type="button"
                  className="btn btn-primary mg-t-5"
                  onClick={() => alert("Print")}
                >
                  <i className="fa fa-download" /> Download
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

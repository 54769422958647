import React, { useContext, useState } from "react";
import { Divider, Avatar, Popconfirm } from "antd";
import { ModalContext } from "context/ModalContext";
import * as Icon from "react-feather";
import AssignRm from "components/widgets/modals/AssignRm";
import AssignSalesRep from "components/widgets/modals/AssignSalesRep";
import CloseRemarks from "components/widgets/modals/CloseRemarks";
import IncentifyModal from "components/widgets/modals/Incentify";
import QuotationModal from "components/widgets/modals/LeadQuotation";
import { useParams } from "react-router-dom";
import { lead_details, close_lead } from "services/apiSrv";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";
import { show_toast } from "utils/helpers";
import routes from "routes";
import { useHistory } from "react-router-dom";

const LeadsDetails = () => {
  const { id } = useParams();
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const [repModalShow, setRepModalShow] = useState(false);
  const [closeModalShow, setCloseModalShow] = useState(false);
  const [incentifyModalShow, setIncentifyModalShow] = useState(false);
  const [quotationModalShow, setQuotationModalShow] = useState(false);
  const history = useHistory();

  const repModalClose = () => {
    setRepModalShow(false);
  };
  const closeModal = () => {
    setCloseModalShow(false);
  };
  const incentifyModalClose = () => {
    setIncentifyModalShow(false);
  };
  const quotationModalClose = () => {
    setQuotationModalShow(false);
  };

  const _leadDetails = async () => {
    let data = await lead_details(id);
    console.log("LEAD DETAILS", data);
    return data.lead_details;
  };

  function confirm(e) {
    close_lead(id);
    show_toast("Lead closed successfully", "success");
    history.push(routes.leads);
  }

  function cancel(e) {
    console.log(e);
  }

  const { data, isSuccess, isLoading } = useQuery("lead details", _leadDetails);
  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && (
        <div>
          <AssignRm show={modalShow} onHide={modalClose} lead_ref={id} />
          <AssignSalesRep
            show={repModalShow}
            onHide={repModalClose}
            lead_ref={id}
          />
          <CloseRemarks
            show={closeModalShow}
            onHide={closeModal}
            lead_ref={id}
          />
          <IncentifyModal
            show={incentifyModalShow}
            onHide={incentifyModalClose}
            lead_ref={id}
          />
          <QuotationModal
            show={quotationModalShow}
            onHide={quotationModalClose}
            lead_ref={id}
          />
          <div className="pageheader pd-t-25 ">
            <div className="pd-t-5">
              <h1 className="pd-0 mg-0 tx-20">Lead Details</h1>
            </div>
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Claims
              </a>
              <a className="breadcrumb-item active" href>
                View
              </a>
            </div>
          </div>
          <div className="card mg-t-45">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 ">
                  <p className="text-center pd-t-5">
                    <i className="text-dark">
                      <b>Lead Contact Information</b>
                    </i>
                  </p>
                  <div className="d-flex flex-column  align-items-center pd-t-10 pd-x-15 flex-wrap">
                    <Avatar size={94} className="mb-3" />
                    <p>
                      <Icon.User size={18} className="mr-2" />
                      Name
                    </p>
                    <p>
                      <Icon.Mail size={18} className="mr-2" />
                      {data.lead_email}
                    </p>
                    <p>
                      <Icon.File size={18} className="mr-2" />
                      {data.lead_ref}
                    </p>
                    <p>
                      <Popconfirm
                        title="Notification with.... will be sent to this lead?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <button className="btn btn-sm btn-prmary">
                          <Icon.Bell size={18} className="mr-2" />
                          Send Notification
                        </button>
                      </Popconfirm>
                    </p>
                  </div>
                </div>

                <div className="col-md-9">
                  <i className="fa fa-bell-o text-primary mb-3 float-right" />
                  <Divider
                    orientation="left"
                    className="bg-light pd-y-5 font-italic"
                  >
                    Lead details
                  </Divider>
                  <div className="mb-3">
                    <strong className="d-inline-block wd-150">
                      Closure Status
                    </strong>
                    {data.lead_closure.closure_status}
                  </div>
                  <div className="mb-3">
                    <strong className="d-inline-block wd-150">
                      Created Date
                    </strong>
                    : {data.created_date}
                  </div>
                  <div className="mb-3">
                    <strong className="d-inline-block wd-150">Sales Rep</strong>
                    : {data.sales_rep.sales_rep_email}
                  </div>
                  <div className="mb-3">
                    <strong className="d-inline-block wd-150">Status</strong>:{" "}
                    {data.lead_status}
                  </div>
                  <div className="mb-3">
                    <strong className="d-inline-block wd-150">Remarks</strong>:{" "}
                    {data.lead_sales_rep_remarks}
                  </div>

                  {data.lead_status === "open" && (
                    <button
                      className="btn btn-sm btn-success mr-3"
                      onClick={() => setModalShow(true)}
                    >
                      Assign RM
                    </button>
                  )}
                  {data.lead_status === "open" && (
                    <button
                      className="btn btn-sm btn-success mr-3"
                      onClick={() => setRepModalShow(true)}
                    >
                      Assign Sales Rep
                    </button>
                  )}

                  {data.lead_status !== "open" && (
                    <button
                      className="btn btn-sm btn-success mr-3 mt-4"
                      onClick={() => setIncentifyModalShow(true)}
                    >
                      Incentify
                    </button>
                  )}
                  {data.lead_status === "open" && (
                    <button
                      className="btn btn-sm btn-success mr-3"
                      onClick={() => setQuotationModalShow(true)}
                    >
                      Create Quotation
                    </button>
                  )}
                  {data.lead_status === "open" && (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => setCloseModalShow(true)}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadsDetails;

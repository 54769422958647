import React, { useContext } from 'react';
import Claims from './Claims';
import { DashboardContext } from 'context/DashboardContext';
import { api_srv } from '../../services';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const ClaimsContainer = () => {
	const history = useHistory();

	const { paginate, setPaginate, numEachPage } = useContext(DashboardContext);

	async function get_all_claims() {
		let resp = await (await api_srv).fetch_all_claims();
		return resp.claims;
	}

	const { isLoading, isSuccess, data } = useQuery('claims', get_all_claims);

	const view_claim_details = async (id) => {
		history.push(`/dashboard/claims/view/${id}`);
	};

	const handlePaginationChange = (value) => {
		setPaginate({
			minValue: (value - 1) * numEachPage,
			maxValue: value * numEachPage,
		});
	};

	return (
		<>
			{isLoading && <Loader />}
			{isSuccess && (
				<Claims
					state={data}
					paginate={paginate}
					numEachPage={numEachPage}
					handlePaginationChange={handlePaginationChange}
					view_claim_details={view_claim_details}
				/>
			)}
		</>
	);
};

export default ClaimsContainer;

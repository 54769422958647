import React, { useState, useContext, useEffect } from "react";
import ViewRole from "./ViewRole";
import { api_srv } from "services";
import { DashboardContext } from "context/DashboardContext";
import { message } from "antd";
import { show_toast } from "utils/helpers";

const ViewRoleContainer = (props) => {
  let role_code = props.match.params.id;

  const [checkedResources, setCheckedResources] = useState([]);
  const [checkedPermissions, setCheckedPermissions] = useState([]);
  const [visible, setVisible] = useState(false);

  const {
    resources,
    setResources,
    roleGrants,
    SetRoleGrants,
    setRoleDetails,
    roleDetails,
  } = useContext(DashboardContext);

  const roles_granted = async () => {
    try {
      let resp = await (await api_srv).list_role_grants(role_code);
      view_role();
      SetRoleGrants(resp.role_resource_grants.grants);
    } catch (err) {
      let error = await err;
    }
  };

  async function fetch_system_resources() {
    try {
      let resp = await (await api_srv).list_system_resources();
      setResources(resp.system_resources);
      roles_granted();
    } catch (err) {
      let error = await err;
    }
  }
  const view_role = async () => {
    setRoleDetails({ ...roleDetails, loading: true });
    try {
      let resp = await (await api_srv).view_role_details(role_code);
      setRoleDetails({
        ...roleDetails,
        data: resp.role_details,
        loading: false,
      });
      console.log("role details", resp);
    } catch (err) {
      let error = await err;
      console.log("eror in details", error);
      setRoleDetails({ ...roleDetails, loading: false });
    }
  };

  const revoke = async (resource_code) => {
    try {
      let resp = await (
        await api_srv
      ).revoke_resource(resource_code, role_code);
      fetch_system_resources();
      show_toast("Resource revoked successfully", "success");
    } catch (err) {}
  };

  const add_resource = async () => {
    if (checkedResources.length === 0) {
      message.error("No resources added");
    } else {
      try {
        let resp = await (
          await api_srv
        ).grant_resource(role_code, checkedResources);
        console.log("added resources", resp);
        setCheckedResources([]);
        fetch_system_resources();
        show_toast("Resource added successfully", "success");
      } catch (err) {
        setCheckedResources([]);
      }
    }
  };

  const add_permissions = async (grant_id) => {
    if (checkedPermissions.length === 0) {
      message.error("No permissions added");
    } else {
      try {
        let resp = await (
          await api_srv
        ).grant_permissions(grant_id, checkedPermissions);
        setCheckedPermissions([]);
        fetch_system_resources();
        show_toast("Permissions added successfully", "success");
      } catch (err) {
        setCheckedPermissions([]);
      }
    }
  };

  const remove_permission = async (grant_id, permission) => {
    let resp = await (await api_srv).remove_permissions(grant_id, permission);
    console.log("the response from remove permisiions", resp);
  };

  useEffect(() => {
    fetch_system_resources();
  }, []);

  console.log("the resources are here", resources);
  console.log("the roleGrants are here", roleGrants);

  function onChange(e) {
    if (e.target.checked) {
      //append to array
      setCheckedResources(checkedResources.concat([e.target.value]));
    } else {
      //remove from array
      setCheckedResources(
        checkedResources.filter(function (val) {
          return val !== e.target.value;
        })
      );
    }
  }

  function onPermissionChange(e) {
    if (e.target.checked) {
      //append to array
      setCheckedPermissions(checkedPermissions.concat([e.target.value]));
    } else {
      //remove from array
      setCheckedPermissions(
        checkedPermissions.filter(function (val) {
          return val !== e.target.value;
        })
      );
    }
  }
  console.log("permissions", checkedPermissions);
  console.log("resourcess", checkedResources);

  const handleVisibleChange = (flag) => {
    setVisible({ visible: flag });
  };

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      setVisible(false);
    }
  };

  return (
    <div>
      <ViewRole
        state={visible}
        onChange={onChange}
        handleVisibleChange={handleVisibleChange}
        handleMenuClick={handleMenuClick}
        revoke={revoke}
        resources={resources}
        roleGrants={roleGrants}
        add_resource={add_resource}
        add_permissions={add_permissions}
        onPermissionChange={onPermissionChange}
        remove_permission={remove_permission}
        roleDetails={roleDetails.data}
      />
    </div>
  );
};

export default ViewRoleContainer;

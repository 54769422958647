import React, { useState, useContext } from "react";
import { Steps, Button } from "antd";
import "../../../assets/css/dashboard.css";
import SelectClass from "./Select_Class";
import SelectProduct from "./Select_Product"
import ProductDetails from "./Product_Details"
import Finish from "./Finish"
import Confirm from "./Confirm"
import { KycContext } from "../../../context/KycContext";
import _ from "lodash";
import axios from "axios";

const { Step } = Steps;

const steps = [

  {
    title: " Select Class",
    content: <SelectClass />,
  },
  {
    title: " Select Product",
    content: <SelectProduct />,
  },
  {
    title: "Product Details",
    content: <ProductDetails />,
  },

  {
    title: "Confirm",
    content: <Confirm />,
  },
  {
    title: "Finish",
    content: <Finish />,
  },

];


export default function Index(props) {

  // access context

  const context = useContext(KycContext);
  const { current } = context;


    return (
        <div>
        <Steps current={current}  type="navigation">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content ">{steps[current].content}</div>
        </div>
    )
}
  




import React from "react";

import { useQuery } from "react-query";

import Profession from "./Profession";
import { get_professions } from "services/apiSrv";
import Loader from "components/widgets/loader";

const ProfessionContainer = () => {
  async function fetch_professions() {
    let resp = await get_professions();
    return resp.intermidiary_proffesion;
  }

  const { isLoading, isSuccess, data } = useQuery(
    "professions",
    fetch_professions
  );

  return (
    <>
      {isLoading && <Loader />}
      {isSuccess && <Profession data={data} />}
    </>
  );
};

export default ProfessionContainer;

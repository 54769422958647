import React, { useContext } from "react";
import { Button } from "antd";
import { KycContext } from "context/KycContext";
import { DashboardContext } from "context/DashboardContext";
import { AlertsContext } from "context/AlertsContext";
import Alert from "components/alerts/WarningAlert";

export default function Confirm() {

  // Access Context
  const { prev } = useContext(KycContext);
  const {
    quotations,
    assetDetails,
    handleAssetChange,
    create_quote,
    loading,
  } = useContext(DashboardContext);
  const { showAlert } = useContext(AlertsContext);

  return (
    <form onSubmit={(e)=>{
      e.preventDefault()
      create_quote()
    } 
      }>
      
      <h5 className="upload-title text-center mt-5">Confirm your Details</h5>
      {showAlert && (
        <Alert
          message="Quotation could not be queued at the moment, Please try again"
          variant="danger"
        />
      )}

      <div className="row mt-md-4 px-md-4">
        <div className="col-md-6">
          <p className="font-weight-bold">
            Insurance Class:{" "}
            <span className="font-weight-normal">
              {quotations.insuranceClass}
            </span>{" "}
          </p>
          <p className="font-weight-bold">
            Product Name:{" "}
            <span className="font-weight-normal">
              {quotations.product_details.details.product_details.product_name}
            </span>{" "}
          </p>
          <p className="font-weight-bold">
            CoverType:{" "}
            <span className="font-weight-normal">
              {" "}
              {
                quotations.product_details.details.product_details
                  .covertype_name
              }
            </span>{" "}
          </p>
        </div>
        <div className="col-md-6">
          <p className="font-weight-bold">
            Underwriter:{" "}
            <span className="font-weight-normal">
              {" "}
              {
                quotations.product_details.details.product_details
                  .underwritter_subclass_name
              }
            </span>{" "}
          </p>
          <p className="font-weight-bold">
            Policy Term:{" "}
            <span className="font-weight-normal">
              {" "}
              {
                quotations.product_details.details.product_details.policy_term
              }{" "}
            </span>{" "}
          </p>
          <p className="font-weight-bold">
            Minimum Premium:{" "}
            <span className="font-weight-normal">
              {" "}
              {
                quotations.product_details.details.product_details
                  .minimum_premium
              }
            </span>{" "}
          </p>
          <div className="form-group mt-0 ">
            <label htmlFor="reference" className="font-weight-bold">
              Quotation Reference
            </label>
            <input
              required
              placeholder="Enter your Quotation Reference"
              name="reference"
              type="text"
              value={assetDetails.reference}
              onChange={handleAssetChange}
              className="form-control border"
            />
          </div>
        </div>
      </div>
      <footer>
        <Button
          className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
          htmlType="submit"
          disabled={loading}
          onClick={create_quote}
        >
          {loading && (
            <i
              className="fa fa-circle-notch fa-spin text-white"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && (
            <span className="text-capitalize text-white">Please wait...</span>
          )}
          {!loading && <span className="text-capitalize"> Done</span>}
        </Button>
        <Button
          className="btn-secondary float-right mb-5 mr-3  px-4 py-1 rounded "
          type="submit"
          onClick={prev}
        >
          Back
        </Button>
      </footer>
    </form>
  );
}

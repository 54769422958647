import React from "react";

import { Modal } from "react-bootstrap";
import { Divider, Button, Input, Form } from "antd";
import { IoMdAttach } from "react-icons/io";

import UploadComponent from "components/uploads";
import { base_url } from "services/apiSrv";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const { TextArea } = Input;
const onFinish = (values) => {
  console.log("Success:", values);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};
const UploadMember = (props) => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">
              Add Batch Members to Group
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Download a template for batch uploads, add members then upload it
          </p>
          <a
            className="text-primary "
            href={base_url + `api/template/scheme_group_member_template.xlsx`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <IoMdAttach />
            {""}
            {""}
            Download Template
          </a>
          <Form
            name="add_member"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            validateMessages={validateMessages}
            layout="vertical"
          >
            <Form.Item
              name="member_file"
              label="Members' File"
              className="mt-3"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadComponent />
            </Form.Item>
            <Form.Item
              label="Description"
              name="member_description"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
                htmlType="submit"
              >
                Upload
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadMember;

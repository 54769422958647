import React, { useEffect, useState, useContext } from "react";
import * as Icon from "react-feather";
import {Progress,Pagination,Input} from "antd";
import { Redirect } from "react-router-dom";
import {DashboardContext} from "context/DashboardContext"
import Moment from 'react-moment'
import NoData from 'components/no-data/NoData'
import Loader from 'components/widgets/loader'



export default function Requests() {

  // access context

  const context =useContext(DashboardContext)

  // desctructure
  
  const {data,fetch_requests, id,paginate, numEachPage,handlePaginationChange,search,setSearch,loading}= context
  const { Search } = Input;



  useEffect(() => {
    try {
      fetch_requests()

   } catch (error) {
       console.log(error.response)
       
     }
   }, []);

let rows= data 

function searchMe(evt){
  evt.preventDefault();

  if (evt.target.value){
    let filteredItems = rows.filter((row)=>{

     
  return(
    row.id.toLowerCase().includes(search.toLowerCase()) ||
    row.name.toLowerCase().includes(search.toLowerCase())|| 
    row.date.toLowerCase().includes(search.toLowerCase()) ||
    row.status.toLowerCase().includes(search.toLowerCase()) 
    // item.id &&    item.name &&
    // Item.date  &&    item.status
    // .match(search)
     
  )
    })
  setSearch(evt.target.value)
  rows += filteredItems

  }else{
    setSearch("")

  }
}


  return (
    <>
   
              <div className="pageheader pd-t-25 pd-b-35">
           
                <div className="breadcrumb pd-0 mg-0">
                  <a className="breadcrumb-item" href="index.html">
                    <i className="icon ion-ios-home-outline" /> Home
                  </a>
                  <a className="breadcrumb-item active" href>
                    Dashboard
                  </a>
                </div>
              </div>
             
                {rows.length===0 && !loading? <NoData message="No requests currently" />:
                  rows.length===0 && loading ? <Loader/>:
                  <div className="col-lg-12 card rounded-10 col-xl-12">
                <div className=" mg-b-20">
                  <div className="card-header">
                  <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20">
                    <Icon.Globe size={34} className="pr-2 text-primary" />
                  Requests to Underwriters
                  </h1>
                </div>
                <Search
              placeholder="input search text"
              type="search"
              // value={search}
              // onChange={searchMe}
              className="ml-auto mg-r-10"
      // onSearch={value => console.log(value)}
      style={{ width: 200 }}
    />
                  </div>

                  <div className=" mg-b-20">
                    <div
                      className="card-body pd-0 collapse show"
                      id="customerDetails"
                    >
                      <div className="table-responsive">
                        <table className="table table-hover mg-0">
                          <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                            <tr className="bg-light">
                              <th>IRA Code</th>
                              <th>Name</th>
                              <th>Request Date</th>
                              <th>Category</th>
                              <th>Status</th>
                              <th>Remarks</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                  { rows && rows.length>0 && rows.slice(paginate.minValue, paginate.maxValue).map((row) => {
                              return (
                                <tr>
                                  <td className="">
                                    {row.ira_code}
                                    {/* <div className="avatar mr-2">
                                      <img
                                        src="https://via.placeholder.com/100x100"
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div> */}
                                    {/* <div className="d-block">
                                      <a href className="my-0 mt-1 ">
                                        {row.name}
                                      </a>
                                   
                                    </div> */}
                                  </td>
                                  <td>
                                    {row.name}
                                  </td>
                                  <td>
                                  <Moment format="DD MMM YYYY" date={row.request_date} />
                                  </td>
                                  <td>
                                    {row.category}
                                  </td>
                                  <td>
                                    {row.request_status}
                                  </td>
                                  <td>
                                    {row.remarks}
                                  </td>
                               

                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination
                    className="ml-auto"
                    responsive={true}
                    dataSource={rows}
                    total={rows.length}
                    showTotal={total => `Total ${total} items`}
                    defaultPageSize={numEachPage}
                    defaultCurrent={1}
                    onChange={handlePaginationChange}
                  />
                </div>

              </div>
}
       
     
    </>
  );
}

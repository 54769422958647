import React, { useContext, useRef } from 'react';
import * as Icon from 'react-feather';
import { DashboardContext } from 'context/DashboardContext';
import { ModalContext } from 'context/ModalContext';
import { Button, Input, Pagination, Avatar } from 'antd';
import Loader from 'components/widgets/loader';
import NoData from 'components/no-data/NoData';
import SelectUser from 'components/widgets/modals/SelectUser';

export default function ViewUsers({ search, searchSpace }) {
	// access context

	const { quotationRequests, paginate, numEachPage, handlePaginationChange } = useContext(DashboardContext);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	const { Search } = Input;

	const items =
		quotationRequests.data.length === 0
			? null
			: quotationRequests.data
					.filter((data) => {
						if (search == null) return data;
						else if (
							data.names.toLowerCase().includes(search.toLowerCase()) ||
							data.created_date.toLowerCase().includes(search.toLowerCase()) ||
							data.request_status.toLowerCase().includes(search.toLowerCase()) ||
							data.remarks.toLowerCase().includes(search.toLowerCase())
						) {
							return data;
						}
					})
					.slice(paginate.minValue, paginate.maxValue)
					.map((row, index) => {
						return (
							<tr key={index}>
								<td>{index}</td>
								<td className="d-flex align-items-center">
									<div className=" mr-4">
										<Avatar
											size={40}
											style={{
												color: '#001f5f',
												backgroundColor: 'rgba(5,88,183,0.10)',
											}}
										>
											<small>U{index}</small>
										</Avatar>
									</div>
									<div className="d-block">
										<a href className="my-0 mt-1 ">
											User {index}
										</a>
									</div>
								</td>
								<td> Str123</td>

								<td>
									<a className="dropdown-link tx-13 tx-gray-500" href>
										<Icon.Trash2 size={14} className="mr-2 text-danger" />
										Remove
									</a>
								</td>
							</tr>
						);
					});

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			<SelectUser show={modalShow} onHide={modalClose} />
			<div className="dropdown">
				<Button
					className="btn-primary rounded-5 float-right mb-3 dropdown-toggle"
					type="button"
					id="dropdownMenuButton"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
					Add User
				</Button>
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					<a className="dropdown-item" onClick={() => setModalShow(true)} href>
						Individual User
					</a>
					<a className="dropdown-item" href>
						User Group
					</a>
				</div>
			</div>
			{/* <Button
                  className="btn-primary rounded-5 float-right mb-3"
                  onClick={() => setModalShow(true)}
                >
                 Add Report
                </Button> */}

			{quotationRequests.data.length === 0 && !quotationRequests.loading ? (
				<NoData message="No users currently" />
			) : quotationRequests.data.length === 0 && quotationRequests.loading ? (
				<Loader />
			) : (
				<>
					<div className="col-lg-12 card rounded-10 col-xl-12">
						<div className="mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5 pd-l-5">
									<h1 className=" tx-18">
										<Icon.Server size={30} className="pr-2 text-primary" />
										Users for Financial Report Group
									</h1>
								</div>
								<Search
									placeholder="Search User"
									type="search"
									onChange={(e) => searchSpace(e)}
									className="ml-md-auto mg-r-10"
									style={{ width: 200 }}
								/>
							</div>
							<div className="mg-b-20">
								<div className="card-body pd-0 collapse show" id="customerDetails">
									<div className="table-responsive">
										<table className="table table-hover mg-0">
											<thead className="tx-dark tx-uppercase tx-12 tx-bold">
												<tr className="bg-light">
													<th className="">#</th>
													<th className="">Names</th>
													<th>Staff Reference</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>{items}</tbody>
										</table>
									</div>
								</div>
							</div>
							<Pagination
								className="ml-auto"
								responsive={true}
								dataSource={quotationRequests}
								total={quotationRequests.data.length}
								showTotal={(total) => `Total ${total} items`}
								defaultPageSize={numEachPage}
								defaultCurrent={1}
								onChange={handlePaginationChange}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}

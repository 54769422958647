import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";

import "assets/css/dashboard.css";
import { risk_details } from "services/apiSrv";
import Loader from "components/widgets/loader/ComponentLoader";

const ViewRiskDetails = (props) => {

  async function fetch_risk_details() {
    let resp = await risk_details(props.risk_ref);
 
   // riskDet=resp.risk_details

   // JSON.parse(data.risk_details.form)
   const dt = JSON.parse(resp.risk_details.risk_details.form)
   //console.log("risk details are here dt", dt);
    return dt;
  }

  const { isLoading, isSuccess, data } = useQuery(
    "risk details",
    fetch_risk_details
  );

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Risk Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <Loader />}
          {isSuccess && (
            <ul>
              {Object.entries(data.detail).map(([key,value], i) => {
                return (
                  <li key={i}>
                    <span className="font-weight-bold">{key}</span>:
                    <span className="d-block">{value}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewRiskDetails;

import React, { useContext } from "react";

import { Button } from "antd";
import { useHistory, Link } from "react-router-dom";

import { ModalContext } from "context/ModalContext";
import AddTax from "components/widgets/modals/Tax";
import CustomTable from "components/widgets/table";
import routes from "routes";

export default function Tax({ data, refetch }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const history = useHistory();

  const view_tax = (id) => {
    history.push(`/dashboard/account-management/tax/details/${id}`);
  };

  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "Tax Name", align: "c" },
      { name: "Ref", align: "c" },
      { name: "Category", align: "c" },
      { name: "Type", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "text", field: "tax_name", class: "center" },
      { type: "text", field: "entity_tax_ref", class: "center" },
      { type: "text", field: "tax_category", class: "center" },
      { type: "text", field: "tax_type", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "entity_tax_ref",
            method: view_tax,
          },
        ],
      },
    ],
  };

  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item active" to="#">
            <i className="icon ion-ios-home-outline " /> Account
          </Link>
          <Link className="breadcrumb-item">Taxes</Link>
        </div>
        <h5>My Taxes</h5>
      </div>

      <div className="clearfix">
        <Button
          className="btn-primary btn-blue rounded mb-3 float-right text-white "
          onClick={() => setModalShow(true)}
        >
          Add Tax
        </Button>
      </div>

      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
      {/* modal */}
      <AddTax show={modalShow} onHide={modalClose} refetch={refetch} />
    </>
  );
}

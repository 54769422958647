import React, { useContext } from "react";

import { Button } from "antd";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import { ModalContext } from "context/ModalContext";
import Request from "components/widgets/modals/RequestUnderwriter";
import CustomTable from "components/widgets/table";
import routes from "routes";

export default function Underwriters({ data, refetch }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  const tableData = {
    icon: <Icon.Briefcase />,
    title: "",
    table_fields: [
      { name: "", align: "c" },
      { name: "Underwriter Name", align: "c" },
      { name: "Category", align: "c" },
      { name: "Contacts", align: "c" },
      { name: "Email", align: "c" },
      { name: "Location", align: "c" },
    ],
    // search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "logo", className: "bold center" },
      { type: "text", field: "name", className: " center" },
      {
        type: "text",
        field: "category",
        className: "center",
      },
      {
        type: "text",
        field: "contacts",
        className: "center",
      },
      {
        type: "text",
        field: "emails",
        className: "center",
      },
      { type: "text", field: "physical_location", className : "center" },
    ],
  };

  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard || '/'}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item ">Underwriters</Link>
        </div>
        <h5>My Underwriters</h5>
      </div>

      <div className=" clearfix">
        <Button
          className="btn-primary btn-blue rounded mb-3 float-right text-white "
          onClick={() => setModalShow("underwriter")}
        >
          Make Request
        </Button>
      </div>
      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
      {/* modal */}
      <Request show={modalShow} onHide={modalClose}  />
    </>
  );
}

import React, { useState, useContext } from "react";
import * as Icon from "react-feather";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { Button, Input, Pagination } from "antd";
import NoData from "components/no-data/NoData";
import AddRole from "components/widgets/modals/AddRole";

export default function Roles({ data, view_role,fetch_roles_list }) {
  // access context

  const {
    paginate,
    numEachPage,
    handlePaginationChange,
    usmg,
  } = useContext(DashboardContext);
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);



  // destructure

  const { Search } = Input;
  const [search, setSearch] = useState(null);

  // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  const items = data
    .filter((obj) => {
      if (search == null) return data;
      else if (
        obj.name.toLowerCase().includes(search.toLowerCase()) ||
        obj.role_code.toLowerCase().includes(search.toLowerCase()) ||
        obj.description.toLowerCase().includes(search.toLowerCase())
      ) {
        return obj;
      }
    })
    .slice(paginate.minValue, paginate.maxValue)
    .map((row) => {
      return (
        <tr key={row.role_code}>
          <td>{row.role_code}</td>
          <td>
            <div className="d-block">
              <a href className="my-0 mt-1 ">
                {row.name}
              </a>
            </div>
          </td>
          <td>
            <p className=" mb-0 ">{row.description}</p>
          </td>

          <td>
            <div className="dropdown">
              <a
                href
                onClick={() => view_role(row.role_code)}
                className="btn btn-sm btn-label-primary"
              >
                view
              </a>
            </div>
          </td>
        </tr>
      );
    });

  return (
  
     
        <>
          <div className="pageheader pd-t-25 pd-b-35">
            <div className="breadcrumb pd-0 mg-0">
              <a className="breadcrumb-item" href="index.html">
                <i className="icon ion-ios-home-outline" /> Home
              </a>
              <a className="breadcrumb-item active" href>
                Dashboard
              </a>
            </div>
          </div>
          <AddRole show={modalShow} onHide={modalClose} fetch_roles_list={fetch_roles_list} />
          <Button
            className="btn-blue text-white rounded float-right mb-3"
            onClick={() => setModalShow(true)}
          >
            Add Role
          </Button>
         { data.length === 0 && !usmg.loading ? (
        <NoData message="No usermanagement roles currently" />
         ):

  
          <div className="col-lg-12 card rounded-10 col-xl-12">
            <div className="mg-b-20">
              <div className="card-header">
                <div className="pd-t-5 pd-b-5 pd-l-5">
                  <h1 className=" tx-18">
                    <Icon.Lock size={30} className="pr-2 text-primary" />
                    Roles
                  </h1>
                </div>
                <Search
                  placeholder="Search Roles"
                  type="search"
                  onChange={(e) => searchSpace(e)}
                  className="ml-md-auto mg-r-10"
                  style={{ width: 200 }}
                />
              </div>
              <div className="mg-b-20">
                <div
                  className="card-body pd-0 collapse show"
                  id="customerDetails"
                >
                  <div className="table-responsive">
                    <table className="table table-hover mg-0">
                      <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                        <tr className="bg-light">
                          <th className="">Code</th>
                          <th className="">Name</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{items}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Pagination
                className="ml-auto"
                responsive={true}
                dataSource={data}
                total={data.length}
                showTotal={(total) => `Total ${total} items`}
                defaultPageSize={numEachPage}
                defaultCurrent={1}
                onChange={handlePaginationChange}
              />
            </div>
          </div>
        
        }
    </>
  );
}

import React, { useState, useContext } from "react";
import FeeForm from "./Fee_Form";
import { show_toast } from "utils/helpers";
import { add_fees, list_taxes, fetch_wallet_info } from "services/apiSrv";
import { ModalContext } from "context/ModalContext";
import { useQuery, useMutation } from "react-query";

const FeeFormLogic = ({ refetch }) => {
  const [taxed, setTaxed] = useState("");
  const [management, setManagement] = useState("");
  const [feeType, setFeeType] = useState("");
  const { modalClose } = useContext(ModalContext);
  const [slab, setSlab] = useState([
    {
      min: "",
      max: "",
      value: "",
      value_type: "fixed",
    },
  ]);

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "taxed") {
      const value = changedValues[fieldName];
      setTaxed(value);
    } else if (fieldName === "management") {
      const value = changedValues[fieldName];
      setManagement(value);
    } else if (fieldName === "fee_type") {
      const value = changedValues[fieldName];
      setFeeType(value);
    }
  };
  const onInputChange = (e, index) => {
    const { name, value } = e.target;
    const tempSlab = [...slab];
    tempSlab[index][name] = value;
    setSlab(tempSlab);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const tempSlab = [...slab];
    tempSlab.splice(index, 1);
    setSlab(tempSlab);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSlab([
      ...slab,
      {
        min: "",
        max: "",
        value: "",
        value_type: "fixed",
      },
    ]);
  };

  const _addfees = useMutation(add_fees, {
    onSuccess: () => {
      modalClose();
      refetch();
      show_toast("Fees added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  // List all taxes created

  async function _listTaxes() {
    let resp = await await list_taxes();
    console.log("THE TAXES", resp.entity_taxes);
    return resp.entity_taxes;
  }
  const { data, isLoading, isSuccess } = useQuery("list taxes", _listTaxes);

  // List all accounts created

  async function _listAccounts() {
    let resp = await await fetch_wallet_info();
    return resp.entity_accounts;
  }
  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    isSuccess: isSuccessAccounts,
  } = useQuery("list accounts", _listAccounts);

  return (
    <div>
      {isLoading && isLoadingAccounts && "fetching required fields"}
      {isSuccess && isSuccessAccounts && (
        <FeeForm
          handleFormValuesChange={handleFormValuesChange}
          _addfees={_addfees}
          taxed={taxed}
          management={management}
          feeType={feeType}
          taxes={data}
          accounts={accounts}
          onInputChange={onInputChange}
          slab={slab}
          handleRemoveClick={handleRemoveClick}
          handleAddClick={handleAddClick}
        />
      )}
    </div>
  );
};

export default FeeFormLogic;

import React, { useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { Layout, Drawer } from "antd";
import { useQuery } from "react-query";

import Sidebar from "components/dashboard-sidebar/index";
import Navbar from "components/dashboard-nav/Nav_Content";
import Footer from "components/dashboard-footer";
import { api_srv } from "services";
import routes from "routes";
import "./AuthLayout.css";
import "../../assets/css/dashboard.css";
import pic from "../../assets/images/logos/Logo_1.jpeg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { DashboardContext } from "context/DashboardContext";

const AuthLayout = (props) => {
  const history = useHistory();
  const { Content, Header, Sider } = Layout;
  const { isToggled, setToggled } = useContext(DashboardContext);
  // const [isToggled, setToggled] = useState(false);

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      // User is not logged in. Redirect back to login
      history.push(routes.login);
      return;
    }
    // Fetch data for logged in user using token
  });

  async function fetch_intermediary_details() {
    let resp = await (await api_srv).get_intermediary_type();
    return resp.intermediary_details;
  }

  const { isSuccess, data } = useQuery("details", fetch_intermediary_details);

  const toggleTrueFalse = () => setToggled(!isToggled);
  const onClose = () => {
    setToggled(false);
  };
  return (
    <>
      {isSuccess && (
        <Layout>
          <Drawer
            placement="left"
            onClose={onClose}
            closable={true}
            visible={isToggled}
            className="hideOnDesktop"
            bodyStyle={{ backgroundColor: "#fff", padding: "0" }}
          >
            <>
              <div style={{ backgroundImage: `url(${pic})` }} />
              <Sidebar />
            </>
          </Drawer>
          <Sider
            trigger={null}
            collapsible
            id="sider"
            breakpoint="lg"
            collapsedWidth={65}
            width="250"
            collapsed={isToggled}
            onCollapse={() => toggleTrueFalse()}
            onBreakpoint={(broken) => {
              setToggled(broken);
            }}
            className="hideOnMobile"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "sticky",
              top: 0,
              left: 0,
            }}
          >
            <>
              <Sidebar />
            </>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                position: "sticky",
                top: 0,
                zIndex: 900,
              }}
            >
              <Navbar />
            </Header>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: "100vh",
              }}
            >
              <div className="">
                <div className="">
                  <div id="main-wrapper">{props.children}</div>{" "}
                </div>{" "}
              </div>
            </Content>
            <Footer />
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default AuthLayout;

import React, { useState } from "react";
import Users from "./Users";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const UsersContainer = (props) => {

  const fetchUsers = async () => {
      let resp = await (await api_srv).list_users();
      console.log("USERS", resp)
      return resp.users
    
  };

  const view_user = (id) => {
    props.history.push(`/dashboard/usmg/users/view/${id}`);
  };

  const { data, isLoading, isSuccess } = useQuery("users", fetchUsers);

  const [search, setSearch] = useState(null);

  // image avatar initials

  var getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  // handle search

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword);
  };

  return (
    <div>
      {isLoading && <Loader/>}
      {isSuccess &&  <Users
        data={data}
        view_user={view_user}
        fetchUsers={fetchUsers}
        getInitials={getInitials}
        searchSpace={searchSpace}
        search={search}
      />}
     
    </div>
  );
};

export default UsersContainer;

import React, { useState, useContext } from "react";
import { Steps, Button } from "antd";
import "assets/css/dashboard.css";
import SearchClient from "./Search_Client";
import SelectClass from "./Select_Class"
import SelectProduct from "./Select_Product"
import RiskDetails from "./Risk_Details"
import Confirm from "./Finish"
import { KycContext } from "context/KycContext";

const { Step } = Steps;

const steps = [

  {
    title: "Search User",
    content: <SearchClient />,
  },
  {
    title: "Insurance Class",
    content: <SelectClass />,
  },
  {
    title: "Product",
    content: <SelectProduct />,
  },

  {
    title: "General requirements",
    content: <RiskDetails />,
  },

  {
    title: "Confirm",
    content: <Confirm />,
  },

];


export default function Index(props) {

  // access context

  const context = useContext(KycContext);
  const { current } = context;


    return (
        <div className="row">
          <div className="col-md-3 border-right">
        <Steps current={current}  direction="vertical">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        </div>
        <div className="col-md-9">
        <div className="steps-content ">{steps[current].content}</div>
        </div>
        </div>
    )
}
  




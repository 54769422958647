import React from 'react';

const FiveHundred = ({ error }) => {
	console.log('DID WE TRY TO GET HERE?', error);
	return (
		<div>
			<h1>ERROR</h1>
		</div>
	);
};

export default FiveHundred;

import React from "react";

import { useQuery } from "react-query";

import Domains from "./Domains";
import Loader from "components/widgets/loader";
import { list_domains } from "services/apiSrv";

const DomainsContainer = () => {
  async function _listDomains() {
    let resp = await await list_domains();
    console.log("THE DATA", resp);
    return resp.domains;
  }
  const { data, isLoading, isSuccess } = useQuery("my domains", _listDomains);

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Domains data={data} />}
    </div>
  );
};

export default DomainsContainer;

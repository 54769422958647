import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import NegotiateForm from "../../stepper/negotiateRate";

const NegotiateRate = (props) => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Negotiate Rate</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NegotiateForm
            system_scheme_group_ref={props.system_scheme_group_ref}
            refetch={props.refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NegotiateRate;

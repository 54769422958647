import React from 'react'
import Tenders from './Tenders'

const TendersContainer = () => {
    return (
        <div>
            <Tenders/>
        </div>
    )
}

export default TendersContainer

import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function AddDepartment({
  handleDepartmentChange,
  add_department,
  state,
}) {

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={state}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Department Name is required"),
          code: Yup.string().required("Department Code is required"),
          description: Yup.string().required("A description is required"),
        })}
        onSubmit={() => add_department()}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <div className="form-group ">
              <label htmlFor="name" className="font-weight-bold">
                Department Name
              </label>
              <input
                name="name"
                type="text"
                value={state.name}
                onChange={handleDepartmentChange}
                className={
                  "form-control border" +
                  (errors.name && touched.name ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="name"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label className="font-weight-bold">Department Code</label>
              <input
                name="code"
                type="text"
                value={state.code}
                onChange={handleDepartmentChange}
                className={
                  "form-control border" +
                  (errors.code && touched.code ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="code"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group  mt-0 ">
              <label className="font-weight-bold"> Description</label>
              <textarea
                rows="4"
                type="text"
                name="description"
                className={
                  "form-control border" +
                  (errors.description && touched.description
                    ? " is-invalid"
                    : "")
                }
                value={state.description}
                onChange={handleDepartmentChange}
              />
              <ErrorMessage
                name="description"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="d-flex justify-content-center">
              <button
                color="success"
                variant="contained"
                type="submit"
                className="btn btn-outline-success px-5 my-4 "
              >
                {state.loading && (
                  <i
                    className="fa fa-circle-notch fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {state.loading && (
                  <span className="text-capitalize">Please wait...</span>
                )}
                {!state.loading && (
                  <span className="text-capitalize"> Add</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

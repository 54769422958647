import React, { useContext } from 'react';
import { Divider, Avatar, Empty, Pagination, Button } from 'antd';
import * as Icon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'context/ModalContext';
import AddSchemeGroup from 'components/widgets/modals/AddSchemeGroup';

const ManageGroupScheme = (props) => {
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
	const history = useHistory();

	const view_scheme_group = (id) => {
		history.push(`/dashboard/schemes/${id}/${1}`);
	};
	return (
		<div>
			<div className="pageheader pd-t-25 ">
				<div className="pd-t-5">
					<h1 className="pd-0 mg-0 tx-20">Manage Scheme Group</h1>
				</div>
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Scheme Group
					</a>
					<a className="breadcrumb-item active" href>
						View
					</a>
				</div>
			</div>
			<div className="card mg-t-45">
				<div className="card-body">
					<div className="">
						<i className="fa fa-bell-o text-primary mb-3 float-right" />
						<Divider orientation="left" className="bg-light pd-y-5 font-italic">
							Group Policy details
						</Divider>
						<div className="row">
							<div className="col-md-2">
								<div className="d-flex flex-column justify-content-center align-items-center pd-t-10 pd-x-15 flex-wrap">
									<Avatar size={94} className="mb-3" />
								</div>
							</div>
							<div className="col-md-5">
								<div className="mb-3">
									<strong className="d-inline-block wd-150">Group Name</strong>: test
								</div>
								<div className="mb-3">
									<strong className="d-inline-block wd-150">Group Description</strong>: Lorem ipsum
									dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
									labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
									ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
									reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
								</div>
							</div>
						</div>

						<>
							<div className="clearfix">
								<Button
									className="btn-primary btn-blue rounded mb-3 float-right text-white "
									onClick={() => setModalShow(true)}
								>
									Create Quotation
								</Button>
							</div>
							<div className="mg-b-20">
								<h5 className="text-center">Scheme Policies</h5>
								<div className="card-body pd-0 collapse show" id="customerDetails">
									<div className="table-responsive">
										<table className="table table-hover mg-0">
											<thead className="tx-dark tx-uppercase tx-12 tx-bold">
												<tr className="bg-light">
													<th className="pl-5">Policy No.</th>
													<th>Premium Amount</th>
													<th>Currency</th>
													<th>Start Date</th>
													<th>End Date</th>
													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="">Policy 123</td>
													<td>100000</td>
													<td>KES</td>
													<td>30/06/2021</td>
													<td>30/06/2022</td>
													<td>Active</td>

													<td>
														<a href className="btn btn-sm btn-label-primary">
															View
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<Pagination
								responsive={true}
								// total={data.length}
								showTotal={(total) => `Total ${total} items`}
								pageSize={10}
								defaultCurrent={1}
							/>
						</>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageGroupScheme;

import React from 'react';
import { Pagination } from 'antd';
import * as Icon from 'react-feather';
import NoData from 'components/no-data/NoData';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';

const Leads = ({ data }) => {
	console.log('THE LEAD DATA', data);
	const history = useHistory();
	return (
		<div>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>

			<div className="row row-xs clearfix">
				<div className="col-lg-12  rounded-10 col-xl-12">
					{data.length === 0 ? (
						<NoData message="You currently don't have any leads" />
					) : (
						<div className=" card mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5">
									<h1 className="pd-0 mg-0 tx-20">
										<Icon.Briefcase size={36} className="pr-2 text-primary" />
										Leads
									</h1>
								</div>
							</div>

							<>
								<div className="mg-b-20">
									<div className="card-body pd-0 collapse show" id="customerDetails">
										<div className="table-responsive">
											<table className="table table-hover mg-0">
												<thead className="tx-dark tx-uppercase tx-12 tx-bold">
													<tr className="bg-light">
														<th className="pl-5">Email</th>
														<th>Date</th>
														<th>Status</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{data.map((row) => (
														<tr key={row.lead_ref}>
															<td className="ml-3">{row.lead_email}</td>
															<td>
																<Moment format="DD-MM-YYYY">{row.created_date}</Moment>
															</td>
															<td>{row.lead_status}</td>

															<td>
																{' '}
																<a
																	href
																	className="btn btn-sm btn-label-primary"
																	onClick={() =>
																		history.push(`/dashboard/leads/${row.lead_ref}`)
																	}
																>
																	View
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<Pagination
									responsive={true}
									total={data.length}
									showTotal={(total) => `Total ${total} items`}
									pageSize={10}
									defaultCurrent={1}
								/>
							</>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Leads;

import React, { useContext, useRef, useState, useEffect } from 'react';
import Navbar from 'components/dashboard-nav';
import Sidebar from 'components/dashboard-sidebar';
import { ModalContext } from 'context/ModalContext';
import { DashboardContext } from 'context/DashboardContext';
import AddNote from 'components/widgets/modals/AddNote';
import * as Icon from 'react-feather';
import { Avatar, Input, Pagination, Button, Divider } from 'antd';
import SelectUser from 'components/widgets/modals/SelectUser';
import Loader from 'components/widgets/loader';
import NoData from 'components/no-data/NoData';

export default function View_User_Group({ data, id, getGroupDetails }) {
	const context = useContext(DashboardContext);
	const modalContext = useContext(ModalContext);
	const { setModalShow, modalShow, modalClose } = modalContext;
	const { quotationRequests, paginate, numEachPage, handlePaginationChange, fetch_quotation_requests } = context;

	useEffect(() => {
		try {
			fetch_quotation_requests();
		} catch (error) {
			console.log(error.response);
		}
	}, []);

	const { Search } = Input;
	const [search, setSearch] = useState(null);

	const searchSpace = (event) => {
		let keyword = event.target.value;
		setSearch(keyword);
	};

	const items = quotationRequests.data
		.filter((data) => {
			if (search == null) return data;
			else if (
				data.names.toLowerCase().includes(search.toLowerCase()) ||
				data.created_date.toLowerCase().includes(search.toLowerCase()) ||
				data.request_status.toLowerCase().includes(search.toLowerCase()) ||
				data.remarks.toLowerCase().includes(search.toLowerCase())
			) {
				return data;
			}
		})
		.slice(paginate.minValue, paginate.maxValue)
		.map((row, index) => {
			return (
				<tr key={index}>
					<td>{index}</td>
					<td className="d-flex align-items-center">
						<div className=" mr-4">
							<Avatar
								size={40}
								style={{
									color: '#001f5f',
									backgroundColor: 'rgba(5,88,183,0.10)',
								}}
							>
								<small>U{index}</small>
							</Avatar>
						</div>
						<div className="d-block">
							<a href className="my-0 mt-1 ">
								User {index}
							</a>
						</div>
					</td>
					<td> Str123</td>

					<td>
						<a className="dropdown-link tx-13 tx-gray-500" href>
							<Icon.Trash2 size={14} className="mr-2 text-danger" />
							Remove
						</a>
					</td>
				</tr>
			);
		});
	return (
		<>
			<div id="main-wrapper">
				{data.length === 0 ? (
					<NoData message="unable to fetch details" />
				) : (
					<>
						<div className="pageheader pd-t-25 ">
							<div className="breadcrumb pd-0 mg-0">
								<a className="breadcrumb-item" href="index.html">
									<i className="icon ion-ios-home-outline" /> Home
								</a>
								<a className="breadcrumb-item active" href>
									Claims
								</a>
								<a className="breadcrumb-item active" href>
									View
								</a>
							</div>
						</div>

						<div className=" pd-20  m-auto">
							<h5 className="card-title bd-b pd-y-20">
								<span href className="pl-2 text-info">
									{data.group_name}
								</span>
							</h5>

							<div className=" pd-0 printing-area">
								<div className="card card-body bg-lighter">
									<div className="row">
										<div className="col-lg-6  d-lg-block">
											<div className="mb-3">
												<strong className="d-inline-block ">Group Name</strong>:
												{data.group_name}
											</div>
											<div className="">
												<strong className="d-inline-block ">Description</strong>
											</div>
											<p className="fs-12">{data.description}</p>
										</div>
										<div className="col-lg-6  d-lg-block">
											<div className="fs-12">
												<div className="mb-3">
													<strong className="d-inline-block ">Code</strong>: {data.group_code}
												</div>
												<div className="mb-3">
													<strong className="d-inline-block ">Created Date</strong>:
													10/05/2020
												</div>
												<div className="mb-3">
													<strong className="d-inline-block ">Status</strong>:{' '}
													<span className="text-warning">Pending Approval</span>
												</div>
											</div>
										</div>
									</div>

									<div className="mg-y-20 pd-20">
										<button type="button" className="btn btn-success mg-t-5 mr-3">
											<i className="fa fa-check" /> Approve
										</button>
										<button type="button" className="btn btn-info mg-t-5">
											<i className="fa fa-edit" /> Edit
										</button>
									</div>
									<div className="pd-t-5 pd-b-5 pd-l-5">
										<h1 className=" tx-18 text-center">
											<Icon.Users size={30} className="pr-2 text-primary" />
											Admin Group Users
										</h1>
										<Divider />
										<SelectUser
											show={modalShow}
											onHide={modalClose}
											getGroupDetails={getGroupDetails}
											id={id}
										/>
										<button
											className="btn btn-blue text-white rounded float-right mb-3"
											onClick={() => setModalShow(true)}
										>
											Add User To Group
										</button>
									</div>
									{data.users.length === 0 ? (
										<b className="text-center">No Users in this group</b>
									) : (
										<>
											<Search
												placeholder="Search User"
												type="search"
												onChange={(e) => searchSpace(e)}
												className="ml-md-auto mg-r-10 mb-3 float-right"
												style={{ width: 200 }}
											/>

											<div className="table-responsive">
												<table className="table table-hover mg-0">
													<thead className="tx-dark tx-uppercase tx-12 tx-bold">
														<tr className="bg-light">
															<th className="">#</th>
															<th className="">Name</th>
															<th className="">Email</th>
															<th className="">Username</th>
															<th className="">Contact</th>
															<th>Role Code</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{data.users.map((user, index) => (
															<tr key={index}>
																<td>{index}</td>
																<td className="d-flex align-items-center">
																	<div className=" mr-4">
																		<Avatar
																			size={40}
																			style={{
																				color: '#001f5f',
																				backgroundColor: 'rgba(5,88,183,0.10)',
																			}}
																		>
																			<small>U{index}</small>
																		</Avatar>
																	</div>
																	<div className="d-block">
																		<a href className="my-0 mt-1 ">
																			{user.names}
																		</a>
																	</div>
																</td>
																<td> {user.email}</td>
																<td> {user.username}</td>
																<td> {user.contact}</td>
																<td> {user.role_code}</td>

																<td>
																	<a className="dropdown-link tx-13 tx-gray-500" href>
																		<Icon.Trash2
																			size={14}
																			className="mr-2 text-danger"
																		/>
																		Remove
																	</a>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
											<Pagination
												className="ml-auto"
												responsive={true}
												dataSource={data}
												total={data.length}
												showTotal={(total) => `Total ${total} items`}
												defaultPageSize={numEachPage}
												defaultCurrent={1}
												onChange={handlePaginationChange}
											/>
										</>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

import React from 'react';
import Leads from './Leads';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const LeadsContainer = () => {
	async function _listLeads() {
		let resp = await (await api_srv).list_leads();

		return resp.leads;
	}
	const { data, isLoading, isSuccess } = useQuery('list leads', _listLeads);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Leads data={data} />}
		</div>
	);
};

export default LeadsContainer;

import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import Map from "../../map";
import { DashboardContext } from "../../../context/DashboardContext";

const Location = (props) => {
  const context = useContext(DashboardContext);
  const { set_intermediary_location, loading } = context;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      top
      transparent={true}
    >
      {/* <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Choose a location</h5>
          </Modal.Title>
        </Modal.Header> */}
      <Modal.Body>
        <Map />
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={set_intermediary_location}
          disabled={loading}
          className="btn btn-blue  mt-5"
        >
          {loading && (
            <i
              className="fa fa-circle-notch fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {!loading && <span className="text-capitalize"> Set Location</span>}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Location;

import React, { useState, useEffect, useContext } from 'react';
import Footer from 'components/dashboard-footer';
import Navbar from 'components/dashboard-nav';
import Sidebar from 'components/dashboard-sidebar';
import { Button, Input } from 'antd';
import { DashboardContext } from 'context/DashboardContext';
import * as Icon from 'react-feather';
import Details from 'components/stepper/asset-stepper';

export default function AssetDetails(props) {
	const context = useContext(DashboardContext);
	const { paginate, view_quotation, listQuotations } = context;

	const [current, setCurrent] = useState(1);
	const { Search } = Input;

	const [search, setSearch] = useState('');
	const onChange = (page) => {
		setCurrent(page);
	};

	const client_quotation_id = props.match.params.id;

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				{/* <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20"><Icon.Shield  size={36} className="pr-2 text-primary" />Claims</h1>
                </div> */}
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			<div className="col-lg-12 col-xl-12">
				<div className="card mg-b-20">
					<div className="card-header">
						<div className="pd-t-5 pd-b-5 pd-l-5">
							<h1 className="tx-20">
								<Icon.FilePlus size={36} className="pr-2 text-primary" />
								Asset Details
							</h1>
						</div>
					</div>
					<div className="card-body collapse show" id="studentDetails">
						<Details id={client_quotation_id} />
					</div>
				</div>
			</div>
		</>
	);
}

import React, { useContext, useEffect } from "react";
import { Skeleton, Button, Avatar } from "antd";
import { KycContext } from "../../../context/KycContext";
import { DashboardContext } from "../../../context/DashboardContext";
import * as Icon from "react-feather";
import NoData from '../../no-data/NoData'
import { CheckOutlined } from '@ant-design/icons';

export default function AssetDetail() {
  // Access Context
  const { prev, next } = useContext(KycContext);
  const {
    quotations,
    fetch_product_summary
  } = useContext(DashboardContext);

  useEffect(() => {
    fetch_product_summary(quotations.product_id);
  }, []);
  return (
    
      <div className="px-5 pt-3">
     
        { quotations.product_details === "" && !quotations.loading? <NoData message="Product details unavailable" /> :quotations.product_details === "" && quotations.loading ?<Skeleton  active avatar paragraph={{ rows: 7 }} />:
        <>
           <h5 className="upload-title text-center mt-5">Product Details</h5>
        <div className="row">
          <div className=" col-md-12 pd-y-20">
            <Avatar
              size={65}
              src={`https://api.nexus.ke${quotations.product_details.details.product_details.logo}`
              }
            />
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <strong className="d-inline-block">Product Name</strong>:{" "}
              {quotations.product_details.details.product_details.product_name}
            </div>
            <div className="mb-3">
              <strong className="d-inline-block">Cover Type</strong>:
              {quotations.product_details.details.product_details.covertype_code}
            </div>
            <div className="mb-3">
              <strong className="d-inline-block">Underwritter </strong>:
            {quotations.product_details.details.product_details.underwritter_subclass_code}
            </div>
            <div className="mb-3">
              <strong className="d-inline-block ">Min. Premium</strong>:
              { quotations.product_details.details.product_details.minimum_premium}
            </div>
            <div className="mb-3">
              <strong className="d-inline-block">
                Notification Frequency
              </strong>
              :
              {quotations.product_details.details.product_details.premium_notification_frequency}
            </div>
            <div className="mb-3">
              <strong className="d-inline-block ">Policy Term</strong>:{" "}
              {quotations.product_details.details.product_details.policy_term}
            </div>
         
          </div>
          <div className="col-md-6">
            <div className="">
              <strong className="d-inline-block ">
                Why choose this product?
              </strong>
            </div>
            <ul className="mb-5 mt-2 pl-0 ml-0">
              {quotations.product_details.benefits.product_benefits.map((benefit) => {
                console.log("the benefits",benefit)
                    return (
                      <li className="pl-0 py-2">
                        <CheckOutlined style={{ fontSize: '16px', color: '#5cb85c', marginRight: '5px' }} />
                          {benefit.name} ~ {benefit.benefit_type}
                      </li>
                    );
                  })}
            </ul>
          </div>
          <div className="col-md-12 mb-3">
              <strong className="d-inline-block">Description</strong>
              <p className="d-block">
                {quotations.product_details.details.product_details.description}
              </p>
            </div>
        </div>  
      </>

              }
      <footer>
        <Button
        disabled={quotations.loading}
          className="btn-blue float-right mb-5 text-white px-4 py-1 rounded "
          htmlType="submit"
          onClick={next}
        >
          Next
        </Button>
        <Button
          className="btn-secondary float-right mb-5 mr-3  px-4 py-1 rounded "
          type="submit"
          onClick={prev}
        >
          Back
        </Button>
      </footer>
    </div>
  );
}

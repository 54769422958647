import React from "react";
import { Avatar, Rate, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Badge from "react-bootstrap/Badge";
import Moment from "react-moment";
import * as Icon from "react-feather";
import NoData from "components/no-data/NoData";
import "./Reviews.css";
import { formatDate } from "utils/helpers";
import { base_url } from "services/apiSrv";

const Reviews = ({ data }) => {
  const { Search } = Input;
  return (
    <div>
      <div className="pageheader pd-t-25 pd-b-35">
        <div className="breadcrumb pd-0 mg-0">
          <a className="breadcrumb-item" href="index.html">
            <i className="icon ion-ios-home-outline" /> Home
          </a>
          <a className="breadcrumb-item active" href>
            Dashboard
          </a>
        </div>
      </div>

      <div className="mg-t-15 pd-b-5 pd-l-5">
        <h1 className=" tx-20">
          <Icon.ThumbsUp size={34} className="pr-2 text-primary" />
          My Reviews
        </h1>
      </div>
      {data.length !== 0 && (
        <Search
          placeholder="search A review"
          type="search"
          //   onChange={(e) => searchSpace(e)}
          className="mb-3"
        />
      )}

      {data.length === 0 ? (
        <NoData message="You currently don't have  any reviews" />
      ) : (
        data.map((review) => (
          <div class="review" key={review.intermediary_review_id}>
            <div className="review-photo">
              <img
                src="https://via.placeholder.com/150x150"
                alt="icon"
                // onerror={(this.src = "https://via.placeholder.com/150x150")}
              />
            </div>

            <div className="review-box">
              <div className="review-author">
                <p>
                  <strong>{review.names}</strong> -{" "}
                  <Rate
                    allowHalf
                    disabled
                    defaultValue={review.rating}
                    style={{ fontSize: "14px" }}
                  />
                  - City
                </p>
              </div>
              <div className="review-comment">
                <p>{review.review}</p>
              </div>

              <div className="review-date">
                <time>{formatDate(review.review_date)}</time>
              </div>
            </div>
          </div>
        ))
      )}
      {/* {data.length===0 ? <NoData message="You currently don't have  any reviews" />: data.map((review)=>(
 <div key={review.intermediary_review_id} className="col-lg-12 card rounded-10 mb-3 col-xl-12">
 <div className="card-body">
   <Avatar size={64} icon={<UserOutlined />} src={review.client_image_path} />
   <h5 className="d-md-inline pl-3 text-muted">{review.names}</h5>
   <div className="">
     <Rate
       allowHalf
       disabled
       defaultValue={review.rating}
       style={{ fontSize: "14px" }}
     />
     <Badge variant={`${review.rating <=2 ? 'danger':review.rating <=3 ?" warning":"success" } ml-2`}>{review.rating}</Badge>
     <i className="float-md-right fs-6 fw-light"> <Moment format="DD-MMM-YYYY HH:mm A">{review.review_date}</Moment></i>
   
     <p className="pt-2">
      {review.review}
     </p>
   </div>
   <footer>
     <i>12 people found this helpful</i>
     <small className="text-uppercase text-danger float-md-right">
       <a href className="fs-6 ">
        <Icon.Flag size={14}  /> flag this review
       </a>
     </small>
     <small className="text-uppercase text-danger float-md-right mr-2">
       <a href className="fs-6 ">
        <Icon.MessageSquare size={14}  /> Respond
       </a>
     </small>
   </footer>
 </div>
</div>
        ))
     
      } */}
    </div>
  );
};

export default Reviews;

import * as yup from "yup";

let SignupSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email"),
  category: yup.string().required("Category is required"),
  ira_code: yup.string().required("IRA Code is required"),
});

export default SignupSchema;

import React, { useContext } from "react";
import { AlertsContext } from "../../context/AlertsContext";
import Alert from "react-bootstrap/Alert";

export default function WarningAlert({variant,message}) {
  const { showAlert, setShowAlert } = useContext(AlertsContext);


  if (showAlert) {
    return (

      <Alert
        variant={variant}
        onClose={() => setShowAlert(false)}
        dismissible
        className={variant==="success"?`alert-success`: `alert-warning`}

      >
        <small>{message}</small>
      </Alert>
    );
  }
}

import React, { useContext } from "react";

import { Button, Input, Form } from "antd";
import { Modal } from "react-bootstrap";
import { useMutation } from "react-query";

import "../../../assets/css/dashboard.css";
import UploadComponent from "components/uploads";
import { ModalContext } from "context/ModalContext";
import { addSchemeDocument } from "services/apiSrv";
import { show_toast } from "utils/helpers";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const AddSchemeDocument = (props) => {
  const { TextArea } = Input;
  const { modalClose } = useContext(ModalContext);
  const add_document = useMutation(addSchemeDocument, {
    onSuccess: (data) => {
      modalClose();
      props.refetch();
      show_toast("Document added successfully", "success");
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
      console.log("error adding scheme document", err);
    },
  });

  const onFinish = (values) => {
    let val = {};
    val = { ...values, scheme_ref: props.scheme_ref };
    console.log("Success:", val);
    add_document.mutate(val);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Add Scheme Document</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name="add_scheme_document"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            validateMessages={validateMessages}
            layout="vertical"
          >
            <Form.Item
              name="document"
              label="Document"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadComponent />
            </Form.Item>
            <Form.Item
              label="Document Name"
              name="doc_name"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="doc_description"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
                htmlType="submit"
                disabled={add_document.isLoading}
              >
                {add_document.isLoading ? (
                  <div className="text-white">
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    Uploading..
                  </div>
                ) : (
                  "Upload"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSchemeDocument;

import React, { useEffect, useContext, useState } from "react";
import Chat from "./Chat";
import "./chat.css";
import io from "socket.io-client";
import { useQuery } from "react-query";
import { api_srv } from "services";
import Loader from "components/widgets/loader";
import { DashboardContext } from "context/DashboardContext";

//  const Endpoint = "https://io.dev.nexus.ke/";
const Endpoint = "https://chat.demo.nexus.ke/";

let socket;

const ChatContainer = () => {
  const { setIncomingMessage } = useContext(DashboardContext);

  const chat_conversations = async () => {
    let resp = await (await api_srv).get_conversations();
    return resp.conversations;
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "conversations",
    chat_conversations
  );

  useEffect(() => {
    socket = io(Endpoint);

    // connect to server
    socket.on("connect", () => {
      console.log("user connected successfully");

      // on successful connection, emit an event to server
      let user_detail = {
        chat_user_identification: "",
      };
      user_detail.chat_user_identification = parseInt(
        sessionStorage.getItem("chat_id")
      );
      socket.emit("user:connected", user_detail);
    });

    //  error connecting to server
    socket.on("connection:error", (error) => {
      console.log(" connection error  ", error);
    });

    // on component unmount
    return () => socket.disconnect();
  }, []);

  // Listen for any messages incoming and push to conversations
  useEffect(() => {
    console.log("START LISTENING FOR ANY MESSAGES");
    socket.on("chat:message", (message) => {
      console.log("LISTENING FOR ANY MESSAGES", message);
      setIncomingMessage(message);
    });
  }, []);

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Chat conversations={data} />}
    </div>
  );
};

export default ChatContainer;

import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

const UploadComponent = ({ value = {}, onChange, accept = "*" }) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const triggerChange = (changedValue) => {
    onChange(changedValue);
  };
  const props = {
    beforeUpload: (file) => {
      if (accept.includes(".png" || ".jpg" || ".jpeg")) {
        if (file.type !== "image/png") {
          show_toast(`${file.name} is not an image file`, "error");
        }
        return file.type === "image/png" ? true : Upload.LIST_IGNORE;
      }
    },
  };
  const handleUploadChange = ({ file, fileList, event }) => {
    setDefaultFileList(fileList);
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    try {
      const res = await (await api_srv).upload_api("image", file);
      onSuccess("Ok");

      console.log("server res: ", res);
      setImageUrl(res.file_path);
      triggerChange(res.file_path);
    } catch (err) {
      let error = await err;
      console.log("Eroor: ", error);
      //   const error = new Error("Some error");
      onError({ err });
    }
  };

  return (
    <div>
      <Upload
        {...props}
        accept={accept}
        value={imageUrl}
        customRequest={uploadImage}
        onChange={handleUploadChange}
        listType="picture"
        defaultFileList={defaultFileList}
        className="upload-list-inline"
      >
        {defaultFileList.length >= 1 ? null : (
          <Button icon={<UploadOutlined />}>Upload</Button>
        )}
      </Upload>
    </div>
  );
};

export default UploadComponent;

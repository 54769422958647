import React, { useState, useEffect } from "react";
import MapComponent from "./MapComponent";


const MapView = (props) => {
  const [location, setLocation] = useState({
    currentLatLng: {
      lat: 0,
      lng: 0,
    },
    isMarkerShown: false,
  });

  useEffect(() => {
    getGeoLocation();
  }, []);

  // const isFirstRender = React.useRef(true);
  // React.useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //     return;
  //   }
  //   /*business logic for component did update*/
  //   getGeoLocation();
  // }, []);


  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation((prevState) => ({
          currentLatLng: {
            ...prevState.currentLatLng,
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        }));
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("lng", position.coords.longitude);
      });
    } else {
      return (error) => console.log(error);
    }
  };

const links=[
  {
    coords: { lat: 42, lng: 42 }, // required: latitude & longitude at which to display the marker
    title: `Life, the Universe and Everything`, // optional
    url: `https://wikipedia.org/wiki/Life,_the_Universe_and_Everything`, // optional
  }
]


  // function addMarkers(map, links) {
  //   links.forEach((link, index) => {
  //     const marker = new window.google.maps.Marker({
  //       map,
  //       position: link.coords,
  //       label: `${index + 1}`,
  //       title: link.title,
  //     })
  //     marker.addListener(`click`, () => {
  //       window.location.href = link.url
  //     })
  //   })
  // }
 let mapProps = {
    options: { center: { lat: location.currentLatLng.lat, lng: location.currentLatLng.lng }, zoom: 15 },
    // onMount: addMarkers, 
    // onMountProps: linksComingFromSomewhere, 
  }
  

  return (

    <MapComponent />
  //   <MapComponent
  //   google={props.google}
  //   center={{lat: location.currentLatLng.lat, lng: location.currentLatLng.lat}}
  //   height='300px'
  //   zoom={15}
  //  />
    
  );
};

export default MapView;

import React, { useState, useContext } from "react";
import AssignRm from "./AssignSalesRep";
import { assign_lead_sales_rep, get_sales_rep } from "services/apiSrv";
import { useMutation, useQuery } from "react-query";
import Loader from "components/widgets/loader/ComponentLoader";
import { show_toast } from "utils/helpers";
import { ModalContext } from "context/ModalContext";

const AssignRmContainer = ({ lead_ref }) => {
  const [selectedSalesRep, setSelectedSalesRep] = useState("");
  const { modalClose } = useContext(ModalContext);

  const _assign = useMutation(assign_lead_sales_rep, {
    onSuccess: (data) => {
      modalClose();
      show_toast("SalesRep added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  function handleChange(e, value) {
    setSelectedSalesRep(value);
  }
  console.log("SELCTED Sales REP", selectedSalesRep);
  async function fetch_sales_rep() {
    let resp = await await get_sales_rep();
    console.log("THE SALES REP HERE", resp);
    return resp.sales_reps;
  }
  const { data, isLoading, isSuccess } = useQuery("sales rep", fetch_sales_rep);
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <AssignRm
          salesRep={data}
          handleChange={handleChange}
          _assign={_assign}
          selectedSalesRep={selectedSalesRep}
          lead_ref={lead_ref}
        />
      )}
    </div>
  );
};

export default AssignRmContainer;

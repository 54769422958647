import React from "react";
import Navbar from "components/dashboard-nav";
import Sidebar from "components/dashboard-sidebar";
import pic from "assets/images/new4.jpg";

export default function View_Chat_User() {
  return (
    <div className="page-container">
      <Sidebar />
      <div className="page-content">
        <Navbar />
        <div className="page-inner">
          <div id="main-wrapper">
            <div className="pageheader pd-t-25 ">
              <div className="pd-t-5">
                <h1 className="pd-0 mg-0 tx-20">View Chat User</h1>
              </div>
              <div className="breadcrumb pd-0 mg-0">
                <a className="breadcrumb-item" href="index.html">
                  <i className="icon ion-ios-home-outline" /> Home
                </a>
                <a className="breadcrumb-item active" href>
                  Claims
                </a>
                <a className="breadcrumb-item active" href>
                  View
                </a>
              </div>
            </div>

            <div className=" pd-20  m-auto">
              <h5 className="card-title bd-b pd-y-20">
                <a href className="tx-dark">
                  Chat User
                </a>{" "}
                Jane Doe
              </h5>

              <div className=" pd-0 printing-area">
                <div className="card-body bg-lighter">
                  <div className="row">
                    <div
                      className="col-lg-3"
                      style={{
                        backgroundImage: `url(${pic})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "25vh",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                    <div className="col-lg-3 ml-md-3  d-lg-block">
                      <div className="fs-12">
                        <div className="mb-3">
                          <strong className="d-inline-block ">Name</strong>:Jane
                          Doe
                        </div>
                        <div className="mb-3">
                          <strong className="d-inline-block ">
                            Staff Reference
                          </strong>
                          : ref123{" "}
                        </div>
                        <div className="mg-t-50 ">
                          {/* <button type="submit" className="btn btn-primary mg-t-5 mr-2" onClick={() => setModalShow(true)}><i className="far fa-sticky-note" /> Add a Note</button> */}
                          <button
                            type="button"
                            className="btn btn-danger mg-t-5 mr-3"
                          >
                            <i className="fa fa-trash" /> Remove
                          </button>
                          <button
                            type="button"
                            className="btn btn-info mg-t-5 mr-2"
                          >
                            <i className="fa fa-history" /> Chat History
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4  d-lg-block">
                      <div className="mb-3">
                        <strong className="d-inline-block ">Status</strong>:{" "}
                        <span className="text-success">Online</span>
                      </div>
                      <div className="">
                        <strong className="d-inline-block ">Description</strong>
                      </div>
                      <p className="fs-12 text-wrap">Best In Motor Insurance</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { greeting } from "utils/helpers";

export const signupForm = {
  form_title: "Create an account",
  form_subtitle: "Join the ",
  button_label: "Sign Up",
  button_styles: "block btn-blue",
  fields: [
    {
      key: "email",
      label: "Email",
      name: "email",
      type: "email",
    },

    {
      key: "category",
      label: "Category",
      name: "category",
      type: "select",
      options: [
        { key: "agent", label: "Agent", value: "agent" },
        { key: "broker", label: "Broker", value: "broker" },
      ],
    },
    {
      key: "ira_code",
      label: "IRA Code",
      name: "ira_code",
      type: "MaskedInput",
    },
  ],
};

import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { AlertsContext } from "../../context/AlertsContext";
import { useHistory } from "react-router-dom";
import Login from ".";
import { api_srv } from "../../services";
import routes from "routes";

const LoginContainer = () => {
  let history = useHistory();

  // ACCESS CONTEXT

  const { user, setUser, alert, setAlert } = useContext(AuthContext);
  const { setShowAlert } = useContext(AlertsContext);

  //   METHODS

  function confirm_kyc(kyc_resp) {
    if (
      kyc_resp.intermediary_details.intermediary_details.kyc_status === "ACTIVE"
    ) {
      history.push("/dashboard");
    } else {
      history.push(`${routes.kyc}`);
    }
  }

  async function login(user) {
    try {
      setUser({ ...user, loading: true });
      await (await api_srv).login(user);

      let kyc_resp = await (await api_srv).check_kyc();
      let chat_resp = await (await api_srv).chat_login();

      confirm_kyc(kyc_resp);
      setUser({ ...user, loading: false });
    } catch (err) {
      setUser({ ...user, loading: false, password: "", username: "" });
      let error = await err;

      setShowAlert(true);

      setAlert({ ...alert, message: error, variant: "danger" });
    }
  }

  const toggleLoginPassword = () => {
    setUser({ ...user, showPassword: !user.showPassword });
  };

  function toggleRememberMe() {
    setUser({ ...user, rememberMe: !user.rememberMe });
  }
  return <Login requests={login} />;
};

export default LoginContainer;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import Domain from '../forms/Domain';

const AddDomains = (props) => {
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Add a domain</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Domain />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddDomains;

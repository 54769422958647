import React, { useEffect, useContext, useState } from 'react';
import Quotations from './Quotations';
import { DashboardContext } from 'context/DashboardContext';
import { api_srv } from 'services';
import Loader from 'components/widgets/loader';
import { useQuery } from 'react-query';
import FiveHundred from 'pages/errors/500';
const QuotationsContainer = (props) => {
	const { listQuotations, setListQuotations } = useContext(DashboardContext);
	const [showAlert, setShowAlert] = useState(false);

	async function fetch_all_quotations() {
		let resp = await (await api_srv).get_all_quotations();
		return resp.client_quotations;
	}

	const { isLoading, isSuccess, data, isError, error } = useQuery('quotations', fetch_all_quotations);

	const handle_view_quotation = async (id) => {
		try {
			//  await view_quotation(id)
			props.history.push(`/dashboard/quotations1/view/${id}`);
		} catch (err) {
			console.log(err);
		}

		props.history.push(`/dashboard/quotations1/view/${id}`);
	};

	const add_asset = async (product_id, client_quotation_id) => {
		product_id = btoa(product_id);
		try {
			localStorage.setItem('client_quotation_id', client_quotation_id);
			props.history.push(`/dashboard/quotations1/asset-details/${product_id}`);
		} catch (err) {
			console.log(err);
		}
	};

	const [search, setSearch] = useState('');

	const searchSpace = (event) => {
		let keyword = event.target.value;
		setSearch(keyword);
	};

	return (
		<div>
			{isLoading && <Loader />}
			{isError && <FiveHundred error={error} />}
			{isSuccess && (
				<Quotations
					data={data}
					add_asset={add_asset}
					handle_view_quotation={handle_view_quotation}
					searchSpace={searchSpace}
					search={search}
					showAlert={showAlert}
				/>
			)}
		</div>
	);
};

export default QuotationsContainer;

import React, { useContext, useReducer } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { KycContext } from "context/KycContext";
import { ModalContext } from "context/ModalContext";
import { DashboardContext } from "context/DashboardContext";
import routes from "routes";

export default function SuccessMsg() {
  const history = useHistory();

  // access context
  const { setCurrent } = useContext(KycContext);
  const { setModalShow } = useContext(ModalContext);
  const { quotations } = useContext(DashboardContext);

  const initialState = quotations;

  const reducer = (state, action) => {
    if (action.type === "reset") {
      return initialState;
    }
    const result = { ...state };
    result[action.type] = action.value;
    return result;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const addAnotherQuotation = () => {
    setCurrent(0);
    dispatch({ type: "reset" });
  };

  return (
    <Result
      status="success"
      title="Quotation queued successfully!"
      subTitle="Proceed to quotations section to add asset and finalize the quotation."
      extra={[
        <Button
          type="primary"
          onClick={() => {
            history.push(routes.quotations1);
            setCurrent(0);
            setModalShow(false);
          }}
        >
          Proceed
        </Button>,
        <Button onClick={() => addAnotherQuotation()}>
          Add another Product
        </Button>,
      ]}
    />
  );
}

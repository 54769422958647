import React from 'react'
import { Empty } from 'antd'
import emptyImage from '../../assets/images/animations/Empty.svg'

export default function NoData({message}) {
    return (
        <div className="mt-5">
             <Empty
    image={emptyImage}
    imageStyle={{
      height: 250,
    }}
    description={
      <span>
    {message}
      </span>
    }
  >
  
  </Empty>,
        </div>
    )
}

import React, { useContext } from "react";

import { Link } from "react-router-dom";

import routes from "routes";
import CreateRequest from "components/widgets/modals/CreateRequest";
import CustomTable from "components/widgets/table";
import { ModalContext } from "context/ModalContext";

export default function Requests({ data, view_quotation_request }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "Client Name", align: "c" },
      { name: "Request Date", align: "c" },
      { name: "Status", align: "c" },
      { name: "Remarks", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "text", field: "names", class: "center" },
      { type: "date", field: "created_date", class: "center" },
      { type: "status", field: "status", class: "center" },
      { type: "text", field: "remarks", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "system_quotation_request_ref",
            method: view_quotation_request,
          },
        ],
      },
    ],
  };

  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item active">Quotations</Link>
          <Link className="breadcrumb-item ">Requests</Link>
        </div>
        <h5>Quotation Requests</h5>
      </div>
      <CreateRequest show={modalShow} onHide={modalClose} />

      {/* <Button
        className="btn-primary btn-blue rounded mb-3 float-right text-white "
        onClick={() => setModalShow(true)}
      >
        Create Request
      </Button> */}

      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
    </>
  );
}

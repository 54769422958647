import React, {useContext} from 'react'
import { Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { DashboardContext } from "context/DashboardContext";

const AddRoleForm = ({addRole,handleChange}) => {

    const {usmg}=useContext(DashboardContext)
    return (
        <Formik
        enableReinitialize
        initialValues={usmg.role_added}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please a name  of role"),
          role_code: Yup.string().required("Role Code is required"),
          description: Yup.string().required("Description of role is required"),
        })}
        onSubmit={() => addRole()}
      >
        {({ errors, touched, setFieldValue }) => (
          
                <Form>
                <div className="form-group mt-0 ">
                  <label htmlFor="Name" className="font-weight-bold">
                    Name of Role
                  </label>
                  <input
                    name="name"
                    value={usmg.role_added.name}
                    onChange={handleChange}
                    type="text"
                    className={
                      "form-control border" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                    id="Name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group mt-0 ">
                  <label htmlFor="role" className="font-weight-bold">
                    Role Code
                  </label>
                  <input
                    name="role_code"
                    value={usmg.role_added.role_code}
                    onChange={handleChange}
                    className={
                      "form-control border" +
                      (errors.role_code && touched.role_code ? " is-invalid" : "")
                    }
                    type="text"
                    id="role"
                  />
                  <ErrorMessage
                    name="role_code"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group mt-0 ">
                  <label className="font-weight-bold">Description</label>
                  <textarea
                    name="description"
                    rows="4"
                    type="text"
                    value={usmg.role_added.description}
                    onChange={handleChange}
                    className={
                      "form-control border" +
                      (errors.description && touched.description ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <button
                  className="btn btn-success btn-block  mt-5 mb-3"
                  type="submit"
                  disabled={usmg.role_added.loading}
                >
                  {usmg.role_added.loading && (
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {usmg.role_added.loading && (
                    <span className="text-capitalize">Please wait</span>
                  )}
                  {!usmg.role_added.loading && (
                    <span className="text-capitalize"> Add Role</span>
                  )}
                </button>
                </Form>
              
         
        )}
      </Formik>
    )
}

export default AddRoleForm

import React from 'react';
import Fees from './Fees';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const FeesContainer = () => {
	async function _fees() {
		let resp = await (await api_srv).list_fees();
		console.log('THE FEES', resp.entity_fees);
		return resp.entity_fees;
	}
	const { data, isLoading, isSuccess, refetch } = useQuery('list fees', _fees);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Fees data={data} refetch={refetch} />}
		</div>
	);
};

export default FeesContainer;

export const calculateTaxForm = {
  form_title: "",
  form_subtitle: "",
  button_label: "Calculate",
  button_styles: "block btn-blue",
  fields: [
    {
      key: "amount",
      label: "Enter Amount",
      name: "amount",
    },
    {
      key: "inclusive",
      label: "Is Tax Inclusive",
      name: "inclusive",
      type: "radio",
      options: [
        {
          key: "true",
          label: "Yes",
          value: true,
        },
        {
          key: "false",
          label: "No",
          value: false,
        },
      ],
    },
  ],
};

import React from 'react';
import Settlement from './Settlement';
import { listSettlement } from 'services/apiSrv';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';
import ServerError from 'pages/errors/500';

const SettlementContainer = () => {
	const settlements = async () => {
		let resp = await listSettlement();
		return resp.settlements;
	};

	const { data, isSuccess, isError, isLoading } = useQuery('list settlements', settlements);

	return (
		<div>
			{isLoading && <Loader />}
			{isError && <ServerError />}
			{isSuccess && <Settlement data={data} />}
		</div>
	);
};

export default SettlementContainer;

import React from "react";
import Navbar from "components/dashboard-nav";
import Sidebar from "components/dashboard-sidebar";
import { Input } from "antd";
import * as Icon from "react-feather";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../components/widgets/loader";
import Moment from "react-moment";
import NoData from '../../components/no-data/NoData'

export default function Claims({
  state,
  view_claim_details,
  search,
  paginate,
  setPaginate,
  numEachPage,
  handlePaginationChange,
}) {
  const { Search } = Input;

  // function searchMe(evt){
  //   evt.preventDefault();

  //   if (evt.target.value){
  //     let filteredItems = state.claims.claims.filter((item)=>{

  //   return(
  //     // item.id.toLowerCase().includes(search.toLowerCase()) ||
  //     item.names.toLowerCase().includes(search.toLowerCase())||
  //     item.claim_type.toLowerCase().includes(search.toLowerCase()) ||
  //     item.created_date.toLowerCase().includes(search.toLowerCase())||
  //     item.row.claim_decision.toLowerCase().includes(search.toLowerCase())
  //     // item.id &&    item.name &&
  //     // Item.date  &&    item.status
  //     // .match(search)

  //   )
  //     })
  //   setSearch(evt.target.value)
  //   setAllClaims({...state,claims:filteredItems})

  //   }else{
  //     setSearch("")
  //     setAllClaims({...state,claims:state.claims.claims})
  //   }
  // }


  
  return (
    <>
    
              <div className="pageheader pd-t-25 pd-b-35">
                {/* <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20"><Icon.Shield  size={36} className="pr-2 text-primary" />Claims</h1>
                </div> */}
                <div className="breadcrumb pd-0 mg-0">
                  <a className="breadcrumb-item" href="index.html">
                    <i className="icon ion-ios-home-outline" /> Home
                  </a>
                  <a className="breadcrumb-item active" href>
                    Dashboard
                  </a>
                </div>
              </div>
              <div className="col-lg-12 card rounded-10 col-xl-12">
             
<h1>Tenders page</h1>
            </div>
       
    </>
  );
}

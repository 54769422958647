import React,{useContext, useRef} from "react";
import "assets/css/dashboard.css";
import bg from "assets/images/bg/bg-7.jpg";
import { Link } from "react-router-dom";
import Alert from "components/alerts/WarningAlert";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {AlertsContext} from 'context/AlertsContext'
import TnC from 'assets/docs/Nexus General Terms and Conditions 27.7.2020.pdf'
import logo from "assets/images/logos/Logo_1.png";

const Reset = ({ state, handleChange, resetPassword, alert}) => {

  const {showAlert} = useContext(AlertsContext);
  const { loading, username } = state;
  const { message, variant } = alert;

  // Category Options


  return (

    <Formik
    enableReinitialize
    initialValues={state}
    validationSchema={Yup.object().shape({
      username: Yup.string()
        .required("Username is required"),
  
    })}
    onSubmit={() => resetPassword()}
  >
    {({ errors, status, touched }) => (
      <div className="d-md-flex d-sm-block">
        <div className="m-auto">
          <img
            src={logo}
            alt="Logo"
            className="d-block d-md-none wd-100 ht-100"
          />
        </div>

        <div
          className="col-md-12 col-sm-12  "
          id="reset-background"
        >
          <div
          style={{
            minHeight:"100%",
             paddingBottom:"40px",
             marginBottom:"-40px"
          }}
          >
          <div className="container">
            <Link to="/" className="">
            <img
                  src={logo}
                  style={{
                    height: "30px",
                    width: "auto",
                    position:"absolute",
                    top:"20px"
                  }}
                  alt="Logo"
                />
            </Link>
            <div
              className="float-left"
              style={{
                position: "absolute",
                top: "40%",
              }}
            >
           
              <h1 className="text-white">
              Can't Remember your Password?
              </h1>
              <h6 className="text-white d-block">
              We can help you reset your password
              </h6>
              <p className="text-white pb-3">
               Enter your account username and an email with a reset token will be sent to your email 
              </p>
            </div>
            <div
              className=""
              style={{
                position: "absolute",
                right: "30px",
                bottom: "0",
              }}
            >
              <a
                href={TnC}
                rel="noopener noreferrer"
                target="_blank"
                className="text-white"
              >
                Terms and conditions |
              </a>
              <Link to="#" className=" text-white">
                {" "}
                &copy; {new Date().getFullYear()} Nexus
              </Link>
            </div>
        
          <div
            className="card"
            style={{
              position: "absolute",
             top: "20%",
             right: "10%",
             borderRadius: "15px 30px",
             maxWidth:"450px"
           }}
          >
            <div className="card-body p-5">
              <h3 className="mb-5 custom-text-primary">Reset Password</h3>
              {showAlert && <Alert message={message} variant={variant} />}
              <Form className="login-form clearfix">
                <div className="form-group mb-4">
                <label htmlFor="username">Enter Username</label>
                    <input
                      name="username"
                      type="text"
                      value={username}
                      onChange={handleChange}
                      className={
                        "form-control border" +
                        (errors.username && touched.username ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="invalid-feedback"
                    />
                </div>
           
             
                <button
                  type="submit"
                  disabled={loading}
                  className="btn mt-3 px-5 btn-login btn-block"
                >
                 {loading && (
                      <i
                        className="fa fa-circle-notch fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && (
                      <span className="text-capitalize">Please wait</span>
                    )}
                    {!loading && (
                      <span className="text-capitalize"> Reset</span>
                    )}
                  </button>
              </Form>
            
              <p className="pt-3 text-right">
                  Go back to   <Link to="/login" className="custom-text-primary">
                      <small>Login</small>
                    </Link>
                </p>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    )}
  </Formik>

  );
};
export default Reset;

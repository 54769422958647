import React, { useContext } from "react";

import * as Icon from "react-feather";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { ImHourGlass, ImCreditCard } from "react-icons/im";

import routes from "routes";
import styles from "./Sidebar.module.css";
import "../../assets/css/dashboard.css";
import { DashboardContext } from "context/DashboardContext";

export default function Sidebar_Inner() {
  const { SubMenu } = Menu;
  const { isToggled } = useContext(DashboardContext);

  return (
    <div className="">
      <div className={styles.logoWrapper}>
        {!isToggled && <div className={styles.logo} />}
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[]}
        class={styles.menu}
      >
        <Menu.Item key="1" icon={<Icon.Home size={19} />}>
          <NavLink to={routes.dashboard}>Dashboard</NavLink>
        </Menu.Item>
        <Menu.Item key="2" icon={<Icon.Users size={19} />}>
          <NavLink to={routes.policies}>Managed Policies</NavLink>
        </Menu.Item>
        <Menu.Item key="3" icon={<Icon.Briefcase size={19} />}>
          <NavLink to={routes.underwriters}>My Underwriters</NavLink>
        </Menu.Item>
        <Menu.Item key="4" icon={<Icon.Award size={19} />}>
          <NavLink to={routes.profession}>My Professions</NavLink>
        </Menu.Item>
        <Menu.Item key="5" icon={<Icon.Calendar size={19} />}>
          <NavLink to={routes.appointments}>Appointments</NavLink>
        </Menu.Item>
        <Menu.Item key="6" icon={<Icon.ThumbsUp size={19} />}>
          <NavLink to={routes.reviews}>Reviews</NavLink>
        </Menu.Item>
        <Menu.Item key="7" icon={<Icon.Globe size={19} />}>
          <NavLink to={routes.domains}>My Domains</NavLink>
        </Menu.Item>
        <Menu.Item key="8" icon={<Icon.Users size={19} />}>
          <NavLink to={routes.openLeads}>Open Leads</NavLink>
        </Menu.Item>
        <Menu.Item key="35" icon={<Icon.Users size={19} />}>
          <NavLink to={routes.schemes}>Scheme Management</NavLink>
        </Menu.Item>

        <SubMenu
          key="accountmgt"
          icon={<ImCreditCard size={19} />}
          title="Account Management"
        >
          <Menu.Item key="9">
            <NavLink to={routes.wallet}>Wallet</NavLink>
          </Menu.Item>
          <Menu.Item key="10">
            <NavLink to={routes.tax}>Entity Tax</NavLink>
          </Menu.Item>
          <Menu.Item key="11">
            <NavLink to={routes.fees}>Entity Fees</NavLink>
          </Menu.Item>

          <SubMenu
            key="settlement"
            icon={<ImCreditCard size={19} />}
            title="Settlement"
          >
            <Menu.Item key="12">
              <NavLink to={routes.settlement}> Settlements</NavLink>
            </Menu.Item>
            <Menu.Item key="13">
              <NavLink to={routes.channels}> Settlement Channel</NavLink>
            </Menu.Item>
            <Menu.Item key="14">
              <NavLink to={routes.settlementConfig}>Settlement Config</NavLink>
            </Menu.Item>
          </SubMenu>
        </SubMenu>
        <SubMenu
          key="usermgt"
          icon={<Icon.UserPlus size={19} />}
          title="User Management"
        >
          <Menu.Item key="15">
            <NavLink to={routes.usmgDepartments}> Departments</NavLink>
          </Menu.Item>
          <Menu.Item key="16">
            <NavLink to={routes.usmgRoles}> Roles</NavLink>
          </Menu.Item>
          <Menu.Item key="17">
            <NavLink to={routes.usmgUsers}>Users</NavLink>
          </Menu.Item>
          <Menu.Item key="18">
            <NavLink to={routes.usmgUserGroup}>User Group</NavLink>
          </Menu.Item>
          <Menu.Item key="19">
            <NavLink to={routes.usmgChatGroups}>Chat Group</NavLink>
          </Menu.Item>
          <Menu.Item key="20">
            <NavLink to={routes.usmgSecurity}> Security Context</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="marketing"
          icon={<Icon.UserPlus size={19} />}
          title="Marketing"
        >
          <Menu.Item key="21">
            <NavLink to={routes.rm}> Relationship Manager</NavLink>
          </Menu.Item>
          <Menu.Item key="22">
            <NavLink to={routes.salesrep}> Sales Representative</NavLink>
          </Menu.Item>
          <Menu.Item key="23">
            <NavLink to={routes.leads}> Leads</NavLink>
          </Menu.Item>
          <Menu.Item key="24">
            <NavLink to={routes.leads}> Promotions</NavLink>
          </Menu.Item>
          <Menu.Item key="25">
            <NavLink to={routes.leads}> Advertisements</NavLink>
          </Menu.Item>
          <Menu.Item key="26">
            <NavLink to={routes.incentives}>Incentives</NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="27" icon={<Icon.ThumbsUp size={19} />}>
          <NavLink to={routes.quotations1}>Quotations</NavLink>
        </Menu.Item>
        {/* <Menu.Item key="28" icon={<Icon.ThumbsUp size={19} />}>
					<NavLink to={routes.quotationRequests1}>Quotation Requests</NavLink>
				</Menu.Item> */}
        <Menu.Item key="29" icon={<Icon.FileText size={19} />}>
          <NavLink to={routes.quotations}>Quotations (New)</NavLink>
        </Menu.Item>
        <Menu.Item key="30" icon={<Icon.Briefcase size={19} />}>
          <NavLink to={routes.quotationRequests}>Quotation Requests </NavLink>
        </Menu.Item>
        <Menu.Item key="31" icon={<Icon.Shield size={19} />}>
          <NavLink to={routes.claims}>Claims</NavLink>
        </Menu.Item>
        <Menu.Item key="32" icon={<ImHourGlass size={19} />}>
          <NavLink to={routes.claimsPending}>Claims Pending Approval</NavLink>
        </Menu.Item>
        <Menu.Item key="33" icon={<Icon.MessageSquare size={19} />}>
          <NavLink to={routes.chat}>Chats</NavLink>
        </Menu.Item>
        <Menu.Item key="34" icon={<Icon.Info size={19} />}>
          <NavLink to="">Terms and Conditions</NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}

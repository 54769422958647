import React, { useContext, useEffect } from "react";
import VerifyEmail from ".";
import { AuthContext } from "../../context/AuthContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { api_srv } from "../../services";
import routes from 'routes'

const VerifyEmailContainer = (props) => {
  const {
    confirmimgToken,
    registrationId,
    setConfirmingToken,
    setRegistrationId,
  } = useContext(AuthContext);

  const { token } = queryString.parse(props.location.search);
  let history = useHistory();

  const verify_token = async (token) => {
    setConfirmingToken(true);
    try {
      let resp = await (await api_srv).verify(token);
      await setRegistrationId(resp.registration_setup_id);
      localStorage.setItem("REGISTRATION_ID", btoa(resp.registration_setup_id));
    

      setConfirmingToken(false);
    } catch (err) {
      let error = await err;
      setConfirmingToken(false);
      history.push(routes.signup);
      // addToast(error.message, {
      //   appearance: "error",
      //   autoDismiss: true,
      // });

    }
  };

  useEffect(() => {
    verify_token(token);
  }, []);

  return (
    <VerifyEmail
      registrationId={registrationId}
      confirmimgToken={confirmimgToken}
    />
  );
};

export default VerifyEmailContainer;

import React,{useEffect, useState} from 'react'
import ViewUser from './ViewUser'
import {api_srv} from 'services'

const ViewUserContainer = (props) => {


const {id}= props.match.params

const [details, setDetails] = useState({
    data:{},
    loading: false
})

    const user_details=async()=>{
        setDetails({...details,loading:true})
        try{
        
            let resp = await (await api_srv).view_user(id)
            setDetails({...details,data:resp.user_details})

        }catch(err){
            let error = await err
            setDetails({...details,loading:false})
            console.log("error from fetching user details", error)
        }
       
    }
useEffect(()=>{
    user_details()
},[])

    return (
        <div>
            <ViewUser
            state={details}
            />
        </div>
    )
}

export default ViewUserContainer

import React, { useState } from "react";

import * as Icon from "react-feather";
import { Select } from "antd";

import Loader from "components/widgets/loader/ComponentLoader";
import { convertCurrency } from "utils/helpers";

export default function Index({ isSuccess, isLoading, isIdle, transactions }) {
  const { Option } = Select;
  const [filter, setFilter] = useState("ALL");
  const [filterItems, setFilteredItems] = useState([]);

  const handleSelect = (value) => {
    console.log(`"select checked",${value}`);
    const newFilterState = transactions.filter(
      (option) => option.tran_status === value
    );
    setFilter(value);
    setFilteredItems(newFilterState);
  };

  return (
    <div className="col-xl-4">
      <div className="card mg-b-20">
        <div className="card-header">
          <h4 className="card-header-title">Transaction History</h4>
          <div className="form-group mt-3 ">
            <Select
              defaultValue="all"
              style={{ width: 120, display: "block" }}
              onChange={handleSelect}
              value={filter}
            >
              <Option value="ALL">All</Option>
              <Option value="COMPLETED">Completed</Option>
              <Option value="PENDING">Pending</Option>
              <Option value="DECLINED">Declined</Option>
            </Select>
          </div>
        </div>

        {isLoading && <Loader />}
        {isSuccess && (
          <div className="collapse show" id="transactionHistory">
            <ul className="list-group list-group-flush tx-13">
              {filter === "ALL"
                ? transactions.map((transaction) => {
                    return (
                      <li className="list-group-item d-flex pd-sm-x-20">
                        <div className="d-none d-sm-block">
                          <span
                            className={`wd-40 ht-40 mg-r-10 d-flex align-items-center justify-content-center rounded ${
                              transaction.tran_status === "COMPLETED"
                                ? "card-icon-success"
                                : transaction.tran_status === "PENDING"
                                ? "card-icon-warning"
                                : "card-icon-danger"
                            }`}
                          >
                            {transaction.tran_status === "COMPLETED" ? (
                              <Icon.Check size={18} />
                            ) : transaction.tran_status === "PENDING" ? (
                              <Icon.Activity size={18} />
                            ) : (
                              <Icon.X size={18} />
                            )}
                          </span>
                        </div>
                        <div className="pd-sm-l-10">
                          <p className="tx-dark mg-b-0">
                            {transaction.remarks}{" "}
                            <a href className="tx-dark mg-b-0 tx-semibold">
                              {transaction.name}
                            </a>
                          </p>
                          <span className="tx-12 mg-b-0 tx-gray-500">
                            A/c Name: {transaction.account_name}
                          </span>
                          <span className="tx-12 mg-b-0 tx-gray-500 d-block">
                            A/c Number: {transaction.account_number}
                          </span>
                        </div>
                        <div className="mg-l-auto text-right">
                          <p className="mg-b-0 tx-rubik tx-dark">
                            {convertCurrency(
                              transaction.tran_amount,
                              transaction.currency_code
                            )}
                          </p>
                          <span
                            className={`tx-12 mg-b-0 text-capitalize ${
                              transaction.tran_status === "COMPLETED"
                                ? "tx-success"
                                : transaction.tran_status === "PENDING"
                                ? " tx-warning"
                                : " tx-danger"
                            }`}
                          >
                            {transaction.tran_status}
                          </span>
                        </div>
                      </li>
                    );
                  })
                : filterItems.map((transaction) => {
                    return (
                      <li className="list-group-item d-flex pd-sm-x-20">
                        <div className="d-none d-sm-block">
                          <span
                            className={`wd-40 ht-40 mg-r-10 d-flex align-items-center justify-content-center rounded ${
                              transaction.tran_status === "COMPLETED"
                                ? "card-icon-success"
                                : transaction.tran_status === "PENDING"
                                ? "card-icon-warning"
                                : "card-icon-danger"
                            }`}
                          >
                            {transaction.tran_status === "COMPLETED" ? (
                              <Icon.Check size={18} />
                            ) : transaction.tran_status === "PENDING" ? (
                              <Icon.Activity size={18} />
                            ) : (
                              <Icon.X size={18} />
                            )}
                          </span>
                        </div>
                        <div className="pd-sm-l-10">
                          <p className="tx-dark mg-b-0">
                            {transaction.remarks}{" "}
                            <a href className="tx-dark mg-b-0 tx-semibold">
                              {transaction.name}
                            </a>
                          </p>
                          <span className="tx-12 mg-b-0 tx-gray-500">
                            A/c Name: {transaction.account_name}
                          </span>
                          <span className="tx-12 mg-b-0 tx-gray-500 d-block">
                            A/c Number: {transaction.account_number}
                          </span>
                        </div>
                        <div className="mg-l-auto text-right">
                          <p className="mg-b-0 tx-rubik tx-dark">
                            KES.{transaction.tran_amount}
                          </p>
                          <span
                            className={`tx-12 mg-b-0 text-capitalize ${
                              transaction.tran_status === "COMPLETED"
                                ? "tx-success"
                                : transaction.tran_status === "PENDING"
                                ? " tx-warning"
                                : " tx-danger"
                            }`}
                          >
                            {transaction.tran_status}
                          </span>
                        </div>
                      </li>
                    );
                  })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ComponentLoader = () => {
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	return (
		<div className="d-flex justify-content-center align-items-center">
			<Spin indicator={antIcon} />
		</div>
	);
};

export default ComponentLoader;

import React, { useState, createContext } from 'react';
import { api_srv } from '../services';
import { message } from 'antd';

const KycContext = createContext();
const KycContextProvider = (props) => {
	// ACCESS CONTEXT

	// STATES

	const [current, setCurrent] = useState(0);

	const [setInfo] = useState();

	const [kyc, setKyc] = useState({
		individualInfo: {
			intermediary_fname: '',
			intermediary_lname: '',
			link_business: '',
			email: '',
			contact: '',
			id_number: '',
			kra_pin: '',
			address: '',
			postal_code: '',
			description: '',
		},
		companyInfo: {
			intermediary_names: '',
			email: '',
			contact: '',
			kra_pin: '',
			address: '',
			postal_code: '',
			description: '',
		},
		directors: [
			{
				title: '',
				firstName: '',
				lastName: '',
				email: '',
				residency: '',
				type: '',
				contact: '',
				postal_code: '',
				address: '',
			},
		],
		partners: [
			{
				title: '',
				firstName: '',
				lastName: '',
				email: '',
				residency: '',
				type: '',
				contact: '',
				postal_code: '',
				address: '',
			},
		],
		intermediary: '',
		isPartnerAvailable: '',
	});

	const [tempPartners, setTempPartners] = useState([...kyc.partners]);

	const [agent, setAgent] = useState({
		agent_type: '',
		individual_type: '',
	});

	const [individualAgent, setIndividualAgent] = useState({
		individual_id: [],
		individual_kra: [],
		individual_ira: [],
	});

	const [image, setImage] = useState([]);
	const [logo, setLogo] = useState({
		logo: [],
		loading: false,
	});

	const [documents, setDocuments] = useState({
		kra_pin: [],
		cr_12: [],
		incorporation_certificate: [],
		memorandum_of_association: [],
		mou: [],
		licence_certificate: [],
	});

	// METHODS/FUNCTIONS

	function next() {
		const current_step = current + 1;
		setCurrent(current_step);
	}

	function prev() {
		const current_step = current - 1;
		setCurrent(current_step);
	}

	async function fetch_intermediary_details() {
		try {
			let resp = await (await api_srv).get_intermediary_type();

			setInfo(resp);
		} catch (err) {
			let error = await err;
		}
	}

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Image must be smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	}

	const handleRemoveRow = (index) => (e) => {
		e.preventDefault();
		const rows = [...kyc.directors];
		rows.splice(index, 1);
		setKyc({ ...kyc, directors: rows });
	};

	const handleCompanyKraChange = (info) => {
		let fileList = [...info.fileList];
		// 1. Limit the number of uploaded files to one

		fileList = fileList.slice(-1);

		// 2. Read from response and show file link
		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, kra_pin: fileList });
	};

	const handleCompanyCR_12Change = (info) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-1);

		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, cr_12: fileList });
	};

	const handleCompanyInc_CertChange = (info) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-1);

		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, incorporation_certificate: fileList });
	};

	const handleCompanyMem_Association = (info) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-1);

		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, memorandum_of_association: fileList });
	};

	const handleCompanyLic_CertChange = (info) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-1);

		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, licence_certificate: fileList });
	};

	const handleCompanyMoUChange = (info) => {
		let fileList = [...info.fileList];

		fileList = fileList.slice(-1);

		fileList = fileList.map((file) => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		setDocuments({ ...documents, mou: fileList });
	};

	// **********Start Company Agents Uploads************

	const uploadCompanyKra = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company KRA', file);
			// const res = await ();
			onSuccess('Ok');
			console.log('server res: ', res);
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, kra_pin: [] });
		}
	};

	const uploadCompanyCR_12 = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company CR12', file);
			// const res = await ();
			console.log('server res: ', res);
			onSuccess('Ok');
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, cr_12: [] });
		}
	};

	const uploadCompanyInc_Cert = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company Incorporation Cert ', file);
			// const res = await ();
			console.log('server res: ', res);
			onSuccess('Ok');
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, incorporation_certificate: [] });
		}
	};

	const uploadCompanyMem_Association = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company Memorandum', file);
			// const res = await ();
			console.log('server res: ', res);
			onSuccess('Ok');
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, memorandum_of_association: [] });
		}
	};

	const uploadCompanyLic_Cert = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company License', file);
			// const res = await ();
			onSuccess('Ok');
			console.log('server res: ', res);
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, licence_certificate: [] });
		}
	};

	const uploadCompanyMou = async (options) => {
		const { onSuccess, onError, file } = options;

		try {
			let res = await (await api_srv).upload_api('kyc', 'Company MoU', file);
			// const res = await ();
			console.log('server res: ', res);
			onSuccess('Ok');
			message.success(`${file.name}'upload successfully.`);
		} catch (err) {
			onError({ err });
			message.error(`${file.name} upload failed.`);
			setDocuments({ ...documents, mou: [] });
		}
	};

	// **********End Company Agents Uploads************

	return (
		<KycContext.Provider
			value={{
				setKyc,
				kyc,
				image,
				setImage,
				documents,
				setDocuments,
				next,
				prev,
				beforeUpload,
				logo,
				setLogo,
				agent,
				setAgent,
				individualAgent,
				setIndividualAgent,
				current,
				setCurrent,
				handleRemoveRow,
				fetch_intermediary_details,
				handleCompanyKraChange,
				handleCompanyCR_12Change,
				handleCompanyInc_CertChange,
				handleCompanyMoUChange,
				handleCompanyLic_CertChange,
				handleCompanyMem_Association,
				uploadCompanyKra,
				uploadCompanyCR_12,
				uploadCompanyInc_Cert,
				uploadCompanyMem_Association,
				uploadCompanyLic_Cert,
				uploadCompanyMou,
				tempPartners,
				setTempPartners,
			}}
		>
			{props.children}
		</KycContext.Provider>
	);
};

const KycConsumer = KycContext.Consumer;
export { KycContextProvider, KycConsumer, KycContext };

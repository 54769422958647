import React, { useState } from "react";

import { Button, Statistic, Pagination } from "antd";
import { Tab, Nav } from "react-bootstrap";
import * as Icon from "react-feather";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";

import NoData from "../../components/no-data/NoData";
import RecentTransactions from "components/recent_transactions";
import Loader from "../../components/widgets/loader";
import routes from "routes";
import styles from "./Wallet.module.css";

export default function Wallet({
  wallet,
  accountTrans,
  view_account_transactions,
}) {
  console.log("TRANSACTIONS", accountTrans);
  const data = {
    labels: ["Income", "Expenses"],
    datasets: [
      {
        data: [300, 100],
        backgroundColor: ["rgba(40,167,69,.70)", "rgba(220,53,69,.70)"],
        hoverBackgroundColor: ["rgba(40,167,69,.70)", "rgba(220,53,69,.70)"],
      },
    ],
  };
  let cards = [
    {
      type: "visa",
      number: "**** **** **** 2562",
      expiration: "12/17",
      transactions: [
        {
          name: "Apple iPhone 6, 6g GB",
          type: "debit",
          category: "Electronics",
          ID: "#343223",
          date: "12 July, 2016",
          amount: "+650.00",
        },
        {
          name: "Funds Added",
          type: "credit",
          category: "Payment",
          ID: "#343212",
          date: "11 July, 2016",
          amount: "-900.00",
        },
        {
          name: "Energy Bill",
          type: "debit",
          category: "",
          ID: "#343566",
          date: "11 July, 2016",
          amount: "+84.96",
        },
        {
          name: "Mega Image SRL",
          type: "debit",
          category: "Food&Health",
          ID: "#343565",
          date: "11 July, 2016",
          amount: "+112.75",
        },
        {
          name: "ATM DV24",
          type: "debit",
          category: "",
          ID: "#343253",
          date: "09 July, 2016",
          amount: "+200.00",
        },
        {
          name: "Lukoil Station",
          type: "debit",
          category: "Gas",
          ID: "#343279",
          date: "09 July, 2016",
          amount: "+190.48",
        },
        {
          name: "Funds Added",
          type: "credit",
          category: "Payment",
          ID: "#343212",
          date: "11 July, 2016",
          amount: "+390.81",
        },
      ],
    },
    {
      type: "amex",
      number: "**** ****** 21001",
      expiration: "07/19",
      transactions: [
        {
          name: "Funds Added",
          type: "credit",
          category: "Payment",
          ID: "#654324",
          date: "23 Dec, 2017",
          amount: "-200.00",
        },
        {
          name: "Uber",
          type: "debit",
          category: "Technology",
          ID: "#654323",
          date: "1 January, 2017",
          amount: "+80.00",
        },
        {
          name: "Firehouse Subs",
          type: "debit",
          category: "Food",
          ID: "#654322",
          date: "11 December, 2016",
          amount: "+32.17",
        },
        {
          name: "Banana Republic",
          type: "debit",
          category: "Retail",
          ID: "#654321",
          date: "11 December, 2016",
          amount: "+147.46",
        },
      ],
    },
    {
      type: "mc",
      number: "**** **** **** 8335",
      expiration: "09/17",
      transactions: [
        {
          name: "Sam Ash Music",
          type: "debit",
          category: "Retail",
          ID: "#654324",
          date: "15 Jan, 2017",
          amount: "+400.00",
        },
        {
          name: "Apple Store",
          type: "debit",
          category: "Technology",
          ID: "#654323",
          date: "3 January, 2017",
          amount: "+60.00",
        },
        {
          name: "LEGO Store",
          type: "debit",
          category: "Retail",
          ID: "#654322",
          date: "20 December, 2016",
          amount: "150.00",
        },
        {
          name: "ATT Wireless",
          type: "debit",
          category: "Mobile",
          ID: "#654321",
          date: "5 December, 2016",
          amount: "+109.54",
        },
        {
          name: "Funds Added",
          type: "credit",
          category: "Payment",
          ID: "#654324",
          date: "23 Dec, 2017",
          amount: "-100.00",
        },
      ],
    },
  ];
  let pageSize = 3;

  const [paginationData, setPaginationData] = useState({
    totalPage: wallet.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });

  const handlePaginationChange = (page) => {
    setPaginationData({
      ...paginationData,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  var cardsList = document.querySelectorAll(`.${styles["credit-card"]}`);
  //Grab single card
  var creditCard = document.querySelector(".credit-card");
  //Grab activeCard
  var activeCard = document.querySelector(`.${styles["credit-card"].active}`);
  //Grab transaction
  //var transaction = document.querySelector(`.${styles.transactions}`);
  //console.log("TRANSACTION TUMEWEZA", transaction);

  // //add class show to transaction div for animation
  // transaction.classList.add("show");

  const handleClick = (e) => {
    //Toggle Active class on Cards and show class on transactions

    e.preventDefault();
    for (let n = 0; n < cardsList.length; n++) {
      if (cardsList[n].classList.contains("active")) {
        cardsList[n].classList.remove("active");
      }
    }

    let n = e.currentTarget;
    n.classList.add("active");
    showTransactions(cardsList, n);

    console.log("WHAT US E", e.currentTarget);

    //transaction.classList.remove("show");

   // void transaction.offsetWidth;

   // transaction.classList.add("show");
  };

  function showTransactions(creditCards = [], card = {}) {
    console.log("CREDIT CARDS ", creditCards);
    console.log("ACTIVE CARDS ", card);
    var total = 0;
    var html;

    for (var i = 0; i < creditCards.length; i++) {
      if (creditCards[i] === card) {
        console.log("CHECK IF TRANS MAKES SENSE", cards[i].transactions);

        for (var n = 0; n < cards[i].transactions.length; n++) {
          console.log("HII NI UKWELI", cards[i].transactions[n].name);
          return (
            <div
              className={`${styles["transaction-item"]} ${cards[i].transactions[n].type}`}
            >
              <div className={styles["transaction-item_details"]}>
                <h3>{cards[i].transactions[n].name}</h3>
                <span className={styles.details}>
                  {cards[i].transactions[n].category}
                  {cards[i].transactions[n].ID} -{cards[i].transactions[n].date}
                </span>
              </div>
              <div className={styles["transaction-item_amount"]}>
                <span>KES</span>
                <p className={styles["amount"]}>
                  {cards[i].transactions[n].amount}
                </p>
              </div>
            </div>
          );
        }
      }
      break;
    }
  }
  return (
    <>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item active" to="#">
            <i className="icon ion-ios-home-outline " /> Account
          </Link>
          <Link className="breadcrumb-item ">Wallet</Link>
        </div>
        <h5>My Accounts</h5>
      </div>

      {/* test here */}
      <div>
        <div className={styles.wrapper}>
          <div className={styles["app-wrapper"]}>
            {/*Wallet*/}
            <aside className={styles.wallet}>
              <h2>My Wallets</h2>
              <div className={styles.cards}>
                {wallet.map(
                  (card, i) =>
                    i >= paginationData.minIndex &&
                    i < paginationData.maxIndex && (
                      <div
                        class={`${styles["credit-card"]} ${card.type}`}
                        key={i}
                        onClick={handleClick}
                      >
                        <div class={styles["card-image"]}></div>
                        <div class={styles["credit-card_number"]}>
                          {" "}
                          {card.account_name}{" "}
                        </div>
                        <div class={styles["credit-card_expiration"]}>
                          {card.account_number}
                        </div>
                      </div>
                    )
                )}
              </div>
              <Pagination
                size="small"
                responsive={true}
                dataSource={wallet}
                total={wallet.length}
                pageSize={pageSize}
                defaultCurrent={1}
                onChange={handlePaginationChange}
                current={paginationData.current}
              />
            </aside>
            <content className={styles["transactions-wrapper"]}>
              <h2>
                Current Balance
                <span className={styles["total-balance"]} />
              </h2>
              <div className={styles.transactions}>{showTransactions()}</div>
            </content>
          </div>
        </div>
      </div>

      {/* test ends here********************************************************************** */}

      {wallet.length === 0 ? (
        <>
          <NoData message="You dont have any accounts yet" />
        </>
      ) : (
        <div className="row">
          <Tab.Container defaultActiveKey={0}>
            <div className="col-md-5">
              <div className="card rounded-10">
                <div className="card-body">
                  <Tab.Content>
                    <div className="row">
                      <div className="col-md-8">
                        <Statistic
                          // title={`Account Balance (${account.account_type})`}
                          // value={account.available_balance}
                          precision={2}
                        />
                        <Button
                          style={{ marginTop: 16 }}
                          type="primary"
                          className="pl-2 pr-3 rounded-5"
                        >
                          <Icon.Send size={15} className="mr-2" /> Send
                        </Button>
                      </div>
                      <div className="col-md-4 bg-light border-left">
                        <div>
                          <small className="pt-2">Income</small>
                          <div className="d-none d-sm-block">
                            <span className="wd-20 ht-20 mg-r-10 d-flex align-items-center justify-content-center rounded card-icon-success">
                              <Icon.ArrowUpRight size={18} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Content>
                </div>
              </div>
              <div className="card rounded-10 mt-md-5">
                <div className="card-body">
                  <Tab.Content>
                    <div className="row">
                      <div className="col-md-4">
                        <p>Account Summary</p>
                        <Button
                          style={{ marginTop: 16 }}
                          type="primary"
                          className="rounded-5"
                        >
                          view
                        </Button>
                      </div>
                      <div className="col-md-8">
                        <Doughnut
                          data={data}
                          width={200}
                          height={150}
                          options={{ maintainAspectRatio: false }}
                        />
                      </div>
                    </div>
                  </Tab.Content>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card rounded-10 mg-b-20">
                <div className="card-header">
                  <h4 className="card-header-title tx-16-force">My Accounts</h4>
                </div>
                <div className="collapse show" id="transactionsHistory">
                  <div className="card-body pd-x-0 pd-y-0">
                    <Nav className="m-0">
                      <ul className="list-group list-group-flush w-100">
                        {wallet.map((account, index) => {
                          return (
                            <Nav.Item>
                              <Nav.Link
                                eventKey={index}
                                key={index}
                                id="account-list"
                                onSelect={() =>
                                  view_account_transactions(
                                    account.account_number
                                  )
                                }
                              >
                                <li className="list-group-item d-flex pd-y-10 pd-sm-x-15 ">
                                  <div className="d-none d-sm-block">
                                    <span className="wd-45 ht-45 d-flex align-items-center justify-content-center rounded card-icon-warning">
                                      <Icon.Layers className="cf cf-btc tx-16 tx-warning" />
                                    </span>
                                  </div>
                                  <div className="pd-sm-l-10">
                                    <p className="tx-dark mg-b-0">
                                      {account.account_name} -{" "}
                                      {account.account_type}
                                    </p>
                                    <span className="tx-12 mg-b-0 tx-gray-500">
                                      Account Number: {account.account_number}
                                    </span>
                                  </div>
                                  <div className="mg-l-auto text-right">
                                    <p className="tx-dark mg-b-0">
                                      Bal:{account.currency_code}{" "}
                                      {account.available_balance}
                                    </p>
                                  </div>
                                </li>
                              </Nav.Link>
                            </Nav.Item>
                          );
                        })}
                      </ul>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 px-md-0 mt-md-5">
              <Tab.Content>
                <RecentTransactions />
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      )}
    </>
  );
}

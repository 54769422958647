import React from 'react';
import { Modal } from 'react-bootstrap';
import 'assets/css/dashboard.css';
import Video from 'components/videoChat/VideoChat';

const VideoChat = (props) => {
	return (
		<div>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body style={{ padding: '0px' }}>
					<Video />
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</div>
	);
};

export default VideoChat;

import React from "react";
import { Form, Divider, Avatar, Drawer, Button } from "antd";
import * as Icon from "react-feather";
import Moment from "react-moment";
import Loader from 'components/widgets/loader'
import _ from 'lodash'

const ViewUser = ({state}) => {
 

return(
  <div>
{state.loading && _.isEmpty(state.data) ?<Loader/>:
<>
{console.log("state here", state.data)}
            <div className="pageheader pd-t-25 ">
             
              <div className="breadcrumb pd-0 mg-0">
                <a className="breadcrumb-item" href="index.html">
                  <i className="icon ion-ios-home-outline" /> Home
                </a>
                <a className="breadcrumb-item active" href>
                  Claims
                </a>
                <a className="breadcrumb-item active" href>
                  View
                </a>
              </div>
            </div>
           
              <div className="card mg-t-45">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3 ">
                      
                      <div className="d-flex flex-column justify-content-center align-items-center pd-t-10 pd-x-15 flex-wrap">
                        <Avatar size={94} src={`https://api.nexus.ke${state.data.profile_photo_path}`} className="mb-3" />
                        <p>
                          <Icon.User size={18} className="mr-2" />
                          {state.data.names}
                        </p>
                        <p>
                          <Icon.Mail size={18} className="mr-2" />
                          {state.data.email}
                        </p>
                        <p>
                          <Icon.Phone size={18} className="mr-2" />
                          {state.data.contact}
                        </p>
                        <div className="pt-md-3 text-center">
                          <button
                            type="submit"
                            className="btn btn-sm btn-outline-primary mg-y-15 mr-2 wd-md-150"
                            // onClick={showDrawer}
                          >
                            <Icon.Lock size={17} />
                            <span className="pl-1">Reset Password</span>
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm btn-outline-blue mg-b-15 wd-md-150"
                            // onClick={showDrawer}
                          >
                            <Icon.Shield size={17} />
                            <span className="pl-1">Audit Trail</span>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-success wd-md-150"
                          >
                           <Icon.Clock size={17} />
                           <span className="pl-1">Login History</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-9">
                      <Divider
                        orientation="left"
                        className="bg-light pd-y-5 font-italic"
                      >
                    
                        User details{" "}<Icon.Edit3 size={16}/>
                        
                      </Divider>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                              Username
                            </strong>
                              {state.data.username}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                              UserType
                            </strong>
                            {state.data.user_sub_type}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                              Login Status
                            </strong>
                          {state.data.login_status}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                              Last Login
                            </strong>
                            <Moment
                              format="DD MMM YYYY"
                              date={
                                state.data.last_login
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                              Current Login Device
                            </strong>
                            : {state.data.current_login_device}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                             Status Code
                            </strong>
                                {state.data.status_code}
                          </div>

                          <div className="mb-3">
                            <strong className="d-inline-block wd-150">
                             Country 
                            </strong>
                            {state.data.country_code}
                          </div>
                          <div className="mb-3 d-flex">
                            <strong className="d-inline-block wd-150">
                             Department Code
                            </strong>
                            {state.data.department_code}
                            {/* <ul>
                              {policyDetails.portfolio_details.workflow.map(
                                (workflow, i) => (
                                  <li key={i}>
                                    <Icon.Check color="green" size={12} />
                                    <i className="pl-2">{workflow.name}~ <span>{workflow.status}</span></i> : 
                                  </li>
                                )
                              )}
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <Divider
                        orientation="left"
                        className="bg-light pd-y-5 font-italic"
                      >
                        Security Context Settings{" "}<Icon.Edit3 size={16}/>
                      </Divider>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <strong className="d-inline-block">
                              Allowed Channels
                            </strong>
                            
                            <ul>
                              {state.data.allowed_channels===undefined? null:state.data.allowed_channels.map(
                                (channel, i) => (
                                  <li key={i}>
                                    <Icon.Check color="green" size={12} />
                                    <i className="pl-2">{channel}</i> 
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">Two Factor authentication</strong>:{" "}
                        {`${state.data.two_factory_auth_enabled}`}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">Password Policy Code</strong>:{" "}
                            {state.data.password_policy_code}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <strong className="d-inline-block">
                             App TnC:{" "}
                            </strong>
                            {" "} {`${state.data.app_tnc}`}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">
                             Security Qst:{" "}
                            </strong>
                            {" "} {`${state.data.security_qst_enabled}`}
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">
                             Boundary Code:{" "}
                            </strong>
                            {" "} {state.data.boundary_code}
                          </div>
                        </div>
                      </div>
                      <Divider
                        orientation="left"
                        className="bg-light pd-y-5 font-italic"
                      >
                        Notification Settings{" "}<Icon.Edit3 size={16}/>
                      </Divider>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <strong className="d-inline-block">
                              Email
                            </strong>
                            :{" "}
                          
                            {state.data.notification_settings===undefined?null:`${state.data.notification_settings.email}`
                              
                            }
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">Sms</strong>:{" "}
                            {state.data.notification_settings===undefined?null:
                              `${state.data.notification_settings.sms}`
                            }
                          </div>
                          <div className="mb-3">
                            <strong className="d-inline-block">InApp</strong>:{" "}
                            {state.data.notification_settings===undefined?null:
                              `${state.data.notification_settings.inapp}`
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
         </>
}
</div>
  );
};

export default ViewUser;

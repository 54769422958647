import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import AddRoleForm from 'components/widgets/forms/addRoles/AddRoleFormContainer'

const AddRole = (props) => {

  return (
       
          <Modal
          show={props.show} 
          onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="text-grey">Add User Management Role</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
         <AddRoleForm fetch_roles_list={props.fetch_roles_list}/>
            </Modal.Body>
          </Modal>
        
   
  );
};

export default AddRole;

import React, { useContext } from "react";

import { Modal } from "react-bootstrap";
import { Button, Input, Form, Radio, Space } from "antd";
import { useMutation } from "react-query";
import { MinusCircleOutlined } from "@ant-design/icons";

import { addSchemeGroupMember } from "services/apiSrv";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const UploadMember = (props) => {
  const { modalClose } = useContext(ModalContext);

  const add_member = useMutation(addSchemeGroupMember, {
    onSuccess: (data) => {
      modalClose();
      props.refetch_members();
      show_toast("Member added successfully", "success");
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
      console.log("error adding scheme member", err);
    },
  });

  const onFinish = (values) => {
    let val = {};
    val = {
      ...values,
      scheme_id: props.scheme_id,
      system_scheme_group_ref: props.schemeref,
    };
    console.log("Success:", val);
    add_member.mutate(val);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Add Member to Group</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            name="add_member"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            scrollToFirstError
            validateMessages={validateMessages}
            layout="vertical"
          >
            <Form.Item
              label="Member Name"
              name="member_names"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Member Number"
              name="member_number"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Member Contact"
              name="member_contact"
              labelAlign="left"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Member Email"
              name="member_email"
              labelAlign="left"
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="id_type"
              label="Identification Type"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="identification card">ID Card</Radio>
                <Radio value="passport">Passport</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="id_number"
              label="Identification Document Number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Other Member Details">
              <Form.List name="member_details">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        align="baseline"
                        style={{ width: "100%" }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "key"]}
                          fieldKey={[field.fieldKey, "key"]}
                          rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                          ]}
                        >
                          <Input placeholder="name" />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          name={[field.name, "value"]}
                          fieldKey={[field.fieldKey, "value"]}
                          rules={[
                            {
                              required: true,
                              message: "Value is required",
                            },
                          ]}
                        >
                          <Input placeholder="value" />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}

                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add details
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
                htmlType="submit"
                disbaled={add_member.isLoading}
              >
                {add_member.isLoading ? (
                  <div className="text-white">
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    Loading..
                  </div>
                ) : (
                  "Add Member"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadMember;

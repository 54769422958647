import React from 'react'
import TnC from './TnC'

const TnCContainer = () => {
    return (
        <div>
            <TnC/>
        </div>
    )
}

export default TnCContainer

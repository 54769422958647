import React, { useState } from 'react';
import './channel.css';
import { Row, Col, Select, Radio, Input, Form, Button, DatePicker } from 'antd';

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

const config = {
	rules: [
		{
			type: 'object',
			required: true,
			message: 'Expiry of card is required!',
		},
	],
};

const SelectChannel = ({ showDetailsDrawer }) => {
	const [channel, setChannel] = useState('');
	const [mobileMode, setMobileMode] = useState('');
	const [form] = Form.useForm();
	const { Option } = Select;

	const handleChannelChange = (e) => {
		const { value } = e.target;
		setChannel(value);
	};

	const handleFormValuesChange = (changedValues) => {
		const fieldName = Object.keys(changedValues)[0];

		if (fieldName === 'mobile_mode') {
			const value = changedValues[fieldName];
			setMobileMode(value);
		}
	};

	const onFinish = (values) => {
		console.log(values);
		showDetailsDrawer();
	};

	return (
		<div>
			<div className="option">
				<input type="radio" name="card" id="bank" value="bank" onChange={handleChannelChange} />
				<label htmlFor="bank" className="channel__label" aria-label="Bank">
					<span />
					Bank
					<div className="card channel__card card--white card--sm">
						<div className="card__chip" />
						<div className="card__content">
							<div className="card__text">
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
							</div>
							<div className="card__symbol">
								<span />
								<span />
							</div>
						</div>
					</div>
				</label>
			</div>
			<div className="option">
				<input type="radio" name="card" id="mobile" value="mobile" onChange={handleChannelChange} />
				<label htmlFor="mobile" className="channel__label" aria-label="Mobile money">
					<span />
					Mobile money
					<div className="card channel__card card--blue card--sm">
						<div className="card__chip" />
						<div className="card__content">
							<div className="card__text">
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
							</div>
							<div className="card__symbol">
								<span />
								<span />
							</div>
						</div>
					</div>
				</label>
			</div>
			<div className="option">
				<input type="radio" name="card" id="card" value="card" onChange={handleChannelChange} />
				<label htmlFor="card" className="channel__label" aria-label="Card">
					<span />
					Card
					<div className="card channel__card card--dark card--sm">
						<div className="card__chip" />
						<div className="card__content">
							<div className="card__text">
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
								<div className="text__row">
									<div className="text__loader" />
									<div className="text__loader" />
								</div>
							</div>
							<div className="card__symbol">
								<span />
								<span />
							</div>
						</div>
					</div>
				</label>
			</div>
			{channel === 'bank' && (
				<div className="mt-3">
					<Form
						form={form}
						layout="vertical"
						name="bank_option"
						onFinish={onFinish}
						validateMessages={validateMessages}
					>
						<Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
							<Select
								showSearch
								placeholder="Select..."
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								<Option value="jack">123</Option>
								<Option value="lucy">456</Option>
							</Select>
						</Form.Item>
						<Form.Item name="account_name" label="Account Name" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="account_number" label="Account Number" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="account_branch" label="Account Branch " rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="account_currency" label="Currency" rules={[{ required: true }]}>
							<Radio.Group>
								<Radio value="KES">KES</Radio>
								<Radio value="USD">USD</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item name="preferred_mode" label="Preferred Mode" rules={[{ required: true }]}>
							<Select
								showSearch
								placeholder="Select..."
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								<Option value="jack">Mode 1</Option>
								<Option value="lucy">Mode 2</Option>
							</Select>
						</Form.Item>
						<Form.Item name="other_details" label="Other Details">
							<Input.TextArea />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" className="float-right">
								Next
							</Button>
						</Form.Item>
					</Form>
				</div>
			)}
			{channel === 'mobile' && (
				<div className="mt-3">
					<Form
						form={form}
						layout="vertical"
						name="mobile_option"
						onFinish={onFinish}
						validateMessages={validateMessages}
						onValuesChange={handleFormValuesChange}
					>
						<Form.Item name="mobile_provider" label="Select Provider" rules={[{ required: true }]}>
							<Radio.Group>
								<Radio value="Safaricom">Safaricom</Radio>
								<Radio value="Airtel">Airtel</Radio>
								<Radio value="Telkom">Telkom</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item name="mobile_mode" label="Select Mode" rules={[{ required: true }]}>
							<Radio.Group>
								<Radio value="contact">Contact</Radio>
								<Radio value="till">Till</Radio>
								<Radio value="paybill">Paybill</Radio>
							</Radio.Group>
						</Form.Item>
						{mobileMode === 'contact' && (
							<Form.Item name="contact" label="Contact Number" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						)}
						{mobileMode === 'till' && (
							<Row>
								<Col md={{ span: 12 }}>
									<Form.Item name="till_number" label="Till Number" rules={[{ required: true }]}>
										<Input />
									</Form.Item>
								</Col>
								<Col md={{ span: 12 }}>
									<Form.Item name="till_name" label="Account Name" rules={[{ required: true }]}>
										<Input />
									</Form.Item>
								</Col>
							</Row>
						)}
						{mobileMode === 'paybill' && (
							<Form.Item name="paybill" label="Paybill Number" rules={[{ required: true }]}>
								<Input />
							</Form.Item>
						)}
						<Form.Item name="other_details" label="Other Details">
							<Input.TextArea />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" className="float-right">
								Next
							</Button>
						</Form.Item>
					</Form>
				</div>
			)}
			{channel === 'card' && (
				<div className="mt-3">
					<Form
						form={form}
						layout="vertical"
						name="card_option"
						onFinish={onFinish}
						validateMessages={validateMessages}
						onValuesChange={handleFormValuesChange}
					>
						<Form.Item name="card_number" label="Card Number" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Row>
							<Col md={{ span: 10 }}>
								<Form.Item name="cvv" label="CVV " rules={[{ required: true }]}>
									<Input />
								</Form.Item>
							</Col>
							<Col md={{ span: 12 }}>
								<Form.Item name="card_expiry" label="Expiry Date" {...config}>
									<DatePicker renderExtraFooter={() => 'extra footer'} picker="month" />
								</Form.Item>
							</Col>
						</Row>
						<Form.Item name="names" label="Name" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="address" label="Address" rules={[{ required: true }]}>
							<Input />
						</Form.Item>
						<Form.Item name="other_details" label="Other Details">
							<Input.TextArea />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" className="float-right">
								Next
							</Button>
						</Form.Item>
					</Form>
				</div>
			)}
		</div>
	);
};

export default SelectChannel;

import React from 'react';
import Channel from './Channel';
import { listChannels } from 'services/apiSrv';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';
import ServerError from 'pages/errors/500';

const ChannelContainer = () => {
	const getChannels = async () => {
		let resp = await listChannels();
		console.log('CHANNELS', resp);
		return resp.settlement_channels;
	};

	const { data, isSuccess, isLoading, isError, refetch } = useQuery('channels', getChannels);

	return (
		<div>
			{isLoading && <Loader />}
			{isError && <ServerError />}
			{isSuccess && <Channel channels={data} refetch={refetch} />}
		</div>
	);
};

export default ChannelContainer;

import React from 'react';
import Reviews from './Reviews';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const ReviewsContainer = () => {
	async function fetch_reviews() {
		let resp = await (await api_srv).get_reviews();
		console.log('TRHE REVIEWS', resp);
		return resp.intermediary_reviews;
	}

	const { isLoading, isSuccess, data } = useQuery('reviews', fetch_reviews);
	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Reviews data={data} />}
		</div>
	);
};

export default ReviewsContainer;

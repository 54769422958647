import React, { useContext, useEffect } from 'react';
import { DashboardContext } from 'context/DashboardContext';
import AssetDetailsForm from '../../widgets/forms/DynamicAssetForm';

export default function AssetDetails(props) {
	// Access Context
	const { quotations, fetch_asset_requirement } = useContext(DashboardContext);

	useEffect(() => {
		fetch_asset_requirement(atob(props.id));
	}, []);

	return <AssetDetailsForm fields={quotations.requirements} />;
}

import React, { useState, useContext } from "react";
import AssignRm from "./AssignRm";
import { assign_rm_lead, get_relationship_managers } from "services/apiSrv";
import { useMutation, useQuery } from "react-query";
import Loader from "components/widgets/loader/ComponentLoader";
import { show_toast } from "utils/helpers";
import { ModalContext } from "context/ModalContext";

const AssignRmContainer = ({ lead_ref }) => {
  const [selectedManager, setSelectedManager] = useState("");
  const { modalClose } = useContext(ModalContext);

  const _assign = useMutation(assign_rm_lead, {
    onSuccess: (data) => {
      get_relationship_managers();

      modalClose();
      show_toast("Manager added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  function handleChange(e, value) {
    setSelectedManager(value);
  }
  console.log("SELCTED MANAGERS", selectedManager);
  const _listRms = async () => {
    let resp = await get_relationship_managers();
    console.log("LOADING RMS DTA", resp);
    return resp.rm;
  };

  const { data: rm, isLoading, isSuccess } = useQuery("listRms", _listRms);
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <AssignRm
          rm={rm}
          handleChange={handleChange}
          _assign={_assign}
          selectedManager={selectedManager}
          lead_ref={lead_ref}
        />
      )}
    </div>
  );
};

export default AssignRmContainer;

import React from "react";

import { Scrollbars } from "react-custom-scrollbars";

import SidebarInner from "./Sidebar_Inner";
import SidebarFooter from "./Sidebar_Footer";

export default function Index() {
  return (
    <>
      <Scrollbars>
        <SidebarInner />
      </Scrollbars>
      <SidebarFooter />
    </>
  );
}

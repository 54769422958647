import React, { useContext } from "react";
import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";
import { KycContext } from "../../context/KycContext";
import { ModalContext } from "../../context/ModalContext";

export default function CustomSuccessMsg({ title, subTitle}) {
  
  const history = useHistory();

  const context = useContext(KycContext);
  const modalContext = useContext(ModalContext);

  const { setCurrent } = context;
  const { setModalShow } = modalContext;
  return (
    <Result
      status="success"
      title={title}
      subTitle={subTitle}
      extra={[
        <Button
          type="primary"
          onClick={() => {
            history.push("/dashboard");
            setCurrent(0);
            setModalShow(false);
          }}
        >
          Done
        </Button>,
      ]}
    />
  );
}

import React from "react";

import { useQuery } from "react-query";

import Tax from "./Tax";
import { list_taxes } from "services/apiSrv";
import Loader from "components/widgets/loader";

const TaxContainer = () => {
  async function listTaxes() {
    let resp = await list_taxes();
    console.log("THE TAXES HERE", resp);
    return resp.entity_taxes;
  }
  const { data, isLoading, isSuccess, refetch } = useQuery(
    "list taxes",
    listTaxes
  );

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Tax data={data} refetch={refetch} />}
    </div>
  );
};

export default TaxContainer;

import React, { useContext, useState } from "react";

import {
  Tabs,
  Avatar,
  Drawer,
  Divider,
  Descriptions,
  Dropdown,
  Menu,
} from "antd";
import { FiEdit } from "react-icons/fi";
import { CgPlayListRemove } from "react-icons/cg";
import { MdBlock } from "react-icons/md";
import { AiOutlineUsergroupAdd, AiOutlineUserAdd } from "react-icons/ai";
import * as Icon from "react-feather";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";

import { ModalContext } from "context/ModalContext";
import BatchSchemeGroupMember from "components/widgets/modals/BatchSchemeGroupMember";
import SingleSchemeGroupMember from "components/widgets/modals/SingleSchemeGroupMember";
import {
  schemeGroupDetails,
  listGroupMembers,
  getMemberDetails,
  base_url,
  removeSchemeGroupMember,
} from "services/apiSrv";
import Loader from "components/widgets/loader";
import { iterateObject, show_toast } from "utils/helpers";
import CustomTable from "components/widgets/table";
import ComponentLoader from "components/widgets/loader/ComponentLoader";
import routes from "routes";

const GroupSchemeDetails = (props) => {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const { TabPane } = Tabs;
  const history = useHistory();
  const { groupId } = useParams();
  const [visible, setVisible] = useState(false);
  const [schemeMemberRef, setSchemeMemberRef] = useState(null);

  const group_member_details = async () => {
    let resp = await getMemberDetails(systemRef, schemeMemberRef);
    console.log("THE GROUP MEMBER DETAILS", resp);
    return resp.member;
  };

  const {
    isLoading: memberDetailsLoading,
    data: memberDetails,
    error,
    isSuccess: memberDetailsSuccess,
  } = useQuery("member details", group_member_details, {
    // manual: true,
    refetchOnWindowFocus: false,
    enabled: !!schemeMemberRef,
  });

  const showDrawer = (id) => {
    setSchemeMemberRef(id);
    console.log("THE MEMBER ID", id);
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const scheme_group_details = async () => {
    let resp = await schemeGroupDetails(groupId);
    return resp.scheme_group;
  };

  const { data, isError, isSuccess, isLoading } = useQuery(
    "scheme group details",
    scheme_group_details
  );

  const systemRef = data?.system_scheme_group_ref;

  const scheme_group_members = async () => {
    let resp = await listGroupMembers(systemRef);
    return resp.members;
  };

  const {
    data: members,
    isError: isErrorMembers,
    isSuccess: isSuccessMembers,
    isLoading: isLoadingMembers,
    refetch: refetchMembers,
  } = useQuery("scheme group memebers", scheme_group_members, {
    enabled: !!systemRef,
  });

  const remove_group_member = async (id) => {
    console.log("ARE WE INCVOKED?/");
    try {
      let res = await removeSchemeGroupMember(id);
      console.log("THE RES FROM DEL", res);
      refetchMembers();
    } catch (err) {
      let error = await err;
    }
  };
  const tableData = {
    icon: "",
    title: "Group Members",
    table_fields: [
      { name: "", align: "c" },
      { name: "Name", align: "c" },
      { name: "Member Number", align: "c" },
      { name: "Created Date", align: "c" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "scheme_logo", class: "bold center" },
      { type: "text", field: "member_names", class: "small center" },
      { type: "text", field: "member_number", class: "small center" },
      { type: "date", field: "created_date", class: "small center" },
      { type: "status", field: "scheme_member_status", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "system_scheme_member_ref",
            method: showDrawer,
          },
          {
            type: "menu",
            title: "Delete",
            id: "delete",
            field: "scheme_member_id",
            method: remove_group_member,
          },
        ],
      },
    ],
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => history.push(`/dashboard/schemes/${1}/manage-scheme`)}
      >
        <Icon.Settings size={16} className="mr-1" color="green" /> Manage Scheme
        Policy
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() =>
          history.push(
            `/dashboard/schemes/${1}/underwriterRate/${
              data.system_scheme_group_ref
            }`
          )
        }
      >
        <Icon.Settings size={16} className="mr-1" color="green" />
        Manage Underwriter Rate
      </Menu.Item>
      <Menu.Item key="3">
        <Icon.Edit3 size={16} className="mr-1" color="#001f5f" />
        Edit
      </Menu.Item>
      <Menu.Item key="4">
        <Icon.Trash2 size={16} className="mr-1" color="red" />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {isLoading && isLoadingMembers && <Loader />}
      {isSuccess && isSuccessMembers && (
        <>
          <Drawer
            title="Group Members"
            width={720}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {memberDetailsLoading && <ComponentLoader />}
            {memberDetailsSuccess && (
              <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="Details" key="1">
                  <p>
                    <span className="font-weight-bold">Group Name:</span>
                    {memberDetails.schemeGroup[0].scheme_group_name}
                  </p>
                  <p>
                    <span className="font-weight-bold">Description:</span>
                    {memberDetails.schemeGroup[0].scheme_group_description}
                  </p>
                  <ul className="d-flex ">
                    <li className="px-3 ">
                      <a href className="text-mint">
                        <MdBlock fontSize={18} />
                        De-activate
                      </a>
                    </li>
                    <li className="px-3 ">
                      <a href className="text-info">
                        <FiEdit fontSize={18} />
                        Edit
                      </a>
                    </li>
                  </ul>
                  <Divider
                    orientation="left"
                    className="bg-light pd-y-5 font-italic"
                  >
                    Member Details
                  </Divider>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <strong className="d-inline-block ">Name:</strong>{" "}
                        {memberDetails.member_names}
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">
                          Member Contact:
                        </strong>{" "}
                        {memberDetails.member_contact}
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">Status:</strong>{" "}
                        {memberDetails.scheme_member_status}
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">
                          Created Date:
                        </strong>{" "}
                        {memberDetails.created_date}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <strong className="d-inline-block ">Email:</strong>{" "}
                        {memberDetails.member_email}
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">
                          Identification:
                        </strong>{" "}
                        {iterateObject(memberDetails.member_identification) ||
                          "null"}
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">
                          Registration Status:
                        </strong>{" "}
                        Active
                      </div>
                      <div className="mb-3">
                        <strong className="d-inline-block ">Other:</strong>{" "}
                        {iterateObject(memberDetails.member_details) || "null"}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Activity" key="2">
                  <div className="table-responsive">
                    <table className="table table-hover mg-0">
                      <thead className="tx-dark tx-uppercase tx-12 tx-bold">
                        <tr className="bg-light">
                          <th>Date</th>

                          <th>Action</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>28/06/2021</td>

                          <td>Created</td>
                          <td>Short description</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </TabPane>
              </Tabs>
            )}
          </Drawer>

          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <Link className="breadcrumb-item active" to={routes.dashboard}>
                <i className="icon ion-ios-home-outline " /> Home
              </Link>
              <Link className="breadcrumb-item active" to={routes.schemes}>
                Schemes
              </Link>
              <Link className="breadcrumb-item">Group Details</Link>
            </div>
            <h5 className="text-capitalize">
              {data.scheme_group_name} Details
            </h5>
          </div>

          <div className="card mg-b-20 mg-t-45">
            <div className="card-body">
              <Descriptions
                title="Group Details"
                size="default"
                extra={
                  <Dropdown.Button type="primary" overlay={menu}>
                    Actions
                  </Dropdown.Button>
                }
              >
                <Descriptions.Item label="Group Name">
                  {data.scheme_group_name}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {data.scheme_group_status}
                </Descriptions.Item>
                <Descriptions.Item label="Created Date"></Descriptions.Item>
                <Descriptions.Item label="Policy Type">
                  {data.policy_management_type}
                </Descriptions.Item>
                <Descriptions.Item label="Group Type">
                  {data.scheme_group_type}
                </Descriptions.Item>
                <Descriptions.Item label="Other Details">
                  {iterateObject(data.scheme_group_detail) || "null"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <div className="card mg-b-20">
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <Avatar
                      size={94}
                      className="mb-3"
                      src={base_url + data.scheme_details[0].scheme_logo}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <Descriptions title="Scheme Details" size="default">
                    <Descriptions.Item label="Scheme Name">
                      {data.scheme_details[0].scheme_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {data.scheme_details[0].scheme_status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {data.scheme_details[0].scheme_description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact">
                      {iterateObject(
                        data.scheme_details[0].scheme_contacts[0]
                      ) || "null"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Other Details">
                      {iterateObject(data.scheme_details[0].scheme_details) ||
                        "null"}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
          </div>

          <div className="clearfix">
            <div className="dropdown float-right">
              <button
                className="btn btn-primary dropdown-toggle mb-3"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Upload Members
              </button>
              <ul
                className="dropdown-menu "
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <a
                    className="dropdown-item tx-12-force"
                    href
                    onClick={() => setModalShow("multiple_members")}
                  >
                    <AiOutlineUsergroupAdd size={16} className="mr-1" />
                    Multiple Members
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item tx-12-force"
                    href
                    onClick={() => setModalShow("single_member")}
                  >
                    <AiOutlineUserAdd size={16} className="mr-1" /> Single
                    Member
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <CustomTable
            table_fields={tableData.table_fields}
            row_data={tableData.row_data}
            table_data={members}
            title={tableData.title}
          />

          {/* modals */}
          <BatchSchemeGroupMember
            show={modalShow === "multiple_members" ? true : false}
            onHide={modalClose}
          />
          <SingleSchemeGroupMember
            show={modalShow === "single_member" ? true : false}
            onHide={modalClose}
            scheme_id={data.scheme_id}
            schemeref={data.system_scheme_group_ref}
            refetch_members={refetchMembers}
          />
        </>
      )}
    </div>
  );
};

export default GroupSchemeDetails;

import React, { useContext, useState } from "react";
import { DashboardContext } from "../../../context/DashboardContext";
import {Select,Checkbox, List,} from 'antd'


//  Validation
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function AddUser() {

  // Access Context

  const dashboardContext = useContext(DashboardContext);

  // Destructure

  const{usersmgt, handleUsersMgtChange,add_user} = dashboardContext
  const { Option } = Select;
 
  // const onGroupChange = (checkedList) => {
  //   setChecked({
  //     ...checked,
  //     checkedList,
  //     checkAll: checkedList.length === benefits.length,
  //   });
  // };

  const reports = [
    {
    name:"Report 1",
    value:"r1",
    description:"Description for report 1"
  },
    {
    name:"Report 2",
    value:"r2",
    description:"Description for report 2"
  },
    {
    name:"Report 3",
    value:"r3",
    description:"Description for report 3"
  },
    {
    name:"Report 4",
    value:"r4",
    description:"Description for report 4"
  },


]

  return (
    <div>
      
      <Checkbox.Group
        // value={checked.checkedList}
        // onChange={onGroupChange}
        style={{ width: "100%" }}
      >
        <List
          // header={
          // <div>
          //   <Checkbox
          //     onChange={onCheck}
          //     checked={checked.checkAll} >
          //       check all
          //   </Checkbox>
          // </div>}
          bordered
          dataSource={reports}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                name={item.name}
                value={item.code}
                // onChange={toggleInput}
              >
                <span className="font-weight-bold">{item.name}</span>
                <i className="d-block pl-4">~{item.description}</i>

              </Checkbox>
            </List.Item>
          )}
        />
      </Checkbox.Group>
      <div className="d-flex justify-content-center">
              <button
                color="primary"
                variant="contained"
                type="submit"
                className="btn btn-outline-success px-5 my-4 "
              >
                Add 
              </button>
            </div>
    </div>
  );
}

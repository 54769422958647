import React, { useContext, useState, useEffect } from "react";
import ViewClaim from "./ViewClaimPending";
import { DashboardContext } from "context/DashboardContext";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const ViewClaimContainer = (props) => {
  const { individualClaimDetails, claimDetails, setClaimDetails } = useContext(
    DashboardContext
  );

  async function send_my_claim(clientId) {
    setClaimDetails({ ...claimDetails, loading: true });
    try {
      await (await api_srv).upload_individual_claim_field(
        props.match.params.id,
        claimDetails.data
      );
      let resp = await (await api_srv).send_claim(
        props.match.params.id,
        clientId
      );
      setClaimDetails({ ...claimDetails, loading: false });
      console.log("claim has been set", resp);
      // history.push('/claims')
    } catch (err) {
      let error = await err;
      setClaimDetails({ ...claimDetails, loading: false, error: error });
    }
  }

  async function get_individual_claim_details() {
    let resp = await (await api_srv).fetch_claim_details(props.match.params.id);
    return resp.claim_details;
  }
  async function get_individual_claim_summary() {
    let resp = await (await api_srv).fetch_claim_summary(props.match.params.id);
    console.log("CLAIM SUMMARY", resp);
    return resp;
  }

  async function get_make_claim_form() {
    let resp = await (await api_srv).make_claim_form(props.match.params.id);
    return resp;
  }

  const { isLoading, isError, isSuccess, data } = useQuery(
    "claim_details",
    get_individual_claim_details
  );
  const {
    isLoading: isLoading2,
    isError: isError2,
    isSuccess: isSuccess2,
    data: claimForm,
  } = useQuery("claim_form", get_make_claim_form);
  const {
    isLoading: isLoading3,
    isError: isError3,
    isSuccess: isSuccess3,
    data: claimSummary,
  } = useQuery("claim_summary", get_individual_claim_summary);

  const [visible, setVisible] = useState(false);
  console.log("individualClaimDetails", individualClaimDetails);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <div>
      {isLoading && isLoading2 && isLoading3 && <Loader />}
      {isSuccess && isSuccess2 && isSuccess3 && (
        <ViewClaim
          data={data}
          claimForm={claimForm}
          claimSummary={claimSummary}
          onClose={onClose}
          visible={visible}
          send_my_claim={send_my_claim}
          individualClaimDetails={individualClaimDetails}
          showDrawer={showDrawer}
          id={props.match.params.id}
        />
      )}
    </div>
  );
};

export default ViewClaimContainer;

import React, { useState, useContext } from "react";
import { Avatar, Drawer, List, Spin } from "antd";
import * as Icon from "react-feather";
import "./sidebar.css";
import SidebarChat from "./SidebarChat";
import { api_srv } from "services";
import { useQuery } from "react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";

const Sidebar = ({ conversations }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  const [showSettings, setShowSettings] = useState(false);
  const [showUsers, setShowUsers] = useState(false);

  const get_userList = async () => {
    let resp = await (await api_srv).list_clients();
    return resp.portfolio;
  };

  const { isLoading, isError, isSuccess, data } = useQuery(
    "clients",
    get_userList
  );

  function handleMenuClick(e) {
    console.log("click", e);
  }
  function handleMenuClick2(e) {
    console.log("click2", e);
  }

  const showSettingsDrawer = () => {
    setShowSettings(true);
  };
  const showUsersDrawer = () => {
    setShowUsers(true);
  };

  const onClose = () => {
    setShowSettings(false);
  };
  const onUsersClose = () => {
    setShowUsers(false);
  };

  const settingList = [
    {
      title: "Notifications",
      icon: <Icon.Bell size={16} />,
    },
    {
      title: "Contacts",
      icon: <Icon.Users size={16} />,
    },
    {
      title: "Calls",
      icon: <Icon.Phone size={16} />,
    },
    {
      title: "Help",
      icon: <Icon.HelpCircle size={16} />,
    },
  ];

  const createChat = async (id) => {
    try {
      let resp = await (await api_srv).add_chat_conversation(id);
      console.log("THE CREATE CHAT RESPONSE", resp);
      get_userList();
      onUsersClose();
    } catch (err) {
      let error = await err;
      console.log("CREATE CHAT ERROR", error.message);
    }
  };
  return (
    <div className="sidebar site-drawer-render-in-current-wrapper ">
      <Drawer
        title="Settings"
        placement="left"
        className="settings_drawer"
        closable={true}
        onClose={onClose}
        visible={showSettings}
        getContainer={false}
        style={{ position: "absolute" }}
      >
        <div className="mb-3">
          <Avatar />
          <span className="pl-2">Robert</span>
        </div>
        <List
          itemLayout="horizontal"
          dataSource={settingList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item.icon}
                title={<a href>{item.title}</a>}
              />
            </List.Item>
          )}
        />
      </Drawer>
      <Drawer
        title="New Chat"
        placement="left"
        closable={true}
        onClose={onUsersClose}
        visible={showUsers}
        getContainer={false}
        style={{ position: "absolute" }}
      >
        {isLoading && <Spin indicator={antIcon} />}
        {isSuccess && (
          <>
            <div className="sidebar__search">
              <div className="sidebar__searchContainer">
                <input placeholder="Search users" type="text" />
              </div>
            </div>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => {
                return (
                  <List.Item onClick={() => createChat(item.user_id)}>
                    <List.Item.Meta
                      avatar={<Avatar />}
                      title={<a href>{item.names}</a>}
                      description={item.email}
                    />
                  </List.Item>
                );
              }}
            />
          </>
        )}
      </Drawer>
      <div className="sidebar__header">
        <Avatar />

        <div className="sidebar__headerRight">
          <a href>
            <Icon.MessageSquare />
          </a>
          <a
            href
            dropdown-toggle
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Icon.MoreVertical />
          </a>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li>
              <a href className="dropdown-item" onClick={handleMenuClick}>
                Create Chat Group
              </a>
            </li>
            <li>
              <a
                href
                className="dropdown-item"
                onClick={() => showSettingsDrawer()}
              >
                Settings
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar__search">
        <div className="sidebar__searchContainer">
          <Icon.Search size={16} />
          <input placeholder="Search or start new chat" type="text" />
        </div>
      </div>

      <div className="sidebar__chats">
        <a href onClick={showUsersDrawer}>
          <SidebarChat addNewChat />
        </a>
        {conversations.length === 0 && (
          <p className="text-center text-primary mt-3">
            Click on Add New Chat to start chatting
          </p>
        )}
        {conversations.map((conversation) => {
          console.log("THE CONVERSATION", conversation);
          return (
            <SidebarChat
              recepient={
                conversation.chat_conversations.chat_user_identification
              }
              id={conversation.chat_conversations.conversation_id}
              name={conversation.names}
              image={conversation.chat_image_path}
              chat_status={conversation.chat_status}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import ViewDepartment from "./View_Department";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const ViewDepartmentContainer = (props) => {
  let id = props.match.params.id;
  const fetch_details = async () => {
    let resp = await (await api_srv).department_details(id);
    return resp.department_details;
  };

  const { isLoading, isSuccess, data } = useQuery(
    "viewDepartment",
    fetch_details
  );
  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <ViewDepartment data={data} />}
    </div>
  );
};

export default ViewDepartmentContainer;

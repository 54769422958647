import React, { useContext } from "react";

import { Button } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "react-query";

import { ModalContext } from "context/ModalContext";
import AddScheme from "components/widgets/modals/NewScheme";
import CustomTable from "components/widgets/table";
import Loader from "components/widgets/loader";
import { listSchemes } from "services/apiSrv";
import routes from "routes";

export default function SchemeList() {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const history = useHistory();

  const handle_view_schemes = (id) => {
    history.push(`/dashboard/schemes/${id}`);
  };

  const list_schemes = async () => {
    let resp = await listSchemes();
    console.log("THE RETURNED SCHEMES", resp);
    return resp.scheme_details;
  };

  const { data, isError, isSuccess, isLoading, refetch } = useQuery(
    "list Schemes",
    list_schemes
  );

  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "", align: "c" },
      { name: "Name", align: "c" },
      { name: "Description", align: "c" },
      { name: "Scheme Ref", align: "c" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "scheme_logo", class: "bold center" },
      { type: "text", field: "scheme_name", class: "small center" },
      { type: "text", field: "scheme_description", class: "small center" },
      { type: "text", field: "system_scheme_ref", class: "small center" },
      { type: "status", field: "scheme_status", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            id: "view",
            field: "scheme_id",
            method: handle_view_schemes,
          },
          {
            type: "menu",
            title: "Delete",
            id: "delete",
            event: "table:delete",
            icon: "fa-trash-alt",
          },
        ],
      },
    ],
  };

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && (
        <>
          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <Link className="breadcrumb-item active" to={routes.dashboard}>
                <i className="icon ion-ios-home-outline " /> Home
              </Link>
              <Link className="breadcrumb-item ">Schemes</Link>
            </div>
            <h5>Available Schemes</h5>
          </div>

          <div className="clearfix">
            <Button
              className="btn-primary btn-blue rounded mb-3 float-right text-white "
              onClick={() => setModalShow(true)}
            >
              Add Scheme
            </Button>
          </div>

          <CustomTable
            table_fields={tableData.table_fields}
            row_data={tableData.row_data}
            table_data={data}
            title={tableData.title}
          />

          {/* modal */}
          <AddScheme show={modalShow} onHide={modalClose} refetch={refetch} />
        </>
      )}
    </div>
  );
}

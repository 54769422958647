import React, { useState, useContext } from 'react';
import * as Icon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { DashboardContext } from 'context/DashboardContext';
import { Button, Input, Pagination, Tabs } from 'antd';
import NoData from 'components/no-data/NoData';

export default function Settlement({ data }) {
	// access context

	const { paginate, numEachPage, handlePaginationChange } = useContext(DashboardContext);
	const history = useHistory();

	const { Search } = Input;
	const [search, setSearch] = useState(null);
	const { TabPane } = Tabs;
	// handle search

	const searchSpace = (event) => {
		let keyword = event.target.value;
		setSearch(keyword);
	};

	const items =
		data.length === 0
			? null
			: data
					.filter((data) => {
						if (search == null) return data;
						else if (
							data.department_code.toLowerCase().includes(search.toLowerCase()) ||
							data.department_name.toLowerCase().includes(search.toLowerCase()) ||
							data.department_description.toLowerCase().includes(search.toLowerCase())
						) {
							return data;
						}
					})
					.slice(paginate.minValue, paginate.maxValue)
					.map((row) => {
						return (
							<tr>
								<td>12345</td>
								<td>10,000</td>
								<td>Bank</td>
								<td>ref123</td>
								<td>10/05/2021</td>
								<td>Success</td>

								<td>
									<div className="dropdown">
										<a
											href
											aria-haspopup="true"
											aria-expanded="false"
											className="btn btn-sm btn-label-primary"
											onClick={() => history.push(`/dashboard/settlement/${1}`)}
										>
											View
										</a>
									</div>
								</td>
							</tr>
						);
					});

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			{data.length === 0 ? (
				<NoData message="No settlements found currently" />
			) : (
				<>
					<div className="col-lg-12 card rounded-10 col-xl-12">
						<div className="mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5 pd-l-5">
									<h1 className=" tx-18">
										<Icon.Archive size={30} className="pr-2 text-primary" />
										Settlement
									</h1>
								</div>
								<Search
									placeholder="Search Settlement"
									type="search"
									onChange={(e) => searchSpace(e)}
									className="ml-md-auto mg-r-10"
									style={{ width: 200 }}
								/>
							</div>
							<Tabs defaultActiveKey="1" tabPosition="left">
								<TabPane tab="Pending" key="1">
									<div className="mg-b-20">
										<div className="card-body pd-0 collapse show" id="customerDetails">
											<div className="table-responsive">
												<table className="table table-hover mg-0">
													<thead className="tx-dark tx-uppercase tx-12 tx-bold">
														<tr className="bg-light">
															<th className="">Account Number</th>
															<th className="">Amount</th>
															<th>Channel</th>
															<th>Reference</th>
															<th>Date</th>
															<th>Status</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>{items}</tbody>
												</table>
											</div>
										</div>
									</div>
									<Pagination
										className="ml-auto"
										responsive={true}
										dataSource={data}
										total={data.length}
										showTotal={(total) => `Total ${total} items`}
										defaultPageSize={numEachPage}
										defaultCurrent={1}
										onChange={handlePaginationChange}
									/>
								</TabPane>
								<TabPane tab="In Progress" key="2">
									<div className="mg-b-20">
										<div className="card-body pd-0 collapse show" id="customerDetails">
											<div className="table-responsive">
												<table className="table table-hover mg-0">
													<thead className="tx-dark tx-uppercase tx-12 tx-bold">
														<tr className="bg-light">
															<th className="">Account Number</th>
															<th className="">Amount</th>
															<th>Channel</th>
															<th>Reference</th>
															<th>Date</th>
															<th>Status</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>{items}</tbody>
												</table>
											</div>
										</div>
									</div>
									<Pagination
										className="ml-auto"
										responsive={true}
										dataSource={data}
										total={data.length}
										showTotal={(total) => `Total ${total} items`}
										defaultPageSize={numEachPage}
										defaultCurrent={1}
										onChange={handlePaginationChange}
									/>
								</TabPane>
							</Tabs>
						</div>
					</div>
				</>
			)}
		</>
	);
}

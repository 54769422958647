import React from "react";
import { Redirect } from "react-router-dom";
import Spinner from "components/widgets/loader/PageLoader";
import "assets/css/custom.css";
import routes from "routes";

export default function VerifyEmail({ confirmimgToken, registrationId }) {
  const styles = {
    height: "70px",
    width: "70px",
  };

  return (
    <div className="centerdiv">
      
      {
      // loading waiting to save reg_id to localStorage 

      confirmimgToken && registrationId === "" ? 
         
          <Spinner style={styles} />
     
       // successfully verified token
       : !confirmimgToken && registrationId !== "" ? (
        <Redirect to={routes.credentials} />
      )

      // error has occured in api request
      // : !confirmimgToken && registrationId === "" ? (
      //   <Redirect to={routes.signup} />
      // ) 

      
      : <Spinner style={styles} /> 
        
      }
    </div>
  );
}

import React, { useContext, useState } from 'react';
import bg from 'assets/images/auth/credentials3.jpg';
import { Link } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Alert from 'components/alerts/WarningAlert';
import { AlertsContext } from 'context/AlertsContext';
import { AuthContext } from 'context/AuthContext';
import TnC from 'assets/docs/Nexus General Terms and Conditions 27.7.2020.pdf';
import logo from 'assets/images/logos/Logo_1.png';
import { Button } from 'antd';

const Credentials = ({
	state,
	alert,
	submit_credentials,
	toggleAcceptTerms,
	handleChange,
	togglePassword,
	toggleConfirmPassword,
}) => {
	const { showAlert } = useContext(AlertsContext);
	const { setCredentials } = useContext(AuthContext);
	const { message, variant } = alert;
	var currentYear = new Date().getFullYear();
	const phoneChange = () => {
		setCredentials({ ...Credentials, contact: state.contact });
	};

	return (
		<Formik
			enableReinitialize
			initialValues={state}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required('First Name is required'),
				lastName: Yup.string().required('Last Name is required'),
				contact: Yup.string().required('Contact is required'),
				// employee_ref: Yup.string().required("Employee reference is required"),
				// username: Yup.string()
				// .required("username is required")
				// .min(4,"Username should be atleast 4 characters"),
				password: Yup.string().min(6, 'Password must be atleast 6 characters').required('Password is required'),
				confirmPassword: Yup.string()
					.oneOf([Yup.ref('password')], 'Passwords do not match')
					.required('Confirm password is required'),
				tnc: Yup.boolean().oneOf([true], 'Please accept Terms and Conditions'),
			})}
			onSubmit={() => submit_credentials()}
		>
			{({ errors, status, touched }) => (
				<div className="d-md-flex d-sm-block">
					<div className="m-auto">
						<img src={logo} alt="Logo" className="d-block d-md-none wd-100 ht-100" />
					</div>

					<div className="col-md-12 col-sm-12  " id="credentials-background">
						<div
							style={{
								minHeight: '100%',
								paddingBottom: '40px',
								marginBottom: '-40px',
							}}
						>
							<div className="container">
								<Link to="/" className="">
									<img
										src={logo}
										style={{
											height: '30px',
											width: 'auto',
											position: 'absolute',
											top: '20px',
										}}
										alt="Logo"
									/>
								</Link>
								<div
									className="float-left"
									style={{
										position: 'absolute',
										top: '40%',
										left: '10%',
									}}
								>
									{/* <h1 >
                Set up your Nexus Credentials<span>.</span>
              </h1>
              <h6 className="text-white d-block">
                just a few steps and you are good to go!
              </h6> */}
								</div>
								<div
									className=""
									style={{
										position: 'fixed',
										right: '40px',
										bottom: '0',
									}}
								>
									<a href={TnC} rel="noopener noreferrer" target="_blank" className="text-white">
										Terms and conditions |
									</a>
									<Link to="#" className=" text-white">
										{' '}
										&copy; {new Date().getFullYear()} Nexus
									</Link>
								</div>

								<div
									className="card"
									style={{
										position: 'absolute',
										top: '8%',
										right: '10%',
										borderRadius: '15px 30px',
										maxWidth: '450px',
									}}
								>
									<div className="card-body p-5">
										<h2 className="mb-5 custom-text-primary">Credentials</h2>
										{showAlert && <Alert message={message} variant={variant} />}
										<Form className="login-form clearfix">
											<div className="form-row">
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="firstName">First Name</label>
														<input
															name="firstName"
															type="text"
															value={state.firstName}
															onChange={handleChange}
															className={
																'form-control border' +
																(errors.firstName && touched.firstName
																	? ' is-invalid'
																	: '')
															}
														/>
														<ErrorMessage
															name="firstName"
															component="div"
															className="invalid-feedback"
														/>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="lastName">Last Name</label>
														<input
															name="lastName"
															type="text"
															value={state.lastName}
															onChange={handleChange}
															className={
																'form-control border' +
																(errors.lastName && touched.lastName
																	? ' is-invalid'
																	: '')
															}
														/>
														<ErrorMessage
															name="lastName"
															component="div"
															className="invalid-feedback"
														/>
													</div>
												</div>
											</div>

											<div className="form-group">
												<label htmlFor="username">Username</label>
												<input
													name="username"
													disabled
													type="text"
													value={localStorage.getItem('REG_EMAIL')}
													// onChange={handleChange}
													className={
														'form-control border' +
														(errors.username && touched.username ? ' is-invalid' : '')
													}
												/>
												<ErrorMessage
													name="username"
													component="div"
													className="invalid-feedback"
												/>
											</div>
											<div className="form-group">
												<label htmlFor="password">Password</label>
												<input
													name="password"
													type={state.showPassword ? 'text' : 'password'}
													value={state.password}
													onChange={handleChange}
													className={
														'form-control border password-input' +
														(errors.password && touched.password ? ' is-invalid' : '')
													}
												/>
												<i
													className={`fa ${
														state.showPassword ? 'fa-eye' : 'fa-eye-slash'
													} password-icon`}
													onClick={togglePassword}
												/>
												<ErrorMessage
													name="password"
													component="div"
													className="invalid-feedback"
												/>
											</div>
											<div className="form-group">
												<label htmlFor="password">Confirm Password</label>
												<input
													name="confirmPassword"
													type={state.showConfirmPassword ? 'text' : 'password'}
													value={state.confirmPassword}
													onChange={handleChange}
													className={
														'form-control border' +
														(errors.confirmPassword && touched.confirmPassword
															? ' is-invalid'
															: '')
													}
												/>
												<i
													className={`fa ${
														state.showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'
													} password-icon`}
													onClick={toggleConfirmPassword}
												/>
												<ErrorMessage
													name="confirmPassword"
													component="div"
													className="invalid-feedback"
												/>
											</div>
											<div className="form-group">
												<label htmlFor="username">Phone Number</label>
												<input
													name="contact"
													type="text"
													value={state.contact}
													onChange={handleChange}
													className={
														'form-control border' +
														(errors.contact && touched.contact ? ' is-invalid' : '')
													}
												/>
												<ErrorMessage
													name="contact"
													component="div"
													className="invalid-feedback"
												/>
											</div>

											<div className="form-group">
												<input
													type="checkbox"
													name="tnc"
													checked={state.tnc}
													onChange={toggleAcceptTerms}
													className={
														'form-check-input' +
														(errors.tnc && touched.tnc ? ' is-invalid' : '')
													}
												/>
												<label className="form-check-label">
													I have read and accept the{' '}
													<a href={TnC} rel="noopener noreferrer" target="_blank">
														Terms and conditions
													</a>
												</label>
												<ErrorMessage name="tnc" component="div" className="invalid-feedback" />
											</div>

											<Button
												htmlType="submit"
												disabled={state.loading}
												className="btn mt-3 px-5 btn-login btn-block"
												// onClick={submit_credentials}
											>
												{state.loading && (
													<i
														className="fa fa-circle-notch fa-spin"
														style={{ marginRight: '5px' }}
													/>
												)}
												{state.loading && (
													<span className="text-capitalize">Please wait...</span>
												)}
												{!state.loading && <span className="text-capitalize"> Submit</span>}
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	);
};
export default Credentials;

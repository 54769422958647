import React, { useContext } from "react";
import DepartmentForm from "./Add_Department";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

const DepartmentContainer = ({ refetch }) => {
  const { addDepartment, setAddDepartment } = useContext(DashboardContext);
  const { modalClose } = useContext(ModalContext);

  const handleDepartmentChange = (e) => {
    const { name, value } = e.target;
    setAddDepartment({ ...addDepartment, [name]: value });
  };

  const add_department = async () => {
    setAddDepartment({ ...addDepartment, loading: true });
    try {
      let resp = await (await api_srv).create_department(addDepartment);
      setAddDepartment({ ...addDepartment, loading: false });
      refetch();
      modalClose();
      show_toast("Department added successfully", "success");
    } catch (err) {
      modalClose();
      setAddDepartment({ ...addDepartment, loading: false });
    }
  };
  return (
    <div>
      <DepartmentForm
        state={addDepartment}
        handleDepartmentChange={handleDepartmentChange}
        add_department={add_department}
      />
    </div>
  );
};

export default DepartmentContainer;

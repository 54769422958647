import React, { useContext, useState } from "react";
import { DashboardContext } from "context/DashboardContext";
import { TimePicker, Radio } from "antd";
import MultiSelect from "react-multi-select-component";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { api_srv } from "services";
import { show_toast } from "utils/helpers";

export default function SecurityContext({
  fetch_security_context,
  setVisible,
}) {
  // Access Context

  const { securityContext, setSecurityContext } = useContext(DashboardContext);

  console.log("checking context input", securityContext);
  function onChange(time, timeString) {
    setSecurityContext({
      ...securityContext,
      login_period_start: timeString,
    });
  }
  function onLowChange(time, timeString) {
    setSecurityContext({
      ...securityContext,
      login_period_end: timeString,
    });
  }

  function handleSecurityContextChange(evt) {
    const value = evt.target.value;
    setSecurityContext({
      ...securityContext,
      [evt.target.name]: value,
    });
  }
  function handleChannelsSelected(checked) {
    setSecurityContext({ ...securityContext, allowed_channels: checked });
  }
  function handleBrowserSelected(checked) {
    setSecurityContext({ ...securityContext, allowed_browsers: checked });
  }
  function handleBrowserVersionSelected(checked) {
    setSecurityContext({ ...securityContext, browser_version: checked });
  }
  function handleDevicesSelected(checked) {
    setSecurityContext({ ...securityContext, allowed_devices: checked });
  }
  function handleDeviceVersionSelected(checked) {
    setSecurityContext({ ...securityContext, device_version: checked });
  }

  const addContext = async () => {
    setSecurityContext({ ...securityContext, loading: true });
    try {
      let resp = await (await api_srv).create_context(securityContext);
      fetch_security_context();
      setSecurityContext({ ...securityContext, loading: false });
      setVisible(false);
      show_toast("Security Context added successfully", "success");
    } catch (err) {
      let error = await err;
      setSecurityContext({ ...securityContext, loading: false });
      setVisible(false);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={securityContext}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          password_policy: Yup.string().required(
            "Please select a password policy"
          ),
          login_period_start: Yup.string().required(
            "Login Period High is required"
          ),
          login_period_end: Yup.string().required(
            "Login Period Low is required"
          ),
          security_context_code: Yup.string().required(
            "Security Context code is required"
          ),
          max_timeout: Yup.string().required("This field is required"),
          token_ttl: Yup.string().required("This field is required"),
          allowed_channels: Yup.string().required(
            "Select the allowed channels"
          ),
          allowed_browsers: Yup.string().required("Check all allowed browsers"),
          browser_version: Yup.string().required("Select a browser versions"),
          allowed_devices: Yup.string().required("Select the allowed devices"),
          device_version: Yup.string().required("This field is required"),
          two_factor_auth: Yup.string().required("This field is required"),
          holiday_login: Yup.string().required("This field is required"),
          description: Yup.string().required("Enter a brief description"),
        })}
        onSubmit={() => addContext()}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <div className="form-group">
              <label htmlFor="name" className="font-weight-bold">
                Context Name
              </label>
              <input
                type="text"
                className={
                  "form-control border" +
                  (errors.name && touched.name ? " is-invalid" : "")
                }
                id="name"
                name="name"
                value={securityContext.name}
                onChange={handleSecurityContextChange}
              />
              <ErrorMessage
                name="name"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="password_policy"
                className="font-weight-bold d-block"
              >
                Password Policy Change
              </label>
              <Radio.Group
                name="password_policy"
                onChange={handleSecurityContextChange}
                value={securityContext.password_policy}
                className={
                  errors.password_policy && touched.password_policy
                    ? " is-invalid"
                    : ""
                }
              >
                <Radio value="weekly">Weekly</Radio>
                <Radio value="monthly">Monthly</Radio>
                <Radio value="quarterly">Quarterly</Radio>
                <Radio value="yearly">Yearly</Radio>
              </Radio.Group>
              <ErrorMessage
                name="password_policy"
                component="div"
                className="invalid-feedback"
              />
            </div>

            <div className="form-group mt-3 ">
              <label htmlFor="login_period" className="font-weight-bold">
                Login Period
              </label>

              <div className="row mt-2 ">
                <div className="col-md-6">
                  <label className="mr-3">High</label>

                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    onChange={onChange}
                    name="login_period_start"
                    className={
                      errors.login_period_start && touched.login_period_start
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <ErrorMessage
                    name="login_period_start"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="col-md-6">
                  <label className="mr-3">Low</label>
                  <TimePicker
                    use12Hours
                    format="h:mm A"
                    onChange={onLowChange}
                    name="login_period_end"
                    className={
                      errors.login_period_end && touched.login_period_end
                        ? " is-invalid"
                        : ""
                    }
                  />
                  <ErrorMessage
                    name="login_period_end"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <label
                  htmlFor="security_context_code"
                  className="font-weight-bold"
                >
                  Security Context Code
                </label>
                <input
                  type="text"
                  className={
                    "form-control" +
                    (errors.security_context_code &&
                    touched.security_context_code
                      ? " is-invalid"
                      : "")
                  }
                  id="security_context_code"
                  name="security_context_code"
                  value={securityContext.security_context_code}
                  onChange={handleSecurityContextChange}
                />
                <ErrorMessage
                  name="security_context_code"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="max_timeout" className="font-weight-bold">
                  Idle Maximum Timeout(minutes)
                </label>
                <select
                  className={
                    "form-control" +
                    (errors.max_timeout && touched.max_timeout
                      ? " is-invalid"
                      : "")
                  }
                  id="max_timeout"
                  name="max_timeout"
                  value={securityContext.max_timeout}
                  onChange={handleSecurityContextChange}
                >
                  <option value="">Select timeout</option>
                  <option value={10}>10 minutes</option>
                  <option value={20}>20 minutes</option>
                </select>
                <ErrorMessage
                  name="max_timeout"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label htmlFor="token_ttl" className="font-weight-bold">
                  Token Time to Live(hours)
                </label>
                <select
                  className={
                    "form-control" +
                    (errors.token_ttl && touched.token_ttl ? " is-invalid" : "")
                  }
                  id="token_ttl"
                  name="token_ttl"
                  value={securityContext.token_ttl}
                  onChange={handleSecurityContextChange}
                >
                  <option value="">Select time for token to live</option>
                  <option value={1}>1</option>
                  <option value="weekly">2</option>
                </select>
                <ErrorMessage
                  name="token_ttl"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group ">
                <label htmlFor="allowed_channels" className="font-weight-bold">
                  Allowed Channels
                </label>
                <MultiSelect
                  options={[
                    { label: "Web", value: "web" },
                    { label: "IOS", value: "ios" },
                  ]}
                  name="allowed_channels"
                  value={securityContext.allowed_channels}
                  onChange={handleChannelsSelected}
                  labelledBy={"Select"}
                  className={
                    errors.allowed_channels && touched.allowed_channels
                      ? " is-invalid"
                      : ""
                  }
                />
                <ErrorMessage
                  name="allowed_channels"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group ">
                <label htmlFor="allowed_browsers" className="font-weight-bold">
                  Allowed Browser
                </label>
                <MultiSelect
                  options={[
                    { label: "Chrome", value: "chrome" },
                    { label: "Edge", value: "edge" },
                    { label: "Safari", value: "safari" },
                    { label: "Mozilla", value: "mozilla" },
                  ]}
                  name="allowed_browsers"
                  value={securityContext.allowed_browsers}
                  onChange={handleBrowserSelected}
                  labelledBy={"Select"}
                  className={
                    errors.allowed_browsers && touched.allowed_browsers
                      ? " is-invalid"
                      : ""
                  }
                />

                <ErrorMessage
                  name="allowed_browsers"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group ">
                <label htmlFor="browser_version" className="font-weight-bold">
                  Browser Version
                </label>
                <MultiSelect
                  options={[
                    { label: "version 1", value: 1 },
                    { label: "version 2", value: 2 },
                  ]}
                  name="browser_version"
                  value={securityContext.browser_version}
                  onChange={handleBrowserVersionSelected}
                  labelledBy={"Select"}
                  className={
                    errors.browser_version && touched.browser_version
                      ? " is-invalid"
                      : ""
                  }
                />
                <ErrorMessage
                  name="browser_version"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group ">
                <label htmlFor="allowed_devices" className="font-weight-bold">
                  Allowed Devices
                </label>
                <MultiSelect
                  options={[
                    { label: "android", value: "android" },
                    { label: "web", value: "web" },
                    { label: "ios", value: "ios" },
                  ]}
                  name="allowed_devices"
                  value={securityContext.allowed_devices}
                  onChange={handleDevicesSelected}
                  labelledBy={"Select"}
                  className={
                    errors.allowed_devices && touched.allowed_devices
                      ? " is-invalid"
                      : ""
                  }
                />
                <ErrorMessage
                  name="allowed_devices"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group ">
                <label htmlFor="device_version" className="font-weight-bold">
                  Device Version
                </label>
                <MultiSelect
                  options={[
                    { label: "1", value: 1 },
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                  ]}
                  name="device_version"
                  value={securityContext.device_version}
                  onChange={handleDeviceVersionSelected}
                  labelledBy={"Select"}
                  className={
                    errors.device_version && touched.device_version
                      ? " is-invalid"
                      : ""
                  }
                />
                <ErrorMessage
                  name="device_version"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="two_factor_auth"
                  className="font-weight-bold d-block"
                >
                  Two Factor Authentication
                </label>

                <Radio.Group
                  name="two_factor_auth"
                  onChange={handleSecurityContextChange}
                  value={securityContext.two_factor_auth}
                  className={
                    errors.two_factor_auth && touched.two_factor_auth
                      ? " is-invalid"
                      : ""
                  }
                >
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
                <ErrorMessage
                  name="two_factor_auth"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="holiday_login"
                  className="font-weight-bold d-block"
                >
                  Holiday Login
                </label>
                <Radio.Group
                  name="holiday_login"
                  onChange={handleSecurityContextChange}
                  value={securityContext.holiday_login}
                  className={
                    errors.holiday_login && touched.holiday_login
                      ? " is-invalid"
                      : ""
                  }
                >
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
                <ErrorMessage
                  name="holiday_login"
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="form-group  mt-3">
                <label
                  htmlFor="description"
                  value={securityContext.description}
                  onChange={handleSecurityContextChange}
                  className="font-weight-bold"
                >
                  Description
                </label>
                <textarea
                  className={
                    "form-control" +
                    (errors.description && touched.description
                      ? " is-invalid"
                      : "")
                  }
                  id="description"
                  rows={3}
                  name="description"
                  value={securityContext.description}
                  onChange={handleSecurityContextChange}
                  defaultValue={""}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                color="primary"
                variant="contained"
                type="submit"
                disabled={securityContext.loading}
                className="btn btn-success px-5 my-4 "
              >
                {securityContext.loading && (
                  <i
                    className="fa fa-circle-notch fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {securityContext.loading && (
                  <span className="text-capitalize">Please wait...</span>
                )}
                {!securityContext.loading && (
                  <span className="text-capitalize"> Save</span>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

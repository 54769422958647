import React from "react";
import Dashboard from "./Dashboard";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";
import './dashboard.css'

const DashboardContainer = () => {
  async function fetch_intermediary_details() {
    let resp = await (await api_srv).get_intermediary_type();
    return resp.intermediary_details;
  }

  const { isLoading, isSuccess, data } = useQuery(
    "details",
    fetch_intermediary_details
  );

  return (
    <div>
      {isLoading && <Loader />}
      {isSuccess && <Dashboard data={data} />}
    </div>
  );
};

export default DashboardContainer;

import React, { useContext } from 'react';
import Credentials from '.';
import { AuthContext } from 'context/AuthContext';
import { AlertsContext } from 'context/AlertsContext';
import { useHistory } from 'react-router-dom';
import { api_srv } from 'services';

const CredentialsContainer = () => {
	const history = useHistory();

	// context
	const { alert, setAlert, credentials, setCredentials, user, setUser } = useContext(AuthContext);
	const { setShowAlert } = useContext(AlertsContext);

	const names = `${credentials.firstName}  ${credentials.lastName}`;

	const handleCredentialsChange = (evt) => {
		const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
		setCredentials({
			...credentials,
			[evt.target.name]: value,
		});
	};

	const togglePassword = () => {
		setCredentials({ ...credentials, showPassword: !credentials.showPassword });
	};

	const toggleConfirmPassword = () => {
		setCredentials({
			...credentials,
			showConfirmPassword: !credentials.showConfirmPassword,
		});
	};

	const toggleAcceptTerms = () => {
		setCredentials({ ...credentials, tnc: !credentials.tnc });
	};

	function toggleCredentialPassword() {
		setUser({ ...user, showPassword: !user.showPassword });
	}

	async function submit_credentials() {
		try {
			console.log('WE ARE AT START POINT');
			setCredentials({ ...credentials, loading: true });
			let reg_id = localStorage.getItem('REGISTRATION_ID');
			const id = atob(reg_id);
			await (await api_srv).submitCredentials(id, names, credentials);

			history.push('/login');
			setCredentials({
				...credentials,
				tnc: '',
				contact: '',
				password: '',
				username: '',
				confirmPassword: '',
			});
			localStorage.removeItem('REG_EMAIL');
		} catch (err) {
			let error = await err;
			setCredentials({
				...credentials,
				tnc: '',
				contact: '',
				password: '',
				username: '',
				confirmPassword: '',
			});
			console.log('THE ERROR CREDENTIALS HERE', error);
			setAlert({ ...alert, message: error, variant: 'danger' });
			setShowAlert(true);
			console.log('WE ARE AT ERROR POINT');
		}
	}

	return (
		<Credentials
			state={credentials}
			handleChange={handleCredentialsChange}
			togglePassword={togglePassword}
			toggleConfirmPassword={toggleConfirmPassword}
			toggleAcceptTerms={toggleAcceptTerms}
			toggleCredentialPassword={toggleCredentialPassword}
			submit_credentials={submit_credentials}
			alert={alert}
		/>
	);
};

export default CredentialsContainer;

import React, { useContext, useEffect } from 'react';
import { Button, Checkbox, List, Form } from 'antd';
import { KycContext } from '../../../context/KycContext';
import { DashboardContext } from '../../../context/DashboardContext';

export default function Benefits(props) {
	// Access Context
	const { prev, next } = useContext(KycContext);
	const {
		benefits,
		setBenefits,
		checked,
		setChecked,
		asset,
		assetLimits,
		setAssetLimits,
		fetch_optional_benefits,
		quotations,
	} = useContext(DashboardContext);

	useEffect(() => {
		async function init() {
			try {
				let option_response = await fetch_optional_benefits(atob(props.id));
				await find_optional_benefits(option_response);
			} catch (err) {
				console.log(err);
			}
		}
		init();
	}, []);

	const handleLimitChange = (e) => {
		e.preventDefault();
		let limit = {
			benefit_code: e.target.id,
			limit: e.target.value,
			benefit_name: e.target.name,
		};
		let arr = assetLimits;
		var index = arr.findIndex(function (o) {
			return o.benefit_code === e.target.id;
		});

		if (index !== -1) {
			assetLimits.splice(index, 1);
			setAssetLimits((assetLimits) => [...assetLimits, limit]);
		} else {
			setAssetLimits((assetLimits) => [...assetLimits, limit]);
		}
	};

	const find_optional_benefits = (quotations) => {
		let tempBenefits = quotations.benefits.product_benefits;
		const optional_benefit = tempBenefits.filter((benefit) => benefit.benefit_type === 'OPTIONAL');

		setBenefits(optional_benefit);
	};

	const onGroupChange = (checkedList) => {
		setChecked({
			...checked,
			checkedList,
			checkAll: checkedList.length === benefits.length,
		});
	};

	let risk = [];
	Object.keys(asset).map((key) => {
		risk.push({
			name: key,
			value: asset[key],
		});
	});

	const onFinish = (values) => {
		console.log('Success:', values);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<div>
			{quotations.loading ? (
				'Loading....'
			) : (
				<>
					<h5 className="upload-title text-center ">Select all your preferential benefits</h5>
					<Form
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Checkbox.Group value={checked.checkedList} onChange={onGroupChange} style={{ width: '100%' }}>
							<List
								bordered
								dataSource={benefits}
								renderItem={(item) => (
									<List.Item>
										<Checkbox
											name={item.name}
											value={item.code}
											id={item.code}

											// onChange={toggleInput}
										>
											<span className="font-weight-bold">{item.name}</span>
											<i className="d-block pl-4">~{item.description}</i>

											<div>
												<input
													name={item.name}
													id={item.code}
													type="number"
													placeholder={`max limit is ${item.free_limit}`}
													onBlur={handleLimitChange}
													className="ml-4 wd-250 mt-2 form-control border"
												/>
											</div>
										</Checkbox>
									</List.Item>
								)}
							/>
						</Checkbox.Group>
					</Form>
					<footer>
						<Button
							disabled={quotations.loading}
							className="btn-blue float-right mb-3 text-white mt-md-5 px-4 py-1 rounded "
							type="submit"
							onClick={next}
						>
							Next
						</Button>
						<Button
							className="btn-secondary float-right mr-3  mb-3 mt-md-5  px-4 py-1 rounded "
							type="submit"
							onClick={prev}
						>
							Back
						</Button>
					</footer>
				</>
			)}
		</div>
	);
}

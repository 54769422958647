import React, { useContext, useState } from "react";
import { ModalContext } from "context/ModalContext";
import { api_srv } from "services";
import { Select, Form } from "antd";
import { useQuery } from "react-query";
import { show_toast } from "utils/helpers";

export default function AddChatGroup({ fetch_user_groups }) {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { modalClose } = useContext(ModalContext);
  const { Option } = Select;
  const [form] = Form.useForm();

  function handleChatGroupChange(value) {
    setSelectedUsers(value);
    console.log(`selected ${value}`);
  }

  const list_chat_users = async () => {
    let resp = await (await api_srv).list_users();
    console.log("fetched chat users", resp);
    return resp.users;
  };

  const add_user = async () => {
    setLoading(true);
    try {
      console.log("THE USERS TO BE ADDED", selectedUsers);
      let resp = await (await api_srv).add_chat_users_to_group(selectedUsers);
      console.log("THE RESPONSE FROM ADDING USERS", resp);
      setLoading(false);
      modalClose();
      show_toast("User added successfully", "success");
    } catch (err) {
      let error = await err;
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    add_user();
  };
  const { data, isSuccess, isError, isLoading } = useQuery(
    "chat users",
    list_chat_users
  );

  return (
    <>
      {isLoading && "Loading Users..."}
      {isSuccess && (
        <Form
          form={form}
          layout="vertical"
          name="control-hooks"
          onFinish={onFinish}
        >
          <Form.Item
            name="users"
            label="Select Users to add to Group"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={handleChatGroupChange}
            >
              {data.map((option) => (
                <Option key={option.key} value={option.user_id}>
                  {option.names} ~ <i>{option.username}</i>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-center">
              <button
                disabled={loading}
                variant="contained"
                type="submit"
                className="btn btn-success px-5 my-4 "
              >
                {loading && (
                  <i
                    className="fa fa-circle-notch fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                )}
                {loading && (
                  <span className="text-capitalize">Adding Users...</span>
                )}
                {!loading && <span className="text-capitalize"> Add</span>}
              </button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { KycContext } from 'context/KycContext';
import Request from 'components/stepper/request-stepper';

const steps = [
	{
		title: ' Select Class',
		content: 1,
	},
	{
		title: ' Select Product',
		content: 2,
	},
	{
		title: 'Product Details',
		content: 3,
	},

	{
		title: 'Confirm',
		content: 4,
	},
	{
		title: 'Finish',
		content: 5,
	},
];

const CreateRequest = (props) => {
	const { current } = useContext(KycContext);

	return (
		<div>
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Create quotation request</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Request />
					<div className="steps-content ">{steps[current].content}</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default CreateRequest;

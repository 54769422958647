import React, { useState, createContext } from "react";
import {api_srv} from '../services'
import{message} from 'antd'

const DirectorContext = createContext();
const DirectorContextProvider = (props) => {


  // states

  const [newDirector, setNewDirector] = useState({
    title:'',
    type:'',
    residency:'',
    firstName: "",
    lastName: "",
    director_pin: "",
    id_number: "",
    email: "",
    contact: "",
    address: "",
    director_passport:'',
    postal_code:'',
 
  });
  const [kra, setKra] = useState([]);
  const [id, setId] = useState([]);
  const [passport, setPassport] = useState([]);
  const [logo, setLogo] = useState({
    loading:false
  });


  // handleChanges

  const handleChange = (evt) => {
    const value = evt.target.value;
    setNewDirector({
      ...newDirector,
      [evt.target.name]: value,
    });
  };


  const handleSelect=(value)=>{
    setNewDirector({ ...newDirector, title: value });
  }


  const handleResidentSelect=(value)=>{
    setNewDirector({ ...newDirector, residency: value });
  }


  const handleTypeSelect=(value)=>{
    setNewDirector({ ...newDirector, type: value });
  }


  const handlePostalCode=(value)=>{
    setNewDirector({ ...newDirector, postal_code: value });
  }


  const handleKraChange = (info) => {
  let fileList = [...info.fileList];

  // 1. Limit the number of uploaded files to one

  fileList = fileList.slice(-1);

  // 2. Read from response and show file link
  fileList = fileList.map((file) => {
  if (file.response) {
    file.url = file.response.url;
  }
  return file;
  });

  setKra(fileList);
  };


  const handleIdChange = (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setId(fileList);
    };

  
  const handlePassportChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setPassport(fileList);
  };


  const upload_director_id = async (options) => {
    const { onSuccess, onError, file} = options;

    try {
      let res = await (await api_srv).upload_api('kyc',"director_id",file)
      onSuccess("Ok");
      message.success(`${file.name}'upload successfully.`);
    } catch (err) {
      
      onError({ err });
      message.error(`${file.name} upload failed.`);
      setId({ ...id, id: [] });
    }
  };


  const upload_director_passport = async (options) => {
    const { onSuccess, onError, file} = options;

    try {
      let res = await (await api_srv).upload_api('kyc',"director_passport",file)
      onSuccess("Ok");
      message.success(`${file.name}'upload successfully.`);
    } catch (err) {
      
      onError({ err });
      message.error(`${file.name} upload failed.`);
      setPassport({ ...passport, passport: [] });
    }
  };


  const upload_director_kra = async (options) => {
    const { onSuccess, onError, file} = options;

    try {
      let res = await (await api_srv).upload_api('kyc',"director_kra",file)
      onSuccess("Ok");
      message.success(`${file.name}'upload successfully.`);
    } catch (err) {
      
      onError({ err });
      message.error(`${file.name} upload failed.`);
      setKra({ ...kra, kra: [] });
    }
  };


  const uploadDirectorImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      let res = await (await api_srv).upload_api('kyc',"image",file)
     // const res = await ();
      onSuccess("Ok");
    } catch (err) {
      console.log("Eroor: ", err);
      onError({ err });
    }
  };

  
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  const handle_director_image_change = (info) => {
    if (info.file.status === "uploading") {
      setLogo({ loading: true, });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>{
        
        setLogo({
          imageUrl,
          loading: false,
        })
      });
    }
  };

  
  return (
    <DirectorContext.Provider
      value={{
        kra, 
        setKra,
        id,
        setId,
        passport,
        logo,
        setLogo,
        setPassport,
        newDirector,
        setNewDirector,
        handleChange,
        handleSelect,
        handleResidentSelect,
        handleTypeSelect,
        handlePostalCode,
        handleKraChange,
        handleIdChange,
        handlePassportChange,
        upload_director_id,
        upload_director_passport,
        upload_director_kra,
        uploadDirectorImage,
        handle_director_image_change}}
    >
      {props.children}
    </DirectorContext.Provider>
  );
};

const KycConsumer = DirectorContext.Consumer;
export { DirectorContextProvider, KycConsumer, DirectorContext };
import React from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import NewUser from "../forms/Add_User"

const AddUser = (props) => {
  return (
    <div>
      <Modal
        show={props.show} 
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey text-center">Add a User</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewUser fetchUsers={props.fetchUsers}/>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddUser;

import React from "react";
import "./input.css";

function InputField({
  label,
  type,
  name,
  handleChange,
  errorMessage,
  isValid,
  value,
  rows = "3",
}) {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <textarea
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        rows={rows}
        className="d-block form-control"
      />
      {errorMessage && !isValid && (
        <span className="error">{errorMessage}</span>
      )}
    </div>
  );
}

export default React.memo(InputField);

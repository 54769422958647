import React, { useContext } from "react";
import { KycContext } from "context/KycContext";
import { ModalContext } from "context/ModalContext";
import "assets/css/dashboard.css";
import { Button, Select, Form, Input } from "antd";
import { useQuery, useMutation } from "react-query";
import Loader from "components/widgets/loader/ComponentLoader";
import {
  get_subclass_products,
  list_special_codes,
  negotiateRate,
} from "services/apiSrv";
import { show_toast } from "utils/helpers";

const AddToGroup = ({ class_id, system_scheme_group_ref, refetch }) => {
  const { modalClose } = useContext(ModalContext);
  async function fetch_products() {
    let resp = await get_subclass_products(class_id);

    return resp.products;
  }
  async function fetch_codes() {
    let resp = await list_special_codes();
    console.log("special codes", resp);
    return resp.intermediary_tokens;
  }

  const { data, isSuccess, isError, isLoading } = useQuery(
    "prods",
    fetch_products
  );
  const {
    data: tokens,
    isSuccess: isSuccessTokens,
    isError: isErrorTokens,
    isLoading: isLoadingTokens,
  } = useQuery("tokens", fetch_codes);
  const { Option } = Select;
  const { TextArea } = Input;
  const { prev, next } = useContext(KycContext);

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const negotiate = useMutation(negotiateRate, {
    onSuccess: (data) => {
      modalClose();
      refetch();
      show_toast("Saved  successfully", "success");
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
    },
  });
  const onFinish = (values) => {
    console.log(values);
    let val = {};
    val = { ...values, system_scheme_group_ref: system_scheme_group_ref };
    negotiate.mutate(val);
  };

  return (
    <>
      {isLoading && isLoadingTokens && <Loader />}
      {isSuccess && isSuccessTokens && (
        <Form
          name="negotiate Rate"
          onFinish={onFinish}
          className=""
          scrollToFirstError
          validateMessages={validateMessages}
          layout="vertical"
        >
          <Form.Item
            label="Select Product"
            name="product_id"
            labelAlign="left"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder="Select..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {data.map((option) => (
                <Option key={option.product_id} value={option.product_id}>
                  {option.product_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Rate Name"
            name="scheme_rate_name"
            labelAlign="left"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Rate Reference"
            name="scheme_rate_ref"
            labelAlign="left"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Select Token"
            name="system_token_ref"
            labelAlign="left"
          >
            <Select
              showSearch
              placeholder="Select..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {tokens.map((option) => (
                <Option value={option.system_token_ref}>
                  {option.token_description}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Brief Description"
            name="scheme_rate_description"
            rules={[{ required: true }]}
          >
            <TextArea rows={3} />
          </Form.Item>

          <Form.Item>
            <Button
              className="btn-blue text-white float-right mb-5  px-4 py-1 rounded "
              htmlType="submit"
            >
              {negotiate.isLoading ? (
                <span>
                  <i
                    className="fa fa-circle-notch fa-spin"
                    style={{ marginRight: "5px" }}
                  />
                  Please wait...
                </span>
              ) : (
                "Negotiate"
              )}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
export default AddToGroup;

import React from "react";
import Navbar from "components/dashboard-nav";
import Sidebar from "components/dashboard-sidebar";
import { Input } from "antd";
import * as Icon from "react-feather";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import Loader from "../../components/widgets/loader";
import Moment from "react-moment";
import NoData from '../../components/no-data/NoData'

export default function Claims({
  state,
  view_claim_details,
  search,
  paginate,
  setPaginate,
  numEachPage,
  handlePaginationChange,
}) {
  const { Search } = Input;

  // function searchMe(evt){
  //   evt.preventDefault();

  //   if (evt.target.value){
  //     let filteredItems = state.claims.claims.filter((item)=>{

  //   return(
  //     // item.id.toLowerCase().includes(search.toLowerCase()) ||
  //     item.names.toLowerCase().includes(search.toLowerCase())||
  //     item.claim_type.toLowerCase().includes(search.toLowerCase()) ||
  //     item.created_date.toLowerCase().includes(search.toLowerCase())||
  //     item.row.claim_decision.toLowerCase().includes(search.toLowerCase())
  //     // item.id &&    item.name &&
  //     // Item.date  &&    item.status
  //     // .match(search)

  //   )
  //     })
  //   setSearch(evt.target.value)
  //   setAllClaims({...state,claims:filteredItems})

  //   }else{
  //     setSearch("")
  //     setAllClaims({...state,claims:state.claims.claims})
  //   }
  // }


  
  return (
    <>
     
              <div className="pageheader pd-t-25 pd-b-35">
                {/* <div className="pd-t-5 pd-b-5">
                  <h1 className="pd-0 mg-0 tx-20"><Icon.Shield  size={36} className="pr-2 text-primary" />Claims</h1>
                </div> */}
                <div className="breadcrumb pd-0 mg-0">
                  <a className="breadcrumb-item" href="index.html">
                    <i className="icon ion-ios-home-outline" /> Home
                  </a>
                  <a className="breadcrumb-item active" href>
                    Dashboard
                  </a>
                </div>
              </div>
              { state.length===0  ?  <NoData message="You appear not to have any claims at the moment"/>:

              <div className="col-lg-12 card rounded-10 col-xl-12">
                <div className="mg-b-20">
                  <div className="card-header">
                    <div className="pd-t-5 pd-b-5 pd-l-5">
                      <h1 className="tx-20">
                        <Icon.Shield size={36} className="pr-2 text-primary" />
                        Claims
                      </h1>
                    </div>
                    <Search
                      placeholder="search your claim"
                      type="search"
                      value={search}
                      // onChange={searchMe}
                      className="ml-auto mg-r-10"
                      style={{ width: 200 }}
                    />
                  </div>
                  <div className="card-body collapse show" id="studentDetails">
                    <div className="">
                      <table className="table table-hover mb-0">
                        <thead className="tx-13 tx-uppercase">
                          <tr className="bg-light">
                            <th>Name of Client</th>
                            <th>Claim Type</th>
                            <th>Date Of Claim</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            state.length > 0 &&
                            state
                              .slice(paginate.minValue, paginate.maxValue)
                              .map((row) => {
                                return (
                                  <tr>
                                    <td>{row.names}</td>
                                    <td>{row.claim_type}</td>
                                    <td>
                                      <Moment
                                        format="DD MMM YYYY"
                                        date={row.created_date}
                                      />
                                    </td>
                                    <td>
                                      <span className="text-success">
                                        {row.claim_decision}
                                      </span>
                                    </td>
                                    <td >
                                      
                                    <a
                      href
                      className="btn btn-sm btn-label-primary"
                      onClick={() =>
                        view_claim_details(row.claim_id)
                      }
                    >
                      View
                    </a>
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                   className="ml-auto"
                  responsive={true}
                  dataSource={state}
                  total={state.length}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={numEachPage}
                  defaultCurrent={1}
                  onChange={handlePaginationChange}
                />
              </div>
}
    
    </>
  );
}

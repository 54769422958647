import React, { useState, useContext, useRef } from 'react';
import * as Icon from 'react-feather';
import { useHistory } from 'react-router-dom';
import { DashboardContext } from 'context/DashboardContext';
import { ModalContext } from 'context/ModalContext';
import { Button, Input, Pagination } from 'antd';
import Loader from 'components/widgets/loader';
import NoData from 'components/no-data/NoData';
import AddConfig from 'components/widgets/modals/AddSettlementConfig';

export default function Config({ configs, refetch }) {
	// access context

	const { paginate, numEachPage, handlePaginationChange } = useContext(DashboardContext);
	const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

	const { Search } = Input;
	const [search, setSearch] = useState(null);
	const history = useHistory();
	// handle search

	const searchSpace = (event) => {
		let keyword = event.target.value;
		setSearch(keyword);
	};

	const viewConfigDetails = (id) => {
		history.push(`/dashboard/settlement/config/${id}`);
	};

	const items =
		configs.length === 0
			? null
			: configs.departments
					.filter((data) => {
						if (search == null) return data;
						else if (
							data.department_code.toLowerCase().includes(search.toLowerCase()) ||
							data.department_name.toLowerCase().includes(search.toLowerCase()) ||
							data.department_description.toLowerCase().includes(search.toLowerCase())
						) {
							return data;
						}
					})
					.slice(paginate.minValue, paginate.maxValue)
					.map((row) => {
						return (
							<tr>
								<td>{row.department_code}</td>
								<td>{row.department_name}</td>
								<td>{row.department_name}</td>
								<td>{row.department_name}</td>

								<td>
									<div className="dropdown">
										<a
											href
											aria-haspopup="true"
											aria-expanded="false"
											className="btn btn-sm btn-label-primary"
											onClick={() => viewConfigDetails(1)}
										>
											View
										</a>
									</div>
								</td>
							</tr>
						);
					});

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			<AddConfig show={modalShow} onHide={modalClose} refetch={refetch} />
			<Button className="text-white btn-blue rounded float-right mb-3" onClick={() => setModalShow(true)}>
				Add Configuration
			</Button>

			{configs.length === 0 ? (
				<NoData message="No settlement configurations currently" />
			) : (
				<>
					<div className="col-lg-12 card rounded-10 col-xl-12">
						<div className="mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5 pd-l-5">
									<h1 className=" tx-18">
										<Icon.Settings size={30} className="pr-2 text-primary" />
										Settlement Configurations
									</h1>
								</div>
								<Search
									placeholder="Search Department"
									type="search"
									onChange={(e) => searchSpace(e)}
									className="ml-md-auto mg-r-10"
									style={{ width: 200 }}
								/>
							</div>
							<div className="mg-b-20">
								<div className="card-body pd-0 collapse show" id="customerDetails">
									<div className="table-responsive">
										<table className="table table-hover mg-0">
											<thead className="tx-dark tx-uppercase tx-12 tx-bold">
												<tr className="bg-light">
													<th className="">Name</th>
													<th className="">Reference</th>
													<th>Type</th>
													<th>Status</th>

													<th>Action</th>
												</tr>
											</thead>
											<tbody>{items}</tbody>
										</table>
									</div>
								</div>
							</div>
							<Pagination
								className="ml-auto"
								responsive={true}
								dataSource={configs.departments}
								total={configs.departments.length}
								showTotal={(total) => `Total ${total} items`}
								defaultPageSize={numEachPage}
								defaultCurrent={1}
								onChange={handlePaginationChange}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}

import React from 'react';
import Incentives from './Incentives';
import { api_srv } from 'services';
import { useQuery } from 'react-query';
import Loader from 'components/widgets/loader';

const IncentivesContainer = () => {
	async function get_my_underwriters() {
		let resp = await (await api_srv).fetch_my_underwriters();
		return resp.underwritters;
	}
	const { data, isLoading, isSuccess } = useQuery('underwriters', get_my_underwriters);

	return (
		<div>
			{isLoading && <Loader />}
			{isSuccess && <Incentives data={data} />}
		</div>
	);
};

export default IncentivesContainer;

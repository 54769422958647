import React, { useState, useContext } from "react";
import { Select } from "antd";
import {
  assign_relationship_manager,
  get_relationship_managers,
} from "services/apiSrv";
import { useMutation } from "react-query";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";

export default function RelationshipManager({ users }) {
  const { Option } = Select;
  const { modalClose } = useContext(ModalContext);

  const [selectedManager, setSelectedManager] = useState();

  const _assign = useMutation(assign_relationship_manager, {
    onSuccess: (data) => {
      get_relationship_managers();

      modalClose();
      show_toast("Manager added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  function handleChange(e, value) {
    setSelectedManager(value);
  }
  console.log("SELCTED MANAGERS", selectedManager);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        _assign.mutate(selectedManager);
      }}
    >
      <div className="form-row">
        <div className="col-md-12">
          <div className="form-group mt-0 mb-3">
            <label className="">Assign a Relationship Manager</label>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              defaultValue={[]}
              onChange={handleChange}
            >
              {users.map((option, i) => (
                <Option key={option.user_id} value={option.entity_id}>
                  {option.names}~{option.email}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mb-4">
        <button
          className="mx-auto btn btn-success"
          type="submit"
          style={{ width: "220px" }}
        >
          {_assign.isLoading && (
            <i
              className="fa fa-circle-notch fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {_assign.isLoading && (
            <span className="text-capitalize">Please wait...</span>
          )}
          {!_assign.isLoading && (
            <span className="text-capitalize">Submit</span>
          )}
        </button>
      </div>
    </form>
  );
}

import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../../assets/css/dashboard.css';
import IncentiveForm from '../forms/addIncentive/AddIncentiveContainer';

const AddIncentive = (props) => {
	return (
		<div>
			<Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title>
						<h5 className="text-grey">Add Incentive</h5>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<IncentiveForm />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default AddIncentive;

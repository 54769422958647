import React from "react";
import RM from "./RM";
import { api_srv } from "services";
import { useQuery } from "react-query";
import Loader from "components/widgets/loader";

const RMContainer = () => {
  async function get_my_rms() {
    let resp = await (await api_srv).get_relationship_managers();
    console.log("THE MANAGERS", resp);
    return resp.rm;
  }
  async function get_my_users() {
    let resp = await (await api_srv).list_users();
    return resp.users;
  }
  const { data, isLoading, isSuccess } = useQuery(
    "relationship managers",
    get_my_rms
  );
  const {
    data: users,
    isLoading: usersLoading,
    isSuccess: successUsers,
  } = useQuery("my users", get_my_users);

  return (
    <div>
      {isLoading && usersLoading && <Loader />}
      {isSuccess && successUsers && <RM data={data} users={users} />}
    </div>
  );
};

export default RMContainer;

import React from "react";

import { useHistory, Link } from "react-router-dom";

import routes from "routes";
import { assign_open_lead } from "services/apiSrv";
import CustomTable from "components/widgets/table";
import { show_toast } from "utils/helpers";

const OpenLeads = ({ data }) => {
  const history = useHistory();

  async function confirm(id) {
    try {
      await assign_open_lead(id);
      show_toast("success", "Success", "Lead assigned successfully");
      history.push(routes.leads);
    } catch (err) {
      let error = await err;
    }
  }

  const tableData = {
    icon: "",
    title: "",
    table_fields: [
      { name: "Email", align: "c" },
      { name: "Date", align: "c" },
      { name: "Status", align: "c" },
      { name: "Action", align: "c" },
    ],
    search_fields: ["question_code", "answer"],
    row_data: [
      { type: "text", field: "lead_email", class: "center" },
      { type: "date", field: "created_date", class: "center" },
      { type: "status", field: "lead_status ", class: "center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "Assign",
            field: "lead_ref",
            method: confirm,
          },
        ],
      },
    ],
  };
  return (
    <div>
      <div className="pageheader">
        <div className="breadcrumb pd-0 mg-0 pd-b-20">
          <Link className="breadcrumb-item active" to={routes.dashboard}>
            <i className="icon ion-ios-home-outline " /> Home
          </Link>
          <Link className="breadcrumb-item ">Leads</Link>
        </div>
        <h5>Open Leads</h5>
      </div>

      <CustomTable
        table_fields={tableData.table_fields}
        row_data={tableData.row_data}
        table_data={data}
        title={tableData.title}
      />
    </div>
  );
};

export default OpenLeads;

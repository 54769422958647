import React from 'react';

export default function View_Report_Group() {
	return (
		<div className="page-inner">
			<div id="main-wrapper">
				<div className="pageheader pd-t-25 ">
					<div className="pd-t-5">
						<h1 className="pd-0 mg-0 tx-20">View Report Group</h1>
					</div>
					<div className="breadcrumb pd-0 mg-0">
						<a className="breadcrumb-item" href="index.html">
							<i className="icon ion-ios-home-outline" /> Home
						</a>
						<a className="breadcrumb-item active" href>
							Claims
						</a>
						<a className="breadcrumb-item active" href>
							View
						</a>
					</div>
				</div>

				<div className=" pd-20  m-auto">
					<h5 className="card-title bd-b pd-y-20">
						Report Group :
						<a href className="tx-dark">
							ABCDE
						</a>
					</h5>

					<div className=" pd-0 printing-area">
						<div className="card card-body bg-lighter">
							<div className="row">
								<div className="col-lg-4  d-lg-block">
									<div className="fs-12">
										<div className="mb-3">
											<strong className="d-inline-block wd-100">Name</strong>: Robert Njoroge
										</div>
										<div className="mb-3">
											<strong className="d-inline-block wd-100">Code</strong>: #45234MKL{' '}
										</div>
										<div className="mb-3">
											<strong className="d-inline-block wd-100">Created Date</strong>: 10/05/2020
										</div>
									</div>
								</div>
								<div className="col-lg-6  d-lg-block">
									<div className="mb-3">
										<strong className="d-inline-block wd-100">Status</strong>:{' '}
										<span className="text-warning">Pending</span>
									</div>
									<div className="">
										<strong className="d-inline-block wd-100">Description</strong>
									</div>
									<p className="fs-12">
										Objectively optimize multidisciplinary solutions with standards compliant
										materials. Uniquely incentivize cross-media internal or "organic" sources
										through virtual data. Energistically benchmark interdependent networks vis
										sticky partnerships.
									</p>
								</div>
							</div>
							<div className="mg-y-20 pd-20">
								{/* <button type="submit" className="btn btn-primary mg-t-5 mr-2" onClick={() => setModalShow(true)}><i className="far fa-sticky-note" /> Add a Note</button> */}
								<button type="button" className="btn btn-success mg-t-5 mr-2">
									<i className="fa fa-check" /> Approve
								</button>
								<button type="button" className="btn btn-info mg-t-5 mr-2">
									<i className="fa fa-edit" /> Edit
								</button>
								<button type="button" className="btn btn-secondary mg-t-5 mr-2">
									<i className="fa fa-print" /> Activate / De-activate
								</button>
								<button type="button" className="btn btn-secondary mg-t-5 ml-4 float-right">
									{' '}
									View Reports
								</button>
								<button type="button" className="btn btn-secondary mg-t-5 ml-4 float-right ">
									{' '}
									View User Groups
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useEffect, useState, useContext, useRef } from 'react';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import { DashboardContext } from 'context/DashboardContext';
import { ModalContext } from 'context/ModalContext';
import { Button, Input, Pagination, Avatar } from 'antd';
import Moment from 'react-moment';
import Loader from 'components/widgets/loader';
import NoData from 'components/no-data/NoData';
import AddUserChat from 'components/widgets/modals/AddUserChat';

export default function ChatUsers(props) {
	// access context

	const context = useContext(DashboardContext);
	const modalContext = useContext(ModalContext);
	const { setModalShow, modalShow, modalClose } = modalContext;

	// destructure

	const {
		quotationRequests,
		fetch_quotation_requests,
		paginate,
		numEachPage,
		handlePaginationChange,
		loading,
	} = context;

	const { Search } = Input;
	const [search, setSearch] = useState(null);

	useEffect(() => {
		try {
			fetch_quotation_requests();
		} catch (error) {
			console.log(error.response);
		}
	}, []);

	// image avatar initials

	var getInitials = function (string) {
		var names = string.split(' '),
			initials = names[0].substring(0, 1).toUpperCase();

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase();
		}
		return initials;
	};

	// handle search

	const searchSpace = (event) => {
		let keyword = event.target.value;
		setSearch(keyword);
	};

	const view_quotation_request = (id) => {
		props.history.push(`/quotation-requests/view/${id}`);
	};

	const items = quotationRequests
		.filter((data) => {
			if (search == null) return data;
			else if (
				data.names.toLowerCase().includes(search.toLowerCase()) ||
				data.created_date.toLowerCase().includes(search.toLowerCase()) ||
				data.request_status.toLowerCase().includes(search.toLowerCase()) ||
				data.remarks.toLowerCase().includes(search.toLowerCase())
			) {
				return data;
			}
		})
		.slice(paginate.minValue, paginate.maxValue)
		.map((row) => {
			let initials = getInitials(row.names);

			return (
				<tr>
					<td className="d-flex align-items-center">
						<div className=" mr-4">
							<Avatar
								size={40}
								style={{
									color: '#001f5f',
									backgroundColor: 'rgba(5,88,183,0.10)',
								}}
							>
								<small>{initials}</small>
							</Avatar>
						</div>
						<div className="d-block">
							<a href className="my-0 mt-1 ">
								{row.names}
							</a>
						</div>
					</td>
					<td>
						<div className="d-block">
							<a href className="my-0 mt-1 ">
								rm001
							</a>
						</div>
					</td>

					<td>
						<span
							className={`badge ${
								row.request_status === 'PENDING'
									? 'badge-warning'
									: row.request_status === 'CLOSED:REJECTED'
									? 'badge-danger'
									: 'badge-success'
							}`}
						>
							{row.request_status}
						</span>
					</td>
					<td>Best in Motor Product</td>

					<td>
						<div className="dropdown">
							<a
								href
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
								className="btn btn-sm btn-label-primary"
							>
								<Icon.MoreHorizontal />
							</a>
							<ul className="dropdown-menu dropdown-menu-left">
								<li className="dropdown-item">
									<Link to="/usermanagement/chat/view" className="dropdown-link tx-13">
										<Icon.Eye size={14} className="mr-2" />
										View
									</Link>
								</li>

								<li className="dropdown-item">
									<a className="dropdown-link tx-13 tx-gray-500" href>
										<Icon.Trash2 size={14} className="mr-2" />
										Delete
									</a>
								</li>
							</ul>
						</div>
					</td>
				</tr>
			);
		});

	return (
		<>
			<div className="pageheader pd-t-25 pd-b-35">
				<div className="breadcrumb pd-0 mg-0">
					<a className="breadcrumb-item" href="index.html">
						<i className="icon ion-ios-home-outline" /> Home
					</a>
					<a className="breadcrumb-item active" href>
						Dashboard
					</a>
				</div>
			</div>
			<AddUserChat show={modalShow} onHide={modalClose} />
			<Button className="btn-primary rounded-5 float-right mb-3" onClick={() => setModalShow(true)}>
				Add User
			</Button>

			{quotationRequests.length === 0 && !loading ? (
				<NoData message="No requests currently" />
			) : quotationRequests.length === 0 && loading ? (
				<Loader />
			) : (
				<>
					<div className="col-lg-12 card rounded-10 col-xl-12">
						<div className="mg-b-20">
							<div className="card-header">
								<div className="pd-t-5 pd-b-5 pd-l-5">
									<h1 className=" tx-18">
										<Icon.MessageSquare size={30} className="pr-2 text-primary" />
										User Chat Group
									</h1>
								</div>
								<Search
									placeholder="Search User"
									type="search"
									onChange={(e) => searchSpace(e)}
									className="ml-md-auto mg-r-10"
									style={{ width: 200 }}
								/>
							</div>
							<div className="mg-b-20">
								<div className="card-body pd-0 collapse show" id="customerDetails">
									<div className="table-responsive">
										<table className="table table-hover mg-0">
											<thead className="tx-dark tx-uppercase tx-12 tx-bold">
												<tr className="bg-light">
													<th className="">Name</th>
													<th className="">Staff Ref</th>
													<th>Status</th>
													<th>Description</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>{items}</tbody>
										</table>
									</div>
								</div>
							</div>
							<Pagination
								className="ml-auto"
								responsive={true}
								dataSource={quotationRequests}
								total={quotationRequests.length}
								showTotal={(total) => `Total ${total} items`}
								defaultPageSize={numEachPage}
								defaultCurrent={1}
								onChange={handlePaginationChange}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}

import React, { useContext, useState } from "react";

import {
  Row,
  Form,
  Col,
  Input,
  Select,
  Radio,
  Upload,
  Button,
  TimePicker,
  InputNumber,
  DatePicker,
} from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { UploadOutlined } from "@ant-design/icons";
import * as Icon from "react-feather";
import { useMutation } from "react-query";

import Location from "components/widgets/modals/AssetLocation";
import { DashboardContext } from "context/DashboardContext";
import { ModalContext } from "context/ModalContext";
import { KycContext } from "context/KycContext";
import { post_risk_form } from "services/apiSrv";
import car_brands from "utils/car_brands";
import { show_toast } from "utils/helpers";

const AssetForm = ({
  fields,
  title,
  id,
  systemRef,
  clientId,
  refetchRisks,
}) => {
  const { address, setAddress, coordinates, quotations, setCoordinates } =
    useContext(DashboardContext);
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const { setCurrent } = useContext(KycContext);

  const [selectedModel, setSelectedModel] = useState("");
  const postRiskForm = useMutation(post_risk_form, {
    onSuccess: (data) => {
      refetchRisks();
      modalClose();
      setCurrent(0);
      show_toast("Risk added successfully", "success");
    },
    onError: async (error) => {
      modalClose();
    },
  });

  const fileList = [
    {
      uid: "-1",
      name: "xxx.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
      thumbUrl:
        "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ];
  const { Option } = Select;

  const handleChange = (address) => {
    setAddress(address);
  };

  function getCarModels(selectedBrand) {
    let models = car_brands.filter((brand) => brand.brand === selectedBrand);
    return models;
  }

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 85 }} defaultValue="254">
        <Option value="254">+254</Option>
        <Option value="255">+255</Option>
        <Option value="256">+256</Option>
      </Select>
    </Form.Item>
  );

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      // get the lat and lng of the  result user has selected
      const { lat, lng } = await getLatLng(results[0]);
      setCoordinates({ ...coordinates, lat: lat, lng: lng });
      setAddress(value);
    } catch (error) {}
  };
  function onMakeChange(value) {
    setSelectedModel(value);
  }

  const onFinish = (values) => {
    switch (id) {
      case "RISK_FORM": {
        let body = {
          riskForm: values,
          system_quotation_request_ref: systemRef,
          client_id: clientId,
        };
        return postRiskForm.mutate(body);
      }
      default:
        return null;
    }

    // setAsset(values);
    // next();
    // console.log('Success ON FINISH:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed DYNAMIC:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      id={id}
    >
      <Location
        show={modalShow === "dynamicMap" ? true : false}
        onHide={modalClose}
      />
      {fields.length === 0 && quotations.loading ? (
        "Loading..."
      ) : (
        <>
          <h5 className="upload-title text-center pb-4">{title}</h5>

          <Row gutter={16}>
            {fields.map((field) => {
              console.log("CHECK FIELD,", field);
              switch (field.type_info.type) {
                case "text":
                  if (field.type_info.sub_type === "textarea") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} placeholder={field.hint} />
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "calender") {
                    return null;
                    // <Col span={12}>
                    //   <Form.Item
                    //     name={field.field_code}
                    //     label={field.field_label}

                    //   >
                    //     <DatePicker />
                    //   </Form.Item>
                    // </Col>
                  } else if (field.type_info.sub_type === "email") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: `${field.validation.is_required}`,
                              message: "Please input your E-mail!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "number") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            name={field.field_code}
                            min={1}
                            placeholder={field.hint}
                          />
                          {/* <Input name={field.field_code} placeholder={field.hint} /> */}
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "contact") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Input
                            addonBefore={prefixSelector}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    );
                  } else {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Input
                            name={field.field_code}
                            placeholder={field.hint}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }

                case "option":
                  if (field.type_info.sub_type === "select") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Select
                            placeholder={field.hint}
                            name={field.field_code}
                          >
                            {field.values.map((option) => (
                              <Option value={option}>{option}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "radio") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Radio.Group name={field.field_code}>
                            {field.values.map((option) => (
                              <Radio style={radioStyle} value={option}>
                                {option}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "date_time") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <TimePicker format="HH:mm" />
                        </Form.Item>
                      </Col>
                    );
                  } else if (
                    field.type_info.sub_type === "calender_from" ||
                    field.type_info.sub_type === "calender_to"
                  ) {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    );
                  } else if (
                    field.type_info.sub_type === "multiple_select_other"
                  ) {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder={field.hint}
                            name={field.field_code}
                          >
                            {field.values.map((option) => (
                              <Option value={option}>{option}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  } else if (
                    field.type_info.sub_type === "yes_qstn" ||
                    field.type_info.sub_type === "yes_no"
                  ) {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Radio.Group name={field.field_code}>
                            {field.values.map((option) => (
                              <Radio style={radioStyle} value={option}>
                                {option}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    );
                  } else if (
                    field.type_info.sub_type === "checkbox" ||
                    field.type_info.sub_type === "multiple_select_other"
                  ) {
                    return null;
                    //             <Col span={12}>
                    //               <Form.Item
                    //                 label={field.field_label}
                    //                 name={field.field_code}
                    //                 rules={[
                    //                   {
                    //                     required: `${field.validation.is_required}`,
                    //                     message: `${field.field_label} is required`,
                    //                   },
                    //                 ]}
                    //               >

                    //                   <Select
                    // mode="multiple"
                    // allowClear
                    // name={field.field_code}
                    // style={{ width: '100%' }}
                    // placeholder="Please select">

                    //                 {field.values.map((checkbox,i) => (
                    //                   <Option key={i}   value={checkbox} >{checkbox}</Option>

                    //                 ))}
                    //                 </Select>
                    //               </Form.Item>
                    //             </Col>
                  }
                  break;
                case "attachement":
                  if (field.type_info.sub_type === "document") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            defaultFileList={[...fileList]}
                            className="upload-list-inline"
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    );
                  } else {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={field.field_label}
                          name={field.field_code}
                          rules={[
                            {
                              required: `${field.validation.is_required}`,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            defaultFileList={[...fileList]}
                            className="upload-list-inline"
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    );
                  }
                case "search":
                  if (field.type_info.sub_type === "location") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: false,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <PlacesAutocomplete
                            value={address}
                            onChange={handleChange}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Location ...",
                                    className:
                                      "location-search-input form-control border ",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, i) => {
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#f5f5f5",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        className="input-suggestion"
                                        key={i}
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        <i className="material-icons">
                                          location_on{" "}
                                        </i>{" "}
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>

                          <a
                            href
                            onClick={() => setModalShow("dynamicMap")}
                            className="d-block text-primary"
                          >
                            <small className="text-primary">
                              or choose from Map <Icon.MapPin size={14} />
                            </small>
                          </a>
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "crsp_Make") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: false,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={field.hint}
                            optionFilterProp="children"
                            onChange={onMakeChange}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {car_brands.map((brand) => (
                              <Option id={brand.brand} value={brand.brand}>
                                {brand.brand}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  } else if (field.type_info.sub_type === "crsp_Model") {
                    return (
                      <Col span={12}>
                        <Form.Item
                          name={field.field_code}
                          label={field.field_label}
                          rules={[
                            {
                              required: false,
                              message: `${field.field_label} is required`,
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder={field.hint}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {selectedModel === ""
                              ? null
                              : getCarModels(selectedModel)[0].models.map(
                                  (model) => (
                                    <Option key={model} value={model}>
                                      {model}
                                    </Option>
                                  )
                                )}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  }
                  break;
                default:
                  console.log("option here");
              }
            })}
          </Row>
          {/* <footer>
        <Button
        
          className="btn-blue float-right mb-3 text-white mt-md-5 px-4 py-1 rounded "
          htmlType="submit"
          onClick={next}
        >
          Next
              

        </Button>
       
      </footer> */}
        </>
      )}
    </Form>
  );
};

export default AssetForm;

import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Switch, useHistory } from "react-router-dom";

// Layouts
import routes from "./routes";
import PublicLayout from "./shared/layout/PublicLayout";
import AuthLayout from "./shared/layout/AuthLayout";

// Public Pages
import Signup from "pages/signup/SignupContainer";
import VerifyEmail from "pages/verifyEmail/VerifyEmailContainer";
import Login from "pages/login/LoginContainer";
import Reset from "pages/resetPassword/ResetPasswordContainer";
import Reset2 from "components/widgets/forms/passwordReset/PasswordResetContainer";
import VerifyReset from "pages/verifyResetToken/VerifyResetTokenContainer";
import Home from "pages/home/Home";
import Error from "pages/errors/404";

// Authenticated Pages
import Dashboard from "pages/dashboard/DashboardContainer";
import Credentials from "pages/credentials/CredentialsContainer";
import Appointments from "pages/appointments/AppointmentContainer";
import Reports_Config from "pages/reportsConfig/Reports_Config";
import View_Report_Group from "pages/viewReportGroup/View_Report_Group";
import Departments from "pages/departments/DepartmentsContainer";
import Users from "pages/users/UsersContainer";
import UserGroups from "pages/userGroups/UserGroupContainer";
import ChatUsers from "pages/chatUsers/Chat_Users";
import SecurityContext from "pages/securityContext/SecurityContextContainer";
import View_Security_Context from "pages/viewSecurityContext/ViewSecurityContextContainer";
import View_Reports from "pages/viewReports/View_Reports";
import View_Users from "pages/viewUsers/ViewUsersContainer";
import View_Department from "pages/viewDepartment/ViewDepartmentContainer";
import ViewUser from "pages/viewUser/ViewUserContainer";
import View_User_Group from "pages/viewUserGroup/ViewUserGroupContainer";
import View_Chat_User from "pages/viewChatUser/View_Chat_User";
import Commissions from "pages/commissions/Commissions";
import TnC from "pages/tnc/TnCContainer";
import Tenders from "pages/tenders/TendersContainer";
import Underwriters from "pages/underwriters/UnderwritersContainer";
import Claims from "pages/claims/ClaimsContainer";

import ViewRenewal from "pages/viewRenewal/View_Renewal";
import ViewRequests1 from "pages/viewRequests1/View_Request";

import Renewals from "pages/renewals/Renewals";

import Requests from "pages/requests/Requests";
import Profession from "pages/profession/ProfessionContainer";

import AssetDetails from "pages/assetDetails/Asset_Details";
import AssetDetails1 from "pages/assetDetails1/Asset_Details";

import Special_Considerations from "pages/Special_Consideration";
import ViewClaim from "pages/viewClaim/ViewClaimContainer";
import Profile from "pages/profile/Profile";

import ViewReports from "pages/viewReports/View_Reports";
import Roles from "pages/roles/RolesContainer";
import ViewRole from "pages/viewRole/ViewRoleContainer";
import Settlement from "pages/settlement/settlement/SettlementContainer";
import Channels from "pages/settlement/channel/ChannelContainer";
import SettlementConfig from "pages/settlement/config/ConfigContainer";
import ConfigDetails from "pages/settlement/config/ConfigDetails";
import SettlementDetails from "pages/settlement/settlement/SettlementDetails";
import ChannelDetails from "pages/settlement/channel/ChannelDetails";
import Reviews from "pages/reviews/ReviewsContainer";
import Chat from "pages/chat/ChatContainer";
import ChatGroup from "pages/chatGroups/ChatGroupContainer";

import RM from "pages/relationshipManager/RMContainer";
import RMDetails from "pages/relationshipManager/RmDetails";
import SalesRep from "pages/salesRepresentative/SalesContainer";
import SalesDetails from "pages/salesRepresentative/SalesDetails";
import Leads from "pages/leads/LeadsContainer";
import LeadDetails from "pages/leads/LeadsDetails";
import ClaimsPending from "pages/claimsPendingApproval/ClaimsPendingContainer";
import ViewClaimPendingContainer from "pages/viewClaimPending/ViewClaimPendingContainer";

import OpenLeads from "pages/openLeads/OpenLeadsContainer";
import IncentivesContainer from "pages/incentives/IncentivesContainer";
import IncentiveDetails from "pages/incentives/IncentiveDetails";

// Quotations
import Quotations from "pages/quotations/QuotationsContainer";
import Quotations1 from "pages/quotation1/QuotationsContainer";
import ViewRequests from "pages/quotations/QuotationRequestDetails";
import QuotationRequests from "pages/quotations/QuotationRequestContainer";
import ViewQuotation from "pages/viewQuotation/ViewQuotationContainer";
import ViewQuotation1 from "pages/viewQuotation1/ViewQuotationContainer";

// policy
import Policies from "pages/policies/PoliciesContainer";
import PolicyDetails from "pages/policies/PolicyDetails";

// account Management
import Wallet from "pages/accountManagement/WalletContainer";
import Tax from "pages/accountManagement/TaxContainer";
import TaxDetails from "pages/accountManagement/TaxDetails";
import Fees from "pages/accountManagement/FeesContainer";
import FeeDetails from "pages/accountManagement/FeeDetails";

// domain
import Domains from "pages/domains/DomainsContainer";

// Scheme Management
import Schemes from "pages/schemeManagement/ListSchemes";
import SchemeDetails from "pages/schemeManagement/SchemeDetails";
import GroupSchemeDetails from "pages/schemeManagement/GroupSchemeDetails";
import UnderwriterDetails from "pages/schemeManagement/UnderwriterRates";
import GroupSchemeManagement from "pages/schemeManagement/ManageGroupScheme";

import NetworkDetector from "services/NetworkDetector";
import Fallback from "shared/errorBoundary/Fallback";

const Kyc = lazy(() => import("pages/kyc/KycContainer"));

const pages = [
  {
    exact: true,
    path: routes.login,
    component: Login,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.dashboard,
    component: Dashboard,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.signup,
    component: Signup,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.home,
    component: Home,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.credentials,
    component: Credentials,
    layout: PublicLayout,
  },

  {
    exact: true,
    path: routes.verify,
    component: VerifyEmail,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.kyc,
    component: Kyc,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.reset,
    component: Reset,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.resetPassword,
    component: VerifyReset,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.reset2,
    component: Reset2,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.underwriters,
    component: Underwriters,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewQuotation,
    component: ViewQuotation,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewQuotation1,
    component: ViewQuotation1,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.assetDetails,
    component: AssetDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.assetDetails1,
    component: AssetDetails1,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.quotations,
    component: Quotations,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.quotations1,
    component: Quotations1,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewClaim,
    component: ViewClaim,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewClaimsPending,
    component: ViewClaimPendingContainer,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewRenewal,
    component: ViewRenewal,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.renewals,
    component: Renewals,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewQuotationRequest,
    component: ViewRequests,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewQuotationRequest1,
    component: ViewRequests1,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewQuotationRequest,
    component: ViewRequests,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.quotationRequests,
    component: QuotationRequests,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.requests,
    component: Requests,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewPolicy,
    component: PolicyDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.policies,
    component: Policies,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.claims,
    component: Claims,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.claimsPending,
    component: ClaimsPending,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.policies,
    component: Policies,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewReportConfig,
    component: ViewReports,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewReportGroup,
    component: View_Report_Group,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewUsers,
    component: View_Users,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.reportConfig,
    component: Reports_Config,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgUsers,
    component: Users,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgViewUserGroup,
    component: View_User_Group,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgRoleView,
    component: ViewRole,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgRoles,
    component: Roles,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgViewDepartment,
    component: View_Department,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgDepartments,
    component: Departments,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.usmgViewUser,
    component: ViewUser,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.usmgUserGroup,
    component: UserGroups,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgViewChat,
    component: View_Chat_User,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgChat,
    component: ChatUsers,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgViewSecurity,
    component: View_Security_Context,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgSecurity,
    component: SecurityContext,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.wallet,
    component: Wallet,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewLeads,
    component: LeadDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.leads,
    component: Leads,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.commissions,
    component: Commissions,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.profession,
    component: Profession,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.profile,
    component: Profile,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.appointments,
    component: Appointments,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgUsers,
    component: Users,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.tnc,
    component: TnC,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.settlementConfig,
    component: SettlementConfig,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.tenders,
    component: Tenders,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.channels,
    component: Channels,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.settlement,
    component: Settlement,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.configDetails,
    component: ConfigDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.settlementDetails,
    component: SettlementDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.channelDetails,
    component: ChannelDetails,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.reviews,
    component: Reviews,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.chat,
    component: Chat,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.usmgChatGroups,
    component: ChatGroup,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.rmDetails,
    component: RMDetails,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.rm,
    component: RM,
    layout: AuthLayout,
  },

  {
    exact: true,
    path: routes.salesrep,
    component: SalesRep,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.viewSalesRep,
    component: SalesDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.taxDetails,
    component: TaxDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.tax,
    component: Tax,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.feeDetails,
    component: FeeDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.fees,
    component: Fees,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.domains,
    component: Domains,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.openLeads,
    component: OpenLeads,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.incentives,
    component: IncentivesContainer,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.incentiveDetails,
    component: IncentiveDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.schemes,
    component: Schemes,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.schemeDetails,
    component: SchemeDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.underwriterRates,
    component: UnderwriterDetails,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.groupSchemeManagement,
    component: GroupSchemeManagement,
    layout: AuthLayout,
  },
  {
    exact: true,
    path: routes.groupSchemeDetails,
    component: GroupSchemeDetails,
    layout: AuthLayout,
  },
];
function App() {
  const history = useHistory();

  return (
    <div className="App">
      <Suspense fallback={<Fallback />}>
        <Switch>
          {pages.map(
            ({ exact, path, component: Component, layout: Layout }, index) => (
              <Route
                key={index}
                exact={exact}
                path={path}
                render={(props) => (
                  <Layout history={history}>
                    <Component {...props} />
                  </Layout>
                )}
              />
            )
          )}
          <Route component={Error} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default NetworkDetector(App);

export const calculateFeeForm = {
  form_title: "",
  form_subtitle: "",
  button_label: "Calculate",
  button_styles: "block btn-blue",
  fields: [
    {
      key: "amount",
      label: "Enter Amount",
      name: "amount",
    },
  ],
};

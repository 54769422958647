import React, { useContext } from "react";

import { Button } from "antd";
import { BiMapPin } from "react-icons/bi";

import Location from "components/widgets/modals/Location";
import { ModalContext } from "context/ModalContext";
import { greeting } from "utils/helpers";
import "assets/css/style.min.css";
import "assets/css/app.min.css";
import CountCard from "components/count_card/CountCardContainer";
import TransactionHistory from "components/transaction_history/TransactionHistoryWrapper";
import RecentRequests from "components/recent_requests/RecentRequestsContainer";

export default function Dashboard({ data }) {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);

  return (
    <>
      <div className="pageheader pd-b-35">
        <div className="pd-t-5 pd-b-5">
          <Button
            className="float-right btn-info d-none d-md-block rounded-5"
            onClick={() => setModalShow(true)}
          >
            <span className="mr-2">
              <BiMapPin size={15} />
            </span>
            Set Location
          </Button>
          <h1 className="pd-0 mg-0 tx-20">
            {greeting()},{""}👋
            <span className="text-primary">
              {console.log("the data", data)}
              {data.intermediary_details.kyc[0].intermediary_names}
            </span>{" "}
          </h1>
        </div>
      </div>
      <Location show={modalShow} onHide={modalClose} />
      <CountCard />

      <div className="row row-xs clearfix">
        <TransactionHistory />
        <RecentRequests />
      </div>
    </>
  );
}

import React, { useState, useContext } from "react";
import { Input, Form, Button, Select } from "antd";
import { add_domains } from "services/apiSrv";
import { useMutation } from "react-query";
import { ModalContext } from "context/ModalContext";
import { show_toast } from "utils/helpers";

export default function RelationshipManager({ users }) {
  const { modalClose } = useContext(ModalContext);

  const [form] = Form.useForm();

  const { Option } = Select;

  const _addDomains = useMutation(add_domains, {
    onSuccess: (data) => {
      modalClose();
      show_toast("Domain added successfully", "success");
    },
    onError: async (error) => {
      let err = await error;
      modalClose();
    },
  });
  const onFinish = (values) => {
    console.log("ZE ZE VALUES", values);
    _addDomains.mutate(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const selectAfter = (
    <Select defaultValue=".com" className="select-after">
      <Option value=".com">.com</Option>
      <Option value=".co.ke">.co.ke</Option>
      <Option value=".org">.org</Option>
    </Select>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      name="add_domains"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Domain Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Please input a domain!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          block
          loading={_addDomains.isLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

import React, { useContext } from "react";

import { Divider, Avatar, Empty, Button, Descriptions } from "antd";
import * as Icon from "react-feather";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";

import { ModalContext } from "context/ModalContext";
import AddSchemeGroup from "components/widgets/modals/AddSchemeGroup";
import AddSchemeDocument from "components/widgets/modals/AddSchemeDocument";
import {
  schemeDetails,
  base_url,
  listSchemeDocuments,
  listSchemeGroup,
} from "services/apiSrv";
import Loader from "components/widgets/loader/PageLoader";
import { iterateObject, fileExtension } from "utils/helpers";
import PdfIcon from "assets/images/pdf.png";
import DocIcon from "assets/images/doc-icon.png";
import FileIcon from "assets/images/file-icon.png";
import ExcelIcon from "assets/images/Excel-Icon.png";
import CustomTable from "components/widgets/table";
import routes from "routes";

const Document = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-gap: 50px;
`;

const DocumentRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;
const DocumentTitle = styled.a`
  line-height: 1.5em;
  font-size: 15px;
  margin-top: 1rem;
`;
const DocumentLogo = styled.img`
  max-width: 70px;
`;

const DocumentItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const DocumentLogoContainer = styled.div`
  display:none ;
  &:hover {
    display:block
    background-color: black;
    width: 100px;
    height: 100px;
    margin: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
  }
`;
const DeleteDocument = styled.div`
  ${DocumentLogoContainer}
  &:hover {
  }
`;

const SchemeDetails = (props) => {
  const { setModalShow, modalShow, modalClose } = useContext(ModalContext);
  const history = useHistory();
  const { schemeId } = useParams();

  const scheme_details = async () => {
    let resp = await schemeDetails(schemeId);
    console.log("THE SCHEME DETAILS", resp);
    return resp.scheme_details;
  };

  const { data, isError, isSuccess, isLoading } = useQuery(
    "scheme details",
    scheme_details
  );
  const schemeRef = data?.system_scheme_ref;

  const scheme_documents = async () => {
    let resp = await listSchemeDocuments(schemeRef);
    console.log("THE SCHEME DOCS", resp);
    return resp.scheme_docs;
  };
  const {
    data: documents,
    isLoading: loadingDocuments,
    isSuccess: isSuccessDocuments,
    refetch: refetchDocuments,
  } = useQuery(
    "scheme documents",
    scheme_documents,

    {
      enabled: !!schemeRef,
    }
  );
  const scheme_groups = async () => {
    let resp = await listSchemeGroup(schemeRef);
    console.log("THE SCHEME GROUPS", resp);
    return resp.scheme_groups;
  };
  const view_scheme_group = (id) => {
    history.push(`/dashboard/schemes/${schemeId}/${id}`);
  };
  const {
    data: groups,
    isLoading: loadingGroups,
    isSuccess: isSuccessGroups,
    refetch: refetchGroups,
  } = useQuery(
    "scheme groups",
    scheme_groups,

    {
      enabled: !!schemeRef,
    }
  );

  const checkExtension = (file_path) => {
    let ext = fileExtension(file_path);
    switch (ext) {
      case "pdf":
        return PdfIcon;
      case "xls":
        return ExcelIcon;
      case "xlsx":
        return ExcelIcon;
      case "doc":
        return DocIcon;
      case "docx":
        return DocIcon;
      default:
        return FileIcon;
    }
  };

  const tableData = {
    icon: "",
    title: "Scheme Group",
    table_fields: [
      { name: "", align: "c" },
      { name: "Name", align: "c" },
      { name: "Description", align: "c" },
      { name: "Policy Mgt Type", align: "c" },
      { name: "Status", align: "c" },
      { name: "Actions", align: "c" },
    ],
    // search_fields: ["question_code", "answer"],
    row_data: [
      { type: "image", field: "scheme_group_logo", class: "bold center" },
      { type: "text", field: "scheme_group_name", class: "small center" },
      {
        type: "text",
        field: "scheme_group_description",
        class: "small center",
      },
      { type: "text", field: "policy_management_type", class: "small center" },
      { type: "text", field: "scheme_group_status", class: "small center" },
      {
        type: "action",
        field: "name",
        class: "center",
        icon: "",
        actions: [
          // {type: 'title', title: 'Actions'},
          {
            type: "menu",
            title: "View",
            field: "system_scheme_group_ref",
            method: view_scheme_group,
          },
          {
            type: "menu",
            title: "Delete",
            event: "table:delete",
            icon: "fa-trash-alt",
          },
        ],
      },
    ],
  };
  return (
    <div>
      {isLoading && loadingDocuments && loadingGroups && <Loader />}
      {isSuccess && isSuccessDocuments && isSuccessGroups && (
        <>
          <div className="pageheader">
            <div className="breadcrumb pd-0 mg-0 pd-b-20">
              <Link className="breadcrumb-item active" to={routes.dashboard}>
                <i className="icon ion-ios-home-outline " /> Home
              </Link>
              <Link className="breadcrumb-item active" to={routes.schemes}>
                Schemes
              </Link>
              <Link className="breadcrumb-item">Details</Link>
            </div>
            <h5 className="text-capitalize">{data.scheme_name} Details</h5>
          </div>
          <div className="card mg-b-20 mg-t-45">
            <div className="card-body">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-center align-items-center">
                    <Avatar
                      size={94}
                      className="mb-3"
                      src={base_url + data.scheme_logo}
                    />
                  </div>
                </div>
                <div className="col-md-10">
                  <Descriptions title="Scheme Details" size="default">
                    <Descriptions.Item label="Scheme Name">
                      {data.scheme_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {data.scheme_status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Registration Date"></Descriptions.Item>
                    <Descriptions.Item label="Description">
                      {data.scheme_description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Other Details">
                      {iterateObject(data.scheme_details) || "null"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Emails">
                      <ul>
                        {data.scheme_email.map(({ name, email }, i) => (
                          <li key={i}>
                            <Icon.Check color="green" size={12} />
                            <i className="pl-2">
                              {name}~ <span>{email}</span>
                            </i>{" "}
                            :
                          </li>
                        ))}
                      </ul>
                    </Descriptions.Item>
                    <Descriptions.Item label="Contacts">
                      <ul>
                        {data.scheme_contacts.map(({ names, contacts }, i) => (
                          <li key={i}>
                            <Icon.Check color="green" size={12} />
                            <i className="pl-2">
                              {names}~ <span>{contacts}</span>
                            </i>{" "}
                            :
                          </li>
                        ))}
                      </ul>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
          </div>
          <div class="card mg-b-20">
            <div class="card-body">
              <div className="clearfix">
                <a
                  href
                  className="float-right"
                  onClick={() => setModalShow("scheme_doc")}
                >
                  Add Document
                </a>
              </div>
              {documents.length === 0 && (
                <div className="d-flex justify-content-center">
                  <Empty description="No documents availabe" />
                </div>
              )}

              <Document>
                <DocumentRow>
                  {documents.map((document) => (
                    <DocumentItem>
                      <>
                        <DocumentLogo
                          src={checkExtension(document.doc_path)}
                          alt="document"
                        />
                        <DocumentLogoContainer>
                          <DeleteDocument>Delete</DeleteDocument>
                        </DocumentLogoContainer>
                      </>
                      <DocumentTitle
                        href={base_url + "/" + document.doc_path}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {document.doc_name}
                      </DocumentTitle>
                      <p>{document.doc_description}</p>
                    </DocumentItem>
                  ))}
                </DocumentRow>
              </Document>
            </div>
          </div>
          <div className="clearfix">
            <Button
              className="btn-primary btn-blue rounded mb-3 float-right text-white "
              onClick={() => setModalShow("scheme_group")}
            >
              Add Group
            </Button>
          </div>
          <CustomTable
            table_fields={tableData.table_fields}
            row_data={tableData.row_data}
            table_data={groups}
            title={tableData.title}
          />

          {/* modals */}
          <AddSchemeGroup
            show={modalShow === "scheme_group" ? true : false}
            onHide={modalClose}
            scheme_id={schemeId}
            refetch={refetchGroups}
          />
          <AddSchemeDocument
            show={modalShow === "scheme_doc" ? true : false}
            onHide={modalClose}
            scheme_ref={data.system_scheme_ref}
            refetch={refetchDocuments}
          />
        </>
      )}
    </div>
  );
};

export default SchemeDetails;

import React, {useContext } from 'react'
import './map.css'
import PlacesAutocomplete,{
geocodeByAddress,
getLatLng
} from 'react-places-autocomplete';
import {DashboardContext} from '../../context/DashboardContext'

const Search =({panTo})=> {

const context = useContext(DashboardContext)
const {address, setAddress,coordinates, setCoordinates}= context


  const handleChange = address => {
    setAddress(address)
  };

  const handleSelect = async (value) => {
   
        try{
            const results = await geocodeByAddress(value)
            // get the lat and lng of the  result user has selected
            const {lat, lng} = await getLatLng(results[0])
            setCoordinates({...coordinates,lat:lat,lng:lng})
            setAddress(value)
           
            panTo({lat, lng})
        }catch(error){

        }
    }
 

    return (
      <div className="canvas">
      <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div  className="search">
          <input
            {...getInputProps({
              placeholder: 'Search Location ...',
              className: 'location-search-input ',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,i) => {
              
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#f5f5f5', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div className="input-suggestion" key={i}
                  {...getSuggestionItemProps(suggestion, {
                    
                    style,
                  })}
                >
                <i className="material-icons" style={{color:"de4228"}}>location_on  </i> <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
      </div>
    )
  
}

export default Search
import React from "react";

import { Link } from "react-router-dom";

import "assets/css/dashboard.css";
import logo from "assets/images/logos/Logo_1.png";
import TnC from "assets/docs/Nexus General Terms and Conditions 27.7.2020.pdf";
import "./login.css";
import { loginForm } from "pages/login/form/Login_Form";
import CustomForm from "components/widgets/forms/CustomForm";
import LoginSchema from "pages/login/validations/LoginSchema";

const Login = ({
  requests,
}) => {
  const onSubmit = (model, setSubmitting ) => {
    requests(model);
  };

  return (
    <>
      <div className="d-md-flex d-sm-block">
        <div className="col-md-12 k col-sm-12 " id="login-background">
          <div
            style={{
              minHeight: "100%",
              paddingBottom: "40px",
              marginBottom: "-40px",
            }}
          >
            <div className="">
              <Link to="/" className="">
                <img
                  src={logo}
                  style={{
                    height: "30px",
                    width: "auto",
                    position: "absolute",
                    top: "20px",
                  }}
                  alt="Logo"
                />
              </Link>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "10%",
                }}
              >
                <h1 className="text-white">Welcome back to Nexus.</h1>
                <h6 className="text-white d-block">
                  sign in to access your portal
                </h6>
              </div>
              <div
                className=""
                style={{
                  position: "fixed",
                  right: "40px",
                  bottom: "0",
                }}
              >
                <a
                  href={TnC}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-white"
                >
                  Terms and conditions |
                </a>
                <Link to="#" className=" text-white">
                  {" "}
                  &copy; {new Date().getFullYear()} Nexus
                </Link>
              </div>

              <div className="card login__card__position">
                <div className="card-body p-md-5">
                  <img
                    src={logo}
                    alt="Logo"
                    className="d-md-none mobile__logo"
                  />
                  <CustomForm
                    className="form mt-md-0 mt-4"
                    validationSchema={LoginSchema}
                    model={loginForm}
                    onSubmit={(model) => {
                      onSubmit(model);
                    }}
                  />
                  <p className="">
                    Don't have an account yet?{" "}
                    <Link to="/signup" className="custom-text-primary">
                      <small>Sign Up</small>
                    </Link>
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;

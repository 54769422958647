import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../../assets/css/dashboard.css";
import { Button, Form, Select, Input } from "antd";
import { useMutation } from "react-query";
import { show_toast } from "utils/helpers";
import { ModalContext } from "context/ModalContext";
import { incentify_lead } from "services/apiSrv";
import routes from "routes";
import { useHistory } from "react-router-dom";

const CloseRemarks = (props) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { modalClose } = useContext(ModalContext);
  const { Option } = Select;
  const history = useHistory();

  const _incentify = useMutation(incentify_lead, {
    onSuccess: (data) => {
      modalClose();
      show_toast("Success", "success");
      history.push(routes.leads);
    },
    onError: async (error) => {
      modalClose();
    },
  });

  const onFinish = (values) => {
    let data = {
      lead_ref: props.lead_ref,
      incentive_amount: values.amount,
      incentive_currency: values.currency,
      incentive_remarks: values.remarks,
    };
    _incentify.mutate(data);
  };
  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="text-grey">Incentify Lead</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item
              label="Enter amount"
              name="amount"
              rules={[{ required: true, message: "Amount is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Select Currency"
              name="currency"
              rules={[{ required: true, message: "Select a Currency" }]}
            >
              <Select>
                <Option value="KES">KES</Option>
                <Option value="USD">USD</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Remarks"
              name="remarks"
              rules={[{ required: true, message: " Remarks are required" }]}
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={_incentify.isLoading}
              >
                {_incentify.isLoading ? (
                  <div className="text-white">
                    <i
                      className="fa fa-circle-notch fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                    Loading..
                  </div>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CloseRemarks;

import React, { useState, createContext, useContext, useEffect } from 'react';
import { AlertsContext } from './AlertsContext';

const AuthContext = createContext();
const AuthContextProvider = (props) => {
	const { setShowAlert, showAlert } = useContext(AlertsContext);

	// STATES

	const [alert, setAlert] = useState({
		message: '',
		variant: '',
	});

	const [confirmimgToken, setConfirmingToken] = useState(false);

	const [registrationId, setRegistrationId] = useState('');

	const [credentials, setCredentials] = useState({
		registration_setup_id: sessionStorage.getItem('registration_id'),
		firstName: '',
		lastName: '',
		username: localStorage.getItem('REG_EMAIL'),
		contact: '',
		password: '',
		confirmPassword: '',
		tnc: false,
		showPassword: false,
		showConfirmPassword: false,
		loading: false,
		error: '',
	});

	const [user, setUser] = useState({
		username: '',
		password: '',
		showPassword: false,
		rememberMe: false,
		loading: false,
	});

	const [newUser, setNewUser] = useState({
		ira_code: '',
		category: '',
		email: '',
		loading: false,
	});

	const [info, setInfo] = useState();

	const [forgotUsername, setForgotUsername] = useState({
		username: '',
		loading: false,
	});

	const [otp, setOtp] = useState({
		code: '',
		loading: false,
	});

	// METHODS

	useEffect(() => {
		if (showAlert) {
			setTimeout(() => {
				setShowAlert(false);
			}, 5000);
		}
	}, [alert, setShowAlert, showAlert]);

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				registrationId,
				confirmimgToken,
				alert,
				setAlert,
				info,
				setInfo,
				newUser,
				setNewUser,
				credentials,
				setCredentials,
				setConfirmingToken,
				setRegistrationId,
				forgotUsername,
				setForgotUsername,
				otp,
				setOtp,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

const AuthConsumer = AuthContext.Consumer;
export { AuthContextProvider, AuthConsumer, AuthContext };
